<template>
  <MainDialog :persistent="false" :show="show" :on-close="onClose">
    <v-card light>
      <v-card-title>Reproduce Order {{ orderId }}</v-card-title>
      <v-card-text style="min-height: 400px">
        <StateDisplay
          :loading="loading"
          :error="!!errorMessage"
          :error-message="errorMessage"
          v-if="loading || error"
        />
        <div v-else>
          <v-row>
            <v-col
              v-for="orderTask in order.tasks"
              cols="12"
              md="6"
              :key="orderTask.taskUid"
            >
              <v-card>
                <v-card-title>{{
                  orderTask.task.printVariantName
                }}</v-card-title>
                <v-card-text>
                  <v-img
                    :src="orderTask.task.finalTemplateThumbnailUrl"
                    height="300"
                    contain
                  />
                </v-card-text>
                <v-card-actions>
                  <v-select
                    :value="selectedQuantities[orderTask.taskUid] || 0"
                    @change="
                      (value) =>
                        selectQuantityToReproduce(value, orderTask.taskUid)
                    "
                    filled
                    :items="getQuantityOptions(orderTask.quantity)"
                  ></v-select>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="onClose">Cancel</v-btn>
        <v-spacer />
        <v-btn
          :loading="isFulfilling"
          :disabled="Object.keys(selectedQuantities).length === 0 || isFulfilling"
          @click="sendToFulfill"
          color="warning"
          >Send To Fulfill</v-btn
        >
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import { db } from "../../../../fb";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import StateDisplay from "@/components/StateDisplay";
import {orderStatus} from "@/constants";
export default {
  name: "ReproduceOrderDialog",
  components: { StateDisplay, MainDialog },
  data() {
    return {
      loading: false,
      error: false,
      errorMessage: "",
      order: null,
      selectedQuantities: {},
      isFulfilling: false
    };
  },
  props: {
    show: {
      type: Boolean,
      require: true,
    },
    onClose: {
      required: false,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.loadOrder();
  },
  methods: {
    async loadOrder() {
      this.loading = true;
      const ordersCollectionRef = collection(db, "orders");
      const orderDoc = doc(ordersCollectionRef, this.orderId);
      const orderRes = await getDoc(orderDoc);
      const order = orderRes.data();
      const tasks = [];
      for await (const orderTask of order.tasks) {
        const tasksCollectionRef = collection(db, "tasks");
        const taskDoc = doc(tasksCollectionRef, orderTask.taskUid);
        const taskRes = await getDoc(taskDoc);
        const task = taskRes.data();

        tasks.push({
          ...orderTask,
          task: task,
        });
      }
      this.order = {
        ...order,
        tasks,
      };
      console.log({
        ...order,
        tasks,
      });
      this.loading = false;
    },
    selectQuantityToReproduce(value, orderUid) {
      this.selectedQuantities = {
        ...this.selectedQuantities,
        [orderUid]: value,
      };
    },
    getQuantityOptions(quantity) {
      return Array.from({ length: quantity + 1 }, (_, i) => i);
    },
    async sendToFulfill() {
      this.isFulfilling = true
      try {
        const ordersCollectionRef = collection(db, "orders");
        const orderDoc = doc(ordersCollectionRef, this.orderId);
        const orderRes = await getDoc(orderDoc)
        const orderData = orderRes.data()
        await setDoc(orderDoc, {
          orderName: orderData.orderName + '_REV',
          isRevision: true,
          fulfillTime: Date.now(),
          status: orderStatus.WAITING_FOR_FULFILL,
          tasks: orderData.tasks.map(t => {
            if (this.selectedQuantities[t.taskUid] && this.selectedQuantities[t.taskUid] >= 1) {
              return {
                ...t,
                quantity: this.selectedQuantities[t.taskUid],
                fulfilled: false,
              }
            } else {
              return t
            }
          })
        }, { merge: true })
        this.isFulfilling = false
        this.onClose()
      } catch (e) {
        this.isFulfilling = false
        alert(e)
      }

    },
  },
};
</script>

<style scoped></style>
