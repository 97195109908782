<template>
  <div>
    <v-text-field
        v-model="search"
        label="Search Orders"
        class="mb-4"
        append-icon="mdi-magnify"
        single-line
        outlined
        hide-details
    ></v-text-field>

    <v-data-table
        :headers="headers"
        :items="filteredOrders"
        :search="search"
        class="elevation-1"
    >
      <template v-slot:item.variantNames="{ item }">
        <span>{{ item.variantNames.join(", ") }}</span>
      </template>
      <template v-slot:item.productionCosts="{ item }">
        <span>{{ formatCurrency(item.productionCosts) }}</span>
      </template>
      <template v-slot:item.shippingCost="{ item }">
        <span>{{ formatCurrency(item.shippingCost) }}</span>
      </template>
      <template v-slot:item.totalCost="{ item }">
        <span>{{ formatCurrency(item.totalCost) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "FulfilledOrderList",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    headers() {
      return [
        { text: "Order Name", value: "orderName" },
        { text: "Fulfiller", value: "fulfiller" },
        { text: "Variant Names", value: "variantNames" },
        { text: "Fulfiller ID", value: "idFromFulfiller" },
        { text: "Production Costs", value: "productionCosts" },
        { text: "Shipping Costs", value: "shippingCost" },
        { text: "Total Costs", value: "totalCost" }
      ];
    },
    allOrders() {
      const orders = [];
      for (const [fulfillerKey, fulfiller] of Object.entries(this.data.actualPaid)) {
        for (const region of Object.values(fulfiller)) {
          for (const regionData of Object.values(region)) {
            for (const order of regionData.orders) {
              orders.push({
                orderName: order.orderName,
                fulfiller: fulfillerKey, // Store the fulfiller key as the fulfiller name
                variantNames: order.fulfilledTasks.map(task => task.variantName),
                idFromFulfiller: order.idFromFulfiller,
                productionCosts: order.productionCosts,
                shippingCost: order.shippingCost,
                totalCost: order.productionCosts + order.shippingCost
              });
            }
          }
        }
      }
      return orders;
    },
    filteredOrders() {
      return this.allOrders;
    }
  },
  methods: {
    formatCurrency(value) {
      return `${value.toFixed(2)}€`;
    }
  }
};
</script>

<style scoped lang="scss">
.text-left {
  text-align: left;
}
</style>
