<template>
  <div>
    <v-card @contextmenu="onRightClick" color="primary" class="card">
      <div class="card-header-function">
        <span class="task-status">{{ task.status }}</span>
        <v-btn
          color="primary"
          dark
          rounded
          link
          class="card-header-button"
          @click="openInShopify"
        >
          {{ task.orderName }}
          <v-icon class="ml-3">mdi-open-in-new</v-icon>
        </v-btn>
        <FeedbackTooltip
          v-if="task.feedback && isAdmin"
          :feedback="task.feedback"
        />
        <OrderNoteTooltip v-if="isAdmin" :task="task" />
        <SupportDialog v-if="isAdmin" :order-id="task.orderId" />
      </div>
      <v-card-text>
        <ExpressLayover :task="task"/>
        <v-img
          width="100%"
          height="500px"
          :src="
            task.finalTemplateThumbnailUrl ||
            task.resultThumbnailUrl ||
            task.thumb
          "
          class="image"
          alt="Task Image"
          :contain="!!task.finalTemplateThumbnailUrl"
        />
        <div :style="{ 'background-color': referrerBackgroundColor}" v-if="task.referrer" class="task-referrer">
          {{ task.referrer }}
        </div>
        <div class="task-product-name">
          {{ task?.productType?.name || "Product unknown" }}<br />
          {{
            task?.customPrintVariantName ||
            task?.printVariantName ||
            "Print Variant unknown"
          }}
          {{task.express ? ' (Express)' : ''}}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="showTaskDetailsDialog = true" dark>View Details</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <ListMenu :show="showMenu" :x="x" :y="y" :list-items="menuItems" />
    <PushTaskBackDialog
      v-if="showPushTaskBackDialog"
      :show="showPushTaskBackDialog"
      :on-close="
        () => {
          showPushTaskBackDialog = false;
        }
      "
      :task-uid="task.uid"
      :status="['DRAW', 'TEMPLATING', 'REVIEW']"
    />
    <TaskDetailsDialog
      :show="showTaskDetailsDialog"
      :on-close="() => (showTaskDetailsDialog = false)"
      :task="task"
    />
    <OrderMailDialog
      v2
      v-if="showMailDialog"
      :show="showMailDialog"
      :on-close="() => (this.showMailDialog = false)"
      :orderId="task.orderId.toString()"
      :mail-template-type="mailTemplateType"
      :wait-for-sent-success="false"
      mail-category="ALL"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {downloadImageFromUrl, getRandomColor, getTrackingUrl, openOrderInShopify} from "@/utils";
import ListMenu from "@/components/ListMenu";
import PushTaskBackDialog from "@/components/PushTaskBackDialog";
import TaskDetailsDialog from "@/components/TaskCards/components/TaskDetailsDialog";
import FeedbackTooltip from "@/components/FeedbackTooltip";
import OrderNoteTooltip from "@/components/OrderNoteTooltip";
import { mailTemplateTypes } from "@/content/mails";
import OrderMailDialog from "@/components/OrderMailDialog";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../fb";
import {EventBus, events} from "../../../event-bus";
import {statisticsColors} from "@/config";
import SupportDialog from "@/components/Support/SupportTooltip";
import ExpressLayover from "@/components/TaskCards/ExpressLayover";

export default {
  name: "TaskDetailsCard",
  components: {
    ExpressLayover,
    SupportDialog,
    OrderMailDialog,
    OrderNoteTooltip,
    FeedbackTooltip,
    TaskDetailsDialog,
    PushTaskBackDialog,
    ListMenu,
  },
  data() {
    return {
      isUploading: false,
      isDownloaded: false,
      uploaded: false,
      finalTemplateFile: null,
      templatePreviewUrl: "",
      showHelpDialog: false,
      showTaskDetailsDialog: false,
      showMailDialog: false,
      x: 0,
      y: 0,
      showMenu: false,
      showPushTaskBackDialog: false,
      menuItems: [
        {
          icon: "mdi-email",
          onClick: () => (this.showMailDialog = true),
          label: "Send Mail",
        },
        {
          icon: "mdi-email-search-outline",
          onClick: this.searchOrderInMails,
          label: "Search In Mails",
        },
        {
          icon: "mdi-clipboard-arrow-right",
          onClick: this.copyOrderTrackingUrl,
          label: "Copy Order Tracking URL",
        },
        {
          icon: "mdi-alpha-t-box",
          onClick: this.openInTeeInBlue,
          label: "Open In TeeInBlue",
        },
        {
          icon: "mdi-thumbs-up-down",
          onClick: this.resolveThumbUpDown,
          label: "Resolve Feedback",
          hide: !this.task.feedback
        },
        {
          icon: "mdi-download",
          onClick: this.downloadOriginalImage,
          label: "Download Original Image",
        },
        {
          icon: "mdi-download",
          onClick: this.downloadResultImage,
          label: "Download Result Image",
        },
        {
          icon: "mdi-download",
          onClick: this.downloadTemplate,
          label: "Download Template",
        },
        {
          icon: "mdi-download",
          onClick: this.downloadFinalTemplate,
          label: "Download Final Template",
        },
        {
          icon: "mdi-mailbox-up",
          onClick: this.mailResults,
          label: "Mail Results",
        },
        {
          icon: "mdi-skip-backward",
          onClick: () => {
            this.showPushTaskBackDialog = true;
          },
          label: "Push Back",
        },
      ],
    };
  },
  props: {
    task: {
      required: true,
    },
  },
  created() {},
  computed: {
    ...mapGetters(["currentUser"]),
    fileNameMatches() {
      if (!this.finalTemplateFile) return true;
      const expectedFileName = `${this.task.uid}_temp.png`;
      return expectedFileName === this.finalTemplateFile.name;
    },
    isAdmin: (state) => state.currentUser?.permissions.includes("ADMIN"),
    mailTemplateType() {
      return mailTemplateTypes.ALL;
    },
    referrerBackgroundColor() {
      return statisticsColors?.[this.task.referrer] || getRandomColor(this.task.referrer)
    }
  },
  methods: {
    ...mapActions(["uploadFinalTemplate", "sendResultsToCustomer"]),
    ...mapMutations(["disableShowDownloadHelp"]),
    downloadFiles() {
      if (this.showDownloadHelp) {
        this.showHelpDialog = true;
        this.disableShowDownloadHelp();
      } else {
        downloadImageFromUrl(this.task.result, this.task.uid);
        this.isDownloaded = true;
      }
    },
    copyOrderTrackingUrl() {
      navigator.clipboard.writeText(getTrackingUrl(this.task.countryCode, this.task.orderKey))
    },
    openInShopify() {
      openOrderInShopify({
        orderId: this.task.orderId,
        countryCode: this.task.countryCode,
        shopId: this.task.shopId,
      });
    },
    async resolveThumbUpDown() {
      await setDoc(
        doc(db, `tasks`, this.task.uid),
        { feedback: null },
        {
          merge: true,
        }
      );
      EventBus.$emit(events.SNACKBAR, {type: 'success', message: 'Resolved Feedback'})
    },
    onRightClick(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    downloadFinalTemplate() {
      downloadImageFromUrl(
        this.task.finalTemplate,
        this.task.uid,
        () => {},
        "jpg"
      );
    },
    downloadTemplate() {
      const adjustedTemplateURL = this.task.template.replace('teeinblue-cdn.nyc3.digitaloceanspaces.com', 'cdn.teeinblue.com');
      downloadImageFromUrl(adjustedTemplateURL, this.task.uid, () => {}, "jpg");
    },
    downloadResultImage() {
      downloadImageFromUrl(this.task.result, this.task.uid);
    },
    downloadOriginalImage() {
      downloadImageFromUrl(this.task.image, this.task.uid);
    },
    searchOrderInMails() {
      window.open(
        `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${
          this.task.orderName.split("#")[1]
        }`,
        "_blank"
      );
    },
    openInTeeInBlue() {
      const url =
        "https://portal.teeinblue.com/orders/?s=" +
        encodeURIComponent(this.task.orderName);
      window.open(url, "_blank");
    },
    mailResults() {
      this.sendResultsToCustomer({
        taskUid: this.task.uid,
        template: this.task.finalTemplate,
        orientation: null,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  overflow: hidden;

  .v-card__text {
    padding: 0;
  }
}

.card-header-function {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    margin: 12px;
  }
}

.image {
  z-index: 1;
  object-fit: cover;
}

.task-status {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: bold;
  background: #e0e0e0;
  padding: 6px;
  border-radius: 5px;
}

.task-referrer {
  padding: 6px;
  border-radius: 5px;
  position: absolute;
  background: rgba(63, 104, 150, 0.76);
  color: white;
  font-weight: bold;
  max-width: 50%;
  bottom: 78px;
  margin: 10px;
  z-index: 2;
}

.task-product-name {
  padding: 6px;
  border-radius: 5px;
  position: absolute;
  background: rgba(0, 0, 0, 0.87);
  max-width: 50%;
  color: white;
  bottom: 60px;
  margin: 10px;
  z-index: 2;
}
</style>
