<template>
  <div class="rating-container">
    <StateDisplay
      class="mt-12"
      v-if="loading || error"
      :loading="loading"
      :error="error"
      :no-results="ratings.length === 0"
    />
    <div v-else>
      <h1 class="mb-3">Bad Ratings <v-icon color="error">mdi-thumb-down</v-icon></h1>
      <v-row>
        <v-col
          cols="12"
          lg="4"
          md="6"
          :key="rating.taskUid"
          v-for="rating in goodRatings"
        >
          <RatingCardAdmin
            @feedbackShared="setRatingToShared"
            :rating="rating"
          />
        </v-col>
      </v-row>
      <h1 class="mt-12 mb-3">Good Ratings <v-icon color="success">mdi-thumb-up</v-icon></h1>
      <v-row>
        <v-col
          cols="12"
          lg="3"
          md="4"
          sm="6"
          :key="rating.taskUid"
          v-for="rating in badRatings"
        >
          <RatingCardAdmin
            @feedbackShared="setRatingToShared"
            :rating="rating"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {db} from "../../../fb";
import StateDisplay from "@/components/StateDisplay";
import {mapGetters} from "vuex";
import RatingCardAdmin from "@/components/AllRatings/RatingCardAdmin";
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";

export default {
  name: "AllRatingsAdmin",
  components: {RatingCardAdmin, StateDisplay},
  data() {
    return {
      ratings: [],
      loading: true,
      error: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapGetters(["userNameIdMap"]),
    goodRatings() {
      return this.ratings.filter(filter => !filter.satisfied)
    },
    badRatings() {
      return this.ratings.filter(filter => filter.satisfied)
    }
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const tasksCollection = collection(db,'tasks')
        const ratingsQuerySatisfied = query(
          tasksCollection,
          where('feedback.hasComment', '==', true),
          where('feedback.satisfied', '==', true),
          orderBy('feedback.timestamp', 'desc'),
          limit(200)
        );
        const ratingsQueryUnsatisfied = query(
          tasksCollection,
          where('feedback.satisfied', '==', false),
          orderBy('feedback.timestamp', 'desc'),
          limit(100)
        );

        const ratingsSatisfiedRes = await getDocs(ratingsQuerySatisfied)
        const ratingsUnsatisfiedRes = await getDocs(ratingsQueryUnsatisfied)

        const ratingsRes = [...ratingsSatisfiedRes.docs, ...ratingsUnsatisfiedRes.docs]

        ratingsRes.forEach(doc => {
          const task = doc.data()

          this.ratings.push({
            taskUid: task.uid,
            orderId: task.orderId,
            orderName: task.orderName,
            artistId: task.editors.DRAW || null,
            satisfied: task.feedback.satisfied,
            timestamp: task.feedback.timestamp,
            shared: task.feedback.shared || false,
            comment: task.feedback.comment,
            result: task.result,
            resultThumbnailUrl: task.resultThumbnailUrl,
            result2: task.result2,
            resultThumbnailUrl2: task.resultThumbnailUrl2,
            countryCode: task.countryCode,
            mockupImage: task.mockupImage,
            task: task
          })
        })
      } catch (e) {
        this.errorMessage = "There was an Error Loading the Ratings";
        console.log(e)
        this.error = true;
      }
      this.loading = false;
    },
    getUsername(artistId) {
      const user = this.userNameIdMap.find((user) => user.uid === artistId);
      return user?.username || "unknown";
    },
    setRatingToShared(taskUid) {
      this.ratings = this.ratings.map((rating) => {
        if (rating.taskUid === taskUid) {
          return {
            ...rating,
            shared: true,
          };
        } else {
          return rating;
        }
      });
    },
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.rating-container {
  min-height: 500px;
}
</style>
