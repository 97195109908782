<template>
  <div class="order-details-modal">
    <v-dialog @click:outside="close" class="modal" v-if="show" v-model="show" :on-close="close">
      <v-card>
        <v-card-title>
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <component v-if="show && modals[selectedModal]" :is="modals[selectedModal]" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrderView from "@/views/OrderView";
export default {
  name: "RouteModals",
  data() {
    return {
      show: false,
      selectedModal: null,
      modals: {
        viewOrderDetails: OrderView
      }
    }
  },
  watch: {
    $route(to, from) {
      this.checkModal()
    }
  },
  methods: {
    close() {
      this.$router.push({ path: this.$route.path, query: {} });
    },
    checkModal() {
      if (this.$route.query.modal && this.modals[this.$route.query.modal]) {
        this.show = true
        this.selectedModal = this.$route.query.modal
      } else {
        this.show = false
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.order-details-modal {
  z-index: 99;
}
</style>
