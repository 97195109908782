<template>
  <div class="mb-6">
    <FulfillOrders />
  </div>
</template>

<script>
import FulfillOrders from "@/components/FulfillOrders/FulfillOrders";
export default {
  name: "FulfillOrdersView",
  components: { FulfillOrders },
};
</script>
