<template>
  <div @mouseenter="onExpand" @mouseleave="onCollapse">
    <v-navigation-drawer
      class="nav-container"
      top
      expand-on-hover
      permanent
      fixed
      clipped
    >
      <v-list class="mt-3" dense nav>
        <NavigationItem
          v-for="route in routesTop"
          :key="route.path"
          :route="route"
        />
        <div class="settings">
          <NavigationItem
            v-for="route in routesBottom"
            :key="route.path"
            :route="route"
          />
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import NavigationItem from "@/components/MainNavigation/NavigationItem";

export default {
  name: "MainNavigation",
  components: { NavigationItem },
  data() {
    return {
      menuItems: [],
    };
  },
  created() {
    setTimeout(() => {
      // console.log(this.$router.getRoutes());
    }, 500);
  },
  computed: {
    ...mapGetters(["navigationItems", "tasksState"]),
    routesTop() {
      return this.navigationItems.filter((route) => !route.meta.navShowBottom);
    },
    routesBottom() {
      return this.navigationItems.filter((route) => route.meta.navShowBottom);
    },
  },
  methods: {
    ...mapMutations(["setNavigationExpanded"]),
    onExpand() {
      this.setNavigationExpanded(true);
    },
    onCollapse() {
      this.setNavigationExpanded(false);
    },
    getCountForState(status) {
      return this.tasksState[status].tasks.length;
    },
  },
};
</script>

<style lang="css" scoped>
.nav-container {
  margin-top: 60px;
}
.settings {
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: 8px;
}
</style>
