<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div
        :style="`background-color: ${indicatorColor}`"
        class="time-indicator-container"
        v-bind="attrs"
        v-on="on"
      >
        <div class="time-indicator-text">{{ daysAfterCreation }}</div>
      </div>
    </template>
    <span>Task is {{ daysAfterCreation }} days old.</span>
  </v-tooltip>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TimeIndicator",
  props: {
    creationDate: {
      type: String,
      require: true,
    },
  },
  computed: {
    daysAfterCreation() {
      return Math.round(dayjs().diff(dayjs(this.creationDate), "days", true));
    },
    indicatorColor() {
      switch (this.daysAfterCreation) {
        case 0:
        case 1:
          return "rgba(181,239,118,0.8)";
        case 2:
          return "rgba(239,223,118,0.8)";
        case 3:
          return "rgba(239,172,118,0.8)";
        default:
          return "rgb(239,118,118)";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.time-indicator-container {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  user-select: none;
}
.time-indicator-text {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
</style>
