import { EventBus, events } from "../event-bus";
import { shopifyNameByShopId } from "@/constants";
import { jsPDF } from "jspdf";
import Vue from "vue";

import dayjs from "dayjs";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import { appSettings } from "@/config";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export const downloadFileFromUrl = async (url, filename, setProgress) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const totalSize = response.headers.get("content-length");
    const contentType = response.headers.get("content-type");
    const reader = response.body.getReader();
    let loaded = 0;
    const chunks = [];

    const processStream = async () => {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);
        loaded += value.length;
        if (setProgress && totalSize) {
          setProgress(Math.round((loaded / totalSize) * 100));
        }
      }
    };

    const downloadPromise = processStream();

    await downloadPromise;

    const blob = new Blob(chunks, { type: contentType });

    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();

    if (setProgress) {
      setProgress(100);
    }
  } catch (error) {
    console.error("Error downloading file:", error);
    // Handle error appropriately
  }
};

export const getDownloadUrlFromPath = async (path) => {
  const storage = getStorage();
  const storageRef = ref(storage, path);

  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error("Error fetching download URL:", error);
    throw error;
  }
};

export const downloadImageFromUrlAsPdf = async (imageUrl, filename) => {
  try {
    // Preload image
    const imageResponse = await fetch(imageUrl);
    const imageBlob = await imageResponse.blob();
    const imageUrlObject = URL.createObjectURL(imageBlob);

    // Get image dimensions
    const img = new Image();
    img.src = imageUrlObject;
    await img.decode(); // Wait for the image to be decoded

    const widthInPoints = img.width * (72 / 96);
    const heightInPoints = img.height * (72 / 96);

    // Create PDF object in parallel
    const pdfPromise = new Promise((resolvePdf) => {
      const doc = new jsPDF({
        orientation: widthInPoints > heightInPoints ? "l" : "p",
        unit: "pt",
        format: [widthInPoints, heightInPoints],
      });

      doc.addImage(imageUrlObject, "PNG", 0, 0, widthInPoints, heightInPoints);
      doc.save(filename + ".pdf");
      resolvePdf();
    });

    // Wait for both image and PDF preparation to finish
    await Promise.all([pdfPromise, img.decode()]);
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

export const downloadImageFromUrl = async (
  url,
  filename,
  setProgress,
  fileType = "png"
) => {
  const name = filename + "." + fileType;
  await downloadFileFromUrl(url, name, setProgress);
};

export const getImageUrl = (path) => {
  return `https://storage.googleapis.com/${process.env.VUE_APP_FB_STORAGE_BUCKET}/${path}`;
};

export const showSnackbar = ({ message, type }) => {
  EventBus.$emit(events.SNACKBAR, {
    message: message,
    type: type,
  });
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}

export function getDataUri(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString();
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}

export const currentMonthYear = `${dayjs().month() + 1}_${dayjs().year()}`;

export const openOrderInShopify = ({ orderId, countryCode, shopId }) => {
  window.open(
    `https://admin.shopify.com/store/${
      shopifyNameByShopId[shopId][countryCode || "DE"] //TODO Delete default DE in future when for almost all tasks a country code exsists
    }/orders/${orderId}`,
    "_blank"
  );
};

export const convertObjectToArray = (obj) => {
  if (!obj) return [];
  return Object.entries(obj).map(([key, value]) => {
    return { ...value, key };
  });
};

export const copyToClipboard = (data) => {
  // Create a temporary textarea element
  const text =
    typeof data === "object" ? JSON.stringify(data, null, 2) : String(data);

  const textarea = document.createElement("textarea");
  textarea.value = text;

  // Append the textarea to the document
  document.body.appendChild(textarea);

  // Select the text within the textarea
  textarea.select();

  // Copy the selected text to the clipboard
  document.execCommand("copy");

  // Remove the temporary textarea from the document
  document.body.removeChild(textarea);
};

export const getDateFromNow = (date) => {
  return dayjs(date).fromNow();
};

export const generateRandom5Digits = () => {
  return Math.floor(Math.random() * 90000) + 10000;
};

export const getDecimalNumberString = (number, fraction = 0) => {
  return number.toLocaleString("de-DE", {
    style: "decimal",
    minimumFractionDigits: fraction,
  });
};

export const getRandomColor = (str) => {
  if (!str) {
    // Generate a random color
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (char + (hash << 5) - hash) & 0xffffff;
  }

  return "#" + ("00000" + hash.toString(16)).slice(-6);
};

export const getRateColor = (optimum, suboptimum, val) => {
  const value = parseFloat(val.toString());
  const colorStops = [
    "#ff8787",
    "#ffa174",
    "#f8cf76",
    "#efeb6f",
    "#e3f67a",
    "#cff67a",
    "#acee7a",
  ];

  const position = (value - suboptimum) / (optimum - suboptimum);
  const index = Math.min(
    Math.floor(position * (colorStops.length - 1)),
    colorStops.length - 1
  );

  return colorStops[index];
};

export function limitString(str, limit = 30) {
  if (str.length > limit) {
    return str.substring(0, limit - 3) + "...";
  }
  return str;
}

export async function convertAndUploadPngUrlToFirebase(
  imageUrl,
  quality = 1.0,
  backgroundColor = "white" // default background color
) {
  try {
    // Fetch the image using axios
    const response = await axios.get(imageUrl, { responseType: "blob" });
    const imageBlob = response.data;

    // Create a canvas and context
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Create an image to draw into the canvas
    const image = new Image();
    image.src = URL.createObjectURL(imageBlob);
    await new Promise((resolve, reject) => {
      image.onload = resolve;
      image.onerror = reject;
    });

    // Set canvas size to the image size
    canvas.width = image.width;
    canvas.height = image.height;

    // Fill the canvas with a background color to replace the transparency
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the image onto the canvas
    ctx.drawImage(image, 0, 0);

    // Convert the canvas to a JPG blob
    const jpgBlob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/jpeg", quality)
    );

    // Create a reference to the Firebase storage location
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `/customTaskImages/${currentMonthYear}/${Date.now()}.jpg`
    );

    // Upload the blob to Firebase Storage
    const uploadResponse = await uploadBytes(storageRef, jpgBlob);

    // Get the download URL
    const downloadURL = await getDownloadURL(uploadResponse.ref);
    return downloadURL;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error for the caller to handle
  }
}

export async function convertAndUploadPngUrlToFirebaseAsPdf(
  imageUrl,
  quality = 1.0,
  filename = "image"
) {
  try {
    // Fetch the image using axios
    const response = await axios.get(imageUrl, { responseType: "blob" });
    const imageBlob = response.data;

    // Create a canvas and context
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Create an image to draw into the canvas
    const image = new Image();
    image.src = URL.createObjectURL(imageBlob);
    await new Promise((resolve, reject) => {
      image.onload = resolve;
      image.onerror = reject;
    });

    // Set canvas size to the image size
    canvas.width = image.width;
    canvas.height = image.height;

    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the image onto the canvas
    ctx.drawImage(image, 0, 0);

    // Create a PDF object
    const pdf = new jsPDF({
      orientation: "p",
      unit: "px",
      format: [canvas.width, canvas.height],
    });

    // Add the canvas as an image to the PDF
    const imgData = canvas.toDataURL("image/jpeg", quality);
    pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);

    // Convert PDF to a blob
    const pdfBlob = new Blob([pdf.output("blob")], { type: "application/pdf" });

    // Create a reference to the Firebase storage location
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `/customTaskImages/${currentMonthYear}/${Date.now()}.pdf`
    );

    // Upload the PDF blob to Firebase Storage
    const uploadResponse = await uploadBytes(storageRef, pdfBlob);

    // Get the download URL
    const downloadURL = await getDownloadURL(uploadResponse.ref);
    return downloadURL;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Rethrow the error for the caller to handle
  }
}

export const openOrderModal = (orderUid) => {
  Vue.$router.push({
    path: Vue.$router.path,
    query: { modal: "viewOrderDetails", orderUid: orderUid },
  });
};

export const delay = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const getTrackingUrl = (countryCode, orderKey, path = "") => {
  return `${
    appSettings.RATETASK_URL
  }/${countryCode.toLowerCase()}/${orderKey}${path}`;
};

export const convertToDayMonth = (date, countryCode) => {
  return date ? dayjs(date).format("DD.MM") : "????";
};
