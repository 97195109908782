var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"loading":_vm.usersLoading,"headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){_vm.showAddUserDialog = true}}},[_vm._v("Add new user")])],1)]},proxy:true},{key:`item.permissions`,fn:function({ item }){return _vm._l((item.permissions),function(permission){return _c('v-chip',{key:permission,staticClass:"ma-1",attrs:{"dark":"","x-small":""}},[_vm._v(" "+_vm._s(permission)+" ")])})}},{key:`item.actions`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openEditUserDialog(item)}}},[_c('v-list-item-title',[_vm._v("Edit User")])],1),(item.payment)?_c('v-list-item',{on:{"click":function($event){return _vm.openPaymentDialog(item)}}},[_c('v-list-item-title',[_vm._v("Make Payout")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.resetPassword(item.email)}}},[_c('v-list-item-title',[_vm._v("Reset Password")])],1)],1)],1)]}}],null,true)}),_c('AddNewUserDialog',{attrs:{"show":_vm.showAddUserDialog,"closeDialog":() => {
        _vm.showAddUserDialog = false;
      }}}),_c('EditUserDialog',{attrs:{"user":_vm.userToEdit,"close-dialog":() => {
        _vm.showEditUserDialog = false;
        _vm.userToEdit = null;
      },"show":_vm.showEditUserDialog}}),(_vm.userToPay)?_c('PayUserDialog',{attrs:{"user":_vm.userToPay,"on-close":() => {
        _vm.userToPay = null;
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }