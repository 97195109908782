import { httpsCallable } from "firebase/functions";
import { functions } from "../../../fb";
import { calculateDaysBetween } from "@/store/utils/statisticsUtils";
import dayjs from "dayjs";

const defaultState = {
  range: {
    min: "2023-10-17T00:00:00+02:00",
    max: "2023-10-17T23:59:59+02:00",
  },
  statistic: null,
  loading: false,
  refreshing: false,
  error: false,
  errorMessage: "",
};

const getters = {
  statistic: (state) => state.statistic,
  statisticRange: (state) => state.range,
  statisticLoading: (state) => state.loading,
  statisticRefreshing: (state) => state.refreshing,
  statisticError: (state) => state.error,
  statisticErrorMessage: (state) => state.errorMessage,
  statisticsDayRange: (state) => {
    return calculateDaysBetween(state.range.min, state.range.max);
  },
};

const actions = {
  async refreshStatistic({ commit }) {
    commit("setStatisticRefreshing", true);
    commit("setStatisticError", false);
    const currentLocalizedDate = dayjs().tz("Europe/Berlin", true);

    try {
      const syncStatistics = httpsCallable(functions, "syncStatistics");
      const res = await syncStatistics({
        created_at_min: currentLocalizedDate.startOf("day").format(),
        created_at_max: currentLocalizedDate.endOf("day").format(),
      });
      commit("setStatistic", res.data);
    } catch (e) {
      commit("setStatisticError", true);
      commit(
        "setStatisticErrorMessage",
        "There was an Error Loading the Statistics"
      );
    }
    commit("setStatisticRefreshing", false);
  },
  async loadStatistic({ state, commit, getters }) {
    commit("setStatisticLoading", true);
    commit("setStatisticError", false);
    try {
      const getStatistics = httpsCallable(functions, "getSyncedStatistics");
      const res = await getStatistics({
        created_at_min: getters.statisticRange.min,
        created_at_max: getters.statisticRange.max,
      });
      commit("setStatistic", res.data);
    } catch (e) {
      commit("setStatisticError", true);
      commit(
        "setStatisticErrorMessage",
        "There was an Error Loading the Statistics"
      );
    }
    commit("setStatisticLoading", false);
  },
};

const mutations = {
  setStatisticLoading: (state, bool) => (state.loading = bool),
  setStatisticRefreshing: (state, bool) => (state.refreshing = bool),
  setStatistic: (state, statistic) => (state.statistic = statistic),
  setStatisticError: (state, bool) => (state.error = bool),
  setStatisticErrorMessage: (state, message) => (state.errorMessage = message),
  setStatisticRange: (state, range) => {
    state.range = {
      min: range.min,
      max: range.max,
    };
  },
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).statistic
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
