<template>
  <MainDialog :persistent="false" :show="show" :on-close="onClose">
    <v-card>
      <v-card-title>Add Doboxa Fulfillment</v-card-title>
      <v-card-text>
        <div v-if="errorMessage">ERROR: {{ errorMessage }}</div>
        <v-text-field v-model="doboxaId" label="Doboxa ID" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="isAddingFulfillment" :loading="isAddingFulfillment" @click="resolveError">Add Fulfillment</v-btn>
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../../fb";
export default {
  name: "ResolveDoboxaErrorDialog",
  components: { MainDialog },
  data() {
    return {
      doboxaId: "",
      isAddingFulfillment: false,
      error: false,
      errorMessage: "",
    }
  },
  props: {
    show: {
      type: Boolean,
      require: true,
    },
    onClose: {
      required: false,
    },
    orderId: {
      type: String,
      required: true
    }
  },
  methods: {
    async resolveError() {
      this.isAddingFulfillment = true
      const resolveErrorDoboxa = httpsCallable(functions, "resolveErrorDoboxa");
      try {
        await resolveErrorDoboxa({
          orderId: this.orderId,
          doboxaId: this.doboxaId
        });
      } catch (e) {
        this.error = true;
        this.errorMessage = e.message
      }
      this.isAddingFulfillment = false
      this.onClose()
    }
  }
};
</script>

<style scoped></style>
