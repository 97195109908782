<template>
  <div>
      <v-simple-table class="total-costs-table">
        <thead>
        <tr>
          <th class="text-left">Fulfiller</th>
          <th v-for="(fulfiller, fulfillerKey) in fulfillers" :key="fulfillerKey" class="text-left">
            {{ fulfillerKey }}
          </th>
          <th class="text-left">Total</th>
        </tr>
        </thead>
        <tbody>
        <!-- Shipping Row -->
        <tr>
          <td>Shipping</td>
          <td v-for="(shippingCost, fulfillerKey) in shippingCosts" :key="fulfillerKey">
            {{ formatCurrency(shippingCost) }}
          </td>
          <td>{{ formatCurrency(totalShipping) }}</td>
        </tr>

        <!-- Production Row -->
        <tr>
          <td>Production</td>
          <td v-for="(productionCost, fulfillerKey) in productionCosts" :key="fulfillerKey">
            {{ formatCurrency(productionCost) }}
          </td>
          <td>{{ formatCurrency(totalProduction) }}</td>
        </tr>

        <!-- Total Row -->
        <tr class="total-row">
          <td>Total</td>
          <td v-for="(total, fulfillerKey) in totalCosts" :key="fulfillerKey">
            <strong>{{ formatCurrency(total) }}</strong>
          </td>
          <td><strong>{{ formatCurrency(grandTotal) }}</strong></td>
        </tr>
        </tbody>
      </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "TotalCosts",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    fulfillers() {
      return this.data.actualPaid;
    },
    shippingCosts() {
      return Object.fromEntries(
          Object.entries(this.fulfillers).map(([key, fulfillerData]) => {
            const shipping = Object.values(fulfillerData)
                .flatMap(region => Object.values(region))
                .reduce((sum, regionData) => sum + regionData.totalShipping, 0);
            return [key, shipping];
          })
      );
    },
    productionCosts() {
      return Object.fromEntries(
          Object.entries(this.fulfillers).map(([key, fulfillerData]) => {
            const production = Object.values(fulfillerData)
                .flatMap(region => Object.values(region))
                .reduce((sum, regionData) => sum + regionData.totalProduction, 0);
            return [key, production];
          })
      );
    },
    totalCosts() {
      return Object.fromEntries(
          Object.keys(this.fulfillers).map(key => [
            key,
            this.shippingCosts[key] + this.productionCosts[key]
          ])
      );
    },
    totalShipping() {
      return Object.values(this.shippingCosts).reduce((sum, cost) => sum + cost, 0);
    },
    totalProduction() {
      return Object.values(this.productionCosts).reduce((sum, cost) => sum + cost, 0);
    },
    grandTotal() {
      return this.totalShipping + this.totalProduction;
    }
  },
  methods: {
    formatCurrency(value) {
      return `${value.toFixed(2)}€`;
    }
  }
};
</script>

<style scoped>
.total-costs-table {
  background-color: #f4f4f4 !important;
  border-radius: 5px;
}

.total-row td {
  font-weight: bold;
}
</style>
