import {
  doc,
  getDoc,
  setDoc,
  deleteField,
  collection,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../fb";
import { convertObjectToArray, getDateFromNow } from "@/utils";
import { v4 as uuidv4 } from "uuid";
import skuMappings from "@/components/Settings/Products/SkuMappings/SkuMappings";

const _ = require("lodash");

const defaultState = {
  loadingPrintShops: false,
  printShops: [],
  loadingProductTypes: false,
  productTypes: [],
  productTypeNames: {},
  loadingPrintVariants: false,
  printVariants: [],
  printVariantNames: [],
  loadingSkuMappings: false,
  skuMappings: [],
  loadingMockups: false,
  mockups: [],
};

const getters = {
  printShops: (state) => state.printShops,
  loadingPrintShops: (state) => state.loadingPrintShops,
  productTypes: (state) => state.productTypes,
  productTypeNames: (state) => state.productTypeNames,
  loadingProductTypes: (state) => state.loadingProductTypes,
  printVariants: (state) => state.printVariants,
  printVariantNames: (state) => state.printVariantNames,
  loadingPrintVariants: (state) => state.loadingPrintVariants,
  skuMappings: (state) => state.skuMappings,
  loadingSkuMappings: (state) => state.loadingSkuMappings,
  mockups: (state) => state.mockups,
  loadingMockups: (state) => state.loadingMockups,
};

const actions = {
  async loadPrintShops({ commit }) {
    commit("setLoadingPrintShops", true);
    commit("setPrintShops", []);
    const printShopDoc = doc(db, "settings", "printShops");
    const printShopRef = await getDoc(printShopDoc);
    const printShopData = printShopRef.data();
    commit("setPrintShops", convertObjectToArray(printShopData));
    commit("setLoadingPrintShops", false);
  },
  async loadProductTypes({ commit }) {
    commit("setLoadingProductTypes", true);
    commit("setProductTypes", []);
    const productTypesDoc = doc(db, "settings", "productTypes");
    const productTypesRef = await getDoc(productTypesDoc);
    const productTypesData = productTypesRef.data();
    commit("setProductTypeNames", _.mapValues(productTypesData, "name"));
    commit("setProductTypes", convertObjectToArray(productTypesData));
    commit("setLoadingProductTypes", false);
  },
  async addProductType({ commit, dispatch }, productType) {
    commit("setLoadingProductTypes", true);
    const productTypesDoc = doc(db, "settings", "productTypes");
    await setDoc(productTypesDoc, { [uuidv4()]: productType }, { merge: true });
    dispatch("loadProductTypes");
  },
  async editProductType({ commit, dispatch }, productType) {
    commit("setLoadingProductTypes", true);
    const productTypesDoc = doc(db, "settings", "productTypes");
    const { key: _, ...productTypeData } = productType; // Cut out key
    await setDoc(
      productTypesDoc,
      { [productType.key]: { ...productTypeData } },
      { merge: true }
    );
    dispatch("loadProductTypes");
  },
  async loadPrintVariants({ commit }) {
    commit("setLoadingPrintVariants", true);
    commit("setPrintVariants", []);
    const printVariantsDoc = doc(db, "settings", "printVariants");
    const printVariantsRef = await getDoc(printVariantsDoc);
    const printVariantsData = printVariantsRef.data();
    commit("setPrintVariantNames", _.mapValues(printVariantsData, "name"));
    commit("setPrintVariants", convertObjectToArray(printVariantsData));
    commit("setLoadingPrintVariants", false);
  },
  async loadMockups({ commit }) {
    commit("setLoadingMockups", true);
    commit("setMockups", []);
    const mockupCollection = collection(db, "mockups");
    const q = query(mockupCollection, orderBy("created", "desc"));
    const querySnapshot = await getDocs(q);

    const mockups = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        uuid: doc.id,
        timeAgo: getDateFromNow(data.created),
        thumbnail: data?.mockupData?.[0].imgSrc || data.mockupData?.[1]?.imgSrc,
        ...data,
      };
    });
    commit("setMockups", mockups);
    commit("setLoadingMockups", false);
  },
  async addPrintVariant({ commit, dispatch }, printVariant) {
    commit("setLoadingPrintVariants", true);
    const printVariantsDoc = doc(db, "settings", "printVariants");
    await setDoc(
      printVariantsDoc,
      { [uuidv4()]: printVariant },
      { merge: true }
    );
    dispatch("loadPrintVariants");
  },
  async editPrintVariant({ commit, dispatch }, printVariant) {
    commit("setLoadingPrintVariants", true);
    const printVariantsDoc = doc(db, "settings", "printVariants");
    const { key: _, ...productTypeData } = printVariant; // Cut out key
    await setDoc(
      printVariantsDoc,
      { [printVariant.key]: { ...printVariant } },
      { merge: true }
    );
    dispatch("loadPrintVariants");
  },
  async loadSkuMappings({ commit }) {
    commit("setLoadingSkuMappings", true);
    commit("setSkuMappings", []);
    const skuMappingsDoc = doc(db, "settings", "skuMappings");
    const skuMappingsRef = await getDoc(skuMappingsDoc);
    const skuMappingsData = skuMappingsRef.data();

    const skuMappings = Object.entries(skuMappingsData).map(([sku, value]) => {
      return { ...value, sku };
    });

    commit("setSkuMappings", [
      {
        sku: "",
        productTypeId: "",
        printVariantId: "",
      },
      ...convertObjectToArray(skuMappings),
    ]);
    commit("setLoadingSkuMappings", false);
  },
  async addSkuMapping({ commit, dispatch }, skuMapping) {
    commit("setLoadingSkuMappings", true);
    const skuMappingDoc = doc(db, "settings", "skuMappings");
    const { sku, ...mapping } = skuMapping;
    await setDoc(skuMappingDoc, { [sku]: mapping }, { merge: true });
    dispatch("loadSkuMappings");
  },
  async deleteSkuMapping({ commit, dispatch }, skuMapping) {
    commit("setLoadingSkuMappings", true);
    const skuMappingDoc = doc(db, "settings", "skuMappings");
    await setDoc(
      skuMappingDoc,
      { [skuMapping.sku]: deleteField() },
      { merge: true }
    );
    dispatch("loadSkuMappings");
  },
};

const mutations = {
  setLoadingPrintShops: (state, bool) => (state.loadingPrintShops = bool),
  setPrintShops: (state, printShops) => (state.printShops = printShops),
  setLoadingProductTypes: (state, bool) => (state.loadingProductTypes = bool),
  setProductTypes: (state, productTypes) => (state.productTypes = productTypes),
  setProductTypeNames: (state, productTypes) =>
    (state.productTypeNames = productTypes),
  setLoadingPrintVariants: (state, bool) => (state.loadingPrintVariants = bool),
  setPrintVariants: (state, productTypes) =>
    (state.printVariants = productTypes),
  setPrintVariantNames: (state, bool) => (state.printVariantNames = bool),
  setLoadingSkuMappings: (state, bool) => (state.loadingSkuMappings = bool),
  setSkuMappings: (state, productTypes) => (state.skuMappings = productTypes),
  setLoadingMockups: (state, bool) => (state.loadingMockups = bool),
  setMockups: (state, mockups) => (state.mockups = mockups),
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).settings
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
