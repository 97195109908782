<template>
  <v-menu
    v-if="show"
    :value="show"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    style="box-shadow: none;"
  >
    <v-list ref="listMenu">
      <template v-for="(listItem, index) in listItems">
        <div :key="index">
          <v-list-item
            :data-show-sub-menu="
              listItem.subListItems ? index + '_submenu' : null
            "
            v-on:mouseenter="(e) => onHoverSubMenu(e, index)"
            v-if="!listItem.hide"
            @click="listItem.onClick"
            dense
            link
          >
            <v-list-item-avatar v-if="listItem.icon">
              <v-icon>{{ listItem.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ listItem.label }}</v-list-item-title>
            <v-icon v-if="listItem.subListItems" class="ml-3"
              >mdi-chevron-right</v-icon
            >
            <ListMenu
              class="sub-menu"
              v-if="listItem.subListItems"
              :list-items="listItem.subListItems"
              :x="x + subMenuOffsetX"
              :y="y + index * 56"
              :show="showSubMenuIndex === index"
            />
          </v-list-item>
        </div>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "ListMenu",
  data() {
    return {
      showSubMenuIndex: null,
      subMenuOffsetX: 0,
    };
  },
  props: {
    show: {
      type: Boolean,
    },
    x: {
      default: 0,
    },
    y: {
      default: 0,
    },
    listItems: {
      require: true,
      type: Array,
    },
  },
  watch: {
    show: {
      handler(newValue) {
        if (newValue === false) {
          this.showSubMenuIndex = null;
        }
      },
    },
  },
  methods: {
    onHoverSubMenu(e, index) {
      this.subMenuOffsetX = e.target.clientWidth - 20;
      if (e.target.dataset.showSubMenu === `${index}_submenu`) {
        this.showSubMenuIndex = index;
      } else {
        this.showSubMenuIndex = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: rgba(99, 99, 99, 0.2) 0 1px 4px 0 !important;
}
</style>
