<template>
  <v-dialog persistent width="500" max-width="100%" :value="show">
    <v-card dark>
      <v-card-title v-if="!receivedPassword">Add New User</v-card-title>
      <v-card-text>
        <v-form v-if="!receivedPassword" v-model="validForm">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Username"
                outlined
                v-model="user.username"
                :rules="usernameRules"
                required
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Pay Per Task"
                outlined
                type="number"
                v-model="user.payment.payPerTask"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Currency e.g €"
                outlined
                v-model="user.payment.currency"
                :disabled="!user.payment.payPerTask"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                class="ml-6"
                v-model="user.needsReview"
                label="User needs Review"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="E-mail"
                outlined
                v-model="user.email"
                :rules="emailRules"
              />
            </v-col>
            <v-col v-if="availablePermissions.length > 1" cols="12">
              <v-combobox
                v-model="user.selectedPermissions"
                :items="availablePermissions"
                label="Permissions"
                multiple
                outlined
                dark
                chips
              ></v-combobox>
            </v-col>
          </v-row>
        </v-form>
        <div v-else class="showPassword">
          <span>Password:</span>
          <h1>{{ receivedPassword }}</h1>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" text>{{
          receivedPassword ? "OK" : "Close"
        }}</v-btn>
        <v-spacer />
        <v-btn
          :disabled="isCreating"
          :loading="isCreating"
          @click="createUser"
          v-if="!receivedPassword"
          >Create User</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { permissions } from "@/constants";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../fb";
import { mapGetters } from "vuex";
import { EventBus, events } from "../../../../event-bus";

export default {
  name: "AddNewUserDialog",
  props: {
    show: {
      default: false,
    },
    closeDialog: {
      required: true,
    },
  },
  data() {
    return {
      user: {
        permissions: [],
        username: "",
        needsReview: true,
        payment: {
          transactionHistory: [],
          unpaidTasks: [],
          currency: "",
          payPerTask: null,
        },
        email: "",
      },
      showDialog: false,
      isCreating: false,
      receivedPassword: "",
      usernameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
        (v) => v.length >= 3 || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      validForm: true,
    };
  },
  created() {
    if (!this.availablePermissions.includes(permissions.ADMIN)) {
      this.selectedPermissions = [this.availablePermissions[0]];
    }
  },
  computed: {
    ...mapGetters(["userPermissions"]),
    availablePermissions() {
      return this.userPermissions.filter((permission) => {
        if (this.userPermissions.includes(permissions.ADMIN)) return true;
        return (
          permission !== permissions.MANAGER &&
          permission !== permissions.TASK_REVIEW
        );
      });
    },
  },
  methods: {
    close() {
      this.closeDialog();
      // Wait for closing animation
      setTimeout(() => {
        this.user = {
          permissions: [],
          username: "",
          needsReview: true,
          payment: {
            transactionHistory: [],
            unpaidTasks: [],
            currency: "₮",
            payPerTask: 1,
          },
          email: "",
        };
      }, 500);
    },
    async createUser() {
      if (!this.validForm) {
        EventBus.$emit(events.SNACKBAR, {
          message: "Please enter all required Fields",
          type: "error",
        });
      } else {
        this.isCreating = true;
        const createUser = httpsCallable(functions, "createUser");
        const response = await createUser({
          email: this.user.email,
          username: this.user.username,
          payPerTask: this.user.payment.payPerTask,
          currency: this.user.payment.currency,
          permissions: this.user.selectedPermissions,
          needsReview: this.user.needsReview,
        });
        this.isCreating = false;
        this.receivedPassword = response.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.showPassword {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  h3 {
    color: white;
  }
}
</style>
