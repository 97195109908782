<template>
  <v-card>
    <v-card-title class="flex justify-space-between">
      Additional Costs
      <v-btn v-if="!showEdit" @click="editCosts" color="success"> Edit </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-if="additionalCosts"
        hide-default-footer
        :headers="showEdit ? headersEdit : headers"
        :items="additionalCosts"
        class="elevation-1"
      >
        <template v-slot:item.name="{ item }">
          <div v-if="!rowsToEdit.includes(item.id)">
            {{ item?.name }}
          </div>
          <v-text-field
            v-else
            v-model="additionalCostsCopy[item.id].name"
            label="Name"
          />
        </template>
        <template v-slot:item.shops="{ item }">
          <div v-if="!rowsToEdit.includes(item.id)">
            {{ getShopsValue(item?.shops) }}
          </div>
          <v-btn
            v-else
            x-small
            @click="showSelectShopsDialog(item.id)"
          >{{ getShopsValue(item?.shops) }} <v-icon small class="ml-2">mdi-pencil</v-icon></v-btn>
        </template>
        <template v-slot:item.monthly="{ item }">
          <div v-if="!rowsToEdit.includes(item.id)">
            <div v-if="item?.monthly.value">
              {{ item?.monthly.value }} {{ item?.monthly.currency }}
            </div>
            <div v-else>-</div>
          </div>
          <v-row v-else>
            <v-col cols="6">
              <v-text-field
                v-model.number="additionalCostsCopy[item.id].monthly.value"
                label="Value"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="availableCurrencies"
                v-model="additionalCostsCopy[item.id].monthly.currency"
                label="Currency"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.cpo="{ item }">
          <div v-if="!rowsToEdit.includes(item.id)">
            <div v-if="item?.cpo.value">
              {{ item?.cpo.value }} {{ item.cpo?.currency }}
            </div>
            <div v-else>-</div>
          </div>
          <v-row v-else>
            <v-col cols="6">
              <v-text-field
                v-model.number="additionalCostsCopy[item.id].cpo.value"
                label="Value"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="availableCurrencies"
                v-model="additionalCostsCopy[item.id].cpo.currency"
                label="Currency"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.rpo="{ item }">
          <div v-if="!rowsToEdit.includes(item.id)">
            <div v-if="item?.rpo">{{ item?.rpo }}%</div>
            <div v-else>-</div>
          </div>
          <v-text-field v-else v-model.number="additionalCostsCopy[item.id].rpo" label="Rate" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="() => rowsToEdit.push(item.id)" depressed v-if="!rowsToEdit.includes(item.id)" small>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="deleteCost(item.id)" depressed small color="error" v-else>
            <v-icon @click="() => rowsToEdit.push(item.id)">mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-if="showEdit" v-slot:body.append>
          <tr>
            <td>
              <v-text-field v-model="newCost.name" label="Name" />
            </td>
            <td>
              <v-btn @click="showAddShops = true" x-small>Select Shops</v-btn>
            </td>
            <td>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="newCost.monthly.value"
                    label="Value"
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    :items="availableCurrencies"
                    v-model="newCost.monthly.currency"
                    label="Currency"
                  />
                </v-col>
              </v-row>
            </td>
            <td>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="newCost.cpo.value"
                    label="Value"
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    :items="availableCurrencies"
                    v-model="newCost.cpo.currency"
                    label="Currency"
                  />
                </v-col>
              </v-row>
            </td>
            <td>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model.number="newCost.rpo" label="Rate" />
                </v-col>
              </v-row>
            </td>
            <td>
              <v-btn @click="addNewCost()" small color="success">Add</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="showEdit">
      <v-btn :disabled="isSaving" small outlined @click="cancelEditCosts">Cancel</v-btn>
      <v-spacer />
      <v-btn :disabled="isSaving" :loading="isSaving" color="success" small @click="saveChanges">Save</v-btn>
    </v-card-actions>
    <MainDialog :show="showAddShops" :on-close="hideSelectShopsDialog" :persistent="false">
      <SelectShops @selection="editSelectedShops"/>
    </MainDialog>
  </v-card>
</template>

<script>
import { db } from "../../../../../fb";
import { doc, setDoc } from "firebase/firestore";
import _ from "lodash";
import MainDialog from "@/components/MainDialog";
import SelectShops from "@/components/Settings/Statistics/AdditionalCosts/SelectShops";

export default {
  name: "AdditionalCosts",
  components: {SelectShops, MainDialog},
  data() {
    return {
      showEdit: false,
      isSaving: false,
      selectedCostId: null,
      rowsToEdit: [],
      additionalCostsCopy: null,
      showAddShops: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Shops", value: "shops" },
        { text: "Monthly Costs", value: "monthly" },
        { text: "Fixed Costs p.O", value: "cpo" },
        { text: "Rate Costs p.O", value: "rpo" },
      ],
      headersEdit: [
        { text: "Name", value: "name" },
        { text: "Shops", value: "shops" },
        { text: "Monthly Costs", value: "monthly" },
        { text: "Fixed Costs p.O", value: "cpo" },
        { text: "Rate Costs p.O", value: "rpo" },
        { text: "", value: "actions" },
      ],
      newCost: this.getInitialCost(),
    };
  },
  props: {
    settings: {
      required: true,
      type: Object,
    },
  },
  created() {
    this.additionalCostsCopy = _.cloneDeep(this.settings.additionalCosts);
  },
  computed: {
    additionalCosts() {
      if (!this.additionalCostsCopy) return []
      return Object.keys(this.additionalCostsCopy).map(id => {
        return {
          id: id,
          ...this.additionalCostsCopy[id]
        }
      }).sort((a, b) => a.id.localeCompare(b.id))
    },
    availableCurrencies() {
      return Object.keys(this.settings.exchangeRates);
    },
  },
  methods: {
    getInitialCost() {
      return {
        name: "",
        monthly: {
          value: 0,
          currency: "",
        },
        cpo: {
          value: 0,
          currency: "",
        },
        rpo: 0,
        shops: null
      };
    },
    editCosts() {
      this.showEdit = true;
    },
    showSelectShopsDialog(id) {
      this.selectedCostId = id
      this.showAddShops = true
    },
    hideSelectShopsDialog() {
      this.selectedCostId = null
      this.showAddShops = false
    },
    editSelectedShops(selectedShops) {
      if (!this.selectedCostId) {
        this.newCost.shops = selectedShops
      } else {
        this.additionalCostsCopy = {
          ...this.additionalCostsCopy,
          [this.selectedCostId]: {
            ...this.additionalCostsCopy[this.selectedCostId],
            shops: selectedShops,
          }
        }
      }
      this.hideSelectShopsDialog()
    },
    getShopsValue(shops) {
      if (!shops) return "-";
      return Object.entries(shops)
        .reduce((acc, [key, value]) =>
          acc.concat(Object.entries(value)
            .filter(([, v]) => v)
            .map(([subKey]) => `${key}_${subKey}`)), [])
        .join(", ");
    },
    cancelEditCosts() {
      this.rowsToEdit = []
      this.additionalCostsCopy = _.cloneDeep(this.settings.additionalCosts);
      this.showEdit = false;
    },
    addNewCost() {
      this.additionalCostsCopy = {
        ...this.additionalCostsCopy,
        [Date.now()]: this.newCost,
      };

      this.newCost = {
        name: "",
        monthly: {
          value: 0,
          currency: "",
        },
        cpo: {
          value: 0,
          currency: "",
        },
        rpo: 0,
      };
    },
    deleteCost(id) {
      const { [id]: __, ...filteredCostsCopy } = this.additionalCostsCopy;

      this.rowsToEdit = this.rowsToEdit.filter(i => i !== id)
      this.additionalCostsCopy = _.cloneDeep(filteredCostsCopy)
    },
    async saveChanges() {
      this.isSaving = true;
      const docRef = doc(db, "settings", "statistics");

      await setDoc(docRef, {
        ...this.settings,
        additionalCosts: this.additionalCostsCopy,
      });

      this.rowsToEdit = []
      this.cancelEditCosts();
      this.isSaving = false;
    },
  },
};
</script>

<style scoped></style>
