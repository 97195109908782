<template>
 <div class="mockup-container">
   <StateDisplay :loading="loadingMockups"/>
   <v-row>
     <v-col v-for="mockup in mockups" :key="mockup.uuid" cols="3">
       <v-card @click="() => copyMockupData(mockup.mockupData)" height="300px" class="mockup-card">
         <div v-if="mockup.aspectRatio" class="mockup-card__aspect-ratio info-box">{{mockup.aspectRatio}}</div>
         <div class="mockup-card__orientation info-box">{{mockup.orientation}}</div>
         <div class="mockup-card__name info-box">{{mockup.name}}</div>
         <v-img height="300px" cover :src="mockup.thumbnail"/>
       </v-card>
     </v-col>
   </v-row>
 </div>
</template>

<script>
import StateDisplay from "@/components/StateDisplay";
import {mapActions, mapGetters} from "vuex";
import {copyToClipboard} from "@/utils";

export default {
  name: "AllMockups",
  components: {StateDisplay},
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    ...mapActions(["loadMockups"]),
    copyMockupData(data) {
      copyToClipboard(data)
    }
  },
  computed: {
    ...mapGetters(["loadingMockups" ,"mockups"])
  },
  mounted() {
    this.loadMockups()
  }
}
</script>

<style lang="scss" scoped>
.mockup-container {
  min-height: 600px;
}

.mockup-card {
  position: relative;

  .info-box {
    background: rgba(45, 45, 45, 0.7);
    color: white;
    font-size: 14px;
    position: absolute;
    z-index: 2;
    padding: 12px;
  }

  &__aspect-ratio {
    left: 12px;
    top: 12px;
    border-radius: 10px;
  }

  &__orientation {
    right: 12px;
    top: 12px;
    border-radius: 10px;
  }

  &__name {
    bottom: 0;
    width: 100%;
  }
}
</style>
