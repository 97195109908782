<template>
  <v-card>
    <v-card-title>
      Select Cost Specific Shops
    </v-card-title>
    <v-card-text>
      <div v-for="(countries, group) in groups" :key="group">
        <v-checkbox
          v-model="selectAll[group]"
          @change="toggleSelectAll(group)"
          :label="group"
        ></v-checkbox>
        <div v-for="country in countries" :key="group + '-' + country" style="margin-left: 20px;">
          <v-checkbox
            v-model="selectedCountries[group][country]"
            :label="country"
            @change="checkAllSelected(group)"
          ></v-checkbox>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn @click="save" color="success">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {shopAndCountryMap} from "@/constants";

export default {
  name: "SelectShops",
  data() {
    return {
      selectAll: {},
      selectedCountries: {}
    };
  },
  computed: {
    groups() {
      return shopAndCountryMap
    }
  },
  created() {
    this.initializeSelection();
  },
  methods: {
    initializeSelection() {
      for (const group in this.groups) {
        this.$set(this.selectAll, group, false);
        this.$set(this.selectedCountries, group, {});
        for (const country of this.groups[group]) {
          this.$set(this.selectedCountries[group], country, false);
        }
      }
    },
    toggleSelectAll(group) {
      const selectAll = this.selectAll[group];
      for (const country in this.selectedCountries[group]) {
        this.selectedCountries[group][country] = selectAll;
      }
    },
    checkAllSelected(group) {
      const allSelected = Object.values(this.selectedCountries[group]).every(
        selected => selected
      );
      this.selectAll[group] = allSelected;
    },
    save() {
      const allFalse = Object.entries(this.selectedCountries).reduce((acc, [key, value]) => acc.concat(Object.entries(value).filter(([, v]) => v).map(([subKey]) => `${key}_${subKey}`)), []).length === 0
      this.$emit('selection', allFalse ? null : this.selectedCountries)
    }
  }
};
</script>

<style scoped>
.v-checkbox {
  margin-left: 20px;
}
</style>
