import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../fb";

const defaultState = {
  ticketsOpen: [],
  ticketsWaiting: [],
  ticketsClosed: [],
  loadingTickets: false,
  unsubTickets: {
    OPEN: null,
    WAITING: null,
    CLOSED: null,
  }, // Updated to null for consistency
  orderIdsInSupport: [],
  initialLoad: true,
};

const getters = {
  // Updated getters to return specific ticket types
  ticketsOpen: (state) => state.ticketsOpen,
  ticketsWaiting: (state) => state.ticketsWaiting,
  ticketsClosed: (state) => state.ticketsClosed,
  unsubTickets: (state) => state.unsubTickets,
  orderIdsInSupport: (state) => state.orderIdsInSupport,
};

const actions = {
  async loadTickets({ commit, state }, type) {
    const initialLoad = state.initialLoad;
    commit("setLoadingTickets", true);
    commit("setTickets", { tickets: [], type });

    // Define the base collection
    const supportCollection = collection(db, "support");
    let supportQuery;

    // Adjust the query based on the ticket type
    switch (type) {
      case "OPEN":
        supportQuery = query(
          supportCollection,
          where("pending", "==", true),
          where("waiting", "==", false),
          orderBy("created", "desc"),
          limit(250)
        );
        break;
      case "WAITING":
        supportQuery = query(
          supportCollection,
          where("pending", "==", true),
          where("waiting", "==", true),
          orderBy("created", "desc"),
          limit(250)
        );
        break;
      case "CLOSED":
        supportQuery = query(
          supportCollection,
          where("pending", "==", false),
          orderBy("created", "desc"),
          limit(20)
        );
        break;
    }

    // Subscribe to the query
    const unsubscribe = onSnapshot(supportQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const ticketData = change.doc.data();

        if (change.type === "added") {
          if (initialLoad) {
            commit("addTicket", { ticket: ticketData, type });
          } else {
            commit("prependTicket", { ticket: ticketData, type });
          }
        } else if (change.type === "modified") {
          commit("updateTicket", { ticket: ticketData, type });
        } else if (change.type === "removed") {
          commit("deleteTicket", { ticket: ticketData, type });
        }
      });
    });

    // Save the unsubscribe function to state
    commit("setUnsubscribeTickets", { unsubscribe, type });

    // If it's the initial load, set initialLoad to false after the action completes
    if (initialLoad) {
      setTimeout(() => commit("setInitialLoad", false), 5000);
    }

    commit("setLoadingTickets", false);
  },
  unsubTickets({ state }) {
    if (state.unsubTickets) {
      if (state.unsubTickets.OPEN) {
        state.unsubTickets.OPEN();
        state.unsubTickets.OPEN = null;
      }

      if (state.unsubTickets.CLOSED) {
        state.unsubTickets.CLOSED();
        state.unsubTickets.CLOSED = null;
      }

      if (state.unsubTickets.WAITING) {
        state.unsubTickets.WAITING();
        state.unsubTickets.WAITING = null;
      }
    }
  },
};

const mutations = {
  setLoadingTickets: (state, bool) => (state.loadingTickets = bool),
  // Updated mutations to handle different ticket types
  setTickets(state, { tickets, type }) {
    state.orderIdsInSupport = tickets.map((t) => t.orderUid);
    switch (type) {
      case "OPEN":
        state.ticketsOpen = tickets;
        break;
      case "WAITING":
        state.ticketsWaiting = tickets;
        break;
      case "CLOSED":
        state.ticketsClosed = tickets;
        break;
    }
  },
  addTicket(state, { ticket, type }) {
    switch (type) {
      case "OPEN":
        state.orderIdsInSupport.push(ticket.orderUid);
        state.ticketsOpen.push(ticket);
        break;
      case "WAITING":
        state.orderIdsInSupport.push(ticket.orderUid);
        state.ticketsWaiting.push(ticket);
        break;
      case "CLOSED":
        state.orderIdsInSupport = state.orderIdsInSupport.filter(
          (t) => t !== ticket.orderUid
        );
        state.ticketsClosed.push(ticket);
        break;
    }
  },
  prependTicket(state, { ticket, type }) {
    switch (type) {
      case "OPEN":
        state.orderIdsInSupport.push(ticket.orderUid);
        state.ticketsOpen.unshift(ticket);
        break;
      case "WAITING":
        state.orderIdsInSupport.push(ticket.orderUid);
        state.ticketsWaiting.unshift(ticket);
        break;
      case "CLOSED":
        state.orderIdsInSupport = state.orderIdsInSupport.filter(
          (t) => t !== ticket.orderUid
        );
        state.ticketsClosed.unshift(ticket);
        break;
    }
  },
  updateTicket(state, { ticket, type }) {
    let index;
    const findIndex = (tickets) =>
      tickets.findIndex((t) => t.orderUid === ticket.orderUid);

    switch (type) {
      case "OPEN":
        index = findIndex(state.ticketsOpen);
        if (index !== -1) state.ticketsOpen.splice(index, 1, ticket);
        break;
      case "WAITING":
        index = findIndex(state.ticketsWaiting);
        if (index !== -1) state.ticketsWaiting.splice(index, 1, ticket);
        break;
      case "CLOSED":
        index = findIndex(state.ticketsClosed);
        if (index !== -1) state.ticketsClosed.splice(index, 1, ticket);
        break;
    }
  },
  deleteTicket(state, { ticket, type }) {
    let index;
    const findIndex = (tickets) =>
      tickets.findIndex((t) => t.orderUid === ticket.orderUid);

    switch (type) {
      case "OPEN":
        index = findIndex(state.ticketsOpen);
        if (index !== -1) state.ticketsOpen.splice(index, 1);
        break;
      case "WAITING":
        index = findIndex(state.ticketsWaiting);
        if (index !== -1) state.ticketsWaiting.splice(index, 1);
        break;
      case "CLOSED":
        index = findIndex(state.ticketsClosed);
        if (index !== -1) state.ticketsClosed.splice(index, 1);
        break;
    }
  },
  setInitialLoad(state, value) {
    state.initialLoad = value;
  },
  setUnsubscribeTickets(state, { type, unsubscribe }) {
    if (type && unsubscribe) {
      state.unsubTickets[type] = unsubscribe;
    }
  },
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).support
  : { ...defaultState };

export default {
  state,
  getters,
  actions,
  mutations,
};
