<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ordersInFulfill"
      :loading="loadingOrders"
      disable-pagination
      :hide-default-footer="true"
      item-key="uid"
      :single-select="false"
    >
      <template v-slot:item.orderName="{ item }">
        <v-icon v-if="item.showOrderWarning" color="warning">mdi-alert</v-icon>
        <a @click="() => openOrder(item.uid)">{{ item.orderName }}</a>
      </template>
      <template v-slot:item.orderDate="{ item }">
        <TimeIndicator :creation-date="item.orderDate" />
      </template>
      <template v-slot:item.name="{ item }">
        {{
          item.shippingAddress.firstName + " " + item.shippingAddress.lastName
        }}
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <FulfillCountdown
            class="mr-6"
            v-if="checkIsCancelable(item)"
            :fulfill-time="item.fulfillTime"
          />
          <v-btn
            @click="() => cancelFulfill(item)"
            v-if="checkIsCancelable(item)"
            small
            outlined
            color="error"
            width="120px"
            >Cancel</v-btn
          >
          <div v-else>
            <v-progress-circular :size="20" class="mr-3" indeterminate />
            Currently Fulfilling...
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimeIndicator from "@/components/TaskCards/components/TimeIndicator";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../fb";
import { orderStatus } from "@/constants";
import FulfillCountdown from "@/components/FulfillOrders/components/FulfillCountdown";
import {openOrderModal} from "@/utils";

export default {
  name: "OrdersInFulfill",
  components: {
    FulfillCountdown,
    TimeIndicator,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["ordersInFulfill", "loadingOrders"]),
    headers() {
      return [
        { text: "Order Name", value: "orderName" },
        { text: "Order Age (in Days)", value: "orderDate" },
        { text: "Name", value: "name" },
        { text: "", value: "action" },
      ];
    },
  },
  methods: {
    openOrder(uid) {
      openOrderModal(uid)
    },
    async fulfillSelectedOrders() {
      this.showWarnDialog = false;
      this.showIsFulfillingDialog = true;
      for await (const order of this.selectedOrders) {
        const orderDocRef = doc(db, "orders", order.uid);
        try {
          await setDoc(
            orderDocRef,
            {
              status: orderStatus.READY_FOR_FULFILL,
            },
            { merge: true }
          );
          this.selectedOrders = this.selectedOrders.filter(
            (o) => o.uid !== order.uid
          );
        } catch (error) {
          console.error("Error updating order document:", error);
        }
      }
      this.showIsFulfillingDialog = false;
    },
    checkIsCancelable(order) {
      return order.status !== orderStatus.IN_FULFILL;
    },
    async cancelFulfill(order) {
      const orderDocRef = doc(db, "orders", order.uid);
      await setDoc(
        orderDocRef,
        {
          status: orderStatus.READY_FOR_FULFILL,
        },
        { merge: true }
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
