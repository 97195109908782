<template>
  <div class="taskContainer">
    <v-expansion-panels v-if="!isLoading && !noResults" v-model="panel">
      <v-expansion-panel v-for="data in tasksByAssignees" :key="data.uid">
        <v-expansion-panel-header>
        <span>
          <v-icon class="mr-3">{{ data.icon }}</v-icon>
          {{ data.title }}
        </span>
          <template v-slot:actions>
            <v-avatar color="secondary" size="30">
              <span class="white--text">{{ data.tasks.length }}</span>
            </v-avatar>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardsGrid :tasks="data.tasks" :CardComponent="cardComponent" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <StateDisplay v-else :loading="isLoading" :no-results="noResults" loading-message="Loading Draw Tasks..." />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UploadResultCard from "@/components/TaskCards/UploadResultCard";
import CardsGrid from "@/components/CardsGrid";
import { auth } from "../../fb";
import { permissions } from "@/constants";
import StateDisplay from "@/components/StateDisplay";

export default {
  name: "DrawTask",
  components: {StateDisplay, CardsGrid },
  data() {
    return {
      cardComponent: UploadResultCard,
      tasksByAssignees: [],
      panel: 1,
      isMapping: true
    };
  },
  mounted() {
    this.mapTasksByAssignees(true);
  },
  computed: {
    ...mapGetters(["drawingTasks", "userPermissions", "userUidMap"]),
    tasks() {
      return this.drawingTasks.tasks;
    },
    isLoading() {
      return this.isMapping || !!this.drawingTasks.loading;
    },
    noResults() {
      return !this.isLoading && this.drawingTasks.tasks.length === 0;
    }
  },
  watch: {
    tasks: {
      handler() {
        this.mapTasksByAssignees(false);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["loadTasks"]),
    mapTasksByAssignees(showLoading) {
      this.isMapping = showLoading
      let userIds = this.userPermissions.includes(permissions.TASKMARKET)
        ? [""]
        : [];
      this.drawingTasks.tasks.forEach((task) => {
        userIds = [...new Set([...userIds, task.assignedTo])];
      });
      const sortedUserIds = userIds.sort().reverse();
      this.tasksByAssignees = sortedUserIds.map((id) => {
        return {
          uid: id,
          title: this.getTitleByUid(id),
          tasks: this.getTasksByAssigneeUid(id),
          icon: this.getTitleIcon(id),
        };
      })
      this.isMapping = false
    },
    getTitleByUid(uid) {
      if (uid === auth.currentUser.uid) {
        return "Your Tasks";
      } else if (uid) {
        return this.userUidMap[uid] || uid;
      } else {
        return "Task Market";
      }
    },
    getTitleIcon(uid) {
      if (uid) {
        return "mdi-clipboard-account";
      } else {
        return "mdi-storefront";
      }
    },
    getTasksByAssigneeUid(uid) {
      return this.drawingTasks.tasks.filter((task) => task.assignedTo === uid);
    },
  },
};
</script>

<style lang="scss" scoped>
.taskContainer {
  min-height: 500px;
}
</style>
