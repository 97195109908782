<template>
  <div class="order-note-tooltip-container">
    <v-tooltip
      v-if="orderNote || orderNoteFiles?.length"
      max-width="300px"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="tooltip-activator-btn" @click="showDialog = true" icon v-bind="attrs" v-on="on">
          <v-icon color="warning"> mdi-note </v-icon>
        </v-btn>
      </template>
      <span
        >{{ orderNote }} <br v-if="orderNote" />
        <template v-if="orderNoteFiles?.length">
          <v-icon color="white" small>mdi-file-image</v-icon> x
          {{ orderNoteFiles?.length }}
        </template>
      </span>
    </v-tooltip>
    <v-btn class="tooltip-activator-btn" v-else @click="showDialog = true" icon>
      <v-icon :color="'rgba(217,217,217,0.4)'"> mdi-note </v-icon>
    </v-btn>
    <OrderNoteDialog
      v-if="showDialog"
      :task="task"
      :order="order"
      :show="showDialog"
      :on-close="() => (showDialog = false)"
      :on-save="onSave"
    />
  </div>
</template>

<script>
import OrderNoteDialog from "@/components/OrderNoteDialog";
export default {
  name: "OrderNoteTooltip",
  components: { OrderNoteDialog },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    orderNote() {
      return this.task ? this.task.orderNote : this.order.orderNote;
    },
    orderNoteFiles() {
      return this.task
        ? this.task.orderNoteFiles || []
        : this.order.orderNoteFiles || [];
    },
  },
  props: {
    task: {
      required: false,
    },
    order: {
      required: false,
    },
    onSave: {
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-activator-btn {
  background-color: #282828;
}
.order-note-tooltip-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
}
</style>
