<template>
  <div class="loader-info-container">
    <v-icon size="120">mdi-cards-outline</v-icon>
    <h1>{{ message }}</h1>
  </div>
</template>

<script>
export default {
  name: "NoResults",
  props: {
    message: {
      default: "No Current Tasks Available...",
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-info-container {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #858585;
}
</style>
