<template>
  <div>
    <AllMockups class="mt-6" />
    <v-expansion-panels focusable class="mt-6">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Create New
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CreateNewMockup />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Fallbacks
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <FallbackMockups/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CreateNewMockup from "@/components/Settings/Mockups/CreateNewMockup";
import AllMockups from "@/components/Settings/Mockups/AllMockups";
import FallbackMockups from "@/components/Settings/Mockups/FallbackMockups";
export default {
  components: {FallbackMockups, AllMockups, CreateNewMockup},
  data() {
    return {};
  },
};
</script>

<style scoped></style>
