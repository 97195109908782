<template>
  <v-container> <Login /> </v-container>
</template>

<script>
import Login from "@/components/AppLogin";
export default {
  name: "LoginView",
  components: { Login },
};
</script>

<style lang="scss" scoped></style>
