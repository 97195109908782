import { countryCodes } from "@/constants";
import { getTrackingUrl } from "@/utils";

export const mailTemplateTypes = {
  IMPORT: "IMPORT",
  REVIEW: "REVIEW",
  ALL: "ALL",
  REVISE: "REVISE",
  SUPPORT_DAMAGE: "SUPPORT_DAMAGE",
  SUPPORT: "SUPPORT",
  SUPPORT_LOST: "SUPPORT_LOST",
  TEMPLATING: "TEMPLATING",
};

export const getMailTemplatesInvalidImage = ({
  customerName,
  orderName,
  countryCode = "DE",
  shopId,
  orderKey,
  taskKey,
}) => {
  return [
    {
      id: "uploadAlternatives",
      label: "Upload Alternatives",
      supportedLanguages: ["DE", "ES", "PL", "FR"],
      message: uploadAlternatives({
        customerName,
        orderName,
        countryCode,
        shopId,
        orderKey,
        taskKey,
      }),
    },
  ];
};

export const getMailTemplatesImport = ({
  customerName,
  orderName,
  countryCode = "DE",
}) => {
  return [
    {
      id: "poorImage",
      label: "Poor Image Quality",
      supportedLanguages: ["DE", "ES", "PL"],
      message: poorImageMail({
        customerName,
        orderName,
        countryCode,
      }),
    },
    {
      id: "askOriginalImage",
      label: "Ask Original Image",
      supportedLanguages: ["DE", "ES", "PL"],
      message: askForOriginalImageMail({
        customerName,
        orderName,
        countryCode,
      }),
    },
    {
      id: "numberPlate",
      label: "Numberplate",
      supportedLanguages: ["DE"],
      message: numberPlateMail({ customerName, orderName, countryCode }),
    },
    {
      id: "unsuportedChars",
      label: "Unsupported Chars",
      supportedLanguages: ["DE"],
      message: unsupportedCharacters({ customerName, orderName, countryCode }),
    },
    {
      id: "poorImageReminder",
      label: "Poor Image Reminder",
      supportedLanguages: ["DE", "ES", "PL"],
      message: poorImageReminderMail({
        customerName,
        orderName,
        countryCode,
      }),
    },
  ].filter((messageTemplate) =>
    messageTemplate.supportedLanguages.includes(countryCode)
  );
};

export const getMailTemplatesFulfill = (order) => {
  return [
    {
      id: "missingStreetNumber",
      label: "Missing Street Number",
      message: missingStreetNumber(order),
    },
    {
      id: "addressFaulty",
      label: "Address Faulty",
      message: errorInAddressMail(order),
    },
    {
      id: "wrongEmail",
      label: "Wrong Email",
      message: wrongEmail(order),
    },
  ];
};

export const getMailTemplatesReview = ({
  customerName,
  orderName,
  countryCode = "DE",
}) => {
  return [
    {
      id: "changeFormatSingle",
      label: "Change Format (single)",
      message: changeFormatSingle({
        customerName,
        orderName,
        countryCode,
      }),
    },
    {
      id: "changeFormat",
      label: "Change Format",
      message: changeFormat({
        customerName,
        orderName,
        countryCode,
      }),
    },
    {
      id: "changeFormatReminder",
      label: "Change Format Reminder",
      message: changeFormatReminder({ customerName, orderName, countryCode }),
    },
  ];
};

export const getAllMailTemplates = ({
  customerName,
  orderName,
  countryCode = "DE",
  email,
}) => {
  return [
    {
      id: "changeFormatSingle",
      label: "Change Format (single)",
      message: changeFormatSingle({
        customerName,
        orderName,
        countryCode,
      }),
    },
    {
      id: "changeFormat",
      label: "Change Format",
      message: changeFormat({
        customerName,
        orderName,
        countryCode,
      }),
    },
    {
      id: "changeFormatReminder",
      label: "Change Format Reminder",
      message: changeFormatReminder({ customerName, orderName, countryCode }),
    },
    {
      id: "poorImage",
      label: "Poor Image Quality",
      message: poorImageMail({
        customerName,
        orderName,
        countryCode,
      }),
    },
    {
      id: "numberPlate",
      label: "Numberplate",
      message: numberPlateMail({ customerName, orderName, countryCode }),
    },
    {
      id: "reviseIllustration",
      label: "Revise Illustration",
      message: reviseIllustrationMail({ customerName, orderName, countryCode }),
    },
    {
      id: "wrongEmail",
      label: "Wrong Email",
      message: wrongEmail({
        customer: { firstName: customerName, email },
        orderName,
        countryCode,
      }),
    },
  ];
};

export const getReviseMailTemplates = ({
  customerName,
  orderName,
  countryCode = "DE",
}) => {
  return [
    {
      id: "reviseIllustration",
      label: "Revise Illustration",
      message: reviseIllustrationMail({ customerName, orderName, countryCode }),
    },
    {
      id: "reviseNewPhotoMail",
      label: "New Photo",
      message: reviseNewPhotoMail({ customerName, orderName, countryCode }),
    },
    {
      id: "reviseCancelWish",
      label: "Cancel Wish",
      message: reviseCancelOrderMail({ customerName, orderName, countryCode }),
    },
    {
      id: "missingTextBG",
      label: "Missing Text/BG",
      message: feedbackIllustrationPersonalisation({
        customerName,
        orderName,
        countryCode,
      }),
    },
  ];
};

const poorImageReminderMail = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName}, <br />vielen Dank für deine Bestellung :-).</p>
                 <p>Wir hatten dir vor einigen Tagen eine Mail gesendet und nach einem neuen Foto gefragt, da die Auflösung des von dir hochgeladenem Foto nicht ganz optimal ist. Leider haben wir diesbezüglich noch keine Rückmeldung von dir erhalten. Daher wollte ich dich nun erneut fragen, ob du ein anderes Foto für uns hast oder vielleicht die Möglichkeit hast, an die originale Datei (Direkt von dem Gerät, mit dem du das Foto aufgenommen hast) zu gelangen.</p>
                 <p>Sollten wir innerhalb der nächsten 24 Stunden keine Rückmeldung erhalten, werden wir das Portrait mit dem von dir eingereichten Foto anfertigen.</p>
                 <p>Ich hoffe sehr, dass dich diese E-Mail erreicht. 🙏</p>
                 <p>Liebe Grüße,<br />Anna
                 <p>
        `,
        subject: `WICHTIG! Wir benötigen eine Rückmeldung von Dir | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName}, <br />gracias por tu pedido :-)</p>
                 <p>Te enviamos un correo electrónico hace unos días pidiéndote una nueva foto, ya que la resolución de la foto que subiste no es del todo óptima. Lamentablemente, todavía no hemos recibido ninguna respuesta tuya al respecto. Por ello, quería preguntarte de nuevo si tienes otra foto para nosotros o si tienes la posibilidad de obtener el archivo original (directamente del dispositivo con el que tomaste la foto).</p>
                 <p>Si no recibimos respuesta en las próximas 24 horas, haremos el retrato con la foto que nos enviaste.</p>
                 <p>Un saludo,<br />Lisa
                 <p>
        `,
        subject: `¡IMPORTANTE! Necesitamos tu comentarios | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
              <div class="container">
                 <p>Cześć ${customerName}, <br /> Dziękujemy za Twoje zamówienie :-).</p>
                 <p>Kilka dni temu wysłaliśmy do Ciebie wiadomość e-mail z prośbą o przesłanie nowego zdjęcia, ponieważ rozdzielczość przesłanego przez Ciebie zdjęcia nie jest optymalna. Niestety nie otrzymaliśmy jeszcze od Ciebie żadnej informacji zwrotnej w tej sprawie. Dlatego chciałem ponownie zapytać, czy masz dla nas inne zdjęcie lub czy masz możliwość uzyskania oryginalnego pliku (bezpośrednio z urządzenia, za pomocą którego zrobiłeś zdjęcie).</p>
                 <p>Jeśli nie odezwiemy się w ciągu następnych 24 godzin, wykonamy portret na podstawie przesłanego przez Ciebie zdjęcia.</p>
                 <p>Mam nadzieję, że ten e-mail do Ciebie dotrze. 🙏</p>
                 <p>Serdecznie pozdrawiam,<br />Lisa
                 <p>
        `,
        subject: `WAŻNE! Potrzebujemy Twojej Informacje zwrotne | Artkwarela.pl ${orderName}`,
      };
  }
};

const askForOriginalImageMail = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName}, <br />vielen Dank für deine Bestellung :-).</p>
                 <p>Leider eignet sich das von dir hochgeladene Foto nicht optimal für ein Aquarell-Portrait, da die Auflösung etwas zu gering ist :-/. Hast du von dem Foto vielleicht auch die originale Datei direkt von dem Gerät mit dem das Foto aufgenommen wurde (Keine Weiterleitung über WhatsApp)? Diese kannst du mir gerne als Antwort auf diese E-Mail zukommen lassen. Bitte achte darauf, dass du nicht die selbe Datei schickst, die du bereits auf unserer Website verwendet hast. Gerne kannst du uns auch eine Auswahl anderer Fotos senden und wir schauen was am besten geeignet ist.</p>
                 <p>Solltest du kein anderes Foto haben, können wir es auch mit diesem versuchen. Bevor wir dein Wandbild in die Produktion geben würden, würden wir dir das Ergebnis per Mail zukommen lassen und dich bitten, uns Feedback zu geben, ob du mit dem Ergebnis zufrieden bist :)</p>
                 <p>Ich entschuldige mich für die Umstände, allerdings wollen wir immer sicherstellen, dass wir das bestmögliche Ergebnis erzielen.</p>
                 <p>Ich freue mich auf deine Rückmeldung,<br />Anna
                 <p>
        `,
        subject: `Die Auflösung deines Fotos ist nicht optimal | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName}, <br />gracias por tu pedido :-)</p>
                 <p>Por desgracia, la foto que has subido no es ideal para un retrato en acuarela, ya que la resolución es un poco demasiado baja :-/. ¿Tienes quizás el archivo original de la foto directamente desde el dispositivo con el que se tomó la foto (no reenviarlo por WhatsApp)? Asegúrate de no enviar el mismo archivo que ya has utilizado en nuestro sitio web. Puedes enviármelo como respuesta a este correo electrónico.</p>
                 <p>Si no tienes otra foto, también podemos probar con ésta. Antes de poner tu lienzo en producción, te enviaríamos el resultado por correo electrónico y te pediríamos que nos dieras tu opinión si estás contento con el resultado.</p>
                 <p>Disculpa las molestias, pero siempre queremos asegurarnos de obtener el mejor resultado posible.</p>
                 <p>Espero tus comentarios,<br />Lisa
                 <p>
        `,
        subject: `La resolución de tu foto no es óptima | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
              <div class="container">
                 <p>Cześć ${customerName}, <br /> Dziękujemy za Twoje zamówienie :-).</p>
                 <p>Niestety, przesłane przez Ciebie zdjęcie nie jest idealne do portretu akwarelowego, ponieważ rozdzielczość jest nieco za niska :-/. Czy masz może oryginalny plik zdjęcia bezpośrednio z urządzenia, którym zostało zrobione (bez przesyłania dalej przez WhatsApp)? Upewnij się, że nie wysyłasz tego samego pliku, który został już użyty na naszej stronie internetowej. Możesz mi go przesłać jako odpowiedź na ten e-mail.</p>
                 <p>Jeśli nie masz innego zdjęcia, możemy również spróbować z tym. Zanim wprowadzimy Twój plakat / płótno do produkcji, wyślemy Ci e-mailem wynik i poprosimy o opinię, czy jesteś zadowolony z wyniku :)</p>
                 <p>Przepraszam za okoliczności, ale zawsze chcemy mieć pewność, że osiągniemy najlepszy możliwy wynik.</p>
                 <p>Czekam na twoją Informacje zwrotne,<br />Lisa
                 <p>
        `,
        subject: `Rozdzielczość Twojego zdjęcia nie jest optymalna | Artkwarela.pl ${orderName}`,
      };
  }
};

const uploadAlternatives = ({
  customerName,
  orderName,
  countryCode,
  shopId,
  orderKey,
  taskKey,
}) => {
  const template = {
    name: `action_invalid_image_${countryCode}_${shopId}`,
    data: {
      uploadAlternativesUrl: getTrackingUrl(countryCode, orderKey),
      noAlternativesUrl: getTrackingUrl(
        countryCode,
        orderKey,
        `?noAlternatives=${taskKey}`
      ),
    },
  };

  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
          <p>Hallo ${customerName},<br/>Vielen Dank für deine Bestellung 🥰</p>
          <p>Leider ist dein hochgeladenes Foto nicht ganz optimal für die Anfertigung eines Portraits geeignet, da die Auflösung
          sehr gering ist. Am besten wäre es, wenn du uns von dem hochgeladenen Foto die originale Datei zukommen lassen könntest.
          Sollte diese nicht zur Verfügung stehen, kannst du uns auch gerne Alternativen senden. Klicke einfach auf den Link und
           lade bis zu 4 Dateien hoch und wir schauen dann, welches der Fotos am besten geeignet ist.</p>
          <p>Solltest du kein anderes Foto haben, können wir auch mit deinem ursprünglichen Foto ein schönes Ergebnis erzielen.
          Bevor wir dein Wandbild in Produktion geben, erhältst du dein Portrait als Bilddatei per E-Mail und kannst uns dann gerne
           Feedback geben :)</p>
          <p>Wir entschuldigen uns für die Umstände, allerdings wollen wir immer sicherstellen, dass wir das bestmögliche Ergebnis erzielen.</p>
        `,
        subject: `Dein Foto eignet sich nicht optimal für ein Portrait | Artquarell.com ${orderName}`,
        template,
      };
    case countryCodes.FR:
      return {
        text: `
    <p>Bonjour ${customerName},<br/>Merci beaucoup pour votre commande 🥰</p>
    <p>Malheureusement, la photo que vous avez téléchargée n'est pas tout à fait optimale pour la réalisation d'un portrait, car la résolution
    est très faible. Il serait préférable que vous nous envoyiez le fichier original de la photo téléchargée.
    Si celui-ci n'est pas disponible, vous pouvez également nous envoyer des alternatives. Cliquez simplement sur le lien et
    téléchargez jusqu'à 4 fichiers, et nous verrons laquelle des photos est la plus appropriée.</p>
    <p>Si vous n'avez pas d'autre photo, nous pouvons également obtenir un beau résultat avec votre photo originale.
    Avant que nous mettions votre tableau en production, vous recevrez votre portrait sous forme de fichier image par e-mail, et vous pourrez
    alors nous donner vos commentaires :)</p>
    <p>Nous nous excusons pour les désagréments, mais nous tenons toujours à vous offrir le meilleur résultat possible.</p>
  `,
        subject: `Votre photo n'est pas optimale pour un portrait | Artquarelle.fr ${orderName}`,
        template: template,
      };
    case countryCodes.ES:
      return {
        text: `
          <p>Hola ${customerName}, <br />gracias por tu pedido 🥰</p>
          <p>Lamentablemente, la foto que has subido no es muy adecuada para hacer un retrato, ya que la resolución es muy baja. Lo mejor sería que nos enviaras el archivo original de la foto subida. Si no es posible, también puedes enviarnos alternativas. Sólo tienes que hacer clic en el enlace y subir hasta 4 archivos, y entonces veremos cuál de las fotos es la más adecuada. </p>
          <p>Si no tienes otra foto, también podemos conseguir un bonito resultado con tu foto original. Antes de que pongamos tu mural en producción, recibirás tu retrato como archivo de imagen por correo electrónico y podrás darnos tu opinión :)</p>
          <p>Pedimos disculpas por las molestias, pero siempre queremos asegurarnos de conseguir el mejor resultado posible.</p>
        `,
        subject: `Tu foto no es ideal para un retrato | Artcuarela.es ${orderName}`,
        template,
      };
    case countryCodes.PL:
      return {
        text: `
          <p>Cześć ${customerName}, <br /> Dziękujemy za Twoje zamówienie 🥰</p>
          <p>Niestety, przesłane przez Ciebie zdjęcie nie nadaje się do wykonania portretu, ponieważ jego rozdzielczość jest bardzo niska. Byłoby najlepiej, gdybyś mógł przesłać nam oryginalny plik przesłanego zdjęcia. Jeśli nie jest to możliwe, możesz również przesłać nam alternatywę. Po prostu kliknij link i prześlij maksymalnie 4 pliki, a my sprawdzimy, które z nich są najbardziej odpowiednie. </p>
          <p>Jeśli nie masz innego zdjęcia, możemy również osiągnąć piękny rezultat z Twoim oryginalnym zdjęciem. Zanim wprowadzimy Twoją fototapetę do produkcji, otrzymasz swój portret jako plik graficzny e-mailem i będziesz mógł przekazać nam swoją opinię :)</p>
          <p>Przepraszamy za niedogodności, ale zawsze chcemy mieć pewność, że osiągniemy najlepszy możliwy rezultat.</p>
        `,
        subject: `Twoje zdjęcie nie jest idealne do portretu | Artkwarela.pl ${orderName}`,
        template,
      };
  }
};

const poorImageMail = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName}, <br />vielen Dank für deine Bestellung :-).</p>
                 <p>Leider eignet sich das von dir hochgeladene Foto nicht optimal für ein Aquarell-Portrait, da die Auflösung zu gering ist :-/. Hast du vielleicht ein anderes Foto für mich oder dasselbe Foto in besserer Auflösung? Dieses kannst du mir gerne als Antwort auf diese E-Mail zukommen lassen :-).</p>
                  <p>Solltest du kein anderes Foto haben, können wir es auch mit diesem versuchen. Bevor wir dein Wandbild in die Produktion geben würden, würden wir dir das Ergebnis per Mail zukommen lassen und dich bitten, uns Feedback zu geben, ob du mit dem Ergebnis zufrieden bist :)</p>
                 <p>Ich entschuldige mich für die Umstände, allerdings wollen wir immer sicherstellen, dass wir das bestmögliche Ergebnis erzielen.</p>
                 <p>Ich freue mich auf deine Rückmeldung,<br />Anna
                 <p>
        `,
        subject: `Die Auflösung deines Fotos ist nicht optimal | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName}, <br />gracias por tu pedido :-)</p>
                 <p>Lamentablemente, la foto que has subido no es ideal para un retrato en acuarela porque la resolución es demasiado baja :-/. ¿Tienes otra foto para mí o la misma foto en mejor resolución? Puede enviármelo como respuesta a este correo electrónico :-).</p>
                 <p>Si no tienes otra foto, también podemos probar con esta, pero puede que no quede muy nítida en el lienzo.</p>
                 <p>Espero tus comentarios,<br />Lisa
                 <p>
        `,
        subject: `La resolución de tu foto no es óptima | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
              <div class="container">
                 <p>Cześć ${customerName}, <br /> Dziękujemy za Twoje zamówienie :-).</p>
                 <p>Niestety zdjęcie, które przesłałeś nie jest idealne do portretu akwarelowego, ponieważ rozdzielczość jest zbyt niska :-/. Czy masz dla mnie inne zdjęcie lub to samo zdjęcie w lepszej rozdzielczości? Możesz mi je przesłać jako odpowiedź na ten e-mail :-).</p>
                 <p>Jeśli nie masz innego zdjęcia, możemy również wypróbować to, ale może ono nie być całkowicie ostre na płótnie.</p>
                 <p>Czekam na twoją Informacje zwrotne,<br />Lisa
                 <p>
        `,
        subject: `Rozdzielczość Twojego zdjęcia nie jest optymalna | Artkwarela.pl ${orderName}`,
      };
  }
};

const unsupportedCharacters = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName}, <br />vielen Dank für deine Bestellung :-).</p>
                 <p>Leider enthält deine Personalisierung Kyrillische Schriftzeichen, die von deiner gewählten Schriftart nicht unterstütz werden. Anbei findest du eine Version deiner Druckvorlage, in der wir die Schriftart ausgewechselt haben. Ist es so für dich in Ordnung?</p>
                 <p>Ich freue mich auf deine Rückmeldung.</p>
                 <p>Liebe Grüße,<br />Anna
                 <p>
        `,
        subject: `Änderungsvorschlag für deine Bestellung | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName}, <br />Gracias por tu pedido :-).</p>
                 <p>Lamentablemente, tu personalización contiene caracteres cirílicos que no son compatibles con el tipo de letra que has elegido. Te adjuntamos una versión de tu diseño en la que hemos cambiado el tipo de letra. ¿Te parece bien?</p>
                 <p>Espero tus comentarios,<br />Anna
                 <p>
        `,
        subject: `Sugerencia para cambiar tu pedido | Artcuarela.es ${orderName}`,
      };
  }
};

const numberPlateMail = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName}, <br />vielen Dank für deine Bestellung :-).</p>
                 <p>Wir haben bei deiner Bestellung festgestellt, dass du das Nummernschild weg retuschiert hast. Gerne könnten wir auch ein eigenes Nummernschild einsetzen. Lass uns wissen, ob wir dies machen sollen :)</p>
                 <p>Ich freue mich auf deine Rückmeldung,<br />Anna
                 <p>
        `,
        subject: `Änderungsvorschlag für deine Bestellung | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName}, <br />Gracias por tu pedido :-).</p>
                 <p>Hemos observado en tu pedido que había retocado la matrícula. Estaremos encantados de utilizar tu propia matrícula. Díganos si deberíamos hacerlo :)</p>
                 <p>Espero tus comentarios,<br />Anna
                 <p>
        `,
        subject: `Sugerencia para cambiar tu pedido | Artcuarela.es ${orderName}`,
      };
  }
};

const changeFormat = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName},</p>
                 <p>Bei der Produktion deiner Bestellung ist uns aufgefallen, dass bei dem gewählten Format relativ viel Freifläche entsteht. Wir würden dir daher vorschlagen, für deine Illustration das Format zu wechseln. Anbei sende ich dir mal beide Versionen.</p>
                 <p>Welche Version gefällt dir besser? Hochkant oder im Querformat?😊</p>
                 <p>Ich freue mich auf deine Rückmeldung,<br />Anna
                 <p>
        `,
        subject: `Änderungsvorschlag für deine Bestellung | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName}, <br />gracias por tu pedido :-)</p>
                 <p>Durante la producción de tu lienzo, hemos observado que hay una cantidad relativamente grande de espacio libre en el formato seleccionado. Por lo tanto, le sugerimos que cambie el formato de tu ilustración. Adjunto le envío las dos versiones.</p>
                 <p>¿Qué versión le gusta más? ¿Formato vertical u horizontal?😊</p>
                 <p>Espero tus comentarios,<br />Lisa
                 <p>
        `,
        subject: `Sugerencia para cambiar tu pedido | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
              <div class="container">
                 <p>Cześć ${customerName},</p>
                 <p>Podczas produkcji Twojego płótna zauważyliśmy, że w wybranym formacie jest stosunkowo dużo wolnego miejsca. W związku z tym sugerujemy zmianę formatu Twojej ilustracji. W załączeniu przesyłam obie wersje.</p>
                 <p>Która wersja bardziej Ci się podoba? Format pionowy czy poziomy?😊</p>
                 <p>Czekam na twoją Informacje zwrotne,<br />Lisa <p>
        `,
        subject: `Propozycja zmiany dla Twojego zamówienia | Artkwarela.pl ${orderName}`,
      };
  }
};

const changeFormatSingle = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName},</p>
                 <p>Bei der Produktion deiner Bestellung ist uns aufgefallen, dass bei dem gewählten Format relativ viel Freifläche entsteht. Wir würden dir daher vorschlagen, für deine Illustration das Format zu wechseln. Anbei sende ich dir mal die angepasste Version.</p>
                 <p>Bist du mit dem Änderungsvorschlag einverstanden?😊</p>
                 <p>Ich freue mich auf deine Rückmeldung,<br />Anna
                 <p>
        `,
        subject: `Änderungsvorschlag für deine Bestellung | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName}, <br />gracias por tu pedido :-)</p>
                 <p>Durante la producción de tu pedido, hemos observado que el formato seleccionado crea una cantidad relativamente grande de espacio libre. Por ello, te sugerimos que cambies el formato de tu ilustración. Te adjuntamos la versión personalizada.</p
                 <p>¿Estás satisfecho con el cambio propuesto? 😊</p>
                 <p>Espero tus comentarios,<br />Lisa
                 <p>
        `,
        subject: `Sugerencia para cambiar tu pedido | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
              <div class="container">
                 <p>Cześć ${customerName},</p>
                 <p>Podczas produkcji Twojego zamówienia zauważyliśmy, że wybrany format tworzy stosunkowo dużą ilość wolnego miejsca. W związku z tym sugerujemy zmianę formatu ilustracji. W załączniku znajdziesz dostosowaną wersję.</p
                 <p>Czy jesteś zadowolony z proponowanej zmiany?</p>
                 <p>Czekam na twoją Informacje zwrotne,<br />Lisa <p>
        `,
        subject: `Propozycja zmiany dla Twojego zamówienia | Artkwarela.pl ${orderName}`,
      };
  }
};

const changeFormatReminder = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName},</p>
                 <p>Bei der Produktion deiner Bestellung ist uns aufgefallen, dass bei dem gewählten Format relativ viel Freifläche entsteht. Wir würden dir daher vorschlagen, für deine Illustration das Format zu wechseln. Wir hatten dir vor einigen Tagen bereits eine Mail mit beiden Versionen gesendet. 😊 Welche Version gefällt dir besser? Hochkant oder im Querformat?😊</p>
                 <p>Sollten wir keine Antwort bis heute Abend 24:00 Uhr erhalten, geben wir deine Leinwand im ursprünglich bestellten Formtat in Produktion. Ich hoffe sehr, dass dich diese Mail erreicht.</p>
                 <p>Liebe Grüße,<br />Anna
                 <p>
        `,
        subject: `WICHTIG Wir brauchen deine Rückmeldung | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName}, <br />gracias por tu pedido :-)</p>
                 <p>Durante la producción de tu pedido, hemos observado que hay una cantidad relativamente grande de espacio libre en el formato seleccionado. Por tanto, te sugerimos que cambies el formato de tu ilustración. Ya te enviamos un correo electrónico con ambas versiones hace unos días. 😊 ¿Qué versión te gusta más? ¿Formato vertical u horizontal?</p>
                 <p>Si no recibimos respuesta antes de esta medianoche, pondremos tu mural en producción en el formato que pediste originalmente. Espero que te llegue este correo electrónico.</p>
                 <p>Un saludo,<br />Lisa
                 <p>
        `,
        subject: `IMPORTANTE Necesitamos tu opinión | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
              <div class="container">
                 <p>Witaj ${customerName},</p>
                 <p>Podczas produkcji Twojego zamówienia zauważyliśmy, że w wybranym formacie jest stosunkowo dużo wolnego miejsca. W związku z tym sugerujemy zmianę formatu ilustracji. Kilka dni temu wysłaliśmy Ci wiadomość e-mail z obiema wersjami. Która wersja bardziej Ci się podoba? Format pionowy czy poziomy?</p>
                 <p>Jeśli nie otrzymamy odpowiedzi do północy dzisiejszego wieczoru, wprowadzimy Twój mural do produkcji w pierwotnie zamówionym formacie. Mam nadzieję, że ten e-mail do Ciebie dotrze.</p>
                 <p>Pozdrawiam serdecznie,<br />Lisa <p>
        `,
        subject: `WAŻNE Potrzebujemy Twojej opinii | Artkwarela.pl ${orderName}`,
      };
  }
};

const missingStreetNumber = (order) => {
  const shippingAddress = order.shippingAddress;
  const changeAddressUrl = getTrackingUrl(
    order.countryCode,
    order.orderKey,
    "/change-address"
  );
  switch (order.countryCode) {
    case countryCodes.DE:
      return {
        text: `
          <p>Hallo ${order.customer.firstName},</p>
          <p>bei der Verarbeitung deiner Bestellung ist uns aufgefallen, dass bei der Adresse "${shippingAddress.addressPrimary}" leider eine Hausnummer fehlt.
          Wir möchten dich bitten, über den folgenden Link deine Adresse zu aktualisieren: <a href="${changeAddressUrl}">${changeAddressUrl}</a></p>
          <p>Sobald die neue Adresse aktualisiert und validiert ist, wird dein Paket automatisch für die Produktion freigegeben.</p>
          <p>Wir danken dir im Voraus für deine Mithilfe und freuen uns, deine Bestellung bald versenden zu dürfen.<p />
          <p>Liebe Grüße,<br />Anna</p>
        `,
        subject: `Deine Hausnummer fehlt | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
          <p>Hola ${order.customer.firstName},</p>
          <p>Al procesar tu pedido, hemos notado que falta un número de casa en la dirección "${shippingAddress.addressPrimary}".
          Te pedimos que actualices tu dirección utilizando el siguiente enlace: <a href="${changeAddressUrl}">${changeAddressUrl}</a></p>
          <p>Una vez que la nueva dirección esté actualizada y validada, tu paquete se liberará automáticamente para la producción.</p>
          <p>Te agradecemos de antemano por tu colaboración y esperamos poder enviar tu pedido pronto.</p>
          <p>Un Saludo,<br />Lisa</p>
  `,
        subject: `Falta el número de tu casa | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
          <p>Cześć ${order.customer.firstName},</p>
          <p>Przetwarzając Twoje zamówienie, zauważyliśmy, że brakuje numeru domu w adresie "${shippingAddress.addressPrimary}".
          Prosimy o aktualizację Twojego adresu za pomocą poniższego linku: <a href="${changeAddressUrl}">${changeAddressUrl}</a></p>
          <p>Po aktualizacji i weryfikacji nowego adresu Twoja paczka zostanie automatycznie uwolniona do produkcji.</p>
          <p>Z góry dziękujemy za Twoją współpracę i czekamy na możliwość wysłania Twojego zamówienia.</p>
          <p>Pozdrowienia,<br />Lisa</p>
  `,
        subject: `Brakuje numeru domu | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const errorInAddressMail = (order) => {
  const shippingAddress = order.shippingAddress;
  const changeAddressUrl = getTrackingUrl(
    order.countryCode,
    order.orderKey,
    "/change-address"
  );

  switch (order.countryCode) {
    case countryCodes.DE:
      return {
        text: `
          <p>Hallo ${order.customer.firstName},</p>
          <p>leider gab es beim Versand deiner Bestellung ein Problem, da DHL die angegebene Adresse als fehlerhaft identifiziert hat. Wir bitten dich, die von dir hinterlegte Adresse zu überprüfen:</p>
          <p>${shippingAddress.firstName} ${shippingAddress.lastName}<br/>
          ${shippingAddress.addressPrimary}<br/>
          ${shippingAddress.addressSecondary}<br/>
          ${shippingAddress.postCode} ${shippingAddress.city}</p>
          <p>Solltest du einen Fehler feststellen, aktualisiere bitte über den folgenden Link deine Adresse: <a href="${changeAddressUrl}">${changeAddressUrl}</a>. Sobald deine Adresse aktualisiert und überprüft ist, wird dein Paket umgehend für den Versand freigegeben.</p>
          <p>Falls du die Adresse als korrekt ansiehst, lass es uns bitte wissen. Wir werden dann umgehend das Problem mit DHL klären.</p>
          <p>Es tut mir leid, dass es zu Unannehmlichkeiten gekommen ist.</p>
          <p>Liebe Grüße,<br />Anna</p>
        `,
        subject: `Dein Paket konnte nicht verchickt werden | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
          <p>Hola ${order.customer.firstName},</p>
          <p>lamentablemente hubo un problema con el envío de tu pedido, ya que DHL identificó la dirección proporcionada como incorrecta. Te pedimos que verifiques la dirección que nos has proporcionado:</p>
          <p>${shippingAddress.firstName} ${shippingAddress.lastName}<br/>
          ${shippingAddress.addressPrimary}<br/>
          ${shippingAddress.addressSecondary}<br/>
          ${shippingAddress.postCode} ${shippingAddress.city}</p>
          <p>Si identificas algún error, por favor actualiza tu dirección utilizando el siguiente enlace: <a href="${changeAddressUrl}">${changeAddressUrl}</a>. Una vez que tu dirección haya sido actualizada y verificada, tu paquete será liberado inmediatamente para el envío.</p>
          <p>Si consideras que la dirección es correcta, por favor háznoslo saber. Nos pondremos en contacto con DHL de inmediato para resolver el problema.</p>
          <p>Lamentamos los inconvenientes que esto haya podido causarte.</p>
          <p>Saludos cordiales,<br />Lisa</p>
        `,
        subject: `No se ha podido enviar tu paquete | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
          <p>Witaj ${order.customer.firstName},</p>
          <p>niestety pojawił się problem z wysyłką twojego zamówienia, ponieważ DHL zidentyfikował podany adres jako błędny. Prosimy o sprawdzenie adresu, który nam podałeś:</p>
          <p>${shippingAddress.firstName} ${shippingAddress.lastName}<br/>
          ${shippingAddress.addressPrimary}<br/>
          ${shippingAddress.addressSecondary}<br/>
          ${shippingAddress.postCode} ${shippingAddress.city}</p>
          <p>Jeśli zauważysz jakiś błąd, proszę zaktualizuj swój adres za pomocą poniższego linku: <a href="${changeAddressUrl}">${changeAddressUrl}</a>. Gdy Twój adres zostanie zaktualizowany i zweryfikowany, twoje zamówienie zostanie natychmiast zwolnione do wysyłki.</p>
          <p>Jeśli uważasz, że adres jest poprawny, proszę daj nam znać. Skontaktujemy się z DHL, aby rozwiązać problem.</p>
          <p>Przepraszamy za wszelkie niedogodności, które mogły powstać.</p>
          <p>Serdecznie pozdrawiam,<br />Lisa</p>
        `,
        subject: `Twoja paczka nie mogła zostać wysłana | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const wrongEmail = (order) => {
  const shippingAddress = order.shippingAddress;
  switch (order.countryCode) {
    case countryCodes.DE:
      return {
        text: `
          <p>Hallo ${order.customer.firstName},</p>
          <p>Bei der Überprüfung ausstehender Bestellungen ist uns eine Bestellung aufgefallen, bei der vergessen wurde, eine Hausnummer anzugeben. Sie liegt daher nun seit 1 Woche bei uns im System und kann nicht ausgeführt werden.</p>
          <p>Auf Nachfragen an die angegebene E-Mail "${order.customer.email}" haben wir leider keine Rückmeldung bekommen. Ich gehe davon aus, dass es zu einem Tippfehler gekommen ist und schreibe daher nun an dich, in der Hoffnung, dass ich richtig liege.</p>
          <p>Sollte dies der Fall sein, bitte ich dich zu Sicherheit einmal deinen vollen Namen zu nennen und uns deine Straße inklusive fehlender Hausnummer zu senden.</p>
          <p>Solltest du keine Bestellung bei uns aufgegeben haben, ignoriere bitte diese Mail.</p>
          <p>Liebe Grüße,<br />Anna von Artquarell</p>
        `,
        subject: `Hast du eine Bestellung bei uns aufgegeben?| Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
          <p>Hola ${order.customer.firstName},</p>
          <p>Revisando los pedidos pendientes, hemos notado uno en el que se olvidó incluir un número de casa. Por lo tanto, ha estado en nuestro sistema durante 1 semana y no podemos procesarlo.</p>
          <p>Al hacer preguntas al correo electrónico proporcionado "${order.customer.email}", lamentablemente no recibimos respuesta. Supongo que ha habido un error de tipeo y por eso te escribo ahora, esperando haber acertado.</p>
          <p>Si este es el caso, te pido por seguridad que nos proporciones tu nombre completo y nos envíes tu calle, incluyendo el número de casa que falta.</p>
          <p>Si no has hecho un pedido con nosotros, por favor ignora este correo.</p>
          <p>Saludos cordiales,<br />Lisa de Artquarell</p>
        `,
        subject: `¿Nos has hecho un pedido? | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
          <p>Cześć ${order.customer.firstName},</p>
          <p>Przy przeglądaniu zaległych zamówień zauważyliśmy, że przy jednym z nich zabrakło podania numeru domu. Dlatego zamówienie to od tygodnia jest w naszym systemie i nie może być zrealizowane.</p>
          <p>Po zapytaniach na podany adres e-mail "${order.customer.email}" niestety nie otrzymaliśmy odpowiedzi. Zakładam, że mogło dojść do literówki, dlatego piszę teraz do ciebie, w nadziei, że trafiłem dobrze.</p>
          <p>Jeśli to prawda, proszę cię dla pewności o podanie pełnego imienia i nazwiska oraz wysłanie nam swojej ulicy wraz z brakującym numerem domu.</p>
          <p>Jeśli nie złożyłeś zamówienia u nas, zignoruj proszę tego maila.</p>
          <p>Serdecznie pozdrawiam,<br />Lisa z Artquarell</p>
        `,
        subject: `Czy złożyłeś u nas zamówienie? | Artkwarela.pl ${order.orderName}`,
      };
    default:
      // Fallback to English
      return {
        text: `
          <p>Hello ${order.customer.firstName},</p>
          <p>While checking pending orders, we noticed one order where the house number was missing in the address "${shippingAddress.addressPrimary}".
          We kindly ask you to update your address using the following link: <a href="${changeAddressUrl}">${changeAddressUrl}</a></p>
          <p>Once the new address is updated and validated, your package will be automatically released for production.</p>
          <p>We thank you in advance for your cooperation and look forward to being able to send your order soon.</p>
          <p>Best regards,<br />Lisa</p>
        `,
        subject: `Did you order with us? | Artquarell.com ${order.orderName}`,
      };
  }
};

const reviseIllustrationMail = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName},</p>
                 <p>Vielen Dank für dein Feedback! Wir schätzen deine Meinung sehr und werden umgehend daran arbeiten, die Illustration nach deinen Wünschen anzupassen. Du kannst mit einer überarbeiteten Version innerhalb der nächsten 48 Stunden rechnen.</p>
                 <p>Wir hoffen, dass die neuen Änderungen genau deinen Vorstellungen entsprechen. Bitte nutze bei Erhalt des Ergebnis erneut die bereitgestellten Optionen, um uns dein Feedback mitzuteilen.</p>
                 <p>Liebe Grüße,<br />Anna🌟
                 <p>
        `,
        subject: `Wir überarbeiten deine Illustration | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName},</p>
                 <p>Muchas gracias por tu comentario. Apreciamos mucho tu opinión y trabajaremos inmediatamente para adaptar la ilustración según tus deseos. Puedes esperar una versión revisada en las próximas 48 horas.</p>
                 <p>Esperamos que los nuevos cambios sean exactamente lo que deseas. Cuando recibas el resultado, te rogamos que vuelvas a utilizar las opciones previstas para enviarnos tu opinión.</p>
                 <p>Un saludo,<br />Lisa 🌟
                 <p>
        `,
        subject: `Revisamos tu ilustración | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
              <div class="container">
                 <p>Cześć ${customerName},</p>
                 <p>Bardzo dziękujemy za Twoją opinię! Bardzo doceniamy Twoją opinię i niezwłocznie podejmiemy prace nad dostosowaniem ilustracji do Twoich życzeń. Możesz spodziewać się poprawionej wersji w ciągu najbliższych 48 godzin.</p>
                 <p>Mamy nadzieję, że nowe zmiany będą dokładnie tym, czego oczekujesz. Gdy otrzymasz wynik, skorzystaj ponownie z dostępnych opcji, aby przesłać nam swoją opinię.</p>
                 <p>Mam nadzieję, że ten e-mail do Ciebie dotrze. 🙏</p>
                 <p>Serdecznie pozdrawiam,<br />Lisa 🌟
                 <p>
        `,
        subject: `Poprawimy twoją ilustrację | Artkwarela.pl ${orderName}`,
      };
  }
};

const reviseCancelOrderMail = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName},</p>
                 <p>vielen Dank für dein Feedback! Es tut uns leid zu hören, dass das Ergebnis noch nicht deinen Erwartungen entspricht. Gerne nehmen wir Anpassungen vor, um das Wandbild so zu gestalten, dass es dir gefällt.</p>
                 <p>Sollte dies für dich nicht infrage kommen, können wir deine Bestellung auch stornieren. Da es sich jedoch um ein personalisiertes Produkt handelt und wir bereits viel Arbeit in die Anfertigung gesteckt haben, müssten wir in diesem Fall einen Betrag von 10,00 € einbehalten, um den bereits entstandenen Aufwand zu decken.</p>
                 <p>Ich verstehe, dass dies möglicherweise nicht die Antwort ist, die du dir erhofft hast. Bitte lass mich wissen, wie du weiter vorgehen möchtest.</p>
                 <p>Liebe Grüße,<br />Anna
                 <p>
        `,
        subject: `Rückmeldung auf dein Feedback | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
          <div class="container">
             <p>Hola ${customerName},</p>
             <p>¡Muchas gracias por tus comentarios! Lamentamos escuchar que el resultado no cumplió con tus expectativas. Estaremos encantados de hacer ajustes para que el cuadro sea de tu agrado.</p>
             <p>Si esta opción no es adecuada para ti, también podemos cancelar tu pedido. Sin embargo, dado que se trata de un producto personalizado y ya hemos invertido mucho trabajo en su realización, en este caso deberíamos retener un importe de 10,00 € para cubrir parte de los costos ya incurridos.</p>
             <p>Entiendo que esta podría no ser la respuesta que esperabas. Por favor, háznos saber cómo te gustaría proceder.</p>
             <p>Un saludo,<br />Lisa</p>
          </div>
        `,
        subject: `Respuesta a tus comentarios | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
          <div class="container">
           <p>Hallo ${customerName},</p>
           <p>dziękujemy za Twoją opinię! Przykro nam słyszeć, że wynik nie spełnia Twoich oczekiwań. Chętnie wprowadzimy zmiany, aby obraz na ścianę odpowiadał Twoim oczekiwaniom.</p>
           <p>Jeśli jednak to rozwiązanie Ci nie odpowiada, możemy anulować Twoje zamówienie. Ponieważ jest to produkt spersonalizowany, a my już włożyliśmy dużo pracy w jego przygotowanie, w takim przypadku będziemy zmuszeni potrącić kwotę 10,00 €, aby pokryć część już poniesionych kosztów.</p>
           <p>Rozumiem, że to może nie być odpowiedź, której się spodziewałeś. Daj nam proszę znać, jak chciałbyś postąpić.</p>
           <p>Serdeczne pozdrowienia,<br />Lisa</p>
          </div>
        `,
        subject: `Odpowiedź na Twoją opinię | Artkwarela.pl ${orderName}`,
      };
  }
};

const reviseNewPhotoMail = ({ customerName, orderName, countryCode }) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName},</p>
                 <p>vielen Dank für dein Feedback! Gerne tauschen wir das Foto aus. Sende es uns dazu einfach als Antwort auf diese E-Mail zu. Wir machen uns dann schnellstmöglich erneut an die Arbeit.</p>
                 <p>Liebe Grüße,<br />Anna
                 <p>
        `,
        subject: `Rückmeldung auf dein Feedback | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
          <div class="container">
            <p>Hola ${customerName},</p>
            <p>¡Muchas gracias por tus comentarios! Con gusto cambiaremos la foto. Simplemente envíanosla respondiendo a este correo electrónico. Nos pondremos manos a la obra lo antes posible.</p>
            <p>Un saludo,<br />Anna</p>
          </div>
        `,
        subject: `Respuesta a tus comentarios | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
          <div class="container">
             <p>Hallo ${customerName},</p>
             <p>dziękujemy za Twoją opinię! Chętnie wymienimy zdjęcie. Prześlij je do nas, odpowiadając na tę wiadomość. Następnie jak najszybciej przystąpimy ponownie do pracy.</p>
             <p>Serdeczne pozdrowienia,<br />Lisa</p>
          </div>
        `,
        subject: `Odpowiedź na Twoją opinię | Artkwarela.pl ${orderName}`,
      };
  }
};

const feedbackIllustrationPersonalisation = ({
  customerName,
  orderName,
  countryCode,
}) => {
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
              <div class="container">
                 <p>Hallo ${customerName},</p>
                 <p>toll, dass dir das Portrait gefällt :) Wir versenden die Bilddatei immer ohne Personalisierung, auf deinem Wandbild wird diese aber natürlich berücksichtigt.</p>
                 <p>Dein Wandbild geht auch nun in Produktion und bald in den Versand.</p>
                 <p>Viele Grüße und viel Freude mit deinem Kunstwerk, <br />Anna🌟
                 <p>
        `,
        subject: `Rückmeldung zu deinem Feedback | Artquarell.com ${orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
              <div class="container">
                 <p>Hola ${customerName},</p>
                 <p>Me alegro de que te guste el retrato :) Siempre enviamos el archivo de imagen sin personalizar, pero por supuesto se incluirá en tu mural.</p>
                 <p>Tu mural está ahora también en producción y pronto se enviará.</p>
                 <p>Mis mejores deseos y disfruta de tu obra de arte,<br />Lisa 🌟
                 <p>
        `,
        subject: `Comentarios sobre tu opinión | Artcuarela.es ${orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
              <div class="container">
                 <p>Cześć ${customerName},</p>
                 <p>Świetnie, że podoba Ci się portret :) Zawsze wysyłamy plik graficzny bez personalizacji, ale oczywiście zostanie ona uwzględniona na Twojej fototapecie.</p>
                 <p>Twój mural jest teraz również w produkcji i wkrótce zostanie wysłany.</p>
                 <p>Mam nadzieję, że ten e-mail do Ciebie dotrze. 🙏</p>
                 <p>Najlepsze życzenia i ciesz się swoimi dziełami sztuki<br />Lisa
                 <p>
        `,
        subject: `Twoja opinia dla nas | Artkwarela.pl ${orderName}`,
      };
  }
};
