<template>
  <v-dialog
    @click:outside="onClose"
    dark
    persistent
    width="500"
    max-width="100%"
    :value="user"
  >
    <v-card>
      <v-card-text v-if="!transactionResponse">
        <v-tabs v-model="tab" dense centered dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#openPayment"> Open Payments</v-tab>
          <v-tab href="#closedPayments"> Payment History</v-tab>
        </v-tabs>

        <v-tabs-items dark v-model="tab">
          <v-tab-item value="openPayment">
            <div class="current-earnings-container">
              <div class="total-earnings">
                {{ currentPayment }}
              </div>
            </div>
            <h3>All Unpaid Tasks {{user.payment.unpaidTasks.length}}</h3>
            <UnpaidTasksList height="300" :payment="user.payment"/>
            <div v-if="duplicates.unpaidTasks.length">
              <h3>Duplicates! {{duplicates.unpaidTasks.length}}</h3>
              <UnpaidTasksList height="100" :payment="duplicates"/>
            </div>
          </v-tab-item>
          <v-tab-item value="closedPayments">
            <div class="current-earnings-container">
              <div class="total-earnings">
                {{ totalPayment }}
              </div>
            </div>
            <v-simple-table height="400">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Transaction ID</th>
                  <th class="text-left">Total Payed</th>
                  <th class="text-left">Transaction Date</th>
                </tr>
                </thead>
                <tbody v-if="user.payment?.transactionHistory.length">
                <tr
                  v-for="payment in user.payment?.transactionHistory"
                  :key="payment.transactionDate"
                >
                  <td>{{ payment.transactionId }}</td>
                  <td>{{ payment.totalPayed }}</td>
                  <td>
                    {{ new Date(payment.transactionDate).toDateString() }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <div class="pa-12" v-else>
        <v-text-field
          readonly
          outlined
          label="Total Amount"
          :value="transactionResponse.totalPayed + user.payment.currency"
        />
        <v-text-field
          readonly
          outlined
          label="Transaction Id"
          :value="transactionResponse.transactionId"
        />
      </div>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          :disabled="transactionResponse"
          color="success"
          :loading="transactionLoading"
          @click="payOutUser()"
        >{{ transactionResponse ? "Payed" : "Pay" }}
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UnpaidTasksList from "@/components/UnpaidTasksList";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../../fb";
import {EventBus, events} from "../../../../event-bus";

export default {
  name: "PayUserDialog",
  components: {UnpaidTasksList},
  data() {
    return {
      transactionResponse: null,
      transactionLoading: false,
      tab: null,
    };
  },
  props: {
    user: {
      default: null,
    },
    onClose: {
      required: true,
    },
  },
  computed: {
    duplicates() {
      const duplicates = this.user.payment?.unpaidTasks?.filter((item, index, self) => self.findIndex(t => t.completedTask === item.completedTask) !== index);
     return {
       unpaidTasks: duplicates
     }
    },
    currentPayment() {
      return this.user.payment
        ? this.user.payment?.unpaidTasks.reduce((accumulator, payment) => {
        return accumulator + payment.price;
      }, 0) + this.user.payment?.currency
        : undefined;
    },
    totalPayment() {
      let transactionHistory = this.user.payment?.transactionHistory;
      let totalPayment = 0;
      if (transactionHistory?.length > 0) {
        transactionHistory.forEach((transaction) => {
          totalPayment += transaction.totalPayed;
        });
      }
      return totalPayment + this.user.payment.currency;
    },
  },
  methods: {
    async payOutUser() {
      this.transactionLoading = true;
      const payOut = httpsCallable(functions, "payOutUser");
      const response = await payOut({
        userUid: this.user.uid,
      });
      if (response.data.success) {
        this.transactionResponse = response.data.transaction;
      } else {
        EventBus.$emit(events.SNACKBAR, {
          message: response.data.errorMessage,
          type: "error",
        });
      }
      this.transactionLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.current-earnings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
}

.total-earnings {
  font-size: 63px;
  line-height: 63px;
  font-weight: bold;
}
</style>
