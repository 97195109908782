<template>
  <div>
    <AllRatingsAdmin v-if="showAdminRatings"/>
    <AllRatingsArtist v-else />
  </div>
</template>

<script>
import AllRatingsAdmin from "@/components/AllRatings/AllRatingsAdmin";
import {mapGetters} from "vuex";
import AllRatingsArtist from "@/components/AllRatings/AllRatingsArtist";
import {permissions} from "@/constants";
export default {
  name: "AllRatingsView",
  components: {AllRatingsArtist, AllRatingsAdmin},
  computed: {
    ...mapGetters(["userPermissions"]),
    showAdminRatings() {
      return this.userPermissions.includes(permissions.ADMIN) || this.userPermissions.includes(permissions.MANAGE_RATINGS)
    }
  }
}
</script>

<style scoped>

</style>
