<template>
  <v-tooltip bottom v-if="orderCount > 1">
    <template v-slot:activator="{ on, attrs }">
      <div class="order-count-indicator-container" v-bind="attrs" v-on="on">
        <v-icon class="pr-1" dark>mdi-alert</v-icon>
        <div class="order-count-indicator-text">{{ orderCount }}. Order</div>
      </div>
    </template>
    <span>This is the {{ orderCount }} Order of the cusrtomer</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "OrderCountIndicator",
  props: {
    orderCount: {
      type: Number,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.order-count-indicator-container {
  height: 36px;
  min-width: 36px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  padding: 0 10px;
  user-select: none;
  background-color: #a20000;
}
.order-count-indicator-text {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
</style>
