<template>
  <div class="order-line-items-container">
    <v-progress-circular indeterminate v-if="loading"/>
    <v-row style="width: 100%" v-else>
      <v-col :key="index" v-for="(task, index) in orderTasks" cols="6">
        <v-card>
          <div v-if="task.fulfilled || task.canceled" class="card-overlay">
            <v-icon color="white" size="100">mdi-cancel</v-icon>
            <h3>{{ task.fulfilled ? "Already Fulfilled" : "Canceled" }}</h3>
          </div>
          <v-card-title>{{
              task.customPrintVariantName || task.printVariantName
            }}
            {{task.express ? ' (Express)' : ''}}
          </v-card-title>
          <v-card-text style="position: relative">
            <TaskDetailsCard :task="task"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TaskDetailsCard from "@/components/TaskCards/TaskDetailsCard";

export default {
  name: "OrderLineItems",
  components: {TaskDetailsCard},
  data() {
    return {
      loading: false,
      lineItems: [],
    };
  },
  props: {
    orderTasks: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.order-line-items-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
}

.card-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.36);
  color: #fff;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  object-fit: contain;
}
</style>
