<template>
  <v-expansion-panels class="actual-costs">
    <v-expansion-panel>
      <v-expansion-panel-header>
        Produktionskosten
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ProductionCosts :data="data" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ProductionCosts from "@/components/Settings/Statistics/ActualCosts/ProductionCosts/ProductionCosts.vue";

export default {
  name: "ActualCosts",
  components: {ProductionCosts},
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style scoped lang="scss">
.actual-costs {
  margin-top: 24px;
}
</style>