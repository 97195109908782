<template>
  <div>
    <ReviewDrawingTaskCard v-if="task.reviewType === 'DRAW'" :task="task" />
    <ReviewTemplatingTaskCard v-else-if="task.reviewType === 'TEMPLATING' || task.reviewType === 'REVIEW'" :task="task" />
  </div>
</template>
<script>

import ReviewDrawingTaskCard from "@/components/TaskCards/ReviewDrawingTakCard";
import ReviewTemplatingTaskCard from "@/components/TaskCards/ReviewTemplatingTakCard";

export default {
  name: "ReviewTaskCard",
  components: {ReviewTemplatingTaskCard, ReviewDrawingTaskCard},
  props: {
    task: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
