<template>
  <div>
    <v-row v-if="tasks.length">
      <v-col
        v-for="(task, index) in tasks"
        :key="task.uid"
        :cols="cols.cols"
        :sm="cols.sm * (task.numberOfSubTasks || 1)"
        :md="cols.md * (task.numberOfSubTasks || 1)"
        :lg="cols.lg * (task.numberOfSubTasks || 1)"
      >
        <v-lazy
          :options="{
          rootMargin: '500px'
        }"
          transition="fade-transition"
        >
          <component :is="CardComponent" :index="index" :task="task" :selected-assignee="selectedAssignee" :selected-edit-style="selectedEditStyle" />
        </v-lazy>
      </v-col>
    </v-row>
    <NoResults v-else />
  </div>
</template>

<script>
import NoResults from "@/components/NoResults";
export default {
  name: "CardsGrid",
  components: { NoResults },
  props: {
    CardComponent: {
      required: true,
    },
    selectedAssignee: { // Declare the selectedAssignee prop
      type: Object,
      default: () => ({}),
    },
    selectedEditStyle: {
      type: Object,
      default: () => ({}),
    },
    tasks: {
      type: Array,
      required: true,
    },
    cols: {
      default: function () {
        return {
          cols: "12",
          sm: "6",
          md: "4",
        };
      },
    },
  },
};
</script>

<style scoped></style>
