import Vue from "vue";

export const EventBus = new Vue();

export const events = {
  SNACKBAR: "SNACKBAR",
  ULTRA_UPSCALE_BY_ID: "ULTRA_UPSCALE_BY_ID_",
  REMOVE_TEMPLATE_PREVIEW_BY_ID: "REMOVE_TEMPLATE_PREVIEW_BY_ID",
  STOP_REMOVE_TEMPLATES: "STOP_REMOVE_TEMPLATES",
};
