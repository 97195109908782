<template>
  <div>
    <v-data-table
      :show-expand="true"
      :headers="headers"
      :items="filteredOrder"
      :loading="loading"
      item-key="uid"
      no-data-text="Quick Search only works for the latest 1000 Orders! If you can't find an order please use specific search (Click arrow next to Quick Search)"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 75],
      }"
    >
      <template v-slot:item.orderName="{ item }">
        <v-icon v-if="item.showOrderWarning" color="warning">mdi-alert</v-icon>
        <a class="link" @click="() => openOrder(item.uid)">{{ item.orderName }}</a>
      </template>
      <template v-slot:item.orderStatus="{ item }">
        {{ item.status }}
      </template>
      <template v-slot:item.orderDate="{ item }">
        {{ getOrderDateFromNow(item.orderDate) }}
      </template>
      <template v-slot:item.name="{ item }">
        {{
          item.shippingAddress.firstName + " " + item.shippingAddress.lastName
        }}
      </template>
      <template v-slot:item.numberOfTasks="{ item }">
        {{ item.tasks.length }}
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <v-icon @click="() => editOrder(item)">mdi-pencil</v-icon>
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td class="expanded-item-cell" :colspan="headers.length">
          <OrderTasks :order-tasks="item.tasks" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import OrderTasks from "@/components/AllOrders/components/OrderTasks";
import {getDateFromNow, openOrderModal} from "@/utils";
import { mapGetters } from "vuex";

export default {
  name: "OrdersTable",
  components: { OrderTasks },
  data() {
    return {
      headers: [
        { text: "Order Name", value: "orderName" },
        { text: "Order Status", value: "orderStatus" },
        { text: "Order Date", value: "orderDate" },
        { text: "Name", value: "name" },
        { text: "Number of Tasks", value: "numberOfTasks" },
        { text: "", value: "action" },
      ],
    };
  },
  props: {
    orders: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["filterQuickSearch"]),
    filteredOrder() {
      return this.orders.filter((item) => {
        // Perform the search on the relevant fields
        const name =
          item.shippingAddress.firstName + " " + item.shippingAddress.lastName;
        const searchText = this.filterQuickSearch.toLowerCase();

        // Return true if any relevant field matches the search query
        return (
          item.orderName.toLowerCase().includes(searchText) ||
          item.status.toLowerCase().includes(searchText) ||
          name.toLowerCase().includes(searchText) ||
          item.tasks.length.toString().includes(searchText) ||
          item?.shippingAddress?.addressPrimary
            ?.toLowerCase()
            .includes(searchText) ||
          item.status.toLowerCase().includes(searchText) ||
          item.uid.toString().includes(searchText) ||
          item.customer?.email?.toLowerCase().includes(searchText) ||
          item.customer?.name?.toLowerCase().includes(searchText)
        );
      });
    },
  },
  methods: {
    openOrder(uid) {
      openOrderModal(uid)
    },
    getOrderDateFromNow(date) {
      return getDateFromNow(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.expanded-item-cell {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}
</style>
