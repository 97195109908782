import { permissions, taskStatusPermissionsMapping } from "@/constants";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../fb";
import { v4 as uuidv4 } from "uuid";

const defaultState = {
  navigationExpanded: false,
  accountSidebarExpanded: false,
  navigationItems: [],
  showDownloadHelp: true,
  showDialogTeeInBlueBearer: false,
  teeInBlueBearer: "",
  mailTemplates: [],
};

const getters = {
  navigationExpanded: (state) => state.navigationExpanded,
  accountSidebarExpanded: (state) => state.accountSidebarExpanded,
  navigationItems: (state) => state.navigationItems,
  showDownloadHelp: (state) => state.showDownloadHelp,
  showDialogTeeInBlueBearer: (state) => state.showDialogTeeInBlueBearer,
  teeInBlueBearer: (state) => state.teeInBlueBearer,
  mailTemplates: (state) => state.mailTemplates,
};

const actions = {
  async loadData({ dispatch, getters }) {
    const userPermissions = getters.userPermissions;

    let promises = [];

    // If the user is an ADMIN, we can load users and orders in parallel.
    if (
      userPermissions.includes(permissions.TASK_IMPORTING) ||
      userPermissions.includes(permissions.ADMIN)
    ) {
      promises.push(dispatch("loadTasksFromShopify"));
    }

    if (
      userPermissions.includes(permissions.READ_ALL_USERS) ||
      userPermissions.includes(permissions.ADMIN)
    ) {
      promises.push(dispatch("loadAllUsers"));
    }

    if (
      userPermissions.includes(permissions.FULFILL_ORDERS) ||
      userPermissions.includes(permissions.ADMIN)
    ) {
      promises.push(dispatch("loadOrders"));
      promises.push(dispatch("loadManualFulfillments"));
    }

    if (userPermissions.includes(permissions.MANAGE_TASKS)) {
      promises.push(dispatch("loadTickets", "OPEN"));
      promises.push(dispatch("loadTickets", "WAITING"));
      promises.push(dispatch("loadTickets", "CLOSED"));
      promises.push(dispatch("loadMailTemplates"));
    }

    // Load tasks based on permissions, can also be in parallel.
    const tasksPermissions = [
      "MANAGER",
      "TASK_DRAWING",
      "TASK_TEMPLATING",
    ].filter((permission) => userPermissions.includes(permission));

    const tasksPromises = tasksPermissions.map((permission) =>
      dispatch("loadTasks", {
        status: taskStatusPermissionsMapping[permission],
      })
    );

    // Add task loading promises to the main promises array.
    promises = promises.concat(tasksPromises);

    try {
      // Wait for all promises to resolve.
      await Promise.all(promises);
    } catch (error) {
      console.error("An error occurred while loading data:", error);
      // Handle any errors that occurred during the dispatches.
    }
  },
  async unsubscribeData({ dispatch }) {
    await dispatch("unsubscribeTasks");
    await dispatch("unsubscribeUser");
    await dispatch("unsubscribeShopifyTasks");
    await dispatch("unsubscribeOrders");
    await dispatch("unsubManualFulfillments");
    await dispatch("unsubTickets");
  },
  async loadMailTemplates({ state }) {
    try {
      const mailTemplatesCollection = collection(db, "mailTemplates");
      const mailTemplateDocs = await getDocs(mailTemplatesCollection);
      state.mailTemplates = [];

      mailTemplateDocs.docs.forEach((doc) => {
        console.log(doc.data());
        state.mailTemplates.push({
          ...doc.data(),
          uid: doc.id,
        });
      });
    } catch (error) {
      console.error("Error loading mail templates:", error);
    }
  },
  async addMailTemplate({ state }, { message, subject, name, category, uid }) {
    const mailTemplatesCollection = collection(db, "mailTemplates");
    const tempUid = uid || uuidv4();
    const mailTemplateDoc = doc(mailTemplatesCollection, tempUid);
    const temp = {
      message,
      subject,
      name,
      category,
    };
    await setDoc(mailTemplateDoc, temp, { merge: true });
    if (uid) {
      // uid means we are editing an exsistent template
      const index = state.mailTemplates.findIndex((t) => t.uid === uid);
      state.mailTemplates.splice(index, 1, temp);
    } else {
      state.mailTemplates.push({ ...temp, uid: tempUid });
    }
    return tempUid;
  },
  async deleteMailTemplate({ state }, { uid }) {
    console.log("DELETE", uid);
    const mailTemplatesCollection = collection(db, "mailTemplates");
    const mailTemplateDoc = doc(mailTemplatesCollection, uid);
    await deleteDoc(mailTemplateDoc);
    state.mailTemplates = state.mailTemplates.filter((t) => t.uid !== uid);
  },
};

const mutations = {
  setNavigationExpanded: (state, bool) => (state.navigationExpanded = bool),
  setNavigationItems: (state, navItems) => (state.navigationItems = navItems),
  disableShowDownloadHelp: (state) => (state.showDownloadHelp = false),
  setAccountSidebarExpanded: (state, bool) =>
    (state.accountSidebarExpanded = bool),
  setTeeInBlueBearer: (state, bearer) => (state.teeInBlueBearer = bearer),
  setShowDialogTeeInBlueBearer: (state, bool) =>
    (state.showDialogTeeInBlueBearer = bool),
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).app
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
