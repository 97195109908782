<template>
  <div class="s-ticket-card">
    <div class="s-ticket-card-header">
      <a class="s-ticket-card-header__title" @click="() => openOrder(ticket.orderUid)">{{
          ticket.orderName
        }}</a>
      <div class="s-ticket-card-header__time">
        {{timeAgo}}
      </div>
    </div>
    <div class="s-ticket-card__content">
      <SupportTicket v-for="data in ticketData" :key="data.type" class="mt-3" :ticket="ticket" :ticket-data="data"/>
    </div>
  </div>
</template>

<script>
import {getDateFromNow, openOrderModal} from "@/utils";
import SupportTicket from "@/components/Support/components/SupportTicket";

export default {
  name: "SupportTicketsCard",
  components: {SupportTicket},
  data() {
    return {

    }
  },
  props: {
    ticket: {
      type: Object,
      required: true
    }
  },
  methods: {
    openOrder(uid) {
      openOrderModal(uid)
    },
  },
  computed: {
    ticketData() {
      return Object.keys(this.ticket.data).map(key => {
        return {
          type: key,
          ...this.ticket.data[key]
        }
      })
    },
    ticketIcons() {
      return Object.keys(this.ticket.data).map(field => {
        if (field === 'damage') return 'mdi-package-variant'
        if (field === 'message') return 'mdi-mail'
        if (field === 'lost') return 'mdi-package-variant-closed-remove'
      })
    },
    timeAgo() {
      return getDateFromNow(this.ticket.created);
    },
  }
}
</script>

<style lang="scss" scoped>
.s-ticket-card {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px, rgba(0, 0, 0, 0.24) 0 1px 2px;
  padding: 12px;
  border-radius: 5px;
}

.s-ticket-card-header {
  display: flex;
  align-items: center;

  &__title {
    font-size: 18px;
    font-weight: bold;
  }

  &__time {
    font-size: 16px;
    font-weight: lighter;
    margin-left: 12px;
  }
}
</style>
