<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ordersWithError"
      :loading="loadingOrders"
      disable-pagination
      :hide-default-footer="true"
      item-key="uid"
    >
      <template v-slot:item.orderName="{ item }">
        <v-icon v-if="item.showOrderWarning" color="warning">mdi-alert</v-icon>
        <a @click="() => openOrder(item.uid)">{{ item.orderName }}</a>
      </template>
      <template v-slot:item.orderNote="{ item }">
        <OrderNoteTooltip :order="item" />
      </template>
      <template v-slot:item.orderDate="{ item }">
        <TimeIndicator :creation-date="item.orderDate" />
      </template>
      <template v-slot:item.name="{ item }">
        {{
          item.shippingAddress.firstName + " " + item.shippingAddress.lastName
        }}
      </template>
      <template v-slot:item.errors="{ item }">
        <ul>
          <li :key="index" v-for="(error, index) in item.errors">
            {{ error.source }}:{{ error.code }} - {{ error }}
          </li>
        </ul>
      </template>
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end align-center">
          <template v-if="!isInvalidAddressError(item)">
            <v-btn
              outlined
              small
              class="mr-3"
              @click="() => retryFulfillment(item.uid)"
              >Retry</v-btn
            >
            <v-btn
              v-if="hasPicanovaErrors(item)"
              outlined
              small
              color="error"
              @click="() => openResolveError(item.uid)"
              >Resolve</v-btn
            >
            <v-btn
              v-if="hasDoboxaErrors(item)"
              outlined
              small
              color="error"
              @click="() => openResolveErrorDoboxa(item.uid)"
              >Resolve</v-btn
            >
          </template>
          <v-btn
            v-if="isInvalidAddressError(item)"
            outlined
            small
            color="error"
            @click="() => openUpdateMyPosterAddress(item)"
            >Update Address</v-btn
          >
          <v-icon @click="(e) => openItemMenu(e, item.uid)"
            >mdi-dots-vertical</v-icon
          >
        </div>
      </template>
    </v-data-table>
    <ListMenu :show="showMenu" :x="x" :y="y" :list-items="menuItems" />
    <ResolvePicanovaErrorDialog
      :show="showResolveDialog"
      :order-id="orderIdToResolve"
      :on-close="closeResolveDialog"
    />
    <ResolveDoboxaErrorDialog
      :show="showResolveDialogDoboxa"
      :order-id="orderIdToResolve"
      :on-close="closeResolveDialog"
    />
    <EditOrderAddressDialog
      v-if="showEditOrderDialog"
      :show="showEditOrderDialog"
      :order="orderToEdit"
      :on-save="sendUpdatesToMyPoster"
      :on-close="closeEditOrderDialog"
    />
    <MailDialog
      v-if="showMailDialog"
      :show="showMailDialog"
      :on-close="() => (this.showMailDialog = false)"
      :receiver="selectedOrder.customer.email"
      :messages="defaultMails"
      :shop-id="selectedOrder.shopId"
      :country-code="selectedOrder.countryCode"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TimeIndicator from "@/components/TaskCards/components/TimeIndicator";
import { doc, setDoc } from "firebase/firestore";
import { db, functions } from "../../../../fb";
import { orderStatus } from "@/constants";
import ResolvePicanovaErrorDialog from "@/components/FulfillOrders/components/ResolvePicanovaErrorDialog";
import { httpsCallable } from "firebase/functions";
import { EventBus, events } from "../../../../event-bus";
import EditOrderAddressDialog from "@/components/FulfillOrders/components/EditOrderAddressDialog";
import OrderNoteTooltip from "@/components/OrderNoteTooltip";
import ListMenu from "@/components/ListMenu";
import { getMailTemplatesFulfill } from "@/content/mails";
import MailDialog from "@/components/MailDialog";
import { openOrderModal } from "@/utils";
import ResolveDoboxaErrorDialog from "@/components/FulfillOrders/components/ResolveDoboxaErrorDialog.vue";

export default {
  name: "OrdersWithError",
  components: {
    ResolveDoboxaErrorDialog,
    MailDialog,
    ListMenu,
    OrderNoteTooltip,
    EditOrderAddressDialog,
    ResolvePicanovaErrorDialog,
    TimeIndicator,
  },
  data() {
    return {
      showMailDialog: false,
      showResolveDialog: false,
      showResolveDialogDoboxa: false,
      orderIdToResolve: "",
      orderToEdit: null,
      showEditOrderDialog: false,
      selectedOrderId: null,
      showMenu: false,
      x: 0,
      y: 0,
      menuItems: [
        {
          icon: "mdi-email-outline",
          onClick: () => {
            this.showMailDialog = true;
          },
          label: "Send Mail",
        },
        {
          icon: "mdi-email-search-outline",
          onClick: this.searchOrderInMails,
          label: "Search In Mails",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["ordersWithError", "loadingOrders"]),
    headers() {
      return [
        { text: "Order Name", value: "orderName" },
        { text: "Order Note", value: "orderNote" },
        { text: "Order Age (in Days)", value: "orderDate" },
        { text: "Name", value: "name" },
        { text: "Errors", value: "errors" },
        { text: "", value: "action" },
      ];
    },
    selectedOrder() {
      return this.ordersWithError.find(
        (order) => order.uid === this.selectedOrderId
      );
    },
    defaultMails() {
      return getMailTemplatesFulfill(this.selectedOrder);
    },
  },
  methods: {
    openOrder(uid) {
      openOrderModal(uid);
    },
    async retryFulfillment(orderUid) {
      const orderDocRef = doc(db, "orders", orderUid);
      try {
        await setDoc(
          orderDocRef,
          {
            status: orderStatus.WAITING_FOR_FULFILL,
          },
          { merge: true }
        );
      } catch (error) {
        console.error("Error updating order document:", error);
      }
    },
    openItemMenu(e, orderId) {
      e.preventDefault();
      this.selectedOrderId = orderId;
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    searchOrderInMails() {
      window.open(
        `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${
          this.selectedOrder.orderName.split("#")[1]
        }`,
        "_blank"
      );
    },
    openResolveError(orderId) {
      this.showResolveDialog = true;
      this.orderIdToResolve = orderId;
    },
    openResolveErrorDoboxa(orderId) {
      this.showResolveDialogDoboxa = true;
      this.orderIdToResolve = orderId;
    },
    closeResolveDialog() {
      this.showResolveDialog = false;
      this.showResolveDialogDoboxa = false;
      this.orderIdToResolve = "";
    },
    isInvalidAddressError(order) {
      return order.errors.some((obj) => obj.code === "INVALID_ADDRESS");
    },
    hasPicanovaErrors(order) {
      return order.errors.some((obj) => obj.source === "picanova");
    },
    hasDoboxaErrors(order) {
      return order.errors.some((obj) => obj.source === "doboxa");
    },
    openUpdateMyPosterAddress(order) {
      this.orderToEdit = order;
      this.showEditOrderDialog = true;
    },
    async sendUpdatesToMyPoster(updatedOrder) {
      const resolveMyPosterAddressError = httpsCallable(
        functions,
        "resolveMyPosterAddressError"
      );
      try {
        const res = await resolveMyPosterAddressError({
          order: updatedOrder,
        });

        if (!res.data.success) {
          EventBus.$emit(events.SNACKBAR, {
            message: res.data.errorMessage,
            type: "error",
          });
        }
      } catch (e) {
        this.error = true;
        this.errorMessage = e.message;
      }
      this.showEditOrderDialog = false;
    },
    closeEditOrderDialog() {
      this.showEditOrderDialog = false;
      this.orderToEdit = null;
    },
  },
};
</script>

<style scoped lang="scss"></style>
