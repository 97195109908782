<template>
  <div @click="click" :class="{ 'information-card--success': success, 'information-card--error': error, 'information-card--clickable': !!onClick }" class="information-card">
    <div class="information-card__title">
      {{title}}
    </div>
    <div class="information-card__divider"/>
    <div class="information-card__value">
      {{value}}
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueCard",
  props: {
    value: {
      required: true,
    },
    title: {
      required: true
    },
    success: {
      default: false
    },
    error: {
      default: false
    },
    onClick: {
      required: false
    }
  },
  methods: {
    click() {
      if (this.onClick) {
        this.onClick()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.information-card {
  width: 100%;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding: clamp(6px, 1vw, 12px);

  &--clickable {
    cursor: pointer;
  }

  &--success {
    box-shadow: inset 0 0 60px 5px rgba(142, 180, 84, 0.59), inset 0 2px 1px 1px rgba(255,255,255,0.9), inset 0 -2px 1px rgb(3, 58, 16) !important;
  }

  &--error {
    box-shadow: inset 0 0 60px 5px rgba(197, 122, 122, 0.59), inset 0 2px 1px 1px rgba(255,255,255,0.9), inset 0 -2px 1px rgba(47, 2, 2, 0.25) !important;
  }

  &__title {
    font-weight: lighter;
    font-size: clamp(12px, 1vw, 18px); // Passt sich dynamisch an die Breite an
    text-transform: uppercase;
    text-align: center;
  }

  &__divider {
    height: 1px;
    background-color: #939393;
    width: 50%; // Oder verwenden Sie calc() um dies dynamisch zu gestalten
    margin: 0 auto;
  }

  &__value {
    margin-top: clamp(6px, 1vh, 12px);
    font-weight: bold;
    font-size: clamp(24px, 1vh, 36px); // Passt sich dynamisch an die Breite an
    text-align: center;
  }
}
</style>
