<template>
  <div class="statistics-header">
      <DatePickerField :defaultDates="defaultDates" label="Zeitraum" range @change="(date) => handleDateChange(date)"/>
    <div class="statistics-header__presets d-none d-lg-flex d-xl-flex">
      <v-btn small v-for="item in presets" :key="item.value" @click="handlePresetClick(item.value)" :color="isSelectedPreset(item.value) ? 'primary' : ''" :text="!isSelectedPreset(item.value)">{{item.title}}</v-btn>
    </div>
    <div class="d-lg-none d-xl-none ml-3">
      <v-menu v-model="openPresets">
        <template v-slot:activator="{ props }">
          <v-btn
            outlined
            color="primary"
            v-bind="props"
            @click="openPresets = !openPresets"
          >
            {{ selectedPresetName }}
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="item in presets"
            :key="item.value"
            @click="handlePresetClick(item.value)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import DatePickerField from "@/components/DatePickerField";
import {mapActions, mapGetters, mapMutations} from "vuex";
import dayjs from "dayjs";

const utc = require('dayjs/plugin/utc'); // Import UTC plugin
const timezone = require('dayjs/plugin/timezone'); // Import timezone plugin
const weekday = require('dayjs/plugin/weekday'); // Import timezone plugin

dayjs.extend(utc); // Enable UTC plugin
dayjs.extend(timezone); // Enable timezone plugin
dayjs.extend(weekday); // Enable timezone plugin

export default {
  name: "StatisticsHeader",
  data() {
    return {
      preset: 'today',
      presets: [
        {
          title: "Today",
          value: "today"
        },
        {
          title: "Yesterday",
          value: "yesterday"
        },{
          title: "This Week",
          value: "thisweek"
        },{
          title: "This Month",
          value: "thismonth"
        },{
          title: "Last 7 Days",
          value: "last7days"
        },{
          title: "Last 30 Days",
          value: "last30days"
        },
      ],
      openPresets: false,
    }
  },
  components: {DatePickerField},
  computed: {
    ...mapGetters(["statisticRange"]),
    defaultDates() {
      return [
        this.statisticRange.min.split("T")[0],
        this.statisticRange.max.split("T")[0]
      ]
    },
    selectedPresetName() {
      return this.presets.find(pre => pre.value === this.preset)?.title || 'Presets'
    }
  },
  methods: {
    ...mapActions(["loadStatistic"]),
    ...mapMutations(["setStatisticRange"]),
    handleDateChange(date) {
      this.preset = null
      const parseDate = (dateStr) =>
        dayjs(dateStr).tz("Europe/Berlin", true);

      const startDate = parseDate(date[0]);
      const endDate = parseDate(date[1]);

      const startOfDay = startDate.startOf("day").format();
      const endOfDay = endDate.endOf("day").format();

      this.setStatisticRange({
        min: startOfDay,
        max: endOfDay,
      });

      this.loadStatistic()
    },
    handlePresetClick(preset) {
      const localizedDayJs = dayjs().tz("Europe/Berlin", true)

      let startDate = localizedDayJs;
      let endDate = localizedDayJs;

      switch (preset) {
        case 'today':
          startDate = localizedDayJs;
          endDate = localizedDayJs;
          break;
        case 'yesterday':
          startDate = localizedDayJs.subtract(1, 'day');
          endDate = localizedDayJs.subtract(1, 'day');
          break;
        case 'last7days':
          startDate = localizedDayJs.subtract(7, 'day');
          endDate = localizedDayJs;
          break;
        case 'last30days':
          startDate = localizedDayJs.subtract(30, 'day');
          endDate = localizedDayJs;
          break;
        case 'thisweek':
          startDate = localizedDayJs.weekday(1);
          endDate = localizedDayJs;
          break;
        case 'thismonth':
          startDate = localizedDayJs.startOf('month');
          endDate = localizedDayJs;
          break;
        default:
          break;
      }

      if (startDate && endDate) {
        const startOfDay = startDate.startOf("day").format();
        const endOfDay = endDate.endOf("day").format();


        this.setStatisticRange({
          min: startOfDay,
          max: endOfDay,
        });

        this.loadStatistic();
      }

      this.preset = preset;
    },
    isSelectedPreset(preset) {
      return this.preset === preset
    }
  }
}
</script>

<style lang="scss" scoped>
.statistics-header {
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include md() {
    padding: 12px 12px 0;
  }

  &__presets {
    display: flex;
    gap: 3px;
    align-items: center;
  }
}
</style>
