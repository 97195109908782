import { render, staticRenderFns } from "./ManualFulfillment.vue?vue&type=template&id=920b800a&scoped=true"
import script from "./ManualFulfillment.vue?vue&type=script&lang=js"
export * from "./ManualFulfillment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "920b800a",
  null
  
)

export default component.exports