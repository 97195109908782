<template>
  <MainDialog :show="show">
    <v-card dark>
      <v-card-title>{{ orderName }} Order Note</v-card-title>
      <v-card-text>
        <v-textarea
          label="Order Note"
          dark
          outlined
          v-model="editedOrderNote"
        />
        <v-row v-if="orderNoteFiles?.length">
          <v-col v-for="file in orderNoteFiles" :key="file.path" cols="4">
            <v-card>
              <v-btn width="100%" small @click="() => downloadOrderNoteFile(file.path, file.filename)">
                {{file.filename}}
                <v-icon small class="ml-3">mdi-download</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
        <v-file-input
          chips
          clearable
          @change.native="onFileChange"
          @click:clear="selectedFiles = []"
          :multiple="true"
          label="Add Files to note"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="onClose">Cancel</v-btn>
        <v-spacer />
        <v-btn
          @click="save"
          :disabled="isSaving"
          :loading="isSaving"
          color="success"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import { mapActions } from "vuex";
import MainDialog from "@/components/MainDialog";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {currentMonthYear, downloadImageFromUrl} from "@/utils";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "OrderNoteDialog",
  components: { MainDialog },
  data() {
    return {
      editedOrderNote: "",
      isSaving: false,
      orderNoteFiles: null,
      selectedFiles: [],
    };
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      required: true,
    },
    task: {
      required: false,
      type: Object,
    },
    order: {
      required: false,
      type: Object,
    },
    onSave: {
      default: null,
    },
  },
  computed: {
    orderName() {
      return this.task ? this.task.orderName : this.order.orderName;
    },
  },
  created() {
    this.editedOrderNote = this.task
      ? this.task.orderNote
      : this.order.orderNote;
    this.orderNoteFiles = this.task
      ? this.task.orderNoteFiles || []
      : this.order.orderNoteFiles || [];
  },
  methods: {
    ...mapActions(["editOrderNote"]),
    async save() {
      this.isSaving = true;
      let orderNoteFiles = []

      if (this.onSave) {
        this.onClose();
        if (this.selectedFiles.length) {
          orderNoteFiles = await this.uploadOrderNoteFiles()
        }
        this.onSave({orderNote: this.editedOrderNote, orderNoteFiles});
        this.isSaving = false;
      } else {
        this.isSaving = false;
        this.onClose();
        if (this.selectedFiles.length) {
          orderNoteFiles = await this.uploadOrderNoteFiles()
        }
        this.editOrderNote({
          taskUid: this.task?.uid || null,
          orderUid: this.order?.uid || null,
          orderNoteFiles: [...this.orderNoteFiles, ...orderNoteFiles],
          orderNote: this.editedOrderNote,
        });
      }
    },
    async uploadOrderNoteFiles() {
      const orderNoteFileUrls = [];
      const storage = getStorage();
      for await (const [index, value] of this.selectedFiles.entries()) {
        const storageRef = ref(
          storage,
          `/orderNoteFiles/${currentMonthYear}/${uuidv4()}.png`
        );
        const uploadResponse = await uploadBytes(storageRef, value, {
          cacheControl: "public",
        });
        orderNoteFileUrls.push({
          filename: value.name,
          path: await getDownloadURL(uploadResponse.ref),
        });
      }
     return orderNoteFileUrls
    },
    async onFileChange(event) {
      if (event.target.files.length) {
        for await (const file of Array.from(event.target.files)) {
          this.selectedFiles.push(file);
        }
      } else {
        this.selectedFiles = null;
      }
    },
    downloadOrderNoteFile(path, filename) {
      const name = filename.split('.')[0]
      const type = filename.split('.')[1]
      downloadImageFromUrl(path, name, () => {}, type)
    }
  },
};
</script>

<style scoped></style>
