<template>
  <v-list-item link :to="route.path" class="routes-list">
    <v-list-item-icon>
      <v-badge offset-x="15px" color="grey" v-if="showBadge" :content="counter">
        <v-icon>{{ route.meta.icon }}</v-icon>
      </v-badge>
      <v-icon v-else>{{ route.meta.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { orderStatus } from "@/constants";

export default {
  name: "NavigationItem",
  props: {
    route: {
      require: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters([
      "tasksLengthsByStatus",
      "ordersToFulfill",
      "ordersToFulfillManual",
      "shopifyTasksLength",
      "manualFulfillments",
      "ticketsOpen",
      "ticketsWaiting"
    ]),
    showBadge() {
      return this.route.meta.counterStatus && this.counter;
    },
    counter() {
      if (this.route.meta.counterStatus) {
        if (this.route.meta.counterStatus === "ShopifyOrders") {
          return this.shopifyTasksLength;
        } else if (
          this.route.meta.counterStatus === orderStatus.READY_FOR_FULFILL
        ) {
          return this.ordersToFulfill.length;
        } else if (
          this.route.meta.counterStatus === "MANUALFULFILLMETS"
        ) {
          return this.manualFulfillments.length;
        } else if (
          this.route.meta.counterStatus === "SUPPORTCOUNTER"
        ) {
          return (this.ticketsOpen?.length || 0) + (this.ticketsWaiting?.length || 0);
        } else {
          return this.tasksLengthsByStatus[this.route.meta.counterStatus];
        }
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.task-counter-badge {
  z-index: 100;
  background-color: #858585;
}
</style>
