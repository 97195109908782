<template>
  <div
    v-if="unpaidTasksAmount"
    :class="{ heartbeat: animate }"
    class="earnings-container"
    @click="openEarningsDialog()"
  >
    <v-icon color="#dcffc2" class="mr-3">mdi-cash-multiple</v-icon>
    {{ unpaidTasksAmount }}
    <EarningsDialog
      :show="showEarningsDialog"
      :onClose="
        () => {
          showEarningsDialog = false;
        }
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EarningsDialog from "@/components/EarningsDialog";

export default {
  name: "CurrentEarnings",
  components: { EarningsDialog },
  data() {
    return {
      showEarningsDialog: false,
      animate: false,
    };
  },
  computed: {
    ...mapGetters(["unpaidTasksAmount", "payPerTask", "currentUser", "userCurrency"]),
  },
  watch: {
    unpaidTasksAmount() {
      this.animatePayment();
    },
  },
  methods: {
    animatePayment() {
      this.animate = true;
      setTimeout(() => {
        this.animate = false;
      }, 2000);
    },
    openEarningsDialog() {
      this.showEarningsDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.earnings-container {
  cursor: pointer;
  background: #676767;
  display: flex;
  align-items: center;
  font-size: 26px;
  line-height: 26px;
  color: #dcffc2;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 6px;

  /* Media query for smartphones */
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 14px;
  }

  &:hover {
    scale: 1.12;
  }
}

.heartbeat {
  animation: heartbeat 2s ease-in-out infinite both;
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
</style>
