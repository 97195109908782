import { getAuth } from "firebase/auth";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../fb";
import { dbCollection } from "@/constants";

const defaultState = {
  user: [],
  currentUser: null,
  unsubUser: () => {},
};

const getters = {
  currentUser: (state) => state.currentUser,
  userPayment: (state) => state.currentUser?.payment,
  unpaidTasks: (state) => state.currentUser?.payment?.unpaidTasks?.length,
  payPerTask: (state) => state.currentUser?.payment?.payPerTask,
  userCurrency: (state) => state.currentUser?.payment?.currency,
  maxOpenTasks: (state) => state.currentUser?.maxOpenTasks,
  unpaidTasksAmount: (state) => {
    return state.currentUser?.payment
      ? state.currentUser?.payment?.unpaidTasks
          .reduce((accumulator, payment) => {
            return accumulator + payment.price;
          }, 0)
          ?.toFixed(2) + state.currentUser?.payment?.currency
      : undefined;
  },
  isAdmin: (state) => state.currentUser?.permissions.includes("ADMIN"),
  currentEarnings: (state) => {
    if (state.user?.payment?.payPerTask) {
      return (
        state.user?.payment?.payPerTask *
          state.user?.payment?.unpaidTasks.length +
        state.user?.payment.currency
      );
    } else {
      return state.user?.payment?.unpaidTasks.length;
    }
  },
  userPermissions: (state) => state.currentUser?.permissions,
  assignedProductTypes: (state) => state.currentUser?.assignedProductTypes,
  usersManager: (state) => state.currentUser?.manager,
  managedUsers: (state) => state.currentUser?.managedUsers,
};

const actions = {
  async unsubscribeUser({ state }) {
    state.unsubUser();
  },
  async listenForUserChanges({ commit, state }) {
    const auth = getAuth();
    state.unsubUser = onSnapshot(
      doc(db, dbCollection.USERS, auth.currentUser.uid),
      (doc) => {
        commit("setCurrentUser", { ...doc.data(), uid: doc.id });
      }
    );
  },
  // eslint-disable-next-line no-empty-pattern
  getUserByUid({}, { userUid }) {
    return new Promise((resolve, reject) => {
      const usersDoc = doc(db, dbCollection.USERS, userUid);
      getDoc(usersDoc)
        .then((ref) => {
          resolve(ref.data());
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
};

const mutations = {
  setCurrentUser: (state, currentUser) => (state.currentUser = currentUser),
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).user
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
