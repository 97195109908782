<template>
  <v-card>
    <v-card-title>
      <span>{{ fulfillment.order.orderName }}</span>
      <v-spacer />
      <v-btn @click="openOrderInShopify" small rounded dark>
        Open in Shopify
        <v-icon class="ml-3">mdi-open-in-new</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          :key="lineItem.task.uid"
          v-for="lineItem in fulfillment.lineItems"
        >
          <LineItemCard :line-item="lineItem" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :loading="isFulfilling"
        :disabled="isFulfilling"
        @click="setAllAsFulfilled"
        color="success"
        >Set All Fulfilled</v-btn
      >
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { openOrderInShopify } from "@/utils";
import LineItemCard from "@/components/ManualFulfillment/LineItemCard";
import {deleteDoc, doc, getDoc, setDoc} from "firebase/firestore";
import { db } from "../../../fb";
import { EventBus, events } from "../../../event-bus";

export default {
  name: "ManualFulfillmentCard",
  components: { LineItemCard },
  data() {
    return {
      downloadProgress: 0,
      isFulfilling: false,
    };
  },
  props: {
    fulfillment: {
      require: true,
    },
  },
  created() {},
  methods: {
    openOrderInShopify() {
      openOrderInShopify({
        orderId: this.fulfillment.order.uid,
        countryCode: this.fulfillment.order.countryCode,
        shopId: this.fulfillment.order.shopId,
      });
    },
    async setAllAsFulfilled() {
      this.isFulfilling = true;
      const orderRef = doc(db, `orders/${this.fulfillment.order.uid}`);
      const orderDoc = await getDoc(orderRef);
      const order = orderDoc.data();

      const taskUidsFulfilled = this.fulfillment.lineItems.map((lineItem) => {
        return lineItem.task.uid;
      });

      // Mark tasks in order as already Fulfilled (Important when pushing back tasks to not perform unnecessary fulfills)
      const updatedOrderTasks = order.tasks.map((task) => {
        return {
          ...task,
          fulfilled: task.fulfilled || taskUidsFulfilled.includes(task.taskUid),
        };
      });

      const existingManualFulfillments = order.fulfillment?.manual || [];

      try {
        await setDoc(
          orderRef,
          {
            fulfillment: {
              manual: [
                ...existingManualFulfillments,
                {
                  success: true,
                  fulfillItems: this.fulfillment.lineItems.map((lineItem) => {
                    return {
                      id: lineItem.task.uid || "",
                      taskId: lineItem.task.uid || "",
                      file: lineItem.task.finalTemplate || "",
                      quantity: lineItem.quantity || "",
                      variant: lineItem.printVariant.name || "",
                      shipment: {
                        carrier: "Manual Fulfillment",
                        trackingCode: "Unknown check Shopify"
                      },
                    };
                  }),
                  creationDate: new Date().toISOString(),
                },
              ],
            },
            fulfillDate: new Date().toISOString(),
            tasks: updatedOrderTasks,
          },
          { merge: true }
        );

        const manualFulfillmentRef = doc(db, `manualFulfillments/${this.fulfillment.order.uid}`);
        await deleteDoc(manualFulfillmentRef)

        this.isFulfilling = false;
      } catch (e) {
        EventBus.$emit(events.SNACKBAR, { message: e.message, type: "error" });
        this.isFulfilling = false;
      }
    },
  },
};
</script>

<style scoped></style>
