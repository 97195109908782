import { render, staticRenderFns } from "./AllRatingsArtist.vue?vue&type=template&id=4b7b0304&scoped=true"
import script from "./AllRatingsArtist.vue?vue&type=script&lang=js"
export * from "./AllRatingsArtist.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b7b0304",
  null
  
)

export default component.exports