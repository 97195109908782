<template>
  <v-app-bar app color="primary" dark height="60">
    <div class="d-flex align-center main-header">
      <v-img
        alt="DriveConnector Logo"
        class="shrink mr-2"
        contain
        :src="require('../assets/logo.png')"
        transition="scale-transition"
        width="80"
      />
    </div>
    <v-spacer />
    <CurrentEarnings />
    <v-spacer />
    <MainAccount />
  </v-app-bar>
</template>

<script>
import MainAccount from "@/components/MainAccount";
import CurrentEarnings from "@/components/CurrentEarnings";

export default {
  name: "MainHeader",
  components: { CurrentEarnings, MainAccount },
  methods: {},
};
</script>

<style scoped>
.main-header {
  z-index: 999;
}
</style>
