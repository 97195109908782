<template>
  <div v-if="show" class="expressOverlay">
    <v-icon class="expressOverlay__icon">mdi-flash</v-icon>
    <div class="expressOverlay__text">EXPRESS</div>
  </div>
</template>

<script>
export default {
  name: "ExpressLayover",
  props: {
    isExpress: {
      default: false,
    },
    task: {
      type: Object
    }
  },
  computed: {
    show() {
      return this.isExpress || (this.task.productType && this.task.productType.isExpress)
    }
  }
}
</script>

<style lang="scss" scoped>
.expressOverlay {
  background: repeating-linear-gradient(
      45deg,
      rgba(250, 238, 5, 0.05) 0,
      rgba(250, 238, 5, 0.05) 10px,
      rgba(255, 255, 255, 0) 10px,
      rgba(255, 255, 255, 0) 20px
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border: 6px solid #c9c011;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  mix-blend-mode: plus-lighter;

  &__icon {
    font-size: 90px !important;
    color: rgba(250, 238, 5, 0.2) !important;
    mix-blend-mode: multiply;
  }

  &__text {
    font-size: 45px;
    font-weight: bolder;
    color: rgba(250, 238, 5, 0.2);
    mix-blend-mode: multiply;
  }
}

</style>
