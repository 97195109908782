<template>
  <v-card class="overview-card">
    <v-card-title>
      <div class="card-header flex justify-space-between">
        {{ title }}
        <div class="d-flex" v-if="!disableSwitch">
          <v-btn
            :disabled="switchItem.data.length === 0"
            class="mr-3"
            x-small
            :text="index !== selectedSwitchItemIndex"
            :key="index"
            :dark="index === selectedSwitchItemIndex"
            v-for="(switchItem, index) in switchData"
            @click="selectedSwitchItemIndex = index"
          >
            {{ switchItem.label }}
          </v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <BarChart :aspect-ratio="aspectRatio" :key="selectedSwitchItemIndex" :data="chartData" :unit="chartUnit" />
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "@/components/BarChart";

export default {
  name: "OverviewBarCard",
  components: { BarChart },
  data() {
    return {
      selectedSwitchItemIndex: 0,
    };
  },
  props: {
    title: String,
    data: Object,
    switchData: Array,
    unit: String,
    disableSwitch: {
      default: false
    },
    aspectRatio: {
      default: 3
    },
  },
  computed: {
    chartData() {
      return this.disableSwitch ? this.data : this.switchData ? this.switchData[this.selectedSwitchItemIndex].data : this.data;
    },
    chartUnit() {
      if (this.switchData && !this.disableSwitch) {
        return this.switchData[this.selectedSwitchItemIndex].unit || this.unit;
      } else {
        return this.unit;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overview-card {
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px, rgba(0, 0, 0, 0.07) 0 2px 4px, rgba(0, 0, 0, 0.07) 0 4px 8px, rgba(0, 0, 0, 0.07) 0 8px 16px, rgba(0, 0, 0, 0.07) 0 16px 32px, rgba(0, 0, 0, 0.07) 0 32px 64px !important;

  @include md() {
    box-shadow: rgba(14, 63, 126, 0.04) 0 0 0 1px, rgba(42, 51, 69, 0.04) 0 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0 6px 6px -3px, rgba(14, 63, 126, 0.04) 0 12px 12px -6px, rgba(14, 63, 126, 0.04) 0 24px 24px -12px !important;
  }

  .card-header {
    height: 52px;
  }
}
</style>
