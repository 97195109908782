<template>
  <figure
    ref="zoomFigure"
    class="zoom"
    @mousemove="zoom"
    :style="`background-image: url(${image}); cursor: ${
      zoomActive ? 'zoom-in' : ''
    };`"
  >
    <div
      :style="{ opacity: zoomActive ? 0 : 1 }"
      class="zoom-hint d-flex align-center"
    >
      <v-icon small>mdi-alpha-y-box-outline</v-icon>
      =
      <v-icon small>mdi-loupe</v-icon>
    </div>
    <img
      alt="Image"
      :src="imageThumb || image"
      :style="{ opacity: zoomActive ? 0 : 1 }"
    />
  </figure>
</template>

<script>
export default {
  name: "ImageZoom",
  data() {
    return {
      zoomVals: {
        offsetX: null,
        offsetY: null,
        zoomX: null,
        zoomY: null,
      },
      zoomActive: false,
      imageOpacity: 1,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    imageThumb: {
      type: String,
      required: false,
    },
  },
  methods: {
    zoom(e) {
      if (this.zoomActive) {
        let zoomer = e.currentTarget;
        e.offsetX
          ? (this.zoomVals.offsetX = e.offsetX)
          : (this.zoomVals.offsetX = e.touches[0].pageX);
        e.offsetY
          ? (this.zoomVals.offsetY = e.offsetY)
          : (this.zoomVals.offsetY = e.touches[0].pageY);
        let x = (this.zoomVals.offsetX / zoomer.offsetWidth) * 100;
        let y = (this.zoomVals.offsetY / zoomer.offsetHeight) * 100;
        zoomer.style.backgroundPosition = x + "% " + y + "%";
      }
    },
    onKeyDown(e) {
      if (e.key === "y" || e.key === "Shift") {
        this.zoomActive = true;
      }
    },
    onKeyUp(e) {
      if (e.key === "y" || e.key === "Shift") {
        this.zoomActive = false;
        // You may want to call zoom method here with a synthetic event object containing the current mouse position and element
        // or use another way to reset the zoom and opacity when shift is released without mouse movement
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.zoom-hint {
  background-color: #ffffff;
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: 30px;
  padding: 9px;
  display: flex;
  z-index: 10;
}
figure.zoom {
  background-position: 50% 50%;
  background-color: black;
  position: relative;
  width: 1000px;
  height: auto;
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  background-size: 300%;
}

figure.zoom img {
  transition: opacity 0.5s;
  display: block;
  width: 100%;
  height: 100%;
  max-width: 90vw;
  max-height: 90vh;
  background-color: black;
  object-fit: contain;
}
</style>
