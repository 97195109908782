<template>
  <div class="app" @dragover.prevent @drop="onDrop">
    <div class="controls">
      <input type="file" @change="onFileChange" ref="fileInput" style="display: none;" />
      <button @click="triggerFileInput">Upload Image</button>
      <button v-if="imageUrl" @click="deleteImage">Delete Image</button>
      <button v-if="positions.length" @click="copyPositions">Copy Positions</button>
      <button v-if="positions.length && this.savable" @click="savePositions">Save</button>
    </div>
    <div v-if="imageUrl" class="image-container">
      <div class="zoom-container">
        <img
          :src="imageUrl"
          @load="onImageLoad"
          @mousemove="onImageHover"
          @mouseleave="onImageLeave"
          @click="onImageClick"
          @contextmenu.prevent="revertLastPosition"
          ref="image"
        />
        <div v-for="(corner, index) in corners" :key="index" class="corner" :style="getCornerStyle(corner)">
          {{ corner.label }}
        </div>
        <div v-if="showMagnifier" :style="magnifierStyle" class="magnifier"></div>
      </div>
    </div>
    <div class="positions" v-if="positions.length">
      <pre>Positions: {{ JSON.stringify(positions) }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "MockupPositioner",
  data() {
    return {
      imageUrl: null,
      positions: [],
      corners: [
        { label: 'Top Left', x: null, y: null },
        { label: 'Top Right', x: null, y: null },
        { label: 'Bottom Right', x: null, y: null },
        { label: 'Bottom Left', x: null, y: null },
      ],
      currentCorner: 0,
      showMagnifier: false,
      magnifierStyle: {},
      isCommandPressed: false,
      previousPositions: [],
    };
  },
  props: {
    file: {
      required: false
    },
    savable: {
      default: false
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      this.loadImage(file);
    },
    onDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.loadImage(file);
    },
    loadImage(file) {
      this.imageUrl = URL.createObjectURL(file);
      this.resetCorners();
    },
    onImageLoad() {
      this.imageWidth = this.$refs.image.width;
      this.imageHeight = this.$refs.image.height;
    },
    onImageClick(event) {
      const rect = this.$refs.image.getBoundingClientRect();
      const x = (event.clientX - rect.left);
      const y = (event.clientY - rect.top);

      this.previousPositions.push(JSON.parse(JSON.stringify(this.corners)));

      this.corners[this.currentCorner].x = x;
      this.corners[this.currentCorner].y = y;
      this.currentCorner = (this.currentCorner + 1) % this.corners.length;

      if (this.currentCorner === 0) {
        this.calculatePositions();
      }
    },
    calculatePositions() {
      this.positions = this.corners.map(corner => [
        parseFloat(((corner.x / this.imageWidth)).toFixed(6)),
        parseFloat(((corner.y / this.imageHeight)).toFixed(6)),
      ]);
    },
    resetCorners() {
      this.corners.forEach(corner => {
        corner.x = null;
        corner.y = null;
      });
      this.positions = [];
      this.currentCorner = 0;
      this.previousPositions = [];
    },
    getCornerStyle(corner) {
      if (corner.x !== null && corner.y !== null) {
        return {
          position: 'absolute',
          left: `${corner.x}px`,
          top: `${corner.y}px`,
          backgroundColor: 'red',
          color: 'white',
          padding: '2px 5px',
          borderRadius: '3px',
        };
      }
      return {};
    },
    onImageHover(event) {
      const rect = this.$refs.image.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const zoomLevel = 3;
      const size = 100;

      this.magnifierStyle = {
        left: `${x}px`,
        top: `${y}px`,
        backgroundImage: `url(${this.imageUrl})`,
        backgroundPosition: `-${x * zoomLevel - size / 2}px -${y * zoomLevel - size / 2}px`,
        backgroundSize: `${this.imageWidth * zoomLevel}px ${this.imageHeight * zoomLevel}px`,
        transform: 'translate(-50%, -50%)',
      };

      this.showMagnifier = true;
    },
    onImageLeave() {
      this.showMagnifier = false;
    },
    onKeyDown(event) {
      if (event.key === 'Meta') {
        this.isCommandPressed = true;
      }
    },
    onKeyUp(event) {
      if (event.key === 'Meta') {
        this.isCommandPressed = false;
      }
    },
    copyPositions() {
      const positionsText = JSON.stringify(this.positions);
      navigator.clipboard.writeText(positionsText).then(() => {
        alert('Positions copied to clipboard!');
      });
    },
    savePositions() {
      this.$emit('apply-positions', this.positions)
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    deleteImage() {
      this.imageUrl = null;
      this.resetCorners();
    },
    revertLastPosition() {
      if (this.previousPositions.length > 0) {
        this.corners = this.previousPositions.pop();
        this.calculatePositions();
        this.currentCorner = (this.currentCorner - 1 + this.corners.length) % this.corners.length;
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
    if (this.file) {
      this.imageUrl = URL.createObjectURL(this.file)
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
  },
};
</script>

<style scoped>
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}

.controls {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.zoom-container {
  position: relative;
  cursor: crosshair;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
}

img {
  width: 100%; /* Ensure the image is always shown in full width */
  height: auto; /* Maintain aspect ratio */
}

.corner {
  position: absolute;
  background-color: red;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

.positions {
  margin-top: 10px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
  word-wrap: break-word;
}

.magnifier {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 1000; /* Ensure the magnifier is on top of other elements */
  transform: translate(-50%, -50%); /* Center the magnifier over the cursor */
}

</style>
