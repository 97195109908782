export const appSettings = {
  DEFAULT_MAX_ALLOWED_OPEN_TASKS: 3, // Number of open tasks allowed before taking new ones
  MAX_DAYS_UNTIL_FULFILL: 3, //Days until an order is seen as Delayed in Fulfill
  MAX_DAYS_UNTIL_SHIPMENT: 3, //Days until an order is seen as Delayed in Shipment
  RATETASK_URL: "https://ratetask.com",
};

export const statisticsColors = {
  production: "#444444",
  shipping: "#4c747c",
  employee: "#13325b",
  payments: "#d06767",
  advertisement: "#6a3c85",
  taxes: "#d3d3d3",
  additionalCosts: "#ffa600",
  AQ_ES: "#ffadca",
  AQ_DE: "#c2adff",
  AQ_PL: "#add3ff",
  TM_DE: "#FFEBAD",
  AQ_FR: "#c8ffad",
  costs: "#cb2727",
  revenue: "#27a950",
  facebook: "#1c6ebb",
  google: "#b2a92b",
  tiktok: "#383232",
  instagram: "#e81d4c",
  friends_family: "#797979",
  refunds: "#ffffff",
};

export const availableAspectRatios = ["DIN", "3:2"];

export const availableOrientations = ["landscape", "portrait"];
