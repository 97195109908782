<template>
  <div>
    <v-row justify="start">
      <v-col class="mb-1 d-flex" cols="12">
        <v-text-field
          full-width
          dense
          class="searchHeader__searchField"
          hide-details
          outlined
          v-model="quickSearch"
          label="Quick Search (Name, Order Number, Email, Address...)"
        />
        <v-btn @click="setShowSearchOption" class="ml-1" icon>
          <v-icon>{{showSearchOptions ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-form @submit.prevent="search">
      <v-row class="searchOptions" :class="{'searchOptions--show':showSearchOptions}">
        <v-col>
          <v-text-field
            dense
            class="searchHeader__searchField"
            hide-details
            outlined
            v-model="orderId"
            label="Order ID"
          />
        </v-col>
        <v-col>
          <v-text-field
            dense
            class="searchHeader__searchField"
            hide-details
            outlined
            v-model="email"
            label="Customer Email"
          />
        </v-col>
        <v-col>
          <v-btn type="submit" color="success" width="100%">Search</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SearchHeader",
  data() {
    return {
      showSearchOptions: false
    }
  },
  computed: {
    ...mapGetters(["filterOrderId", "filterEmail", "filterQuickSearch"]),
    orderId: {
      get() {
        return this.filterOrderId;
      },
      set(val) {
        let updatedValue = val;
        if (updatedValue && updatedValue.charAt(0) !== "#") {
          updatedValue = "#" + updatedValue;
        }
        this.setFilterOrderId(updatedValue);
      },
    },
    email: {
      get() {
        return this.filterEmail;
      },
      set(val) {
        this.setFilterEmail(val);
      },
    },
    quickSearch: {
      get() {
        return this.filterQuickSearch;
      },
      set(val) {
        this.setFilterQuickSearch(val);
      },
    },
  },
  methods: {
    ...mapMutations([
      "setFilterOrderId",
      "setFilterEmail",
      "setFilterQuickSearch",
    ]),
    ...mapActions(["loadAllOrders"]),
    search() {
      this.loadAllOrders();
    },
    setShowSearchOption() {
      this.showSearchOptions = !this.showSearchOptions
    }
  },
};
</script>

<style lang="scss" scoped>
.searchOptions {
  overflow: hidden; /* Hide the overflow content */
  max-height: 0; /* Set initial max-height to 0 */
  transition: max-height 0.33s ease-in; /* Transition the max-height property */

  &--show {
    max-height: 1000px; /* Set to a value that is likely taller than the content */
  }
}

.searchBox {
  display: flex;
  width: 100%;

  &__searchField {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 40px;
  }
}
</style>
