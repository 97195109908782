<template>
  <v-row :dense="$vuetify.breakpoint.mobile">
    <v-col cols="12" sm="6" v-for="shopData in data" :key="shopData.shopCode">
      <ShopStatisticCard :shop-statistic="shopData" />
    </v-col>
  </v-row>
</template>

<script>
import ShopStatisticCard from "@/components/Statistics/StatisticByShops/ShopStatisticCard";
export default {
  name: "StatisticByShops",
  components: {ShopStatisticCard},
  props: {
    data: {
      type: Array,
    },
  },
  computed: {
    adSpend() {
      return [

      ]
    }
  }
}
</script>

<style scoped>

</style>
