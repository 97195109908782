<template>
  <div style="min-height: 500px">
    <StateDisplay v-if="loading" :loading="loading" />
    <div v-else>
      <ExchangeRates :settings="settings" />
      <GatewayCharges class="mt-6" :settings="settings" />
      <AdditionalCosts class="mt-6 mb-12" :settings="settings" />
    </div>
  </div>
</template>

<script>
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../fb";
import StateDisplay from "@/components/StateDisplay";
import ExchangeRates from "@/components/Settings/Statistics/ExchangeRates";
import GatewayCharges from "@/components/Settings/Statistics/GatewayCharges";
import AdditionalCosts from "@/components/Settings/Statistics/AdditionalCosts/AdditionalCosts";

export default {
  name: "AdminStatistics",
  components: {AdditionalCosts, GatewayCharges, ExchangeRates, StateDisplay },
  data() {
    return {
      settings: null,
      loading: false,
      unsubscribe: null, // To store the unsubscribe function
    };
  },
  created() {
    this.observeChanges();
  },
  beforeDestroy() {
    // Ensure we unsubscribe from the listener when the component is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    observeChanges() {
      this.loading = true;
      const docRef = doc(db, 'settings', 'statistics');

      // Set up the onSnapshot listener
      this.unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          this.settings = data;
        } else {
          console.log("No such document!");
          this.settings = null;
        }
        this.loading = false;
      }, (error) => {
        console.error("Error fetching document:", error);
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
/* Your styles here */
</style>
