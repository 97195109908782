<template>
  <div>
    <!-- Show loading spinner when loading mockups -->
    <v-progress-circular
      v-if="loadingMockups"
      indeterminate
      color="primary"
      class="mx-auto"
    ></v-progress-circular>

    <!-- Mockup selector dropdown -->
    <div @click="() => onSelect(null)" v-else-if="selectedMockupId" class="selected-container">
      <v-avatar size="64" class="mr-4">
        <v-img :src="selectedMockup.thumbnail" :alt="selectedMockup.name"></v-img>
      </v-avatar>
      <span>{{ selectedMockup.name }}</span>
    </div>
    <v-select
      v-else-if="!loadingMockups"
      :items="filteredMockups"
      item-value="id"
      item-text="name"
      hide-details
      :label="label"
      :value="selectedMockupId || 'none'"
      outlined
      dense
      ref="mockupSelect"
    >
    <!-- Custom item slot for rendering the thumbnail and name -->
    <template v-slot:selection="data">
      <v-avatar size="64" class="mr-4">
        <v-img :src="data.item.thumbnail" :alt="data.item.name"></v-img>
      </v-avatar>
      <span>{{ data.item.name }}</span>
    </template>

    <template v-slot:item="data">
      <v-list-item
        :class="{ 'active-item': selectedMockupId === data.item.uuid }"
        @click="() => onSelect(data.item.uuid)"
      >
        <v-list-item-avatar size="64">
          <v-img :src="data.item.thumbnail" :alt="data.item.name"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MockupSelector",
  props: {
    orientation: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: String,
      required: true,
    },
    selectedMockupId: {
      type: String, // The selected mockup ID
      default: null,
    },
    label: {
      default: "Select Mockup"
    }
  },
  computed: {
    ...mapGetters(["mockups", "loadingMockups"]),
    filteredMockups() {
      return this.mockups.filter(
        (mockup) =>
          mockup.orientation === this.orientation &&
          mockup.aspectRatio === this.aspectRatio
      );
    },
    selectedMockup() {
      return this.mockups.find(mockup => mockup.uuid === this.selectedMockupId)
    }
  },
  methods: {
    ...mapActions(["loadMockups"]),
    onSelect(id) {
      this.$emit("select", id); // Emit the selected mockup ID
      this.$refs.mockupSelect.blur(); // Close the dropdown
    },
  },
  mounted() {
    if (this.mockups.length === 0) {
      this.loadMockups();
    }
  },
};
</script>

<style scoped>
.v-avatar {
  margin-right: 16px;
}

.v-list-item-avatar img {
  object-fit: cover;
}

.v-select .v-input__control {
  display: flex;
  align-items: center;
}

.mx-auto {
  margin: 0 auto;
}

/* Highlight the active item */
.active-item {
  background-color: #e0e0e0 !important;
}

.selected-container {
  cursor: pointer;
  border-radius: 5px;
  border: #565656 solid 1px;
}
</style>
