<template>
  <MainDialog :on-close="cancel" :show="show" :persistent="false">
    <v-card light>
      <v-card-title>
        <span class="headline"
        >{{ !productType ? "Create" : "Edit" }} Product Type</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="productTypeCopy.name"
                label="Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                prefix="€"
                type="number"
                v-model.number="productTypeCopy.costs"
                label="Costs Estimation"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h4>Define Image Properties to use as Image</h4>
            </v-col>
          </v-row>
          <v-row
            v-for="(value, key) in productTypeCopy.imagePropName"
            :key="key"
          >
            <v-col cols="5">
              <v-text-field
                disabled
                :value="key"
                label="Country Code"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="productTypeCopy.imagePropName[key]"
                label="Property Name to fetch"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                outlined
                color="danger"
                width="100%"
                @click="() => deleteImagePropName(key)"
                x-large
              >
                <v-icon color="error">mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="availableCountries.length">
            <v-col cols="5">
              <v-select
                :items="availableCountries"
                filled
                label="Choose Country"
                v-model="newImagePropNameKey"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="newImagePropNameValue"
                label="New Value"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                x-large
                width="100%"
                color="primary"
                @click="addImagePropName"
              >Add
              </v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-checkbox
                v-model="productTypeCopy.isDigital"
                label="Is Digital"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="productTypeCopy.resultsInTask"
                label="Results in Task"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="productTypeCopy.useTeeinblue"
                label="Wait for Template from Teeinblue"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="productTypeCopy.isPreview"
                label="Is Preview"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="productTypeCopy.sendFullTemplate"
                label="Send Full Template"
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="productTypeCopy.sendIllustration"
                label="Send Illustration"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="isSaving"
          :loading="isSaving"
          color="primary"
          text
          @click="cancel"
        >Cancel
        </v-btn
        >
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import {mapActions} from "vuex";
import {countryCodes} from "@/constants";

const _ = require("lodash");

export default {
  name: "AddProductTypeDialog",
  components: {MainDialog},
  data() {
    return {
      isSaving: false,
      defaultProductType: {
        imagePropName: {},
        isDigital: false,
        name: "",
        resultsInTask: false,
        useTeeinblue: false,
        isPreview: false,
        sendFullTemplate: false,
        sendIllustration: false,
        costs: 0
      },
      productTypeCopy: {},
      newImagePropNameKey: "",
      newImagePropNameValue: "",
      availableCountries: [],
    };
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      required: true,
    },
    productType: {
      type: Object,
      required: false,
    },
  },
  computed: {
    allCountries() {
      return Object.keys(countryCodes);
    },
  },
  watch: {
    productType: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.productTypeCopy = _.cloneDeep(newVal);
        }
      },
    },
    "productTypeCopy.imagePropName": {
      deep: true,
      handler: function () {
        this.availableCountries = this.allCountries.filter(
          (country) =>
            !Object.keys(this.productTypeCopy.imagePropName)?.includes(country)
        );
      },
    },
  },
  created() {
    this.productTypeCopy = _.cloneDeep(this.defaultProductType);
    this.availableCountries = this.allCountries;
  },
  methods: {
    ...mapActions(["addProductType", "editProductType"]),
    async save() {
      this.isSaving = true;
      if (this.productType) {
        await this.editProductType(this.productTypeCopy);
        this.isSaving = false;
        this.onClose();
      } else {
        await this.addProductType(this.productTypeCopy);
        this.isSaving = false;
        this.onClose();
      }
    },
    addImagePropName() {
      const key = this.newImagePropNameKey.trim();
      const value = this.newImagePropNameValue.trim();

      if (key && value) {
        this.productTypeCopy = {
          ...this.productTypeCopy,
          imagePropName: {
            ...this.productTypeCopy.imagePropName,
            [key]: value,
          },
        };
        this.newImagePropNameKey = "";
        this.newImagePropNameValue = "";
      }
    },
    deleteImagePropName(key) {
      const {[key]: _, ...newImagePropNames} =
        this.productTypeCopy.imagePropName;
      this.productTypeCopy = {
        ...this.productTypeCopy,
        imagePropName: {
          ...newImagePropNames,
        },
      };
    },
    cancel() {
      this.productTypeCopy = _.cloneDeep(this.productType || this.defaultProductType);
      this.onClose();
    },
  },
};
</script>

<style scoped></style>
