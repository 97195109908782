<template>
  <MainDialog :show="show" :on-close="onCancel">
    <v-card>
      <v-card-title class="flex justify-center">{{ title }}</v-card-title>
      <v-card-text>
        <div class="confirm-container">
          <v-icon color="error" :size="120" class="confirm-container__icon">{{
            icon
          }}</v-icon>
          <p class="confirm-container__message">{{ message }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="onCancel">{{ cancelText }}</v-btn>
        <v-btn :loading="loading" :color="acceptColor" @click="onAccept">{{ acceptText }}</v-btn>
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
export default {
  name: "ConfirmDialog",
  components: { MainDialog },
  props: {
    show: {
      default: false,
    },
    loading: {
      default: false,
    },
    onCancel: {
      required: true,
    },
    onAccept: {
      required: true,
    },
    cancelText: {
      default: "cancel",
    },
    acceptText: {
      default: "OK",
    },
    acceptColor: {
      default: "primary",
    },
    message: {
      default: "Are you sure?",
    },
    title: {
      required: false,
      type: String,
    },
    icon: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__message {
    margin: 24px;
    font-size: 18px;
    text-align: center;
  }
}
</style>
