<template>
  <MainDialog v-if="show" :show="show" :onClose="onClose">
    <v-card>
      <v-card-title>Order Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="orderCopy.shippingAddress.firstName"
              outlined
              label="First Name"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="orderCopy.shippingAddress.lastName"
              outlined
              label="Last Name"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="orderCopy.shippingAddress.addressPrimary"
              outlined
              label="Address Primary"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="orderCopy.shippingAddress.addressSecondary"
              outlined
              label="Address Secondary"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="orderCopy.shippingAddress.company"
              outlined
              label="Company"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="orderCopy.shippingAddress.city"
              outlined
              label="City"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="orderCopy.shippingAddress.postCode"
              outlined
              label="Post Code"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="orderCopy.shippingAddress.province"
              outlined
              label="Province"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              disabled
              :value="orderCopy.shippingAddress.countryCode"
              outlined
              label="Country Code"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onClose">Cancel</v-btn>
        <v-spacer />
        <v-btn @click="saveChanges" :disabled="!hasDifferences" color="success"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../fb";
const _ = require("lodash");

export default {
  name: "EditOrderAddressDialog",
  components: { MainDialog },
  data() {
    return {
      orderCopy: {},
      originalOrder: {},
      isSaving: false,
    };
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      require: true,
    },
    order: {
      required: true,
    },
    onSave: {
      required: false,
    },
  },
  computed: {
    hasDifferences() {
      return !_.isEqual(this.originalOrder, this.orderCopy);
    },
  },
  created() {
    this.originalOrder = _.cloneDeep(this.order);
    this.orderCopy = _.cloneDeep(this.order);
  },
  methods: {
    async saveChanges() {
      this.isSaving = true;
      const orderDocRef = doc(db, "orders", this.order.uid);
      try {
        await setDoc(
          orderDocRef,
          {
            shippingAddress: this.orderCopy.shippingAddress,
          },
          { merge: true }
        );

        if (this.onSave) {
          this.onSave(this.orderCopy);
        }
      } catch (error) {
        console.error("Error updating order document:", error);
      }
      this.isSaving = false;
      this.onClose();
    },
  },
};
</script>

<style scoped></style>
