<template>
  <v-lazy :options="{ rootMargin: '1000px' }" v-model="isVisible">
    <div>
      <v-card
        @contextmenu="onRightClick"
        @dragover="showDropZone = true"
        color="primary"
        class="card"
        min-width="200"
      >
        <div class="card-header-function">
          <v-btn
            @click="downloadImages"
            :color="downloadProgress === 100 ? 'success' : 'primary'"
            dark
            small
            fab
            class="card-header-button"
          >
            <v-progress-circular width="3" :value="downloadProgress">
              <v-icon>mdi-download</v-icon>
            </v-progress-circular>
          </v-btn>
          <v-btn
            @click="openTask"
            color="primary"
            dark
            rounded
            link
            class="card-header-button"
          >
            {{ task.orderName }}
            <v-icon x-small v-if="isAdmin" class="ml-3">mdi-open-in-new</v-icon>
          </v-btn>
          <OrderNoteTooltip v-if="isAdmin" :task="task" />
          <SupportTooltip v-if="isAdmin" :order-id="task.orderId" />
          <TimeIndicator :creation-date="task.createdAt" />
        </div>

        <ExpressLayover :task="task"/>

        <div v-if="showDropZone" class="d-flex">
          <v-card
            v-for="index in task.numberOfSubTasks || 1"
            :key="index"
            :width="`${100 / task.numberOfSubTasks}%`"
            @dragleave="showDropZone = false"
            class="card-overlay"
            :style="{ left: 100 - 100 / index ? `${100 - 100 / index}%` : 0 }"
            @drop.prevent="(e) => dropFile(e, index)"
            @dragenter.prevent
            @dragover.prevent
          >
            <v-icon
              :style="{ 'pointer-events': 'none' }"
              @drop.prevent="(e) => dropFile(e, index)"
              size="130"
              color="white"
            >
              mdi-select-drag }}
            </v-icon>
            {{ index }}
          </v-card>
        </div>
        <div class="d-flex">
          <div :style="{ width: `${100 / task.numberOfSubTasks}%` }">
            <v-img
              :height="resultFile[1] ? '250px' : '500px'"
              position="center"
              :src="task.thumb"
              :lazy-src="task.thumb"
              class="image"
            />
            <v-img
              v-if="resultFile[1] && resultPreviewUrl[1]"
              height="250px"
              position="center"
              :src="resultPreviewUrl[1]"
              class="image"
            />
          </div>
          <div
            v-if="task.image2"
            :style="{ width: `${100 / task.numberOfSubTasks}%` }"
          >
            <v-img
              :height="resultFile[2] ? '250px' : '500px'"
              position="center"
              :src="task.image2Thumb"
              class="image"
            />
            <v-img
              v-if="resultFile[2] && resultPreviewUrl[2]"
              height="250px"
              position="center"
              :src="resultPreviewUrl[2]"
              class="image"
            />
          </div>
        </div>
        <div class="revision-reason-container" v-if="task.isRevision">
          Revision: {{ task.revisionReason }}
        </div>
        <v-card-actions class="card-actions">
          <v-btn
            v-if="!task.assignedTo"
            :loading="isAssigning"
            :disabled="isAssigning"
            color="success"
            @click="() => assign()"
            >Assign to me</v-btn
          >
          <v-row v-else>
            <v-col :cols="12 / task.numberOfSubTasks">
              <v-file-input
                v-model="resultFile[1]"
                @change.native="(e) => onFileChange(e, 1)"
                clearable
                dark
                label="File input"
                outlined
                dense
                hide-details
                :disabled="uploaded"
              ></v-file-input>
            </v-col>
            <v-col v-if="task.image2" :cols="12 / task.numberOfSubTasks">
              <v-file-input
                v-model="resultFile[2]"
                @change.native="(e) => onFileChange(e, 2)"
                clearable
                dark
                label="File input"
                outlined
                dense
                hide-details
                :disabled="uploaded"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-btn
                :loading="isUploading"
                class="pt-0"
                width="100%"
                v-if="resultFile"
                type="file"
                dark
                color="green"
                @click="upload"
                :disabled="disableUpload"
              >
                <v-icon>{{
                  uploaded ? "mdi-check-bold" : "mdi-upload"
                }}</v-icon>
                {{ uploaded ? "Uploaded" : "Upload Result" }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      <ListMenu :show="showMenu" :x="x" :y="y" :list-items="menuItems" />
      <v-dialog persistent width="500" v-model="showHelpDialog">
        <v-card dark>
          <v-img :src="require('@/assets/downloadHelp.webp')" />
          <v-card-actions>
            <v-spacer />
            <v-btn outlined @click="showHelpDialog = false">
              <v-icon>mdi-check</v-icon>
              I Understood
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-lazy>
</template>

<script>
import {downloadImageFromUrl, openOrderModal} from "@/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getAuth } from "firebase/auth";
import { EventBus, events } from "../../../event-bus";
import OrderNoteTooltip from "@/components/OrderNoteTooltip";
import { permissions } from "@/constants";
import ListMenu from "@/components/ListMenu";
import TimeIndicator from "@/components/TaskCards/components/TimeIndicator";
import SupportTooltip from "@/components/Support/SupportTooltip";
import ExpressLayover from "@/components/TaskCards/ExpressLayover";

export default {
  name: "UploadResultCard",
  components: {ExpressLayover, SupportTooltip, TimeIndicator, ListMenu, OrderNoteTooltip },
  data() {
    return {
      isVisible: false,
      resultFile: {
        1: null,
        2: null,
      },
      resultPreviewUrl: {
        1: null,
        2: null,
      },
      isUploading: false,
      isAssigning: false,
      uploaded: false,
      showDropZone: false,
      downloadProgress: 0,
      showMenu: false,
      showHelpDialog: false,
      x: 0,
      y: 0,
    };
  },
  props: {
    task: {
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "userPermissions",
      "allUsers",
      "isAdmin",
      "showDownloadHelp",
    ]),
    disableUpload() {
      return (
        this.uploaded ||
        this.isUploading ||
        (!!this.task.image && !this.resultFile[1]) ||
        (!!this.task.image2 && !this.resultFile[2])
      );
    },
    menuItems() {
      return [
        {
          icon: "mdi-account-arrow-right",
          onClick: () => {},
          label: "Reassign Task",
          subListItems: this.assigneeList,
          hide: !this.isAdmin,
        },
        {
          icon: "mdi-download",
          onClick: () => this.downloadOriginalImage(1),
          label: this.task.image2
            ? "Download Original Image Left"
            : "Download Original Image",
        },
        {
          icon: "mdi-download",
          onClick: () => this.downloadOriginalImage(2),
          label: "Download Original Image Right",
          hide: !this.task.image2,
        },
        {
          icon: "mdi-open-in-new",
          onClick: this.openImage,
          label: "Open Image",
        },
        {
          icon: "mdi-email-search-outline",
          onClick: this.searchOrderInMails,
          label: "Search In Mails",
          hide: !this.isAdmin,
        },
      ];
    },
    assigneeList() {
      const allUsersAllowedToDraw = this.allUsers.filter(
        (user) =>
          user.permissions.includes(permissions.TASK_DRAWING) &&
          !user.permissions.includes(permissions.ADMIN) &&
          user.assignedProductTypes?.includes(this.task.productType.id) &&
          user.uid !== this.task.assignedTo
      );
      if (this.task.assignedTo !== this.currentUser.uid) {
        allUsersAllowedToDraw.push(this.currentUser);
      }
      return allUsersAllowedToDraw.map((user) => {
        return {
          label: user.username,
          onClick: () => this.assign(user.uid),
        };
      });
    },
  },
  watch: {
    "resultFile.size": {
      handler: async function (newValue) {
        if (newValue && newValue > 50000000) {
          this.resultFile = null;
          EventBus.$emit(events.SNACKBAR, {
            type: "error",
            message:
              "Please only upload Files with a max. Size of <strong>50MB</strong></br>Still make sure <strong>Quality</strong> will not suffer under compression!",
          });
        }
      },
    },
  },
  methods: {
    ...mapMutations(["disableShowDownloadHelp"]),
    ...mapActions(["uploadResultImage", "assignTask"]),
    openTask() {
      if (this.isAdmin) {
        openOrderModal(this.task.orderId)
      }
    },
    openImage() {
      window.open(this.task.image, "_blank");
    },
    onRightClick(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    downloadImages() {
      if (this.task.numberOfSubTasks > 1 && this.showDownloadHelp) {
        this.showHelpDialog = true;
        this.disableShowDownloadHelp();
      } else {
        this.downloadOriginalImage(1);
        if (this.task.numberOfSubTasks > 1) this.downloadOriginalImage(2);
      }
    },
    downloadOriginalImage(imageIndex) {
      const imageToDownload =
        imageIndex === 1 ? this.task.image : this.task.image2;
      const fileAppendix =
        this.task.numberOfSubTasks > 1
          ? imageIndex === 1
            ? "_LEFT"
            : "_RIGHT"
          : "";
      const fileName = `${this.task.orderName}${
        fileAppendix ? "_" + fileAppendix : ""
      }`;
      downloadImageFromUrl(
        imageToDownload,
        fileName,
        (progress) => (this.downloadProgress = progress)
      );
    },
    onFileChange(event, imageIndex) {
      this.resultPreviewUrl[imageIndex] = URL.createObjectURL(
        event.target.files[0]
      );
    },
    upload() {
      this.isUploading = true;
      this.uploadResultImage({
        task: this.task,
        imageFile: this.resultFile[1],
        imageFile2: this.resultFile[2],
        callBack: () => {
          this.isUploading = false;
          this.uploaded = true;
        },
      });
    },
    dropFile(e, imageIndex) {
      e.preventDefault();
      this.showDropZone = false;
      let droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      if (droppedFiles) {
        this.resultFile[imageIndex] = droppedFiles[0];
        this.resultPreviewUrl[imageIndex] = URL.createObjectURL(
          droppedFiles[0]
        );
      }
    },
    assign(uid) {
      this.isAssigning = true;
      const auth = getAuth();
      this.assignTask({
        task: this.task,
        assignToUid: uid || auth.currentUser.uid,
        callBack: () => {
          this.showMenu = false;
          this.isAssigning = false;
        },
      });
    },
    searchOrderInMails() {
      window.open(
        `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${
          this.task.orderName.split("#")[1]
        }`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  overflow: hidden;
}
.card-header-function {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .v-btn {
    margin: 12px;
  }
  .image {
    cursor: pointer;
    z-index: 1;
  }
}
.card-actions {
  position: relative;
  height: 124px;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.card-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(115, 115, 115, 0.7);
}

.revision-reason-container {
  position: absolute;
  bottom: 124px;
  left: 0;
  display: flex;
  z-index: 2;
  background: #a20000;
  color: #ffffff;
  padding: 5px 10px;
}
</style>
