<template>
  <MainDialog :on-close="onClose" :persistent="false" :show="show">
    <v-card dark>
      <v-card-text>
        <v-tabs v-model="tab" dense centered dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#openPayment"> Open Payments</v-tab>
          <v-tab href="#closedPayments"> Payment History</v-tab>
        </v-tabs>

        <v-tabs-items dark v-model="tab">
          <v-tab-item value="openPayment">
            <div class="current-earnings-container">
              <div class="total-earnings">
                {{ unpaidTasksAmount }}
              </div>
            </div>
            <UnpaidTasksList :payment="userPayment"/>
          </v-tab-item>
          <v-tab-item value="closedPayments">
            <div class="current-earnings-container">
              <div class="total-earnings">
                {{ totalPayment }}
              </div>
            </div>
            <v-simple-table height="400">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Transaction ID</th>
                  <th class="text-left">Total Payed</th>
                  <th class="text-left">Transaction Date</th>
                </tr>
                </thead>
                <tbody v-if="userPayment?.transactionHistory.length">
                <tr
                  v-for="payment in userPayment?.transactionHistory"
                  :key="payment.transactionDate"
                >
                  <td>{{ payment.transactionId }}</td>
                  <td>{{ payment.totalPayed }}</td>
                  <td>
                    {{ new Date(payment.transactionDate).toDateString() }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
        <div class="pt-3">Please let Arne know when you want a payout.</div>
      </v-card-text>
    </v-card>
  </MainDialog>
</template>

<script>
import {mapGetters} from "vuex";
import UnpaidTasksList from "@/components/UnpaidTasksList";
import MainDialog from "@/components/MainDialog";

export default {
  name: "EarningsDialog",
  components: {MainDialog, UnpaidTasksList},
  data() {
    return {
      tab: "",
    };
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "userPayment",
      "payPerTask",
      "unpaidTasks",
      "userCurrency",
      "unpaidTasksAmount",
    ]),
    totalPayment() {
      let transactionHistory = this.userPayment?.transactionHistory;
      let totalPayment = 0;
      if (transactionHistory?.length > 0) {
        transactionHistory.forEach((transaction) => {
          totalPayment += transaction.totalPayed;
        });
      }
      return totalPayment + this.userCurrency;
    },
  },
};
</script>

<style lang="scss" scoped>
.current-earnings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
}

.total-earnings {
  font-size: 63px;
  line-height: 63px;
  font-weight: bold;
}
</style>
