import {
  collection,
  query,
  where,
  setDoc,
  doc,
  onSnapshot,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { auth, db, functions } from "../../../fb";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import { permissions, taskStatus } from "@/constants";
import { EventBus, events } from "../../../event-bus";
import { getManagerOfUser } from "@/api/users";
import {
  currentMonthYear,
  generateRandom5Digits,
  getImageUrl,
  limitString,
} from "@/utils";
import { appSettings } from "@/config";

const defaultState = {
  DRAW: {
    loading: true,
    initial: true,
    errorMessage: "",
    tasks: [],
  },
  TEMPLATING: {
    loading: true,
    initial: true,
    errorMessage: "",
    tasks: [],
  },
  REVIEW: {
    loading: true,
    initial: true,
    errorMessage: "",
    tasks: [],
  },
  CLOSED: {
    loading: true,
    initial: true,
    errorMessage: "",
    tasks: [],
  },
  allTasks: {
    loading: true,
    initial: true,
    errorMessage: "",
    tasks: [],
  },
  unsubTasks: {
    DRAW: () => {},
    TEMPLATING: () => {},
    REVIEW: () => {},
    CLOSED: () => {},
    allTasks: () => {},
  },
};

const getters = {
  tasksState: (state) => state,
  drawingTasks: (state) => state.DRAW,
  templatingTasks: (state) => state.TEMPLATING,
  reviewTasks: (state) => state.REVIEW,
  drawTasksPerUser: (state) => {
    const countMap = {};
    for (const task of state.DRAW.tasks) {
      const userId = task.assignedTo;
      if (countMap[userId]) {
        countMap[userId]++;
      } else {
        countMap[userId] = 1;
      }
    }
    return countMap;
  },
  allTasks: (state) => state.allTasks,
  tasksLengthsByStatus: (state) => {
    return {
      DRAW: state.DRAW.tasks.length,
      TEMPLATING: state.TEMPLATING.tasks.length,
      REVIEW: state.REVIEW.tasks.length,
      CLOSED: state.CLOSED.tasks.length,
      allTasks: state.allTasks.tasks.length,
    };
  },
};

const actions = {
  unsubscribeTasks({ state }) {
    state.unsubTasks.DRAW();
    state.unsubTasks.TEMPLATING();
    state.unsubTasks.REVIEW();
    state.unsubTasks.CLOSED();
    state.unsubTasks.allTasks();
  },
  async loadTasksBySearchTerm({ commit }, { searchTerm }) {
    commit("setTasksLoading", {
      bool: true,
      status: "allTasks",
    });
    commit("setTasks", {
      tasks: [],
    });
    const tasksRef = collection(db, "tasks");

    const q = query(
      tasksRef,
      where("orderName", ">=", searchTerm),
      where("orderName", "<=", searchTerm + "~")
    );
    const docsSnap = await getDocs(q);

    docsSnap.forEach((doc) => {
      commit("addTask", {
        task: {
          ...doc.data(),
        },
        status: "allTasks",
      });
    });

    commit("setTasksLoading", {
      bool: false,
      status: "allTasks",
    });
  },
  async loadTasks({ commit, getters, state }, { status }) {
    commit("setTasksLoading", {
      bool: true,
      status,
    });
    commit("setTasks", {
      tasks: [],
      status,
    });
    const coll = collection(db, "tasks");
    let docs = null;

    if (
      getters.userPermissions.includes(permissions.ADMIN) ||
      getters.userPermissions.includes(permissions.MANAGE_TASKS)
    ) {
      docs = status
        ? query(
            coll,
            where("status", "==", status),
            orderBy("createdAt", "asc")
          )
        : coll;
    } else {
      const managedUsers = getters.managedUsers || [];
      const showTaskMarket = getters.userPermissions.includes(
        permissions.TASKMARKET
      );

      switch (status) {
        case taskStatus.REVIEW:
          docs = query(
            coll,
            where("status", "==", status),
            where("assignedTo", "in", [...managedUsers]),
            orderBy("createdAt", "asc")
          );
          break;
        case taskStatus.TEMPLATING:
          docs = query(
            coll,
            where("status", "==", status),
            where("assignedTo", "in", [
              ...(showTaskMarket ? [""] : []),
              auth.currentUser.uid.toString(),
              ...managedUsers,
            ]),
            orderBy("createdAt", "asc")
          );
          break;
        case taskStatus.DRAW:
          docs = query(
            coll,
            where("status", "==", status),
            where("assignedTo", "in", [
              ...(showTaskMarket ? [""] : []),
              auth.currentUser.uid.toString(),
              ...managedUsers,
            ]),
            orderBy("createdAt", "asc")
          );
          break;
        default:
          docs = query(
            coll,
            where("status", "==", status),
            where("assignedTo", "in", [
              "",
              auth.currentUser.uid.toString(),
              ...managedUsers,
            ]),
            orderBy("createdAt", "asc")
          );
      }
    }
    state.unsubTasks[status] = onSnapshot(docs, (snapshot) => {
      if (!snapshot.docs.length && state[status].initial) {
        // No documents, initial load is complete, but no data.
        commit("setTasksLoading", { bool: false, status });
      }

      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          commit("addTask", {
            task: {
              ...change.doc.data(),
            },
            status,
          });
        }
        if (change.type === "modified") {
          commit("updateTask", {
            task: {
              ...change.doc.data(),
            },
            status,
          });
        }
        if (change.type === "removed") {
          commit("deleteTask", {
            task: {
              ...change.doc.data(),
            },
            status,
          });
        }
      });
      if (state[status].initial) {
        // This was the first snapshot, which means initial loading is done.
        commit("setTasksLoading", { bool: false, status });
        // After the initial load, set isInitialLoad to false so that subsequent changes do not trigger this block
      }
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async uploadResultImage(
    { getters, dispatch },
    { task, imageFile, imageFile2, callBack }
  ) {
    const storage = getStorage();
    const getStorageRef = (imgId) => {
      return ref(
        storage,
        `/results/${currentMonthYear}/${task.uid}_${imgId}.png`
      );
    };

    const imageId = generateRandom5Digits(); // Used to have unique link important when task is pushed back
    const uploadResponse = await uploadBytes(getStorageRef(imageId), imageFile);
    const resultUrl = await getDownloadURL(uploadResponse.ref);
    const resultThumbnailUrl = getImageUrl(
      `results/${currentMonthYear}/thumbnails/${task.uid}_${imageId}_500x500.png`
    );

    let resultUrl2 = null;
    let resultThumbnailUrl2 = null;
    if (imageFile2) {
      const imageId2 = generateRandom5Digits(); // Used to have unique link important when task is pushed back
      const uploadResponse2 = await uploadBytes(
        getStorageRef(imageId2),
        imageFile2
      );
      resultUrl2 = await getDownloadURL(uploadResponse2.ref);
      resultThumbnailUrl2 = getImageUrl(
        `results/${currentMonthYear}/thumbnails/${task.uid}_${imageId2}_500x500.png`
      );
    }

    const needsReview = getters.currentUser?.needsReview;

    let statusData = {
      status:
        task.template && task.template !== "none"
          ? taskStatus.TEMPLATING
          : taskStatus.CLOSED,
      reviewType: null,
    };

    if (needsReview) {
      statusData.status = taskStatus.REVIEW;
      statusData.reviewType = taskStatus.DRAW;
    }

    await setDoc(
      doc(db, `tasks`, task?.uid?.toString()),
      {
        ...statusData,
        result: resultUrl,
        resultThumbnailUrl: resultThumbnailUrl,
        result2: resultUrl2,
        resultThumbnailUrl2: resultThumbnailUrl2,
        assignedTo: needsReview ? auth.currentUser.uid : "",
        isRevision: false,
        editors: {
          [task.status]: auth.currentUser.uid,
        },
      },
      { merge: true }
    );

    callBack();
  },
  async acceptTaskResult(
    { dispatch, getters },
    { task, customImage, callback, orientation }
  ) {
    const needsReview = getters.currentUser?.needsReview;
    const isReviewTypeDrawing = task.reviewType === taskStatus.DRAW;

    const results = {
      result: task.result || "",
      result2: task.result2 || "",
      finalTemplate: task.finalTemplate || "",
    };

    // If Custom image update task and send the correct image to the customer
    if (customImage[1] || customImage[2]) {
      const imageId = generateRandom5Digits(); // Used to have a unique link, important when the task is pushed back
      const isReviewTypeDrawing = task.reviewType === taskStatus.DRAW;

      const uploadAndSetImage = async (customImage, isReviewTypeDrawing) => {
        const resultsUrl = `/results/${currentMonthYear}/${task.uid}_REV_${imageId}.png`;
        const templatesUrl = `/templates/${currentMonthYear}/${task.uid}_temp_REV_${imageId}.jpg`;
        const resultsUrlThumbnail = `results/${currentMonthYear}/thumbnails/${task.uid}_REV_${imageId}_500x500.png`;
        const templatesUrlThumbnail = `templates/${currentMonthYear}/thumbnails/${task.uid}_temp_REV_${imageId}_500x500.jpg`;

        const storageUrl = isReviewTypeDrawing ? resultsUrl : templatesUrl;
        const thumbnailUrl = isReviewTypeDrawing
          ? resultsUrlThumbnail
          : templatesUrlThumbnail;

        const storage = getStorage();
        const storageRef = ref(storage, storageUrl);
        const uploadResponse = await uploadBytes(storageRef, customImage);
        const downloadUrl = await getDownloadURL(uploadResponse.ref);

        return {
          imageUrl: downloadUrl,
          thumbnailUrl: getImageUrl(thumbnailUrl),
        };
      };

      if (customImage[1]) {
        const customResults = await uploadAndSetImage(
          customImage[1],
          isReviewTypeDrawing
        );
        if (isReviewTypeDrawing) {
          results.result = customResults.imageUrl;
          results.resultThumbnailUrl = customResults.thumbnailUrl;
        } else {
          results.finalTemplate = customResults.imageUrl;
          results.finalTemplateThumbnailUrl = customResults.thumbnailUrl;
        }
      }

      if (customImage[2]) {
        const customResults = await uploadAndSetImage(
          customImage[2],
          isReviewTypeDrawing
        );
        results.result2 = customResults.imageUrl;
        results.resultThumbnailUrl2 = customResults.thumbnailUrl;
      }
    }

    if (!needsReview) {
      let nextStatus =
        isReviewTypeDrawing && task.template !== "none"
          ? taskStatus.TEMPLATING
          : taskStatus.CLOSED;

      if (!isReviewTypeDrawing && task.productType.isDigital) {
        nextStatus = taskStatus.CLOSED;
      }

      if (!isReviewTypeDrawing) {
        await dispatch("sendResultsToCustomer", {
          taskUid: task.uid,
          template: results.finalTemplate,
          orientation: orientation,
        });
      }

      await setDoc(
        doc(db, `tasks`, task.uid),
        {
          ...results,
          status: nextStatus,
          reviewType: null,
          isRevision: false,
          assignedTo: "",
          editors: {
            [taskStatus.REVIEW]: auth.currentUser.uid,
          },
        },
        { merge: true }
      );
      callback();
    } else {
      await setDoc(
        doc(db, `tasks`, task.uid),
        {
          ...results,
          assignedTo: auth.currentUser.uid,
          editors: {
            [taskStatus.REVIEW]: auth.currentUser.uid,
          },
        },
        { merge: true }
      );
      callback();
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async pushTaskBack({}, { taskUid, status, reason, callback }) {
    const pushBack = httpsCallable(functions, "pushTaskBack");
    const res = await pushBack({
      taskUid,
      status,
      reason,
    });
    callback();
    if (res?.data?.code === 403) {
      EventBus.$emit(events.SNACKBAR, {
        message: `You are missing the Permission \"MANAGE_TASKS\"`,
        type: "error",
      });
    }
  },
  async sendResultsToCustomer({}, { taskUid, template, orientation }) {
    // eslint-disable-next-line no-empty-pattern
    try {
      // const storage = getStorage();
      // let previewUrl = await new Promise((resolve) => {
      //   if (isPreview) {
      //     // watermark from remote source
      //     const options = {
      //       init(img) {
      //         img.crossOrigin = "anonymous";
      //       },
      //     };
      //
      //     watermark(
      //       [
      //         resultImageUrl,
      //         require(`../../assets/watermark_${countryCode}.png`),
      //       ],
      //       options
      //     )
      //       .image(watermark.image.center(0.4))
      //       .then(async (watermarkedImage) => {
      //         const base64img = watermarkedImage.getAttribute("src");
      //         const storageRefWatermark = ref(
      //           storage,
      //           `/results/${currentMonthYear}/${taskUid}_watermark.png`
      //         );
      //         const uploadResponse = await uploadString(
      //           storageRefWatermark,
      //           base64img,
      //           "data_url"
      //         );
      //         const watermarkUrl = await getDownloadURL(uploadResponse.ref);
      //         resolve(watermarkUrl);
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //       });
      //   } else {
      //     resolve(null);
      //   }
      // });
      console.log(template, orientation);

      const mailResults = httpsCallable(functions, "mailResults");
      await mailResults({
        taskUid: taskUid,
        template,
        orientation,
      });

      return {
        success: true,
      };
    } catch (e) {
      EventBus.$emit(events.SNACKBAR, { message: e, type: "error" });
      throw new Error("ABORT MAIL DID NOT WENT OUT!", e);
    }
  },
  async uploadFinalTemplate(
    { getters, dispatch },
    { task, templateFile, callBack, orientation }
  ) {
    const storage = getStorage();
    const imageId = generateRandom5Digits(); // Used to have unique link important when task is pushed back
    const storageRef = ref(
      storage,
      `/templates/${currentMonthYear}/${task.uid}_temp_${imageId}.jpg`
    );
    const uploadResponse = await uploadBytes(storageRef, templateFile);
    const resultUrl = await getDownloadURL(uploadResponse.ref);

    const needsReview = getters.currentUser?.needsReview;

    let statusData = {
      status: needsReview ? taskStatus.REVIEW : taskStatus.CLOSED,
      reviewType: needsReview ? taskStatus.TEMPLATING : null,
    };

    const finalTemplateThumbnailUrl = getImageUrl(
      `templates/${currentMonthYear}/thumbnails/${task.uid}_temp_${imageId}_500x500.jpg`
    );

    if (!needsReview) {
      await dispatch("sendResultsToCustomer", {
        taskUid: task.uid,
        template: resultUrl,
        orientation: orientation,
      });
    }

    await setDoc(
      doc(db, `tasks`, task?.uid?.toString()),
      {
        finalTemplate: resultUrl,
        finalTemplateThumbnailUrl: finalTemplateThumbnailUrl,
        ...statusData,
        isRevision: false,
        editors: {
          TEMPLATING: auth.currentUser.uid,
        },
      },
      { merge: true }
    );

    callBack();
  },
  // eslint-disable-next-line no-empty-pattern
  // async cancelTask({}, { task, callBack }) {
  //   await setDoc(
  //     doc(db, `tasks`, task?.uid?.toString()),
  //     {
  //       status: taskStatus.CANCELED,
  //     },
  //     { merge: true }
  //   );
  //
  //   const updateOrderTags = httpsCallable(functions, "updateShopifyOrderTags");
  //   await updateOrderTags({
  //     orderId: task.orderId,
  //     tags: taskStatus.CLOSED,
  //     countryCode: task.countryCode,
  //   });
  //
  //   callBack();
  // },
  // eslint-disable-next-line no-empty-pattern
  async assignTask({ state, getters }, { task, assignToUid, callBack }) {
    const assignedTasks = state[task.status].tasks.filter(
      (task) => task.assignedTo === auth.currentUser.uid
    );
    const maxTasksAllowed =
      getters.maxOpenTasks || appSettings.DEFAULT_MAX_ALLOWED_OPEN_TASKS; // If user has custom allowance of max open tasks use
    if (assignedTasks.length < maxTasksAllowed) {
      // Get Manager of Assignee
      const managerUid = await getManagerOfUser(assignToUid);
      await setDoc(
        doc(db, `tasks`, task?.uid?.toString()),
        {
          assignedTo: assignToUid,
          manager: managerUid || "",
        },
        { merge: true }
      );
    } else {
      EventBus.$emit(events.SNACKBAR, {
        type: "error",
        message: `You are only allowed to have up to ${maxTasksAllowed} Tasks open before picking new ones.`,
      });
    }
    callBack();
  },
  // eslint-disable-next-line no-empty-pattern
  async editOrderNote({}, { taskUid, orderUid, orderNote, orderNoteFiles }) {
    const editOrderNote = httpsCallable(functions, "editOrderNote");
    const res = await editOrderNote({
      taskUid,
      orderNote,
      orderNoteFiles,
      orderUid,
    });
    if (res.data.success) {
      EventBus.$emit(events.SNACKBAR, {
        message: `Note: \"${limitString(orderNote, 15)}\" saved successfully!`,
        type: "success",
      });
    } else {
      EventBus.$emit(events.SNACKBAR, {
        message: `Note: \"${limitString(orderNote, 15)}\" not saved!`,
        type: "error",
      });
    }
  },
};

const mutations = {
  setTasks: (state, data) => {
    const { tasks, status } = data;
    state[status || "allTasks"].tasks = tasks;
  },
  setTasksLoading: (state, data) => {
    const { bool, status = "allTasks" } = data;
    state[status] = {
      ...state[status],
      loading: bool,
      initial: bool,
    };
  },
  addTask: (state, data) => {
    const { task, status } = data;
    if (task.productType.isExpress) {
      state[status || "allTasks"].tasks.unshift(task);
    } else {
      state[status || "allTasks"].tasks.push(task);
    }
  },
  updateTask: (state, data) => {
    const { task, status } = data;
    const index = state[status || "allTasks"].tasks.findIndex(
      (existingTask) => existingTask.uid === task.uid
    );
    Object.assign(state[status || "allTasks"].tasks[index], { ...task });
  },
  deleteTask: (state, data) => {
    const { task, status } = data;
    state[status || "allTasks"].tasks = state[
      status || "allTasks"
    ].tasks.filter((existingTask) => existingTask.uid !== task.uid);
  },
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).tasks
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
