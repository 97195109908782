<template>
  <v-card>
    <v-card-title>Print Shops</v-card-title>
    <v-card-text>
      <v-data-table
        hide-default-footer
        :loading="loadingPrintShops"
        :headers="headers"
        :items="printShops"
        class="elevation-1"
      >
        <template v-slot:item.printShop="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.countries="{ item }">
          {{ item.countries.join(", ") || "-" }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PrintShops",
  data() {
    return {
      headers: [
        { text: "Print Shop Name", value: "printShop" },
        { text: "Countries Responsible For", value: "countries" },
      ],
    };
  },
  computed: {
    ...mapGetters(["printShops", "loadingPrintShops"]),
  },
  mounted() {
    this.loadPrintShops();
  },
  methods: {
    ...mapActions(["loadPrintShops"]),
  },
};
</script>

<style scoped></style>
