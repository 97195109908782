<template>
  <v-dialog v-if="user" persistent width="500" max-width="100%" :value="show">
    <v-card dark>
      <v-card-title v-if="!receivedPassword">Add New User</v-card-title>
      <v-card-text>
        <v-form v-if="!receivedPassword" v-model="validForm">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Username"
                outlined
                v-model="editedUser.username"
                :rules="usernameRules"
                required
              />
            </v-col>
            <template v-if="editedUser.payment">
              <v-col cols="12" md="6">
                <v-text-field
                  label="Pay Per Task"
                  outlined
                  type="number"
                  v-model="editedUser.payment.payPerTask"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Currency e.g €"
                  outlined
                  v-model="editedUser.payment.currency"
                  :disabled="!editedUser.payment.payPerTask"
                />
              </v-col>
            </template>
            <v-col cols="12" md="6">
              <v-checkbox
                class="ml-6"
                v-model="editedUser.needsReview"
                label="User needs Review"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                disabled
                label="E-mail"
                outlined
                v-model="editedUser.email"
                :rules="emailRules"
              />
            </v-col>
            <v-col v-if="availablePermissions.length > 1" cols="12">
              <v-combobox
                v-model="editedUser.permissions"
                :items="availablePermissions"
                label="Permissions"
                multiple
                outlined
                dark
                chips
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="editedUser.assignedProductTypes"
                :items="availableProductTypes"
                label="Assigned Product Types"
                multiple
                item-text="name"
                item-value="key"
                outlined
                dark
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <div v-else class="showPassword">User Changed!</div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" text>Close</v-btn>
        <v-spacer />
        <v-btn
          :disabled="!hasChanges || isUpdating"
          :loading="isUpdating"
          @click="updateUser"
          v-if="!receivedPassword"
          >Update User</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { permissions } from "@/constants";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../fb";
import { mapGetters } from "vuex";
import { EventBus, events } from "../../../../event-bus";

const _ = require("lodash");

export default {
  name: "EditUserDialog",
  props: {
    show: {
      default: false,
    },
    closeDialog: {
      required: true,
    },
    user: {
      required: true,
    },
  },
  data() {
    return {
      editedUser: {
        permissions: [],
        username: "",
        needsReview: true,
        payment: {
          transactionHistory: [],
          unpaidTasks: [],
          currency: "",
          payPerTask: 1,
        },
        email: "",
      },
      showDialog: false,
      isUpdating: false,
      receivedPassword: "",
      usernameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
        (v) => v.length >= 3 || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      validForm: true,
    };
  },
  watch: {
    user: {
      deep: true,
      handler: function (newVal) {
        if (newVal) {
          this.editedUser = _.cloneDeep(newVal);
        }
      },
    },
  },
  computed: {
    ...mapGetters(["userPermissions", "productTypes"]),
    availablePermissions() {
      return this.userPermissions.filter((permission) => {
        if (this.userPermissions.includes(permissions.ADMIN)) return true;
        return (
          permission !== permissions.MANAGER &&
          permission !== permissions.TASK_REVIEW
        );
      });
    },
    availableProductTypes() {
      return this.productTypes.map(productType => {
        return {
          name: productType.name,
          key: productType.key,
        }
      })
    },
    hasChanges() {
      return !_.isEqual(this.user, this.editedUser);
    },
  },
  methods: {
    close() {
      this.closeDialog();
      // Wait for closing animation
      setTimeout(() => {
        this.editedUser = _.cloneDeep(this.user);
      }, 500);
    },
    async updateUser() {
      if (!this.validForm) {
        EventBus.$emit(events.SNACKBAR, {
          message: "Please enter all required Fields",
          type: "error",
        });
      } else {
        this.isUpdating = true;
        const updateUser = httpsCallable(functions, "updateUser");
        const response = await updateUser({
          uid: this.editedUser.uid,
          username: this.editedUser.username,
          payPerTask: this.editedUser.payment?.payPerTask,
          currency: this.editedUser.payment?.currency,
          permissions: this.editedUser.permissions,
          needsReview: this.editedUser.needsReview,
          assignedProductTypes: this.editedUser.assignedProductTypes,
        });
        this.isUpdating = false;

        if (response.data.success) {
          EventBus.$emit(events.SNACKBAR, {
            message: " Updated Successfully " + this.editedUser.username,
          });
          this.close();
        } else {
          EventBus.$emit(events.SNACKBAR, {
            type: "error",
            message: response.data.errorMessage,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.showPassword {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  h3 {
    color: white;
  }
}
</style>
