<template>
  <MainDialog
      :dark="false"
      :on-close="onClose"
      :show="show"
      :persistent="persistent"
  >
    <v-card>
      <v-card-title>Re-Upload Task</v-card-title>
      <v-card-subtitle>
        Result and Final Template can be uploaded
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="8">
                <v-text-field
                    outlined
                    hide-details
                    label="Result"
                    v-model="taskCopy.result"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                    :loading="uploadStates.result"
                    @click="handleFileImport('result', 'resultThumbnailUrl')"
                    height="56px"
                    width="100%"
                >Upload</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-img
                    class="mt-3"
                    max-height="400"
                    contain
                    :src="taskCopy.result"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="8">
                <v-text-field
                    outlined
                    hide-details
                    label="Final Template"
                    v-model="taskCopy.finalTemplate"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                    :loading="uploadStates.finalTemplate"
                    @click="handleFileImport('finalTemplate', 'finalTemplateThumbnailUrl')"
                    height="56px"
                    width="100%"
                >Upload</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-img
                    class="mt-3"
                    max-height="400"
                    contain
                    :src="taskCopy.finalTemplate"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <input
            ref="uploader"
            style="display: none"
            type="file"
            @change="onFileChanged"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            :loading="isSaving"
            :disabled="isReassigning"
            color="success"
            @click="uploadAndSendResults"
        >Save and Send Results</v-btn
        >
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import { mapActions, mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { EventBus, events } from "../../../event-bus";
import { currentMonthYear, getImageUrl } from "@/utils";
import _ from "lodash";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../fb";
import { taskStatus } from "@/constants";

export default {
  name: "ReUploadDialog",
  components: { MainDialog },
  data() {
    return {
      uploadStates: {
        result: false,
        finalTemplate: false,
      },
      selectedFieldName: null,
      selectedFieldNameThumb: null,
      taskCopy: null,
      isReassigning: false,
      isSaving: false,
    };
  },
  props: {
    show: Boolean,
    persistent: Boolean,
    task: Object,
    onClose: Function,
    setResolved: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["userNameIdMap"]),
  },
  created() {
    this.taskCopy = _.cloneDeep(this.task);
  },
  methods: {
    ...mapActions(["sendResultsToCustomer"]),
    getImageDimensions(imageSrc) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
          const orientation =
              this.width > this.height ? "landscape" : "portrait";
          resolve(orientation);
        };
        img.onerror = reject;
        img.src = imageSrc;
      });
    },
    handleFileImport(fieldName, fieldNameThumb) {
      this.selectedFieldName = fieldName;
      this.selectedFieldNameThumb = fieldNameThumb;
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      const id = uuidv4();
      const fieldName = this.selectedFieldName;
      this.uploadStates[fieldName] = true;

      try {
        const storage = getStorage();
        const uploadedFileType = e.target.files[0].type.split("/")[1];
        const fileType = uploadedFileType === "jpeg" ? "jpg" : uploadedFileType;

        if (fieldName === "finalTemplate" && fileType !== "jpg") {
          throw new Error("Templates must have JPG format");
        }

        const storageRef = ref(
            storage,
            `/customTaskImages/${currentMonthYear}/${this.task.uid}_${id}.${fileType}`
        );
        const uploadResponse = await uploadBytes(storageRef, e.target.files[0]);

        this.taskCopy[fieldName] = await getDownloadURL(uploadResponse.ref);

        if (this.selectedFieldNameThumb) {
          this.taskCopy[this.selectedFieldNameThumb] = getImageUrl(
              `customTaskImages/${currentMonthYear}/thumbnails/${this.task.uid}_${id}_500x500.png`
          );
        }
      } catch (error) {
        EventBus.$emit(events.SNACKBAR, {
          message: error.message || "Upload failed",
          type: "error",
        });
      } finally {
        this.uploadStates[fieldName] = false;
        this.selectedFieldName = null;
        this.$refs.uploader.value = null;
      }
    },
    async uploadAndSendResults() {
      this.isSaving = true;
      await setDoc(
          doc(db, `tasks`, this.task.uid),
          {
            ...this.taskCopy,
            isRevision: "",
            feedback: null,
            status: taskStatus.CLOSED,
          },
          {
            merge: true,
          }
      );

      const orientation = await this.getImageDimensions(
          this.taskCopy.finalTemplate
      );

      await this.sendResultsToCustomer({
        taskUid: this.taskCopy.uid,
        template: this.taskCopy.finalTemplate,
        orientation: orientation,
      });
      this.isSaving = false;
      this.setResolved();
      this.onClose();
    },
  },
};
</script>

<style scoped></style>
