<template>
  <v-card class="exchangeRateCard">
    <v-card-title class="flex justify-space-between">
      Exchange Rates
      <v-btn
        v-if="!showAddCurrency"
        @click="showAddCurrency = true"
        color="success"
        >ADD CURRENCY
      </v-btn>
      <div class="d-flex flex flex-nowrap justify-end" v-else>
        <v-autocomplete dense hide-details class="mr-3" outlined style="max-width: 100px" :items="allCurrency" v-model="currencyToAdd" />
        <v-btn :disabled="isSaving" :loading="isSaving" @click="addCurrency" class="mr-3" color="success">Save</v-btn>
        <v-btn :disabled="isSaving" @click="showAddCurrency = false" outlined color="grey">Cancel</v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="exchangeRates"
        class="elevation-1"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import {db} from "../../../../fb";
import {doc, setDoc} from "firebase/firestore";

export default {
  name: "ExchangeRates",
  data() {
    return {
      showAddCurrency: false,
      isSaving: false,
      currencyToAdd: "",
      allCurrency: [
        "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "ARS", "AUD", "AWG", "AZN",
        "BAM", "BBD", "BDT", "BGN", "BHD", "BIF", "BMD", "BND", "BOB", "BRL",
        "BSD", "BTN", "BWP", "BYN", "BZD", "CAD", "CDF", "CHF", "CLP", "CNY",
        "COP", "CRC", "CUP", "CVE", "CZK", "DJF", "DKK", "DOP", "DZD", "EGP",
        "ERN", "ETB", "EUR", "FJD", "FKP", "FOK", "GBP", "GEL", "GGP", "GHS",
        "GIP", "GMD", "GNF", "GTQ", "GYD", "HKD", "HNL", "HRK", "HTG", "HUF",
        "IDR", "ILS", "IMP", "INR", "IQD", "IRR", "ISK", "JMD", "JOD", "JPY",
        "KES", "KGS", "KHR", "KID", "KMF", "KPW", "KRW", "KWD", "KYD", "KZT",
        "LAK", "LBP", "LKR", "LRD", "LSL", "LYD", "MAD", "MDL", "MGA", "MKD",
        "MMK", "MNT", "MOP", "MRO", "MRU", "MUR", "MVR", "MWK", "MXN", "MYR",
        "MZN", "NAD", "NGN", "NIO", "NOK", "NPR", "NZD", "OMR", "PAB", "PEN",
        "PGK", "PHP", "PKR", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF",
        "SAR", "SBD", "SCR", "SDG", "SEK", "SGD", "SHP", "SLE", "SLL", "SOS",
        "SRD", "SSP", "STN", "SVC", "SYP", "SZL", "THB", "TJS", "TMT", "TND",
        "TOP", "TRY", "TTD", "TVD", "TWD", "TZS", "UAH", "UGX", "USD", "UYU",
        "UZS", "VES", "VND", "VUV", "WST", "XAF", "XCD", "XDR", "XOF", "XPF",
        "YER", "ZAR", "ZMW", "ZWL"
      ],
      headers: [
        { text: "Target Currency", value: "target" },
        { text: "Base", value: "base" },
        { text: "Rate", value: "rate" },
      ],
    };
  },
  props: {
    settings: {
      required: true,
      type: Object,
    },
  },
  computed: {
    exchangeRates() {
      return Object.keys(this.settings.exchangeRates).map((key) => {
        return {
          base: "EUR",
          target: key.toUpperCase(),
          rate: this.settings.exchangeRates[key],
        };
      });
    },
  },
  methods: {
    async addCurrency() {
      this.isSaving = true
      const d = doc(db, 'settings', 'statistics')
      await setDoc(d, {
        exchangeRates: {
          [this.currencyToAdd.toLowerCase()]: "Will be fetched at 00:00"
        }
      }, { merge: true })

      this.currencyToAdd = ""
      this.showAddCurrency = false
      this.isSaving = false
    }
  }
};
</script>

<style scoped lang="scss"></style>
