<template>
  <div>
    <v-data-table
      :loading="usersLoading"
      :headers="headers"
      :items="users"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer/>
          <v-btn @click="showAddUserDialog = true" dark>Add new user</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.permissions`]="{ item }">
        <v-chip
          class="ma-1"
          dark
          x-small
          v-for="permission in item.permissions"
          :key="permission"
        >
          {{ permission }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom right>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openEditUserDialog(item)">
              <v-list-item-title>Edit User</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.payment" @click="openPaymentDialog(item)">
              <v-list-item-title>Make Payout</v-list-item-title>
            </v-list-item>
            <v-list-item @click="resetPassword(item.email)">
              <v-list-item-title>Reset Password</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <AddNewUserDialog
      :show="showAddUserDialog"
      :closeDialog="
        () => {
          showAddUserDialog = false;
        }
      "
    />
    <EditUserDialog
      :user="userToEdit"
      :close-dialog="
        () => {
          showEditUserDialog = false;
          userToEdit = null;
        }
      "
      :show="showEditUserDialog"
    />
    <PayUserDialog
      v-if="userToPay"
      :user="userToPay"
      :on-close="
        () => {
          userToPay = null;
        }
      "
    />
  </div>
</template>

<script>
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {showSnackbar} from "@/utils";
import AddNewUserDialog from "@/components/Settings/Users/AddNewUserDialog";
import PayUserDialog from "@/components/Settings/Users/PayUserDialog";
import {mapGetters} from "vuex";
import EditUserDialog from "@/components/Settings/Users/EditUserDialog";

export default {
  name: "AdminUsers",
  components: {PayUserDialog, AddNewUserDialog, EditUserDialog},
  data() {
    return {
      userToPay: null,
      userToEdit: null,
      showAddUserDialog: false,
      showEditUserDialog: false,
      headers: [
        {
          text: "User Name",
          align: "start",
          value: "username",
        },
        {text: "Unpaid Tasks", value: "unpaidTasks"},
        {text: "Total Paid", value: "tasksPaid"},
        {text: "Email", value: "email"},
        {text: "Permissions", value: "permissions"},
        {text: "Actions", value: "actions"},
      ],
    };
  },
  computed: {
    ...mapGetters(["allUsers", "usersLoading"]),
    users() {
      return this.allUsers.map((user) => {
        let tasksPaid = "-";
        if (user.payment && user.payment.transactionHistory) {
          tasksPaid = 0;
          user.payment.transactionHistory.forEach((transaction) => {
            tasksPaid += transaction?.totalPayed;
          });
        }
        return {
          tasksPaid:
            tasksPaid !== "-"
              ? tasksPaid + (user?.payment?.currency || "?")
              : "-",
          unpaidTasks: user.payment?.unpaidTasks?.length || "-",
          ...user,
        };
      });
    },
  },
  methods: {
    resetPassword(userEmail) {
      const auth = getAuth();
      sendPasswordResetEmail(auth, userEmail)
        .then(() => {
          showSnackbar({
            message: "New password send!",
            type: "success",
          });
        })
        .catch((error) => {
          showSnackbar({
            message: error.message,
            type: "success",
          });
        });
    },
    openPaymentDialog(user) {
      this.userToPay = user;
    },
    openEditUserDialog(user) {
      this.userToEdit = {...user};
      this.showEditUserDialog = true;
    },
  },
};
</script>

<style scoped></style>
