<template>
  <v-card>
    <v-card-title>
      <a class="mr-3">{{ rating.orderName }}</a>
      <v-icon :color="rating.satisfied ? 'success' : 'error'">{{
          rating.satisfied ? "mdi-thumb-up" : "mdi-thumb-down"
        }}
      </v-icon>
    </v-card-title>
    <v-card-subtitle> feedback received {{ timeAgo }}</v-card-subtitle>
    <v-card-text class="card-content">
      <v-img
        contain
        height="300px"
        v-if="!expandComment"
        :src="rating.resultThumbnailUrl"
      />
      <div
        @click="
          () => {
            expandComment = !expandComment;
          }
        "
        :class="{ 'rating-comment--full': expandComment }"
        class="rating-comment"
      >
        {{ rating.comment }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {getDateFromNow} from "@/utils";

export default {
  name: "RatingCardArtist",
  data() {
    return {
      expandComment: false,
    };
  },
  props: {
    rating: {
      required: true,
      type: Object,
    },
  },
  computed: {
    timeAgo() {
      return getDateFromNow(this.rating.timestamp);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-content {
  height: 380px;
}

.rating-comment {
  cursor: pointer;
  font-size: 21px;
  line-height: 1.2em; /* Adjust this based on your design needs */
  max-height: 2.4em; /* 2 times the line-height, to display 2 lines of text */
  min-height: 2.4em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  &--full {
    max-height: 100%;
    overflow: scroll;
    display: -webkit-box;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    text-overflow: unset;
  }
}
</style>
