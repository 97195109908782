import { render, staticRenderFns } from "./FallbackMockups.vue?vue&type=template&id=3dcb4aee&scoped=true"
import script from "./FallbackMockups.vue?vue&type=script&lang=js"
export * from "./FallbackMockups.vue?vue&type=script&lang=js"
import style0 from "./FallbackMockups.vue?vue&type=style&index=0&id=3dcb4aee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dcb4aee",
  null
  
)

export default component.exports