<template>
  <v-card class="shop-statistic-card">
    <v-card-title class="shop-domain">{{ shopStatistic.domain }}</v-card-title>
    <v-card-text class="card-container">
      <v-row>
        <v-col cols="12" md="6">
          <ValueCard :on-click="setShowAll" :success="totalProfit > 0" :error="totalProfit <= 0" :value="totalProfitString" title="Gewinn"/>
          <div v-if="showAll" class="refund-container">
            <ValueCard title="Refunds" :value="shopStatistic?.refunds?.totalNumber || 0"></ValueCard>
            <ValueCard title="Refund" :value="(-shopStatistic?.refunds?.revenue || 0) + ' €'"></ValueCard>
          </div>
          <OverviewCard v-if="showAll" class="mt-3" light title="Details" :switch-data="costsChartData"
          />
        </v-col>
        <v-col v-if="showAll" cols="12" md="6"
          >
          <v-card light class="ads-insights mt-3 mb-3">
            <v-card-title>Werbekosten</v-card-title>
            <v-card-text>
              <BarChart :aspect-ratio="1.5" :data="adSpendData" />
              <v-row>
                <v-col v-for="roas in allROAS" :key="roas.label">
                  <div
                    @click="selectedInsightsTab = roas.label"
                    class="ads-insights__roas"
                    :class="{
                      'ads-insights__roas--selected':
                        roas.label === selectedInsightsTab,
                    }"
                    :style="{ backgroundColor: getRoasColor(roas.value) }"
                  >
                    <div class="channel">{{ roas.label }}</div>
                    <div class="roas">{{ roas.value }}</div>
                  </div>
                </v-col>
              </v-row>
              <v-data-table
                mobile-breakpoint="0"
                class="mt-6 insight-table"
                hide-default-footer
                :headers="addInsightsHeader"
                :items="getAddInsight()"
              >
                <template v-slot:body="{ items, headers }">
                  <tbody>
                  <tr v-for="item in items" :key="item.type">
                    <td v-for="header in headers" :key="header.value" :class="{'insight-table--highlight': selectedInsightsTab === header.value}">
                      {{ item[header.value] }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { getDecimalNumberString, getRandomColor, getRateColor } from "@/utils";
import OverviewCard from "@/components/Statistics/StatisticOverview/OverviewCard";
import { statisticsColors } from "@/config";
import ValueCard from "@/components/Statistics/StatisticOverview/components/ValueCard";
import BarChart from "@/components/BarChart";

const _ = require("lodash");

export default {
  name: "ShopStatisticCard",
  components: {BarChart, ValueCard, OverviewCard },
  props: {
    shopStatistic: Object,
  },
  data() {
    return {
      showAll: !this.$vuetify.breakpoint.mobile,
      selectedInsightsTab: "facebook",
      addInsightsHeader: [
        { text: "Type", value: "type" },
        { text: "Facebook", value: "facebook" },
        { text: "Google", value: "google" },
        { text: "TikTok", value: "tiktok" },
      ],
    };
  },
  computed: {
    totalProfit() {
      const costComponents = Object.keys(this.shopStatistic.costs);

      // Calculate total cost components dynamically
      const totalCosts = costComponents.reduce((total, component) => {
        return total + this.shopStatistic.costs[component];
      }, 0);

      return Number((this.shopStatistic.revenue.total - totalCosts).toFixed(2));
    },
    allROAS() {
      return Object.keys(
        this.shopStatistic?.advertisement?.byChannel || []
      ).map((adKey) => {
        let revenue = this.shopStatistic.revenue?.bySource?.[adKey] || 0;
        const spend =
          this.shopStatistic?.advertisement?.byChannel?.[adKey]?.spend || 0;

        if (adKey === "facebook") {
          revenue += this.shopStatistic.revenue?.bySource?.instagram || 0;
        }

        return {
          label: adKey,
          value: getDecimalNumberString(revenue / spend),
        };
      }).sort((a, b) => a.label.localeCompare(b.label));
    },
    costsChartData() {
      const costs = Object.keys(this.shopStatistic.costs).map((costId) => {
        return {
          label: costId,
          count: this.shopStatistic.costs[costId],
          color: statisticsColors[costId],
        };
      });

      const allCoupons = []
      const afterPrev = {
        label: "After Preview",
        count: 0,
        amount: 0,
        color: getRandomColor('AfterPreview')
      }
      const freePrev = {
        label: "Free Preview",
        count: 0,
        amount: 0,
        color: getRandomColor('FreePreview')
      }

      const loox = {
        label: "Loox",
        count: 0,
        amount: 0,
        color: getRandomColor('Loox')
      }

      const coupons = Object.keys(this.shopStatistic.details.coupons).map(
        (key) => {
          return {
            label: `${this.shopStatistic.details.coupons[key].count} x ${key}`,
            count: -this.shopStatistic.details.coupons[key].amount,
            color: getRandomColor(key),
          };
        }
      );

      coupons.forEach(coupon => {
        if (coupon.label.split(" ")[0] === "1") {
          if (coupon.label.split(" ")[2].slice(0, 4) === "PREV") {
            afterPrev.amount += coupon.count
            afterPrev.count++
          } else if(coupon.count === -6.99) {
            freePrev.amount += coupon.count
            freePrev.count++
          } else if(coupon.label.split(" ")[2].slice(0,3) === "LX-") {
            loox.amount += coupon.count
            loox.count++
          } else {
            if(coupon.label.includes('FULL100') || coupon.label.includes('FULL100')) {
              allCoupons.push(coupon)
            }
          }
        } else {
          if(!coupon.label.includes('FULL100') && !coupon.label.includes('FULL100')) {
            allCoupons.push(coupon)
          }
        }
      })

      if (freePrev.count) {
        allCoupons.push({
          ...freePrev,
          label: `${freePrev.count} x ${freePrev.label}`,
          count: freePrev.amount
        })
      }

      if (loox.count) {
        allCoupons.push({
          ...loox,
          label: `${loox.count} x ${loox.label}`,
          count: loox.amount
        })
      }

      if (afterPrev.count) {
        allCoupons.push({
          ...afterPrev,
          label: `${afterPrev.count} x ${afterPrev.label}`,
          count: afterPrev.amount
        })
      }

      const printVariants = Object.keys(
        this.shopStatistic.details.printVariants
      ).map((key) => {
        return {
          label: this.shopStatistic.details.printVariants[key].label,
          count: this.shopStatistic.details.printVariants[key].count,
          color: getRandomColor(key),
        };
      });

      return [
        {
          label: "Kosten",
          data: costs,
          unit: "€",
        },
        {
          label: "Coupons",
          data: allCoupons,
          unit: "€",
        },
        {
          label: "Varianten",
          data: printVariants,
          unit: "",
        },
      ];
    },
    totalProfitString() {
      return getDecimalNumberString(this.totalProfit, 2) + "€";
    },
    adSpendData() {
      const advertisementKeys = Object.keys(
        this.shopStatistic.advertisement.byChannel
      ).filter(key => key !== 'refunds');
      const revenueKeys = Object.keys(this.shopStatistic.revenue.bySource);

      const channels = _.union(advertisementKeys, revenueKeys);

      const labels = channels.map((label) => {
        if (label === "facebook") {
          return "FB & IG";
        } else {
          return label;
        }
      });
      const chartData = {
        labels: labels,
        datasets: [
          {
            label: "Ad Spend",
            backgroundColor: "#d70000",
            data: channels.map(
              (key) =>
                this.shopStatistic.advertisement.byChannel[key]?.spend || 0
            ), // Ad spend for each channel
          },
          {
            label: "Generated Revenue",
            backgroundColor: "#43a653",
            data: channels.map((key) => {
              if (key === "facebook") {
                return (
                  (this.shopStatistic.revenue.bySource?.facebook || 0) +
                  (this.shopStatistic.revenue.bySource?.instagram || 0)
                );
              } else {
                return this.shopStatistic.revenue.bySource?.[key] || 0;
              }
            }), // Ad revenue for each channel
          },
        ],
      };

      return chartData;
    },
  },
  methods: {
    setShowAll() {
      this.showAll = !this.$vuetify.breakpoint.mobile ? true : !this.showAll
    },
    getRoasColor(roas) {
      return getRateColor(3.5, 0, roas);
    },
    getAddInsight() {
      let rows = [];

      Object.keys(this.shopStatistic.advertisement.byChannel).forEach((channel) => {
        Object.keys(this.shopStatistic.advertisement.byChannel[channel])
          .filter((key) => key !== "timelineSpend")
          .sort((a, b) => a.localeCompare(b))
          .forEach((key) => {
            const val = this.shopStatistic.advertisement.byChannel?.[channel]?.[key] || 0;
            let row = rows.find(r => r.type === key);

            if (row) {
              row[channel] = row[channel] !== '-' ? row[channel] + Number(val) : Number(val);
            } else {
              // Initialize row with all channels set to '-'
              row = { type: key };
              Object.keys(this.shopStatistic.advertisement.byChannel).forEach(ch => {
                row[ch] = '-';
              });
              row[channel] = val !== 0 ? Number(val.toFixed(2)) : '-';
              rows.push(row);
            }
          });
      });

      // Ensure all rows have their values properly formatted
      rows = rows.map(row => {
        Object.keys(row).forEach(channel => {
          if (channel !== 'type' && row[channel] === 0) {
            row[channel] = '-';
          }
        });
        return row;
      });

      // Ensure all rows have their values properly formatted
      rows = rows.map(row => {
        Object.keys(row).forEach(channel => {
          if (channel !== 'type') {
            if (row[channel] === 0 || row[channel] === '-') {
              row[channel] = '-';
            } else {
              row[channel] = Number(row[channel].toFixed(2));
            }
          }
        });
        return row;
      });

      return rows;
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-statistic-card {
  box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px, rgba(0, 0, 0, 0.07) 0 2px 4px, rgba(0, 0, 0, 0.07) 0 4px 8px, rgba(0, 0, 0, 0.07) 0 8px 16px, rgba(0, 0, 0, 0.07) 0 16px 32px, rgba(0, 0, 0, 0.07) 0 32px 64px !important;

  @include md() {
    box-shadow: rgba(14, 63, 126, 0.04) 0 0 0 1px, rgba(42, 51, 69, 0.04) 0 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0 6px 6px -3px, rgba(14, 63, 126, 0.04) 0 12px 12px -6px, rgba(14, 63, 126, 0.04) 0 24px 24px -12px !important;
  }

  .refund-container {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin: 12px 0;
  }

  .shop-domain {
    display: flex;
    justify-content: center;
    font-weight: bolder;
    font-size: 24px;
    width: 100%;
  }

  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ads-insights {
    width: 100%;

    &__roas {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: #eaeaea;
      padding: 6px;
      box-shadow: inset 0 0 35px 5px rgba(0,0,0,0), inset 0 -2px 1px rgba(0,0,0,0) !important;
      cursor: pointer;

      .channel {
        font-weight: lighter;
        font-size: 12px;
        line-height: 12px;
      }

      .roas {
        margin-top: 3px;
        line-height: 14px;
        font-size: 14px;
        font-weight: bolder;
      }

      &--selected {
        transform: scale(1.1);
        box-shadow: inset 0 0 35px 5px rgba(129, 115, 115, 0.25), inset 0 -2px 1px rgba(0,0,0,0.25) !important;
      }
    }
  }

  .profit-counter {
    font-size: 28px;
    text-align: center;
    color: #43a653;
    font-weight: bolder;
    padding: 16px 16px;
    width: 100%;

    &--negative {
      color: #d70000;
    }
  }
}

.insight-table {
  &--highlight {
    background-color: #e0e0e0;
  }
}
</style>
