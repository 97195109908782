<template>
  <div v-if="feedback" :style="{backgroundColor: isSatisfied ? '#bafc56': '#f52929'}" class="feedback-tooltip-container">
    <v-tooltip
      v-if="feedbackComment"
      max-width="300px"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on"> {{ icon }}</v-icon>
      </template>
      <div class="feedback-timestamp">{{ timeAgo }}</div>
      <span>{{ feedbackComment }}</span>
    </v-tooltip>
    <v-icon v-else> {{ icon }}</v-icon>
  </div>
</template>

<script>
import {getDateFromNow} from "@/utils";

export default {
  name: "FeedbackTooltip",
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    timeAgo() {
      return getDateFromNow(this.feedback.timestamp)
    },
    feedbackComment() {
      return this.feedback?.comment;
    },
    isSatisfied() {
      return this.feedback?.satisfied;
    },
    icon() {
      if (this.isSatisfied) {
        return `mdi-thumb-up${!this.feedbackComment ? '-outline' : ''}`
      } else {
        return `mdi-thumb-down${!this.feedbackComment ? '-outline' : ''}`
      }
    }
  },
  props: {
    feedback: {
      required: true,
    },
    onSave: {
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-tooltip-container {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
}

.feedback-timestamp {
  text-align: start;
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: lighter;
}
</style>
