import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../../../fb";
import { dbCollection, permissions } from "@/constants";

const defaultState = {
  users: [],
  loading: true,
  errorMessage: "",
  userUidMap: {},
};

const getters = {
  allUsers: (state) => state.users,
  usersLoading: (state) => state.loading,
  userNameIdMap: (state) =>
    state.users.map((user) => {
      return {
        username: user.username,
        uid: user.uid,
      };
    }),
  userUidMap: (state) => state.userUidMap,
};

const actions = {
  async loadAllUsers({ commit, getters, state }) {
    try {
      state.loading = true;
      const readAllUsers =
        getters.userPermissions.includes(permissions.ADMIN) ||
        getters.userPermissions.includes(permissions.READ_ALL_USERS);
      commit("setAllUsers", []);
      const usersCollection = collection(db, dbCollection.USERS);
      const q = readAllUsers
        ? undefined
        : query(usersCollection, where("manager", "==", auth.currentUser.uid));
      const docs = readAllUsers ? usersCollection : q;
      const usersRef = await getDocs(docs);
      usersRef.forEach((doc) => {
        commit("addUser", { ...doc.data(), uid: doc.id });
      });
      state.loading = false;
    } catch (e) {
      console.log("ERRROR", e);
    }
  },
};

const mutations = {
  setAllUsers: (state, users) => (state.users = users),
  addUser: (state, user) => {
    state.userUidMap = {
      ...state.userUidMap,
      [user.uid]: user.username,
    };
    state.users.push(user);
  },
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).users
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
