<template>
  <v-card>
    <v-card-title class="flex justify-space-between">
      Sku Mappings
      <v-text-field
        class="search-field"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search for SKU"
        single-line
        hide-details
        outlined
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :search="search"
        :loading="loadingSkuMappings"
        :headers="headers"
        :items="skuMappings"
        class="mappingList elevation-1"
      >
        <template v-slot:[`item.sku`]="{ item }">
          <div v-if="item.sku">{{ item.sku }}</div>
          <v-text-field outlined v-model="newSku" dense hide-details v-else />
        </template>
        <template v-slot:[`item.productTypeId`]="{ item }">
          <div v-if="item.productTypeId">
            {{ productTypeNames[item.productTypeId] }}
          </div>
          <v-autocomplete
            outlined
            :items="productTypes"
            item-text="name"
            item-value="key"
            v-model="newProductTypeId"
            dense
            hide-details
            v-else
          />
        </template>
        <template v-slot:[`item.printVariantId`]="{ item }">
          <div v-if="item.printVariantId">
            {{ printVariantNames[item.printVariantId] }}
          </div>
          <v-autocomplete
            outlined
            :items="printVariants"
            item-text="name"
            item-value="key"
            v-model="newPrintVariantId"
            dense
            hide-details
            v-else-if="!item.sku"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="mappingList__actions">
            <v-icon
              color="error"
              v-if="item.sku"
              small
              @click="() => confirmDelete(item)"
              >mdi-delete</v-icon
            >
            <v-btn
              v-else
              :disabled="!isSavable"
              color="success"
              @click="() => saveSkuMapping(item)"
              >Add Sku</v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions> </v-card-actions>
    <ConfirmDialog
      :show="showDeleteDialog"
      :on-cancel="
        () => {
          showDeleteDialog = false;
        }
      "
      :on-accept="deleteMapping"
      icon="mdi-trash-can"
      title="Delete Sku Mapping"
      message="You are about to delete the Sku Mapping. You can't revert the deletion! Are you sure to do so?"
      accept-text="DELETE"
      accept-color="error"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "SkuMappings",
  components: { ConfirmDialog },
  data() {
    return {
      productTypeToEdit: null,
      headers: [
        { text: "SKU", value: "sku" },
        { text: "Product Type", value: "productTypeId" },
        { text: "Print Variant", value: "printVariantId" },
        { text: "", value: "actions" },
      ],
      newSku: "",
      newProductTypeId: "",
      newPrintVariantId: "",
      showDeleteDialog: false,
      itemToDelete: null,
      search: "",
    };
  },
  computed: {
    ...mapGetters([
      "skuMappings",
      "loadingSkuMappings",
      "productTypeNames",
      "printVariantNames",
      "productTypes",
      "printVariants",
    ]),
    isSavable() {
      return this.newSku && this.newProductTypeId;
    },
  },
  mounted() {
    this.loadSkuMappings();
  },
  methods: {
    ...mapActions(["loadSkuMappings", "addSkuMapping", "deleteSkuMapping"]),
    async saveSkuMapping() {
      if (this.isSavable)
        await this.addSkuMapping({
          sku: this.newSku,
          productTypeId: this.newProductTypeId,
          printVariantId: this.newPrintVariantId,
        });
      this.resetFields();
    },
    confirmDelete(item) {
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },
    deleteMapping() {
      this.showDeleteDialog = false;
      this.resetFields();
      this.deleteSkuMapping(this.itemToDelete);
    },
    resetFields() {
      this.newSku = "";
      this.newProductTypeId = "";
      this.newPrintVariantId = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.mappingList {
  &__actions {
    display: flex;
    justify-content: center;
  }
}

.search-field {
  max-width: 400px;
}
</style>
