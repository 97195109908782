<template>
  <v-card>
    <v-card-title class="flex justify-space-between">
      Gateway Charges
      <v-btn v-if="!showAddGateway" @click="showAddGateway = true" color="success">
        ADD Gateway
      </v-btn>
      <div class="d-flex flex flex-nowrap justify-end" v-else>
        <v-text-field dense hide-details class="mr-3" outlined style="max-width: 100px" v-model="gateWayToAdd" />
        <v-btn :disabled="isAddingGateWay" :loading="isAddingGateWay" @click="addGateWay" class="mr-3" color="success">Save</v-btn>
        <v-btn :disabled="isAddingGateWay" @click="showAddGateway = false" outlined color="grey">Cancel</v-btn>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6" v-for="gateway in gateways" :key="gateway.id">
          <v-card>
            <v-card-title class="flex justify-space-between">
              {{ gateway.id }}
              <v-icon v-if="!isEditing(gateway)" @click="editGateway(gateway)">mdi-pencil</v-icon>
            </v-card-title>
            <v-card-text>
              <v-data-table
                hide-default-footer
                :headers="isEditing(gateway) ? headersEdit : headers"
                :items="isEditing(gateway) ? selectedGateway.charges : gateway.charges"
                class="elevation-1"
              >
                <template v-slot:item.currency="{ item }">
                  <v-text-field v-model="item.currency" v-if="isEditing(gateway)" />
                  <div v-else>{{ item.currency }}</div>
                </template>
                <template v-slot:item.fixed="{ item }">
                  <v-text-field v-model.number="item.fixed" v-if="isEditing(gateway)" />
                  <div v-else>{{ item.fixed }}</div>
                </template>
                <template v-slot:item.rate="{ item }">
                  <v-text-field v-model.number="item.rate" v-if="isEditing(gateway)" />
                  <div v-else>{{ item.rate }}</div>
                </template>
                <template v-if="isEditing(gateway)" v-slot:item.actions="{ item }">
                  <v-btn @click="deleteCurrencyCharges(item.currency)" small color="error">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-if="availableCurrencies.length > 0" v-slot:body.append>
                  <tr v-if="isEditing(gateway)">
                    <td>
                      <v-autocomplete :items="availableCurrencies" v-model="newCurrency.currency" label="Currency" />
                    </td>
                    <td>
                      <v-text-field v-model.number="newCurrency.fixed" label="Fixed" />
                    </td>
                    <td>
                      <v-text-field v-model.number="newCurrency.rate" label="Rate" />
                    </td>
                    <td>
                      <v-btn @click="addCurrencyCharges" small color="success">Add</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions v-if="isEditing(gateway)">
              <v-btn :disabled="isSaving" color="grey" @click="cancelEdit" small outlined>Cancel</v-btn>
              <v-spacer/>
              <v-btn :loading="isSaving" :disabled="isSaving" @click="saveGateway" small color="success">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>
import { db } from "../../../../fb";
import { doc, setDoc } from "firebase/firestore";
import _ from "lodash";

export default {
  name: "GatewayCharges",
  props: {
    settings: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showAddGateway: false,
      isSaving: false,
      selectedGateway: null,
      gateWayToAdd: "",
      isAddingGateWay: false,
      newCurrency: this.getInitialCurrencyState(),
      headers: [
        { text: "Currency", value: "currency" },
        { text: "Fixed", value: "fixed" },
        { text: "Rate", value: "rate" },
      ],
      headersEdit: [
        { text: "Currency", value: "currency" },
        { text: "Fixed", value: "fixed" },
        { text: "Rate", value: "rate" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    gateways() {
      return Object.entries(this.settings.gatewayCharges).map(([id, charges]) => ({
        id,
        charges: Object.entries(charges).map(([currency, details]) => ({
          currency,
          ...details
        }))
      })).sort((a, b) => a.id.localeCompare(b.id));
    },
    availableCurrencies() {
      if (!this.selectedGateway) return [];
      const usedCurrencies = this.selectedGateway.charges.map(c => c.currency);
      return Object.keys(this.settings.exchangeRates).filter(currency => !usedCurrencies.includes(currency));
    }
  },
  methods: {
    getInitialCurrencyState() {
      return {
        currency: "",
        fixed: 0,
        rate: 0,
      };
    },
    isEditing(gateway) {
      return this.selectedGateway && this.selectedGateway.id === gateway.id;
    },
    editGateway(gateway) {
      this.selectedGateway = _.cloneDeep(gateway); // Create a shallow copy for editing
    },
    cancelEdit() {
      this.selectedGateway = null;
      this.newCurrency = this.getInitialCurrencyState();
    },
    addCurrencyCharges() {
      this.selectedGateway = {
        ...this.selectedGateway,
        charges: [
          ...this.selectedGateway.charges,
          this.newCurrency
        ]
      }
      this.newCurrency = this.getInitialCurrencyState();
    },
    deleteCurrencyCharges(currency) {
      this.selectedGateway = {
        ...this.selectedGateway,
        charges: [
          ...this.selectedGateway.charges.filter(charge => charge.currency !== currency)
        ]
      }
    },
    async addGateWay() {
      this.isAddingGateWay = true
      this.selectedGateway = {
        id: this.gateWayToAdd,
        charges: []
      }

      await this.saveGateway()
      this.isAddingGateWay = false
      this.showAddGateway = false
    },
    async saveGateway() {
      if (!this.selectedGateway) return;

      this.isSaving = true;
      const docRef = doc(db, 'settings', 'statistics');

      const transformedCharges = this.selectedGateway.charges.reduce((acc, { currency, fixed, rate }) => {
        acc[currency] = { fixed, rate };
        return acc;
      }, {});

      // Replace the entire gatewayCharges object for the specific selectedGateway.id
      const updatedGatewayCharges = {
        ...this.settings.gatewayCharges,
        [this.selectedGateway.id]: transformedCharges
      };

      await setDoc(docRef, {
        ...this.settings,
        gatewayCharges: updatedGatewayCharges
      });

      this.cancelEdit();
      this.isSaving = false;
    }
  }
};
</script>


<style scoped></style>
