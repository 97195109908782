<template>
  <div class="all-tasks-container">
    <v-row dense>
      <v-col cols="12" md="6">
        <v-text-field
          placeholder="Type at least 2 Characters"
          v-model="searchTerm"
          append-icon="mdi-magnify"
          @click:append="search"
          @keyup.enter="search"
          prepend-icon="mdi-pound"
          label="Order Name"
          type="text"
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!loading && !noResults">
      <v-col
        v-for="task in allTasks.tasks"
        :key="task.uid"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <TaskDetailsCard :task="task" />
      </v-col>
    </v-row>
    <StateDisplay :loading="loading" :no-results="noResults" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import TaskDetailsCard from "@/components/TaskCards/TaskDetailsCard";
import StateDisplay from "@/components/StateDisplay";

export default {
  name: "AllTasks",
  components: { StateDisplay, TaskDetailsCard },
  data() {
    return {
      searchTerm: "",
    };
  },
  created() {
    this.setTasks({
      tasks: [],
      status: "allTasks",
    });
  },
  computed: {
    ...mapGetters(["allTasks"]),
    loading() {
      return this.allTasks.loading;
    },
    noResults() {
      return !this.allTasks.tasks.length;
    },
  },
  methods: {
    ...mapActions(["loadTasksBySearchTerm"]),
    ...mapMutations(["setTasks"]),
    search() {
      this.loadTasksBySearchTerm({ searchTerm: `#${this.searchTerm}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.all-tasks-container {
  min-height: 70vh;
}
</style>
