<template>
  <MainDialog :persistent="false" :show="show" :on-close="onClose">
    <v-card>
      <v-card-title>Add Manual Fulfillment {{orderId}}</v-card-title>
      <v-card-text>
        <div v-if="errorMessage">ERROR: {{ errorMessage }}</div>
        <v-text-field v-model="trackingId" label="Tracking ID" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="isAddingFulfillment" :loading="isAddingFulfillment" @click="addManualFulfillment">Add Fulfillment</v-btn>
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import {db} from "../../../../fb";
import {collection, doc, getDoc, setDoc} from "firebase/firestore";
import {orderStatus, taskStatus} from "@/constants";
export default {
  name: "ManualFulfillDialog",
  components: { MainDialog },
  data() {
    return {
      trackingId: "",
      isAddingFulfillment: false,
      error: false,
      errorMessage: "",
    }
  },
  props: {
    show: {
      type: Boolean,
      require: true,
    },
    onClose: {
      required: false,
    },
    orderId: {
      type: String,
      required: true
    }
  },
  methods: {
    async addManualFulfillment() {
      const fulfillment = {
        manual: [{
          creationDate: (new Date()).toISOString(),
          fulfillItems: [],
          fulfillerId: null,
          success: true,
        }]
      }
      this.isAddingFulfillment = true
      const ordersCollectionRef = collection(db, "orders");
      const orderDoc = doc(ordersCollectionRef, this.orderId)
      const orderRes = await getDoc(orderDoc)
      const order = orderRes.data()

      for await (const orderTask of order.tasks) {
        const tasksCollectionRef = collection(db, "tasks")
        const taskDoc = doc(tasksCollectionRef, orderTask.taskUid)
        const taskRes = await getDoc(taskDoc)
        const taskData = taskRes.data()

        fulfillment.manual[0].fulfillItems.push({
          file: taskData.finalTemplate || null,
          variant: taskData.printVariantName,
          quantity: orderTask.quantity,
          taskId: orderTask.taskUid,
          shipment: {
            trackingCode: this.trackingId,
            shippingDate: (new Date()).toISOString()
          }
        })

        await setDoc(taskDoc, {
          status: taskStatus.FULFILLED
        }, { merge: true })
      }

      await setDoc(orderDoc, {
        status: orderStatus.CLOSED,
        fulfillment: {
          ...order.fulfillment,
          ...fulfillment
        }
      }, { merge: true })
      this.isAddingFulfillment = false
      this.onClose()
    }
  }
};
</script>

<style scoped></style>
