<template>
  <v-card :light="light" :dark="dark" class="overview-card">
    <v-card-title>
      <div class="card-header flex justify-space-between">
        {{ title }}
        <div class="d-flex">
          <v-btn
            :disabled="switchItem.data.length === 0"
            class="overview-card__switch-button mr-3"
            x-small
            :text="index !== selectedSwitchItemIndex"
            :key="index"
            :dark="index === selectedSwitchItemIndex"
            v-for="(switchItem, index) in switchData"
            @click="selectedSwitchItemIndex = index"
          >
            {{ switchItem.label }}
          </v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-subtitle v-if="subTitle">
      {{subTitle}}
    </v-card-subtitle>
    <v-card-text>
      <DonutChart v-if="chartType === 'doughnut'" :key="selectedSwitchItemIndex" :data="chartData" :unit="chartUnit" />
      <BarChart v-else :key="selectedSwitchItemIndex" chart-height="300px" :data="chartData" :unit="chartUnit" :aspect-ratio="0.6" :hide-legend="true"/>
    </v-card-text>
  </v-card>
</template>

<script>
import DonutChart from "@/components/DonutChart";
import BarChart from "@/components/BarChart";

export default {
  name: "OverviewCard",
  components: {BarChart, DonutChart },
  data() {
    return {
      selectedSwitchItemIndex: 0,
    };
  },
  props: {
    title: String,
    subTitle: String,
    data: Array,
    switchData: Array,
    unit: String,
    difference: Object,
    light: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "doughnut"
    }
  },
  computed: {
    chartData() {
      return this.data || this.switchData[this.selectedSwitchItemIndex].data
    },
    chartUnit() {
      if (this.switchData) {
        return this.switchData[this.selectedSwitchItemIndex].unit || this.unit
      } else {
        return this.unit
      }
    },
    chartType() {
      if (this.switchData) {
        return this.switchData[this.selectedSwitchItemIndex].chartType || this.type
      } else {
        return this.type
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.overview-card {
  width: 100%;

  &__switch-button {
    margin-right: 18px;

    @include md() {
      margin-right: 6px !important;
      padding: 0 3.888889px !important;
      font-size: 10px !important;
    }
  }

  .card-header {
    height: 52px;
  }
}
</style>
