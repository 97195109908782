<template>
  <div>
    <TotalCosts :data="data" />
    <FulfilledOrderList class="fulfilled-orders-list" :data="data" />
  </div>
</template>

<script>
import TotalCosts from "@/components/Settings/Statistics/ActualCosts/ProductionCosts/TotalCosts.vue";
import FulfilledOrderList from "@/components/Settings/Statistics/ActualCosts/ProductionCosts/FulfilledOrderList.vue";

export default {
  name: "ProductionCosts",
  components: {FulfilledOrderList, TotalCosts},
  props: {
    data: {
      type: Object
    }
  }
}
</script>

<style scoped lang="scss">
.fulfilled-orders-list {
  margin-top: 12px;
}
</style>