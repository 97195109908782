import {
  collection,
  query,
  where,
  limit,
  orderBy,
  onSnapshot,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../fb";
import { checkIsValidAddress } from "@/store/utils/ordersUtils";
import { dbCollection, orderFilterCategories, orderStatus } from "@/constants";
import { appSettings } from "@/config";

const defaultState = {
  loadingOrders: false,
  orders: [],
  ordersToFulfill: [],
  ordersInFulfill: [],
  ordersWithError: [],
  filter: {
    orderId: "",
    email: "",
    quickSearch: "",
    filterCategory: orderFilterCategories.ALL,
  },
  unsubOrdersToFulfill: () => {},
  unsubOrdersInFulfill: () => {},
  unsubOrdersWithError: () => {},
};

const getters = {
  ordersToFulfill: (state) => state.ordersToFulfill,
  ordersInFulfill: (state) => state.ordersInFulfill,
  ordersWithError: (state) => state.ordersWithError,
  loadingOrders: (state) => state.loadingOrders,
  ordersPerPage: (state) => state.ordersPerPage,
  currentOrdersPage: (state) => state.currentOrdersPage,
  ordersSortBy: (state) => state.filter.sortBy,
  ordersSortDesc: (state) => state.filter.sortDesc,
  filterOrderId: (state) => state.filter.orderId,
  filterEmail: (state) => state.filter.email,
  filterQuickSearch: (state) => state.filter.quickSearch,
  filterCategory: (state) => state.filter.filterCategory,
  orders: (state) => state.orders,
};

const actions = {
  async loadOrders({ dispatch }) {
    dispatch("loadOrdersToFulfill");
    dispatch("loadOrdersInFulfill");
    dispatch("loadOrdersWithError");
  },
  async loadAllOrders({ commit, state }) {
    commit("setLoadingOrders", true);
    commit("setOrders", {
      orders: [],
      stateFieldName: "orders",
    });

    const ordersCollection = collection(db, "orders");
    let ordersQuery = query(
      ordersCollection,
      orderBy("orderDate", "desc"),
      limit(1000)
    );

    const { orderId, email, filterCategory } = state.filter;

    if (filterCategory === orderFilterCategories.DELAYED_FULFILL) {
      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(
        threeDaysAgo.getDate() - appSettings.MAX_DAYS_UNTIL_FULFILL
      );

      ordersQuery = query(
        ordersCollection,
        where("orderDate", "<", threeDaysAgo.toISOString()),
        where("status", "in", [
          orderStatus.IN_PROGRESS,
          orderStatus.ORDER_CREATED,
        ]),
        limit(200)
      );
    }

    if (filterCategory === orderFilterCategories.DELAYED_SHIPMENT) {
      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(
        threeDaysAgo.getDate() - appSettings.MAX_DAYS_UNTIL_SHIPMENT
      );

      ordersQuery = query(
        ordersCollection,
        where("fulfillDate", "<", threeDaysAgo.toISOString()),
        where("status", "==", orderStatus.FULFILLED),
        limit(200)
      );
    }

    if (orderId) {
      ordersQuery = query(
        ordersCollection,
        orderBy("orderName", "desc"),
        where("orderName", ">=", orderId),
        where("orderName", "<=", orderId + "~"),
        limit(1000)
      );
    }

    if (email) {
      ordersQuery = query(ordersQuery, where("customer.email", "==", email));
    }

    const querySnapshot = await getDocs(ordersQuery);
    const orders = querySnapshot.docs.map((doc) => doc.data());

    commit("setOrders", {
      orders: orders,
      stateFieldName: "orders",
    });
    commit("setLoadingOrders", false);
  },
  async loadOrdersToFulfill({ commit, state }) {
    commit("setLoadingOrders", true);
    commit("setOrders", {
      orders: [],
      stateFieldName: "ordersToFulfill",
    });

    const ordersCollection = collection(db, "orders");
    const ordersQuery = query(
      ordersCollection,
      where("status", "==", orderStatus.READY_FOR_FULFILL),
      orderBy("orderDate", "desc"),
      limit(500)
    );

    state.unsubOrdersToFulfill();
    state.unsubOrdersToFulfill = onSnapshot(ordersQuery, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        const changedOrder = change.doc.data();
        const showOrderWarning = !checkIsValidAddress(changedOrder);
        const fullTasks = [];

        for await (const orderTask of changedOrder.tasks) {
          const taskDoc = doc(db, dbCollection.TASKS, orderTask.taskUid);
          const taskRes = await getDoc(taskDoc);
          if (taskRes.exists()) {
            fullTasks.push({
              ...orderTask,
              ...taskRes.data(),
            });
          }
        }

        const orderData = {
          ...changedOrder,
          showOrderWarning,
          tasks: fullTasks,
        };
        if (change.type === "added") {
          commit("addOrder", {
            order: orderData,
            stateFieldName: "ordersToFulfill",
          });
        } else if (change.type === "modified") {
          commit("updateOrder", {
            order: orderData,
            stateFieldName: "ordersToFulfill",
          });
        } else if (change.type === "removed") {
          commit("deleteOrder", {
            order: orderData,
            stateFieldName: "ordersToFulfill",
          });
        }
      });
    });

    commit("setLoadingOrders", false);
  },
  async loadOrdersInFulfill({ commit, state }) {
    commit("setLoadingOrders", true);
    commit("setOrders", {
      orders: [],
      stateFieldName: "ordersInFulfill",
    });

    const ordersCollection = collection(db, "orders");
    const ordersQuery = query(
      ordersCollection,
      where("status", "in", [
        orderStatus.WAITING_FOR_FULFILL,
        orderStatus.IN_FULFILL,
      ]),
      orderBy("orderDate", "desc"),
      limit(250)
    );

    state.unsubOrdersInFulfill = onSnapshot(ordersQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const changedOrder = change.doc.data();
        const showOrderWarning = !checkIsValidAddress(changedOrder);
        const orderData = {
          ...changedOrder,
          showOrderWarning,
        };
        if (change.type === "added") {
          commit("addOrder", {
            order: orderData,
            stateFieldName: "ordersInFulfill",
          });
        } else if (change.type === "modified") {
          commit("updateOrder", {
            order: orderData,
            stateFieldName: "ordersInFulfill",
          });
        } else if (change.type === "removed") {
          commit("deleteOrder", {
            order: orderData,
            stateFieldName: "ordersInFulfill",
          });
        }
      });
    });

    commit("setLoadingOrders", false);
  },
  async loadOrdersWithError({ commit, state }) {
    commit("setLoadingOrders", true);
    commit("setOrders", {
      orders: [],
      stateFieldName: "ordersWithError",
    });

    const ordersCollection = collection(db, "orders");
    const ordersQuery = query(
      ordersCollection,
      where("status", "==", orderStatus.FULFILL_ERROR),
      orderBy("orderDate", "desc"),
      limit(100)
    );

    state.unsubOrdersWithError = onSnapshot(ordersQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const changedOrder = change.doc.data();
        const showOrderWarning = !checkIsValidAddress(changedOrder);
        const orderData = {
          ...changedOrder,
          showOrderWarning,
        };
        if (change.type === "added") {
          commit("addOrder", {
            order: orderData,
            stateFieldName: "ordersWithError",
          });
        } else if (change.type === "modified") {
          commit("updateOrder", {
            order: orderData,
            stateFieldName: "ordersWithError",
          });
        } else if (change.type === "removed") {
          commit("deleteOrder", {
            order: orderData,
            stateFieldName: "ordersWithError",
          });
        }
      });
    });

    commit("setLoadingOrders", false);
  },
  unsubscribeOrders({ state }) {
    state.unsubOrdersToFulfill();
    state.unsubOrdersInFulfill();
    state.unsubOrdersWithError();
  },
};

const mutations = {
  setLoadingOrders: (state, bool) => (state.loadingOrders = bool),
  setOrders: (state, { orders, stateFieldName }) =>
    (state[stateFieldName] = orders),
  addOrder(state, { order, stateFieldName }) {
    // Add the task to the state
    if (order.isExpress) {
      state[stateFieldName].unshift(order);
    } else {
      state[stateFieldName].push(order);
    }
  },
  updateOrder(state, { order, stateFieldName }) {
    // Find the index of the task in the state
    const index = state[stateFieldName].findIndex((o) => o.uid === order.uid);
    // Update the task in the state
    if (index !== -1) {
      state[stateFieldName].splice(index, 1, order);
    }
  },
  deleteOrder(state, { order, stateFieldName }) {
    // Find the index of the task in the state
    const index = state[stateFieldName].findIndex((o) => o.uid === order.uid);
    // Remove the task from the state
    if (index !== -1) {
      state[stateFieldName].splice(index, 1);
    }
  },
  setFilterOrderId: (state, orderId) => (state.filter.orderId = orderId),
  setFilterEmail: (state, email) => (state.filter.email = email),
  setFilterCategory: (state, filterCategory) =>
    (state.filter.filterCategory = filterCategory),
  setFilterQuickSearch: (state, searchTerm) =>
    (state.filter.quickSearch = searchTerm),
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).orders
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
