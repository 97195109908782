<template>
  <AllOrders />
</template>

<script>
import AllOrders from "@/components/AllOrders/AllOrders";
export default {
  name: "AllOrdersView",
  components: { AllOrders },
};
</script>

<style scoped></style>
