<template>
  <v-card>
    <v-card-title
    ><span :class="lineItem.quantity > 1 ? 'quantity-warning' : ''">{{ lineItem.quantity }}</span>x {{ lineItem.printVariant.name }}</v-card-title
    >
    <v-card-text>
      <v-img
        height="500"
        contain
        :src="lineItem.task.finalTemplateThumbnailUrl || lineItem.task.result"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        v-if="lineItem.task.finalTemplateThumbnailUrl"
        :loading="isDownloadingPDF"
        small
        outlined
        @click="() => downloadPDF(lineItem.task)"
      >
        <v-icon>mdi-download</v-icon>
        PDF
      </v-btn>
      <v-btn
        :loading="isDownloadingJPG"
        small
        outlined
        @click="() => downloadJPG(lineItem.task)"
      >
        <v-icon>mdi-download</v-icon>
        JPG
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { downloadImageFromUrl, downloadImageFromUrlAsPdf } from "@/utils";

export default {
  name: "LineItemCard",
  data() {
    return {
      isDownloadingJPG: false,
      isDownloadingPDF: false,
    };
  },
  props: {
    lineItem: {
      required: true,
    },
  },
  methods: {
    getFileName(task) {
      const prefix = task.printVariantName.includes("A4")
        ? "A4"
        : task.printVariantName.includes("A3")
        ? "A3"
        : task.printVariantName;
      return `${prefix}_${task.orderName}`;
    },
    async downloadJPG(task) {
      this.isDownloadingJPG = true;
      const fileToDownload = task.finalTemplate || task.result
      await downloadImageFromUrl(
        fileToDownload,
        this.getFileName(task),
        () => {},
        "jpg"
      );
      this.isDownloadingJPG = false;
    },
    async downloadPDF(task) {
      this.isDownloadingPDF = true;
      await downloadImageFromUrlAsPdf(
        task.finalTemplate,
        this.getFileName(task)
      );
      this.isDownloadingPDF = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.quantity-warning{
  font-weight: bolder;
  color: #ff1e1e;
  font-size: 60px;
}
</style>
