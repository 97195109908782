import { render, staticRenderFns } from "./ReviewTemplatingTakCard.vue?vue&type=template&id=973e4262&scoped=true"
import script from "./ReviewTemplatingTakCard.vue?vue&type=script&lang=js"
export * from "./ReviewTemplatingTakCard.vue?vue&type=script&lang=js"
import style0 from "./ReviewTemplatingTakCard.vue?vue&type=style&index=0&id=973e4262&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "973e4262",
  null
  
)

export default component.exports