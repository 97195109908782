<template>
  <v-dialog
    @click:outside="onClickOutside"
    :dark="dark"
    :persistent="persistent"
    :width="width"
    max-width="100%"
    :value="show"
  >
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  name: "MainDialog",
  props: {
    show: {
      default: false,
      required: true,
    },
    onClose: {
      required: false,
    },
    width: {
      default: "800",
    },
    persistent: {
      default: true,
    },
    dark: {
      default: true
    }
  },
  methods: {
    onClickOutside() {
      if (!this.persistent) this.onClose();
    },
  },
};
</script>

<style scoped></style>
