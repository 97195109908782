<template>
    <v-form @submit.prevent="saveMockupData">
      <v-card flat>
        <v-card-title class="headline mb-2">
          <v-row>
            <v-col cols="8">
              <v-text-field hide-details dense class="txt-field-mockup-name" v-model="mockupName"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-autocomplete label="Orientation" outlined :items="orientations" hide-details dense v-model="mockupOrientation"></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete label="Aspect Ratio" outlined :items="aspectRatios" hide-details dense v-model="mockupAspectRatio"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-for="(layer, index) in mockupData" :key="index" dense>
            <v-col cols="12">
              <v-card class="mb-2 pa-3" outlined>
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-between align-center">
                    <span class="font-weight-bold">Layer {{ index + 1 }}</span>
                    <v-btn icon small @click="removeLayer(index)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="3">
                    <v-autocomplete
                      label="Blending Mode"
                      v-model="layer.blending"
                      :items="blendingModes"
                      dense
                      outlined
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Opacity"
                      v-model.number="layer.opacity"
                      type="number"
                      min="0"
                      max="1"
                      step="0.1"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="layer.isDynamic" cols="1">
                    <v-text-field
                      label="Dy. Index"
                      v-model.number="layer.dynamicSrcIndex"
                      type="number"
                      min="0"
                      step="1"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="layer.isDynamic ? 2 : 3">
                    <v-switch
                      style="margin: 0 6px;"
                      hide-details
                      v-model="layer.isDynamic"
                      @change="handleDynamicSourceChange(index)"
                      label="Dynamic"
                      dense
                    ></v-switch>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Positions"
                      v-model="layer.positionString"
                      @input="onPositionInputChange(layer)"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!layer.isDynamic" dense>
                  <v-col cols="6">
                    <v-file-input
                      label="Upload Image"
                      accept="image/*"
                      @change.native="(e) => onFileChange(e, index)"
                      dense
                      outlined
                    ></v-file-input>
                  </v-col>
                  <v-col cols="6">
                    <v-img
                      contain
                      v-if="layer.file"
                      :src="layer.file ? getObjectUrl(layer.file) : ''"
                      max-height="150"
                      class="elevation-1"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      color="primary"
                      small
                      @click="openPositioner(index)"
                      :disabled="!layer.file && !layer.isDynamic"
                    >
                      Define Positions
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-btn color="blue" @click="addLayer" small>
            Add Layer
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isSaving" :disabled="isSaving" color="success" type="submit" small>Save</v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog fullscreen v-model="dialog">
        <div class="mockup-positioner-dialog">
          <v-btn color="red" class="mockup-positioner-dialog__close-btn" @click="dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <MockupPositioner
            v-if="currentLayerIndex !== null"
            :file="getPositionerImage()"
            :savable="true"
            @apply-positions="applyPositions"
          />
        </div>
      </v-dialog>
    </v-form>
</template>

<script>
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import MockupPositioner from './MockupPositioner.vue';
import {collection, doc, setDoc} from "firebase/firestore";
import {db} from "../../../../fb";
import { v4 as uuidv4 } from "uuid";
import {availableAspectRatios, availableOrientations} from "@/config";
import {mapActions} from "vuex";

export default {
  components: {
    MockupPositioner,
  },
  data() {
    return {
      uuid: uuidv4(),
      isSaving: false,
      mockupName: "New Mockup",
      mockupAspectRatio: availableAspectRatios[0],
      mockupOrientation: availableOrientations[0],
      currentDynamicSrcIndex: 1,
      dialog: false,
      currentLayerIndex: null,
      aspectRatios: availableAspectRatios,
      orientations: availableOrientations,
      blendingModes: [
        'source-over',
        'source-in',
        'source-out',
        'source-atop',
        'destination-over',
        'destination-in',
        'destination-out',
        'destination-atop',
        'lighter',
        'copy',
        'xor',
        'multiply',
        'screen',
        'overlay',
        'darken',
        'lighten',
        'color-dodge',
        'color-burn',
        'hard-light',
        'soft-light',
        'difference',
        'exclusion',
        'hue',
        'saturation',
        'color',
        'luminosity',
      ],
      mockupData: [
        {
          layer: 0,
          blending: 'source-over',
          isDynamic: false,
          file: null,
          dynamicSrcIndex: null,
          opacity: 1,
          position: [
            [0, 0],
            [1, 0],
            [1, 1],
            [0, 1],
          ],
          positionString: '[ [0, 0], [1, 0], [1, 1], [0, 1] ]',
        },
      ],
    };
  },
  methods: {
    ...mapActions(["loadMockups"]),
    getObjectUrl(file) {
      return URL.createObjectURL(file);
    },
    setCurrentLayerIndex(index) {
      this.currentLayerIndex = index;
    },
    handleDynamicSourceChange(index) {
      const layer = this.mockupData[index];
      if (layer.isDynamic) {
        this.$set(layer, 'dynamicSrcIndex', this.currentDynamicSrcIndex);
        this.currentDynamicSrcIndex += 1;
      } else {
        this.$set(layer, 'dynamicSrcIndex', null);
        this.currentDynamicSrcIndex -= 1;
      }
    },
    onPositionInputChange(layer) {
      try {
        const parsed = JSON.parse(layer.positionString);
        if (Array.isArray(parsed)) {
          this.$set(layer, 'position', parsed);
        }
      } catch (error) {
        // Invalid JSON, handle it gracefully
      }
    },
    onFileChange(event, index) {
      const file = event.target?.files?.[0];
      if (file) {
        this.$set(this.mockupData[index], 'file', file);
      }
    },
    getPositionerImage() {
      const layer = this.mockupData[this.currentLayerIndex];
      if (layer.isDynamic && this.mockupData[0]?.file) {
        return this.mockupData[0].file; // Use background image if dynamic source is selected
      }
      return layer.file || null;
    },
    async uploadImages() {
      const storage = getStorage();

      for (const layer of this.mockupData) {
        if (layer.file) {
          const storageRef = ref(storage, `mockups/${layer.file.name}`);
          await uploadBytes(storageRef, layer.file);
          const downloadURL = await getDownloadURL(storageRef);
          this.$set(layer, 'imgSrc', downloadURL);
          this.$set(layer, 'file', null);
        }
      }
    },
    addLayer() {
      this.mockupData.push({
        layer: this.mockupData.length,
        blending: 'multiply',
        isDynamic: false,
        file: null,
        dynamicSrcIndex: null,
        imgSrc: '',
        opacity: 1,
        position: [
          [0, 0],
          [1, 0],
          [1, 1],
          [0, 1],
        ],
        positionString: '[ [0, 0], [1, 0], [1, 1], [0, 1] ]',
      });
    },
    removeLayer(index) {
      this.mockupData.splice(index, 1);
      this.reorderLayers();
    },
    reorderLayers() {
      this.mockupData.forEach((layer, index) => {
        layer.layer = index;
        this.$set(layer, 'dynamicSrcIndex', index + 1);
      });
    },
    openPositioner(index) {
      this.currentLayerIndex = index;
      this.dialog = true;
    },
    applyPositions(positions) {
      if (this.currentLayerIndex !== null) {
        this.mockupData[this.currentLayerIndex].position = positions;
        this.mockupData[this.currentLayerIndex].positionString = JSON.stringify(positions);
        this.dialog = false;
        this.currentLayerIndex = null;
      }
    },
    flattenPosition(position) {
      return position.flat();
    },
    unflattenPosition(flattenedPosition) {
      const unflattened = [];
      for (let i = 0; i < flattenedPosition.length; i += 2) {
        unflattened.push([flattenedPosition[i], flattenedPosition[i + 1]]);
      }
      return unflattened;
    },
    async saveMockupData() {
      this.isSaving = true;
      await this.uploadImages();
      console.log('Save', this.mockupData);

      // Flatten the position arrays before saving
      const mockupDataToSave = this.mockupData.map(layer => ({
        ...layer,
        position: this.flattenPosition(layer.position),
      }));

      const mockupsCollection = collection(db, "mockups");
      const mockupDoc = doc(mockupsCollection, this.uuid)
      await setDoc(mockupDoc,{
        created: Date.now(),
        name: this.mockupName,
        aspectRatio: this.mockupAspectRatio,
        orientation: this.mockupOrientation,
        mockupData: mockupDataToSave,
      }, { merge: true });

      this.resetForm();
      await this.loadMockups()
      this.isSaving = false;
    },
    resetForm() {
      this.uuid = uuidv4();
      this.mockupName = "New Mockup";
      this.mockupAspectRatio = availableAspectRatios[0];
      this.mockupOrientation = availableOrientations[0]
      this.currentDynamicSrcIndex = 1;
      this.mockupData = [
        {
          layer: 0,
          blending: 'source-over',
          isDynamic: false,
          file: null,
          dynamicSrcIndex: null,
          opacity: 1,
          position: [
            [0, 0],
            [1, 0],
            [1, 1],
            [0, 1],
          ],
          positionString: '[ [0, 0], [1, 0], [1, 1], [0, 1] ]',
        },
      ];
    },
  },
  watch: {
    mockupData: {
      handler(newData) {
        newData.forEach(layer => {
          layer.positionString = JSON.stringify(layer.position);
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.txt-field-mockup-name {
  font-size: 30px;
}

.mockup-positioner-dialog {
  background: white;
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.v-card {
  padding: 8px;
}

.v-btn {
  margin-left: 8px;
}
</style>
