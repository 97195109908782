<template>
  <MainDialog :persistent="false" v-if="show" :show="show" :onClose="onClose">
    <v-card light class="previousTasks">
      <v-card-title v-if="!loading && hasResults"
      >Use Previous Task From Customer
      </v-card-title
      >
      <StateDisplay
        v-if="loading || !hasResults"
        :loading="loading"
        :no-results="!hasResults"
        loading-message="Loading Tasks"
      />
      <v-card-text v-else>
        <v-row>
          <v-col v-for="task in previousTaskResults" :key="task.uid" cols="6">
            <v-card
              dark
              @click="() => (selectedPreviousTask = task)"
              :class="{
                previousTaskCard__selected:
                  selectedPreviousTask?.uid === task.uid,
              }"
              class="previousTaskCard"
            >
              <v-card-title>{{ task.orderName }}</v-card-title>
              <v-card-subtitle>
                {{ getDateFromNow(task.createdAt) }} by
                {{ task.artistName }}
              </v-card-subtitle
              >
              <v-card-subtitle>
                {{ task.customPrintVariantName || task.printVariantName }}
              </v-card-subtitle
              >
              <v-card-text>
                <v-img
                  contain
                  height="300px"
                  :src="task.resultThumbnailUrl || task.result"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!loading && hasResults">
        <v-btn @click="onClose" text>Cancel</v-btn>
        <v-spacer/>
        <v-btn
          @click="pushTaskToTemplating"
          :disabled="!selectedPreviousTask || isPushing"
          :loading="isPushing"
          color="success"
        >Push To Templating
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import StateDisplay from "@/components/StateDisplay";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {db} from "../../../../fb";
import {getDateFromNow} from "@/utils";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PreviousTasksDialog",
  components: {StateDisplay, MainDialog},
  data() {
    return {
      loading: false,
      isPushing: false,
      previousTaskResults: [],
      selectedPreviousTask: null,
    };
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      require: false,
    },
    task: {},
  },
  computed: {
    ...mapGetters(["userNameIdMap"]),
    hasResults() {
      return this.previousTaskResults.length > 0;
    },
  },
  created() {
    this.loadTasksByCustomerId();
  },
  methods: {
    ...mapActions(["importTaskFromShopify"]),
    async loadTasksByCustomerId() {
      this.loading = true;
      this.previousTaskResults = [];
      const taskCollection = collection(db, "orders");
      const taskQuery = query(
        taskCollection,
        where("customer.id", "==", this.task.customerId)
      );

      const querySnapshot = await getDocs(taskQuery);
      await Promise.all(
        querySnapshot.docs.map(async (orderDoc) => {
          const order = orderDoc.data();
          for (const {taskUid} of order.tasks) {
            const taskDoc = doc(db, "tasks", taskUid);
            const taskRes = await getDoc(taskDoc);
            const task = taskRes.data();

            if (task?.result) {
              this.previousTaskResults.push({
                uid: task.uid,
                createdAt: task.createdAt,
                orderName: task.orderName,
                result: task.result,
                resultThumbnailUrl: task.resultThumbnailUrl,
                artistName: this.userNameIdMap?.find(user => user.uid === task.editors.DRAW)?.username || "",
              });
            }
          }
        })
      );
      this.loading = false;
    },
    getDateFromNow(date) {
      return getDateFromNow(date);
    },
    async pushTaskToTemplating() {
      this.isPushing = true;
      await this.importTaskFromShopify({
        task: this.task,
        customImage: this.customImage,
        image: this.task.img,
        existentTask: this.selectedPreviousTask,
        usedUpscaling: false,
        callBack: () => {
          this.isPushing = false;
          this.onClose();
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.previousTasks {
  min-height: 400px;
}

.previousTaskCard {
  cursor: pointer;
  box-shadow: none;
  transition: all ease-in 0.125s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    background-color: #5e5e5e;
  }

  &__selected {
    box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
    background-color: #5e5e5e;
  }
}
</style>
