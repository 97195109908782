<template>
  <div class="state-display-container">
    <div v-if="error">
      <h1 class="state-display-message">{{ errorMessage }}</h1>
    </div>
    <template v-else-if="loading">
      <div class="loader-container" v-if="loading">
        <div class="loader"></div>
      </div>
      <h1 class="state-display-message">{{ loadingMessage }}</h1>
    </template>
    <div v-else-if="noResults">
      <h1 class="state-display-message">{{ noResultsMessage }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "StateDisplay",
  props: {
    loading: {
      type: Boolean,
    },
    loadingMessage: {
      default: "Loading...",
    },
    noResults: {
      type: Boolean,
    },
    noResultsMessage: {
      default: "No Results",
    },
    error: {
      type: Boolean,
    },
    errorMessage: {
      default: "There was an Error",
    },
  },
};
</script>

<style lang="scss" scoped>
.state-display-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.state-display-message {
  color: #9f9f9f;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey background */
  border-top: 6px solid #9f9f9f; /* Dark grey */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
