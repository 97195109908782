<template>
  <v-dialog
    class="no-box-shadow"
    @click:outside="onClose"
    max-width="90vw"
    max-height="90vh"
    width="fit-content"
    :value="show"
  >
    <div @click="onClose">
      <ImageZoom :image="image" :image-thumb="imageThumb || image" />
    </div>
    <slot></slot>
  </v-dialog>
</template>

<script>
import ImageZoom from "@/components/ImageZoom";
export default {
  name: "ImageDialog",
  components: { ImageZoom },
  props: {
    image: {
      type: String,
      required: true,
    },
    imageThumb: {
      type: String,
      required: false,
    },
    show: {
      default: false,
      required: true,
    },
    onClose: {
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  box-shadow: unset;
}
</style>