<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>
        Open
        <v-badge
          v-if="ticketsOpen.length"
          offset-x="0"
          offset-y="0"
          :content="ticketsOpen.length"
        ></v-badge>
      </v-tab>
      <v-tab>
        Waiting
        <v-badge
          v-if="ticketsWaiting.length"
          offset-x="0"
          offset-y="0"
          :content="ticketsWaiting.length"
        ></v-badge>
      </v-tab>
      <v-tab>
        Closed
        <v-badge
          v-if="ticketsClosed.length"
          offset-x="0"
          offset-y="0"
          :content="ticketsClosed.length"
        ></v-badge>
      </v-tab>
    </v-tabs>
    <v-tabs-items style="padding: 12px" v-model="tab">
      <v-tab-item>
        <v-row class="ticket-row">
          <v-col class="ticket-col" cols="12" md="12" v-for="ticket in ticketsOpen" :key="ticket.orderUid">
            <SupportTicketsCard :ticket="ticket"/>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="ticket-row">
          <v-col class="ticket-col" cols="12" md="12" v-for="ticket in ticketsWaiting" :key="ticket.orderUid">
            <SupportTicketsCard :ticket="ticket"/>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="ticket-row">
          <v-col class="ticket-col" cols="12" md="12" v-for="ticket in ticketsClosed" :key="ticket.orderUid">
            <SupportTicketsCard :ticket="ticket"/>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SupportTicketsCard from "@/components/Support/SupportTicketsCard";

export default {
  name: "SupportView",
  components: {SupportTicketsCard},
  data() {
    return {
      tab: 0,
    }
  },
  computed: {
    ...mapGetters(["ticketsOpen", "ticketsWaiting", "ticketsClosed", "unsubTickets"])
  },
  methods: {

  },
}
</script>

<style scoped>
.ticket-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px; /* Adjust based on Vuetify gutter value */
  margin-right: -8px; /* Adjust based on Vuetify gutter value */
}

.ticket-col {
  padding-left: 8px; /* Adjust based on Vuetify gutter value */
  padding-right: 8px; /* Adjust based on Vuetify gutter value */
}
</style>
