<template>
  <v-dialog v-if="hasSupportTicket" class="support-dialog-container" v-model="dialogVisible">
    <template v-slot:activator="{ on, attrs }">
      <v-btn height="36px" class="support-activator-btn" icon v-bind="attrs" v-on="on">
        <v-icon color="warning">mdi-headset</v-icon>
      </v-btn>
    </template>
    <div class="support-dialog-container__content">
      <StateDisplay v-if="loading" :loading="loading" />
      <SupportTicketsCard v-else-if="ticket && !loading" :ticket="ticket" />
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SupportTicketsCard from "@/components/Support/SupportTicketsCard";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../fb";
import StateDisplay from "@/components/StateDisplay";

export default {
  name: "SupportDialog",
  components: { StateDisplay, SupportTicketsCard },
  data() {
    return {
      dialogVisible: false,
      ticket: null,
      loading: false
    };
  },
  watch: {
    dialogVisible(newValue) {
      if (newValue && !this.ticket) {
        this.loadTicket();
      }
    }
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(["orderIdsInSupport"]),
    hasSupportTicket() {
      return this.orderIdsInSupport.includes(this.orderId);
    }
  },
  methods: {
    async loadTicket() {
      this.loading = true;
      const supportDoc = doc(db, "support", this.orderId);
      const supportRes = await getDoc(supportDoc);
      if (supportRes.exists()) {
        this.ticket = supportRes.data();
      } else {
        // Handle case where the document does not exist.
        this.ticket = null;
      }
      this.loading = false;
    }
  }
}
</script>


<style lang="scss" scoped>
.support-activator-btn {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
  background-color: #282828;
}

.support-dialog-container {
  &__content {
    background-color: white;
    min-height: 300px;
    min-width: 300px;
   }
}
</style>
