<template>
  <div>
    <PrintShops />
    <ProductTypes class="mt-6" />
    <PrintVariants class="mt-6" />
    <SkuMapping class="mt-6" />
  </div>
</template>

<script>
import PrintShops from "@/components/Settings/Products/PrintShops/PrintShops";
import SkuMapping from "@/components/Settings/Products/SkuMappings/SkuMappings";
import ProductTypes from "@/components/Settings/Products/ProductTypes/ProductTypes";
import PrintVariants from "@/components/Settings/Products/PrintVariants/PrintVariants";
export default {
  name: "AdminProducts",
  components: { PrintVariants, ProductTypes, SkuMapping, PrintShops },
};
</script>

<style scoped></style>
