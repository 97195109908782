<template>
  <div class="taskContainer">
    <v-expansion-panels v-if="!isLoading && !noResults" v-model="panel">
      <v-expansion-panel v-for="data in tasksByAssignees" :key="data.uid">
        <v-expansion-panel-header>
        <span>
          <v-icon class="mr-3">{{ data.icon }}</v-icon>
          {{ data.title }}
        </span>
          <template v-slot:actions>
            <v-avatar color="secondary" size="30">
              <span class="white--text">{{ data.tasks.length }}</span>
            </v-avatar>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CardsGrid :tasks="data.tasks" :CardComponent="CardComponent" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <StateDisplay v-else :loading="isLoading" loading-message="Loading Templating Tasks..." />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TemplatingTaskCard from "@/components/TaskCards/TemplatingTaskCard";
import CardsGrid from "@/components/CardsGrid";
import { auth } from "../../fb";
import StateDisplay from "@/components/StateDisplay";
import {permissions} from "@/constants";

export default {
  name: "TemplatingTasks",
  components: {StateDisplay, CardsGrid },
  data() {
    return {
      tasksByAssignees: [],
      CardComponent: TemplatingTaskCard,
      panel: 0,
      isMapping: false
    };
  },
  mounted() {
    this.mapTasksByAssignees(true);
  },
  computed: {
    ...mapGetters(["templatingTasks", "userUidMap", "userPermissions"]),
    tasks() {
      return this.templatingTasks.tasks;
    },
    isLoading() {
      return this.isMapping || !!this.templatingTasks.loading;
    },
    noResults() {
      return !this.isLoading && this.templatingTasks.tasks.length === 0;
    }
  },
  watch: {
    tasks: {
      handler() {
        this.mapTasksByAssignees(false);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["loadTasks"]),
    async mapTasksByAssignees(showLoading) {
      this.isMapping = showLoading
      let userIds = this.userPermissions.includes(permissions.TASKMARKET) ? [""] : [];
      this.templatingTasks.tasks.forEach((task) => {
        userIds = [...new Set([...userIds, task.assignedTo])];
      });
      const sortedUserIds = userIds.sort().reverse();
      this.tasksByAssignees = sortedUserIds.map((id) => {
        return {
          uid: id,
          title: this.getTitleByUid(id),
          tasks: this.getTasksByAssigneeUid(id),
          icon: this.getTitleIcon(id),
        };
      })
      this.isMapping = false
    },
    getTitleByUid(uid) {
      if (uid === auth.currentUser.uid) {
        return "Your Tasks";
      } else if (uid) {
        return this.userUidMap[uid];
      } else {
        return "Task Market";
      }
    },
    getTitleIcon(uid) {
      if (uid) {
        return "mdi-clipboard-account";
      } else {
        return "mdi-storefront";
      }
    },
    getTasksByAssigneeUid(uid) {
      return this.templatingTasks.tasks.filter(
        (task) => task.assignedTo === uid
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.taskContainer {
  min-height: 400px;
}
</style>
