export const checkIsValidAddress = (order) => {
  let isValid = true;

  if (order.shippingAddress.checked) return true;

  // Check if addressPrimary ends or starts (FR) with a digit or digit followed by one char
  isValid =
    isValid &&
    /^(\d+\s?[A-Za-z]?\s.*|.*\s\d+\s?[A-Za-z]?)\s?$/.test(
      order.shippingAddress.addressPrimary
    );

  // Check if addressSecondary is not defined or empty
  isValid =
    isValid &&
    (!order.shippingAddress.addressSecondary ||
      order.shippingAddress.addressSecondary.trim() === "");

  return isValid;
};
