<template>
  <v-row :dense="$vuetify.breakpoint.mobile" class="d-flex align-stretch">
    <v-col cols="6" sm="6" md="3">
      <OverviewCard class="statistic-overview__card" title="Bestellungen" :data="orderChartData" unit="Orders" type="doughnut" />
    </v-col>
    <v-col cols="6" sm="6" md="3">
      <OverviewCard class="statistic-overview__card" title="Umsatz" :switch-data="revenueChartData" unit="€" type="doughnut" />
    </v-col>
    <v-col cols="6" sm="6" md="3">
      <OverviewCard class="statistic-overview__card" title="Kosten" :switch-data="costsChartData" unit="€" type="doughnut" />
    </v-col>
    <v-col cols="6" sm="6" md="3">
      <OverviewCard class="statistic-overview__card" title="Gewinn" :switch-data="profitData" />
    </v-col>
    <v-col cols="12">
      <OverviewBarCard
        title="Zeitliche Verteilung"
        :disable-switch="disableSwitch"
        :data="timelineSwitchData[1].data"
        :switch-data="timelineSwitchData"
        unit="€"
        :aspect-ratio="$vuetify.breakpoint.mobile ? 1 : 3"
      />
    </v-col>
<!--    <v-col cols="12">-->
<!--      <AdditionalInformation :data="data"/>-->
<!--    </v-col>-->
  </v-row>
</template>

<script>
import OverviewCard from "@/components/Statistics/StatisticOverview/OverviewCard";
import { statisticsColors } from "@/config";
import OverviewBarCard from "@/components/Statistics/StatisticOverview/OverviewBarCard";
import { mapGetters } from "vuex";
import {getDateFromNow} from "@/utils";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../../fb";

export default {
  name: "StatisticOverview",
  components: { OverviewBarCard, OverviewCard },
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["statisticsDayRange"]),
    disableSwitch() {
      return this.statisticsDayRange < 1
    },
    orderChartData() {
      // Compute data for order numbers
      return this.data.shops.map((item) => ({
        count: item.totalOrders,
        label: item.shopCode,
        color: statisticsColors[item.shopCode],
      }));
    },
    revenueChartData() {
      return [
        {
          label: "By Shops",
          data: this.data.shops.map((item) => ({
            count: item.revenue.total,
            label: item.shopCode,
            color: statisticsColors[item.shopCode],
          })),
        },
        {
          label: "By Source",
          data: this.data.shops.reduce((acc, shop) => {
            let totalTracked = 0;

            for (const revenueSource in shop.revenue.bySource) {
              const revenueValue = shop.revenue.bySource[revenueSource];
              totalTracked += revenueValue;

              const index = acc.findIndex(
                (item) => item.label === revenueSource
              );
              if (index === -1) {
                acc.push({
                  label: revenueSource,
                  count: revenueValue,
                  color: statisticsColors[revenueSource],
                });
              } else {
                acc[index].count += revenueValue;
              }
            }

            // Adding the untracked revenue to a specific 'untracked' item
            const untrackedRevenue = shop.revenue.total - totalTracked;
            const untrackedIndex = acc.findIndex(
              (item) => item.label === "Source Unknown"
            );

            if (untrackedIndex === -1) {
              acc.push({
                label: "Source Unknown",
                count: untrackedRevenue,
                color: "#dedede",
              });
            } else {
              acc[untrackedIndex].count += untrackedRevenue;
            }

            return acc;
          }, []),
        },
      ];
    },
    costsChartData() {
      return [
        {
          label: "By Shops",
          data: this.data.shops.reduce((acc, shop) => {
            const totalCost =
              shop.costs.production +
              shop.costs.taxes +
              shop.costs.additionalCosts +
              shop.costs.shipping +
              shop.costs.employee +
              shop.costs.payments +
              shop.costs.advertisement;
            acc.push({
              label: shop.shopCode,
              count: -Number(totalCost.toFixed(2)),
              color: statisticsColors[shop.shopCode],
            });
            return acc;
          }, []),
        },
        {
          label: "By Type",
          data: this.data.shops
            .reduce((acc, shop) => {
              for (const costType in shop.costs) {
                const index = acc.findIndex((item) => item.label === costType);
                const costValue = shop.costs[costType] * 100; // Multiply by 100 to work with whole numbers
                if (index === -1) {
                  acc.push({ label: costType, count: costValue });
                } else {
                  acc[index].count += costValue;
                }
              }
              return acc;
            }, [])
            .map((item) => ({
              label: item.label,
              count: -item.count / 100, // Convert back to the desired format with two decimal places
              color: statisticsColors[item.label],
            })),
        },
      ];
    },
    timelineData() {
      return {
        labels: Object.keys(this.data.shops[0].timelines.costs),
        datasets: [
          {
            label: "Facebook AdSpend",
            data: this.data.shops.reduce((acc, shop) => {
              const timeline =
                shop.advertisement?.byChannel?.facebook?.timelineSpend || {};

              Object.keys(timeline).forEach((key) => {
                if (!acc[key]) {
                  // Initialize the accumulator with the value from the current shop if key doesn't exist
                  acc[key] = timeline[key];
                } else {
                  // Add the values from the current shop to the accumulator
                  acc[key] += timeline[key];
                }
              });

              return acc;
            }, {}),
            backgroundColor: statisticsColors.facebook,
            borderColor: statisticsColors.facebook,
            tension: 0.4,
            fill: true,
            stack: "Costs",
          },
          {
            label: "Tiktok AdSpend",
            data: this.data.shops.reduce((acc, shop) => {
              const timeline =
                shop.advertisement?.byChannel?.tiktok?.timelineSpend || {};

              Object.keys(timeline).forEach((key) => {
                if (!acc[key]) {
                  // Initialize the accumulator with the value from the current shop if key doesn't exist
                  acc[key] = timeline[key];
                } else {
                  // Add the values from the current shop to the accumulator
                  acc[key] += timeline[key];
                }
              });

              return acc;
            }, {}),
            backgroundColor: statisticsColors.tiktok,
            borderColor: statisticsColors.tiktok,
            tension: 0.4,
            fill: true,
            stack: "Costs",
          },
          {
            label: "Google AdSpend",
            data: this.data.shops.reduce((acc, shop) => {
              const timeline =
                shop.advertisement?.byChannel?.google?.timelineSpend || {};

              Object.keys(timeline).forEach((key) => {
                if (!acc[key]) {
                  // Initialize the accumulator with the value from the current shop if key doesn't exist
                  acc[key] = timeline[key];
                } else {
                  // Add the values from the current shop to the accumulator
                  acc[key] += timeline[key];
                }
              });

              return acc;
            }, {}),
            backgroundColor: statisticsColors.google,
            borderColor: statisticsColors.google,
            tension: 0.4,
            fill: true,
            stack: "Costs",
          },
          {
            label: "Production Costs",
            data: this.data.shops.reduce((acc, shop) => {
              const timeline = shop.timelines.costs;

              Object.keys(timeline).forEach((key) => {
                if (!acc[key]) {
                  // Initialize the accumulator with the value from the current shop if key doesn't exist
                  acc[key] = timeline[key];
                } else {
                  // Add the values from the current shop to the accumulator
                  acc[key] += timeline[key];
                }
              });

              return acc;
            }, {}),
            backgroundColor: statisticsColors.costs,
            borderColor: statisticsColors.costs,
            tension: 0.4,
            fill: true,
            stack: "Costs",
          },
          {
            label: "Revenue",
            data: this.data.shops.reduce((acc, shop) => {
              const timeline = shop.timelines.revenue;

              Object.keys(timeline).forEach((key) => {
                if (!acc[key]) {
                  // Initialize the accumulator with the value from the current shop if key doesn't exist
                  acc[key] = timeline[key];
                } else {
                  // Add the values from the current shop to the accumulator
                  acc[key] += timeline[key];
                }
              });

              return acc;
            }, {}),
            backgroundColor: statisticsColors.revenue,
            borderColor: statisticsColors.revenue,
            tension: 0.4,
            fill: true,
          },
        ],
      };
    },
    timelineSwitchData() {
      return [
        {
          label: "Time Span",
          data: {
            labels: this.timelineData.labels.slice(24),
            datasets: this.timelineData.datasets.map((set) => {
              return {
                ...set,
                data: Object.fromEntries(Object.entries(set.data).slice(24)),
              };
            }),
          },
        },
        {
          label: "Merged Day",
          data: {
            labels: this.timelineData.labels.slice(0, 24),
            datasets: this.timelineData.datasets.map((set) => {
              return {
                ...set,
                data: Object.fromEntries(Object.entries(set.data).slice(0, 24)),
              };
            }),
          },
        },
      ];
    },
    profitData() {
      return [
        {
          label: "Profit",
          unit: "€",
          chartType: 'doughnut',
          data: this.data.shops.reduce((acc, shop) => {
            const totalCost =
              shop.costs.production +
              shop.costs.shipping +
              shop.costs.employee +
              shop.costs.payments +
              shop.costs.taxes +
              shop.costs.additionalCosts +
              shop.costs.advertisement;
            const profit = Number((shop.revenue.total - totalCost).toFixed(2));
            acc.push({
              label: shop.domain,
              count: profit,
              color: statisticsColors[shop.shopCode],
            });
            return acc;
          }, [])
        },
        {
          label: "Margin",
          unit: "%",
          chartType: 'bar',
          data: {
            labels: this.data.shops.map(shop => shop.domain),
            datasets: [
              {
                label: "Margin (%)",
                data: this.data.shops.map(shop => {
                  const totalCost =
                    shop.costs.production +
                    shop.costs.shipping +
                    shop.costs.employee +
                    shop.costs.payments +
                    shop.costs.taxes +
                    shop.costs.additionalCosts +
                    shop.costs.advertisement;
                  const profit = shop.revenue.total - totalCost;
                  const margin = shop.revenue.total === 0 ? 0 : Number(((profit / shop.revenue.total) * 100).toFixed(2));
                  return margin;
                }),
                backgroundColor: this.data.shops.map(shop => statisticsColors[shop.shopCode]),
                borderColor: this.data.shops.map(shop => statisticsColors[shop.shopCode]),
                tension: 0.4,
                fill: true,
              },
            ],
          }
        }
      ]
    },
  },
  methods: {
    async refresh() {
      const getStatistics = httpsCallable(functions, "syncStatistics");
      const res = await getStatistics({
        created_at_min: getters.statisticRange.min,
        created_at_max: getters.statisticRange.max,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.statistic-overview {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;

  &__card {
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.07) 0 1px 2px, rgba(0, 0, 0, 0.07) 0 2px 4px, rgba(0, 0, 0, 0.07) 0 4px 8px, rgba(0, 0, 0, 0.07) 0 8px 16px, rgba(0, 0, 0, 0.07) 0 16px 32px, rgba(0, 0, 0, 0.07) 0 32px 64px !important;

    @include md() {
      box-shadow: rgba(14, 63, 126, 0.04) 0 0 0 1px, rgba(42, 51, 69, 0.04) 0 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0 6px 6px -3px, rgba(14, 63, 126, 0.04) 0 12px 12px -6px, rgba(14, 63, 126, 0.04) 0 24px 24px -12px !important;
    }
  }
}
</style>
