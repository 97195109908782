<template>
  <MainDialog width="400" v-if="show" :show="show" :onClose="onClose">
    <v-card>
      <v-card-title class="d-flex justify-center"
        >Send Order {{ totalNumberFulfilled }} of {{ totalNumberToFulfill }} to
        Fulfill</v-card-title
      >
      <v-card-text class="d-flex justify-center">
        <v-progress-circular
          class="ma-12"
          :size="200"
          :value="fulfillProgress"
          :width="20"
        />
      </v-card-text>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
export default {
  name: "IsFulfillingDialog",
  components: { MainDialog },
  data() {
    return {
      totalNumberToFulfill: 0,
    };
  },
  computed: {
    totalNumberFulfilled() {
      return this.totalNumberToFulfill - this.ordersToFulfill.length;
    },
    fulfillProgress() {
      return (this.totalNumberFulfilled / this.totalNumberToFulfill) * 100;
    },
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    onClose: {
      required: true,
    },
    ordersToFulfill: {
      type: Array,
    },
  },
  mounted() {
    this.totalNumberToFulfill = this.ordersToFulfill.length;
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
