<template>
  <MainDialog :show="show">
    <v-card light>
      <v-card-title> Send Mail </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col v-if="messages" cols="12">
            <div class="messages-chip-container">
              <v-chip
                v-for="message in messages"
                :key="message.label"
                @click="selectedMessage = message"
                :color="selectedMessage?.id === message?.id ? '#ccffc4' : '#fff'"
                :class="{'messages-chip--highlight': checkTriggerWords(message)}"
              >
                {{ message.label }}
              </v-chip>
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Receiver"
              outlined
              hide-details
              v-model="receiverMail"
            />
          </v-col>
          <v-col cols="9">
            <v-text-field
              hide-details
              label="Subject"
              outlined
              v-model="subjectValue"
            />
          </v-col>
          <v-col cols="12">
            <VueTrix
              class="wysiwyg-editor"
              style="color: black"
              v-model="messageValue"
              placeholder="Enter content"
              localStorage
            />
          </v-col>
          <v-col cols="12">
            <v-file-input
              outlined
              @change.native="onFileChange"
              @click:clear="attachments = []"
              chips
              clearable
              label="Attachments"
              multiple
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="onClose">Cancel</v-btn>
        <v-spacer />
        <v-checkbox class="mr-3" v-model="autoTranslateEnabled" :label="`Auto Translate ${countryCode}`"/>
        <v-btn
          :disabled="isSending"
          :loading="isSending"
          color="success"
          @click="sendMail"
          >Send Mail</v-btn
        >
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import VueTrix from "vue-trix";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../fb";
import { EventBus, events } from "../../event-bus";
import { currentMonthYear } from "@/utils";
import MainDialog from "@/components/MainDialog";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
export default {
  name: "MailDialog",
  components: {
    MainDialog,
    VueTrix,
  },
  data() {
    return {
      showDialog: false,
      receiverMail: '',
      messageValue: "",
      subjectValue: "",
      isSending: false,
      selectedMessage: this.messages?.[0] || null,
      attachments: [],
      template: null,
      autoTranslateEnabled: false,
      buttonCountryCode: {
        ES: "SPANISH",
        DE: "GERMAN",
        PL: "POLISH"
      }
    };
  },
  watch: {
    selectedMessage() {
      this.messageValue = this.selectedMessage.message.text;
      this.subjectValue = this.selectedMessage.message.subject;
      this.template = this.selectedMessage.message.template || null;
    },
  },
  created() {
    this.autoTranslateEnabled = this.countryCode !== "DE" ? this.autoTranslate : false
    this.receiverMail = this.receiver
    this.subjectValue = this.selectedMessage
      ? this.selectedMessage.message.subject
      : this.subject;
    this.messageValue = this.selectedMessage
      ? this.selectedMessage.message.text
      : this.message;

    this.template = this.selectedMessage.message.template || null
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      required: true,
    },
    onSent: {
      required: false,
    },
    receiver: {
      required: true,
      type: String,
    },
    countryCode: {
      required: true,
      type: String,
    },
    shopId: {
      required: true,
      type: String,
    },
    subject: {
      type: String,
    },
    message: {
      type: String,
    },
    messages: {
      type: Array,
    },
    waitForSentSuccess: {
      type: Boolean,
      default: true
    },
    customerMessage: {
      type: String,
      required: false,
    },
    autoTranslate: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    async sendMail() {
      this.isSending = true;
      if (!this.waitForSentSuccess) {
        this.onClose();
      }
      const attachmentsURLs = [];
      if (this.attachments.length) {
        const storage = getStorage();
        for await (const [index, value] of this.attachments.entries()) {
          const storageRef = ref(
            storage,
            `/mailAttachments/${currentMonthYear}/${
              this.receiverMail.split("@")[0]
            }_${index}.png`
          );
          const uploadResponse = await uploadBytes(storageRef, value, {
            cacheControl: "public,max-age=300",
          });
          attachmentsURLs.push({
            filename: value.name,
            path: await getDownloadURL(uploadResponse.ref),
          });
        }
      }
      const mailToCustomer = httpsCallable(functions, "sendMail");

      const mailData = {
        receiver: this.receiverMail,
        subject: this.subjectValue,
        message: this.messageValue,
        countryCode: this.countryCode,
        shopId: this.shopId,
        attachments: attachmentsURLs,
        template: this.template,
        autoTranslate: this.autoTranslateEnabled
      }

      const res = await mailToCustomer(mailData);

      if (res?.data?.status === "error") {
        EventBus.$emit(events.SNACKBAR, {
          message: `Send Mail ${this.subjectValue} failed! ${res.data.message}`,
          type: "error",
        });
      } else {
        if (!!this.onSent) {
          this.onSent({mail: mailData})
        }
        EventBus.$emit(events.SNACKBAR, {
          message: `Send Mail ${this.subjectValue} to Customer by ${this.countryCode}-Domain`,
          type: "success",
        });
      }

      if (!!this.waitForSentSuccess) {
        this.onClose();
      }
    },
    checkTriggerWords(message) {
      if (!this.customerMessage) return false
      const triggerWords = message?.triggerWords?.[this.countryCode];
      const messageText = this.customerMessage.toLowerCase();

      if (!triggerWords || !messageText) return false;

      const isTriggerWordPresent = triggerWords.some(word => {
        return messageText.includes(word.toLowerCase());
      });

      return isTriggerWordPresent;
    },
    async onFileChange(event) {
      if (event.target.files.length) {
        for await (const file of Array.from(event.target.files)) {
          this.attachments.push(file);
        }
      } else {
        this.attachments = null;
      }
    },
  },
};
</script>

<style lang="scss">
.wysiwyg-editor {
  margin-top: 12px;
  button {
    background-color: white;
    opacity: 0.9;
  }
}

.messages-chip-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 6px;
}

.messages-chip--highlight {
  border: 1px solid #beffa1 !important;
}
</style>
