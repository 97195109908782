<template>
  <div>
    <StateDisplay v-if="isLoading" :loading="isLoading" />
    <v-card v-else flat>
      <v-row class="pa-6">
        <v-col v-for="orientation in orientations" :key="orientation" cols="6">
          <MockupSelector
            :label="`Select ${orientation} Mockup`"
            @select="(id) => onSelect(orientation, id)"
            :orientation="orientation"
            :aspect-ratio="null"
            :selected-mockup-id="
          mockupSettings.fallbacks?.[orientation]
        "
          ></MockupSelector>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { availableAspectRatios, availableOrientations } from "@/config";
import MockupSelector from "@/components/Settings/Mockups/MockupSelector";
import {doc, getDoc, setDoc} from "firebase/firestore";
import { db } from "../../../../fb";
import StateDisplay from "@/components/StateDisplay";

export default {
  name: "FallbackMockups",
  components: { StateDisplay, MockupSelector },
  data() {
    return {
      isLoading: false,
      orientations: availableOrientations,
      aspectRatios: availableAspectRatios,
      mockupSettings: {
        fallbacks: {}
      }
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.isLoading = true;
      const mockupSettingsDoc = doc(db, "settings", "mockups");
      const mockupSettingsRef = await getDoc(mockupSettingsDoc);
      this.mockupSettings = mockupSettingsRef.data();
      this.isLoading = false;
    },
    async onSelect(orientation, id) {
      const mockupSettingsDoc = doc(db, "settings", "mockups");
      const newMockupSettings = {
        ...this.mockupSettings,
        fallbacks: {
          ...this.mockupSettings.fallbacks,
          [orientation]: id
        }
      }
      await setDoc(mockupSettingsDoc, newMockupSettings, { merge: true })
      this.mockupSettings = {...newMockupSettings}
    }
  },
};
</script>

<style scoped>
.title-fallback {
  font-size: 16px;
}
</style>
