<template>
  <div id="app">
    <v-app>
      <div v-if="currentUser && ready && !isStatisticsSubdomain">
        <MainHeader />
        <MainAccountSidebar />
        <MainNavigation />
        <v-main :class="navigationExpanded ? 'main main-expanded' : 'main'">
          <router-view />
        </v-main>
      </div>
      <LoginView v-else-if="!currentUser" />
      <div class="statistic-view" v-else-if="isStatisticsSubdomain">
        <StatisticsView />
      </div>
      <StateDisplay v-else :loading="true" />
      <AppSnackbars />
      <RouteModals />
    </v-app>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader";
import MainNavigation from "@/components/MainNavigation/MainNavigation";
import { getAuth, onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { app } from "../fb";
import { mapActions, mapGetters, mapMutations } from "vuex";
import LoginView from "@/views/LoginView";
import AppSnackbars from "@/components/AppSnackbars";
import MainAccountSidebar from "@/components/MainAccountSidebar";
import StateDisplay from "@/components/StateDisplay";
import RouteModals from "@/components/RouteModals";
import StatisticsView from "@/views/StatisticsView";


export default {
  components: {
    StatisticsView,
    RouteModals,
    StateDisplay,
    MainAccountSidebar,
    AppSnackbars,
    LoginView,
    MainNavigation,
    MainHeader,
  },
  data() {
    return {
      ready: false,
      dataLoaded: false,
    };
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  async mounted() {
    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await this.listenForUserChanges();
      } else {
        this.setCurrentUser(null);
      }
    });

    onIdTokenChanged(auth, async (data) => {
      if (!this.ready && data?.auth?.currentUser?.uid) {
        this.ready = true;
        await this.loadAllData();
      }
    });
  },
  computed: {
    ...mapGetters(["currentUser", "navigationExpanded"]),
    isStatisticsSubdomain() {
      const currentUrl = window.location.href;
      const mainDomain = 'taskdash.de';
      const subdomain = 'stats.taskdash.de';

      if (currentUrl.includes(mainDomain)) {
        return currentUrl.includes(subdomain);
      }
      return false;
    }
  },
  methods: {
    ...mapActions(["listenForUserChanges", "loadData", "unsubscribeData"]),
    ...mapMutations(["setCurrentUser"]),
    unsubscribe() {
      this.unsubscribeData();
    },
    async loadAllData() {
      // hi
      if (this.isStatisticsSubdomain) return
      await this.loadData()
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin-left: 76px;
  margin-top: 20px;
  margin-right: 20px;
  width: calc(100% - 100px);
}

.main-expanded {
  margin-left: 276px;
}

.statistic-view {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin: 12px;
  width: calc(100% - 24px);

  @include md() {
    margin: 6px;
    width: calc(100% - 12px);
  }
}
</style>
