export const filterByTerm = (task, term) => {
  const searchTerm = term?.toLowerCase();
  return (
    !searchTerm ||
    task?.customerEmail?.toLowerCase().includes(searchTerm) ||
    task?.orderId?.toString().toLowerCase().includes(searchTerm) ||
    task?.orderName?.toLowerCase().includes(searchTerm)
  );
};

const filterByFilter = (task, filter) => {
  switch (filter) {
    case "marked":
      return task.isMarked;
    case "unmarked":
      return !task.isMarked;
    default:
      return true;
  }
};

export const filterShopifyTask = ({ task, term, filter, markedTaskIds }) => {
  return (
    filterByTerm(task, term) && filterByFilter(task, filter, markedTaskIds)
  );
};
