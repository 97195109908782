<template>
  <div class="allOrders">
    <SearchHeader />
    <v-toolbar class="mt-6" dark flat>
      <v-tabs v-model="category" centered>
        <v-tab :value="categories.ALL">All Orders</v-tab>
        <v-tab :value="categories.DELAYED_FULFILL">Delayed Fulfill</v-tab>
        <v-tab :value="categories.DELAYED_SHIPMENT">Delayed Shipment</v-tab>
      </v-tabs>
    </v-toolbar>
    <OrdersTable :orders="orders" :loading="loadingOrders" />
  </div>
</template>

<script>
import SearchHeader from "@/components/AllOrders/components/SearchHeader";
import { mapActions, mapGetters, mapMutations } from "vuex";
import OrdersTable from "@/components/AllOrders/components/OrdersTable";
import { orderFilterCategories } from "@/constants";
export default {
  name: "AllOrders",
  components: { OrdersTable, SearchHeader },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["orders", "loadingOrders", "filterCategory"]),
    category: {
      get() {
        return this.filterCategory;
      },
      set(value) {
        this.setFilterCategory(value);
      },
    },
    categories() {
      return orderFilterCategories;
    },
  },
  mounted() {
    this.setFilterOrderId("");
    this.setFilterEmail("");
    this.setFilterQuickSearch("");
    this.loadAllOrders();
  },
  watch: {
    category() {
      this.loadAllOrders();
    },
  },
  methods: {
    ...mapActions(["loadAllOrders"]),
    ...mapMutations([
      "setFilterCategory",
      "setFilterOrderId",
      "setFilterEmail",
      "setFilterQuickSearch",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.search-error {
  color: #d70000;
}
</style>
