<template>
  <div v-if="showCountdown" class="countdown">
    {{ countdown }}
  </div>
</template>

<script>
export default {
  name: "FulfillCountdown",
  data() {
    return {
      countdown: null,
      showCountdown: true,
    };
  },
  props: {
    fulfillTime: {
      required: true,
    },
  },
  mounted() {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeDifference = currentTime - this.fulfillTime;

      if (timeDifference < 0) {
        clearInterval(interval);
        this.countdown = "00:00";
        this.showCountdown = false;
        return;
      }

      const minutes = Math.floor(2 - (timeDifference / 1000 / 60) % 60);
      const seconds = Math.floor(60 - (timeDifference / 1000) % 60);
      this.countdown = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }, 1000); // Update every second (1000 milliseconds)
  },
};
</script>

<style lang="scss" scoped>
.countdown {
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
}
</style>
