<template>
  <div class="orderTasksContainer">
    <v-progress-circular indeterminate v-if="loading" />
    <v-row style="width: 100%" v-else>
      <v-col :key="index" v-for="(task, index) in tasks" cols="6">
        <TaskDetailsCard v-if="task" :task="task" />
        <v-card class="taskNotPushedCard" v-else>
          <h2>
            Task not Imported <br />
            into TaskDash yet
          </h2>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../fb";
import TaskDetailsCard from "@/components/TaskCards/TaskDetailsCard";

export default {
  name: "OrderTasks",
  components: { TaskDetailsCard },
  data() {
    return {
      loading: false,
      tasks: [],
    };
  },
  props: {
    orderTasks: {
      required: true,
    },
  },
  mounted() {
    this.loadLineItems();
  },
  methods: {
    async loadLineItems() {
      this.loading = true;

      for await (const task of this.orderTasks) {
        const taskDoc = doc(db, `tasks/${task.taskUid}`);
        const taskRef = await getDoc(taskDoc);
        const taskData = taskRef.data();

        this.tasks.push(taskData);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.orderTasksContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
}

.taskNotPushedCard {
  height: 100%;
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
