<template>
  <div>
    <v-tooltip open-delay="200" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="button-hold-animation" :class="{ holding: holding && holdingTime > 0 }">
          <v-btn
            :disabled="disabled"
            :loading="loading"
            small
            v-bind="attrs"
            v-on="on"
            @mousedown="startHold"
            @mouseup="cancelHold"
            @mouseleave="cancelHold"
            @click.prevent="resolve"
            :color="color"
            :outlined="outlined || holding"
          >
            <v-icon>{{ icon }}</v-icon>
          </v-btn>
        </div>
      </template>
      <span>{{ hoverText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "TooltipButton",
  data() {
    return {
      isResolving: false,
      resolved: false,
      reached: false,
      holding: false,
      holdTimer: null,
    };
  },
  props: {
    onClick: {
      require: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    holdingTime: {
      default: 0,
    },
    hoverText: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      default: "primary",
    },
    disabled: {
      default: false,
    },
    loading: {
      default: false,
    },
  },
  watch: {
    reached() {
      if (this.reached) this.onClick();
    },
  },
  methods: {
    startHold() {
      console.log("START");
      this.holding = true;
      this.holdTimer = setTimeout(() => {
        this.reached = this.holdingTime > 0;
      }, this.holdingTime);
    },
    cancelHold() {
      console.log("STOP");
      clearTimeout(this.holdTimer);
      this.reached = false;
      this.holding = false;
    },
    resolve() {
      if (this.holdingTime === 0) this.onClick(); // Action only if button was held for the required duration
    },
  },
};
</script>

<style lang="scss" scoped>
/* Initial state */
.button-hold-animation {
  position: relative;
}

::v-deep .button-hold-animation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0; /* Start from 0 */
  height: 100%;
  background-color: rgba(190, 204, 190, 0.8);
  z-index: 0;
  transition: width 0s linear;
}

/* Animated state */
.holding::before {
  width: 100%;
  transition: width 1s linear;
}
</style>
