<template>
  <div>
    <v-card
        @dragover="showDropZone = true"
      @contextmenu="onRightClick"
      color="primary"
      class="card"
      min-width="200"
    >
      <div
          v-if="showDropZone"
          @click="() => (showDropZone = false)"
          class="d-flex dropzone"
      >
        <v-card
            width="100%"
            @dragleave="showDropZone = false"
            class="card-overlay"
            @drop.prevent="(e) => dropFile(e)"
            @dragenter.prevent
            @dragover.prevent
        >
          <v-icon
              :style="{ 'pointer-events': 'none' }"
              @drop.prevent="(e) => dropFile(e)"
              size="130"
              color="white"
          >
            mdi-select-drag }}
          </v-icon>
        </v-card>
      </div>
      <div class="card-header-function">
        <v-btn
          @click="downloadFiles"
          :color="downloadProgress === 100 ? 'success' : 'primary'"
          dark
          small
          fab
          class="mark-button"
        >
          <v-progress-circular width="3" :value="downloadProgress">
            <v-badge color="green" content="2">
              <v-icon>mdi-download</v-icon>
            </v-badge>
          </v-progress-circular>
        </v-btn>
        <v-btn
          @click="openTask"
          color="primary"
          dark
          rounded
          link
          v-if="isAdmin"
          class="card-header-button"
        >
          {{ task.orderName }}
          <v-icon x-small v-if="isAdmin" class="ml-3">mdi-open-in-new</v-icon>
        </v-btn>
        <FeedbackTooltip v-if="task.feedback && isAdmin" :feedback="task.feedback"/>
        <OrderNoteTooltip v-if="isAdmin" :task="task"/>
        <SupportDialog v-if="isAdmin" :order-id="task.orderId" />
        <TimeIndicator :creation-date="task.createdAt"/>
      </div>
      <v-card-text class="image-container">
        <ExpressLayover :task="task"/>
        <div class="d-flex">
          <v-img
            height="500px"
            position="center"
            :src="task.resultThumbnailUrl"
            :lazy-src="task.resultThumbnailUrl"
            class="image"
            :width="`${100 / task.numberOfSubTasks}%`"
          />
          <v-img
            height="500px"
            position="center"
            :src="task.resultThumbnailUrl2"
            :lazy-src="task.resultThumbnailUrl2"
            class="image"
            :width="`${100 / task.numberOfSubTasks}%`"
          />
        </div>
        <div v-if="!!task.assignedTo" class="task-product-name">
          {{ task.productType.name }}<br/>
          {{ task?.customPrintVariantName || task.printVariantName }}
        </div>
        <div class="file-name-container">
          <span
            :class="{
              'file-name-warning': !fileNameMatches,
            }"
          >{{ task.orderName }}_temp</span
          >
        </div>
        <div
          class="task-original-image task-original-image--1"
          @click="
            openFullSizeImage({
              image: task.image,
              thumb: task.thumb
            })
          "
        >
          <v-img contain :src="task.thumb"/>
        </div>
        <div
          v-if="!task.productType.isPreview"
          class="task-template"
          @click="
            openFullSizeImage({
              image: task.template,
              thumb: task.templateThumb
            })
          "
        >
          <v-img contain :src="task.templateThumb"/>
        </div>
      </v-card-text>
      <div class="revision-reason-container" v-if="task.isRevision">
        Revision: {{ task.revisionReason }}
      </div>
      <v-card-actions class="card-actions">
        <template v-if="!task.assignedTo">
          <v-spacer/>
          <v-btn
            :loading="isAssigning"
            :disabled="isAssigning"
            color="success"
            @click="() => assign()"
          >Assign to me
          </v-btn
          >
          <v-spacer/>
        </template>
        <v-row v-else>
          <v-col cols="12">
            <v-file-input
              :error-messages="
                !fileNameMatches
                  ? 'The uploaded File does not matches with tasks template name. You can still continue if it is the correct file.'
                  : undefined
              "
              v-model="finalTemplateFile"
              @change.native="onFileChange"
              clearable
              dark
              label="File Input (JPG)"
              outlined
              dense
              :hide-details="fileNameMatches"
              :disabled="uploaded"
              accept=".jpg"
            ></v-file-input>
          </v-col>
          <v-col class="pt-0" cols="12">
            <v-btn
              :loading="isUploading"
              class="pt-0"
              width="100%"
              v-if="finalTemplateFile"
              type="file"
              dark
              color="green"
              @click="upload"
              :disabled="uploaded"
            >
              <v-icon>{{ uploaded ? "mdi-check-bold" : "mdi-upload" }}</v-icon>
              {{ uploaded ? "Uploaded" : "Upload Result" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ListMenu :show="showMenu" :x="x" :y="y" :list-items="menuItems"/>
    <PushTaskBackDialog
      v-if="adminTasksAllowed && showPushTaskBackDialog"
      :show="showPushTaskBackDialog"
      :on-close="
        () => {
          showPushTaskBackDialog = false;
        }
      "
      :task-uid="task.uid"
      :status="['DRAW']"
    />
    <v-dialog persistent width="500" v-model="showHelpDialog">
      <v-card dark>
        <v-img :src="require('@/assets/downloadHelp.webp')"/>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="showHelpDialog = false">
            <v-icon>mdi-check</v-icon>
            I Understood
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <OrderMailDialog
      v2
      :wait-for-sent-success="false"
      :show="showMailDialog"
      :on-close="() => (this.showMailDialog = false)"
      :orderId="task.orderId.toString()"
      mail-category="TEMPLATING"
    />
    <ImageDialog
      :show="showSelectedTaskImage"
      :on-close="() => (showSelectedTaskImage = false)"
      :image="selectedFullscreenImage.img"
      :image-thumb="selectedFullscreenImage.thumb"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {downloadImageFromUrl, openOrderModal} from "@/utils";
import PushTaskBackDialog from "@/components/PushTaskBackDialog";
import {getAuth} from "firebase/auth";
import OrderNoteTooltip from "@/components/OrderNoteTooltip";
import ListMenu from "@/components/ListMenu";
import TimeIndicator from "@/components/TaskCards/components/TimeIndicator";
import OrderMailDialog from "@/components/OrderMailDialog";
import {mailTemplateTypes} from "@/content/mails";
import FeedbackTooltip from "@/components/FeedbackTooltip";
import ImageDialog from "@/components/ImageDialog";
import {permissions} from "@/constants";
import SupportDialog from "@/components/Support/SupportTooltip";
import ExpressLayover from "@/components/TaskCards/ExpressLayover";

export default {
  name: "TemplatingTaskCard",
  components: {
    ExpressLayover,
    SupportDialog,
    ImageDialog,
    FeedbackTooltip,
    OrderMailDialog,
    TimeIndicator,
    OrderNoteTooltip,
    ListMenu,
    PushTaskBackDialog,
  },
  data() {
    return {
      isUploading: false,
      uploaded: false,
      finalTemplateFile: null,
      templatePreviewUrl: "",
      showHelpDialog: false,
      showPushTaskBackDialog: false,
      showMenu: false,
      showSelectedTaskImage: '',
      selectedFullscreenImage: '',
      x: 0,
      y: 0,
      downloadProgressTemplate: 0,
      downloadProgressResultImage: 0,
      downloadProgress: 1,
      isAssigning: false,
      showMailDialog: false,
      orientation: null,
      showDropZone: false,
    };
  },
  props: {
    task: {
      required: true,
    },
  },
  created() {
  },
  computed: {
    ...mapGetters(["showDownloadHelp", "isAdmin", "userPermissions", "allUsers", "currentUser"]),
    adminTasksAllowed() {
      return this.isAdmin || this.userPermissions.includes(permissions.MANAGE_TASKS)
    },
    isIos() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    fileNameMatches() {
      if (!this.finalTemplateFile) return true;
      const expectedFileName = `${this.task.orderName}_temp`;
      return expectedFileName === this.finalTemplateFile.name?.split(".")[0];
    },
    mailTemplateType() {
      return mailTemplateTypes.TEMPLATING;
    },
    assigneeList() {
      const allUsersAllowedToDraw = this.allUsers.filter(
        (user) =>
          user.permissions.includes(permissions.TASK_TEMPLATING) &&
          user.uid !== this.task.assignedTo
      );
      if (this.task.assignedTo !== this.currentUser.uid) {
        allUsersAllowedToDraw.push(this.currentUser);
      }
      return allUsersAllowedToDraw.map((user) => {
        return {
          label: user.username,
          onClick: () => this.assign(user.uid),
        };
      });
    },
    menuItems() {
      return [
        {
          icon: "mdi-download",
          onClick: () => this.downloadResultImage(1),
          label: this.task.image2
            ? "Download Result Image Left"
            : "Download Result Image",
        },
        {
          icon: "mdi-account-arrow-right",
          onClick: () => {},
          label: "Reassign Task",
          subListItems: this.assigneeList,
          hide: !this.adminTasksAllowed,
        },
        {
          icon: "mdi-download",
          onClick: () => this.downloadResultImage(2),
          label: "Download Result Image Right",
          hide: !this.task.image2,
        },
        {
          icon: "mdi-download",
          onClick: this.downloadTemplate,
          label: "Download Template",
        },
        {
          icon: "mdi-email-outline",
          onClick: () => {
            this.showMailDialog = true;
          },
          label: "Send Mail",
          hide: !this.adminTasksAllowed,
        },
        {
          icon: "mdi-email-search-outline",
          onClick: this.searchOrderInMails,
          label: "Search For Response",
          hide: !this.adminTasksAllowed,
        },
        {
          icon: "mdi-skip-backward",
          onClick: () => {
            this.showPushTaskBackDialog = true;
          },
          label: "Push Back",
          hide: !this.adminTasksAllowed,
        },
      ];
    },
  },
  watch: {
    downloadProgressTemplate() {
      if (this.downloadProgressResultImage) {
        this.downloadProgress =
          (this.downloadProgressResultImage / this.task.numberOfSubTasks +
            this.downloadProgressTemplate) /
          2;
      } else {
        this.downloadProgress = this.downloadProgressTemplate;
      }
    },
    downloadProgressResultImage() {
      if (this.downloadProgressTemplate) {
        this.downloadProgress =
          (this.downloadProgressResultImage / this.task.numberOfSubTasks +
            this.downloadProgressTemplate) /
          2;
      } else {
        this.downloadProgress = this.downloadProgressResultImage;
      }
    },
  },
  methods: {
    ...mapActions(["uploadFinalTemplate", "assignTask"]),
    ...mapMutations(["disableShowDownloadHelp"]),
    openTask() {
      if (this.isAdmin) {
        openOrderModal(this.task.orderId)
      }
    },
    downloadFiles(e) {
      if (this.isIos) {
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.$nextTick(() => {
          this.showMenu = true;
        });
      } else if (this.showDownloadHelp) {
        this.showHelpDialog = true;
        this.disableShowDownloadHelp();
      } else {
        this.downloadTemplate();
        this.downloadResultImage(1);
        if (this.task.result2) {
          this.downloadResultImage(2);
        }
      }
    },
    downloadResultImage(imageIndex) {
      const imageToDownload =
        imageIndex === 1 ? this.task.result : this.task.result2;
      const fileAppendix =
        this.task.numberOfSubTasks > 1
          ? imageIndex === 1
            ? "_LEFT"
            : "_RIGHT"
          : "";
      const fileName = `${this.task.orderName}${
        fileAppendix ? "_" + fileAppendix : ""
      }`;
      downloadImageFromUrl(
        imageToDownload,
        fileName,
        (progress) => (this.downloadProgressResultImage = progress)
      );
    },
    downloadTemplate() {
      const adjustedTemplateURL = this.task.template.replace('teeinblue-cdn.nyc3.digitaloceanspaces.com', 'cdn.teeinblue.com');
      downloadImageFromUrl(
        adjustedTemplateURL,
        this.task.orderName + "_temp",
        (progress) => (this.downloadProgressTemplate = progress),
        "jpg"
      );
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.processFile(file);
      this.finalTemplateFile = file; // Update the finalTemplateFile with the selected file
    },
    dropFile(e) {
      e.preventDefault(); // Prevent default behavior (e.g., open file in the browser)
      this.showDropZone = false;

      // Access the dropped files
      const file = e.dataTransfer.files[0];

      if (file) {
        this.processFile(file);
        this.finalTemplateFile = file; // Update the finalTemplateFile with the dropped file
      }
    },
    processFile(file) {
      // Validate that the file is an image (if needed)
      if (file.type !== 'image/jpeg') {
        alert("Please drop a .jpg file");
        return;
      }

      // Create a URL for the dropped file to preview it
      this.templatePreviewUrl = URL.createObjectURL(file);

      // Create an image to check dimensions
      const img = new Image();
      img.onload = () => {
        this.orientation = img.width > img.height ? "landscape" : "portrait";
      };
      img.src = this.templatePreviewUrl;
    },
    upload() {
      this.isUploading = true;
      this.uploadFinalTemplate({
        task: this.task,
        templateFile: this.finalTemplateFile,
        orientation: this.orientation,
        callBack: () => {
          this.isUploading = false;
          this.uploaded = true;
        },
      });
    },
    onRightClick(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    assign(uid) {
      this.isAssigning = true;
      const auth = getAuth();
      this.assignTask({
        task: this.task,
        assignToUid: uid || auth.currentUser.uid,
        callBack: () => {
          this.showMenu = false;
          this.isAssigning = false;
        },
      });
    },
    searchOrderInMails() {
      window.open(
        `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${
          this.task.orderName.split("#")[1]
        }`,
        "_blank"
      );
    },
    openFullSizeImage({image, thumb}) {
      this.selectedFullscreenImage = {
        img: image,
        thumb: thumb,
      };
      this.showSelectedTaskImage = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  overflow: hidden;

  .v-card__text {
    padding: 0;
  }
}

.card-header-function {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .v-btn {
    margin: 12px;
  }
}

.card-actions {
  min-height: 124px;
  z-index: 100;
}

.image-container {
  position: relative;
}

.image {
  z-index: 1;
}

.file-name-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;

  span {
    padding: 6px;
    color: white;
    background: black;
    border-radius: 3px;
  }

  .file-name-warning {
    color: black;
    background: orange;
  }
}

.revision-reason-container {
  position: absolute;
  bottom: 124px;
  left: 0;
  display: flex;
  z-index: 2;
  background: #a20000;
  color: #ffffff;
  padding: 5px 10px;
}

.task-product-name {
  padding: 6px;
  border-radius: 5px;
  position: absolute;
  background: rgba(0, 0, 0, 0.87);
  max-width: 50%;
  color: white;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.task-original-image {
  width: 50px;
  height: fit-content;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  z-index: 2;
  transform-origin: bottom left;
  transform: scale(1);
  transition: all 0.5s;

  &--1 {
    left: 0;
    z-index: 3;
  }

  &--2 {
    left: 90px;
  }

  &:hover {
    transform: scale(6);
  }
}

.task-template {
  width: 80px;
  height: fit-content;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  transform-origin: bottom right;
  transform: scale(1);
  transition: all 0.5s;

  &:hover {
    transform: scale(4);
  }
}

.card-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(115, 115, 115, 0.7);
}

</style>
