<template>
  <MainDialog v-if="show" :show="show" :onClose="onClose">
    <v-card>
      <v-card-title>Task Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              height="56"
              v-model="selectedPrintVariant"
              :items="printVariants"
              dense
              item-text="name"
              item-value="key"
              outlined
              hide-details
              label="Print Variant"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="taskCopy.productType.isExpress"
              :label="`Express Produktion`"
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="orderCopy.isExpress"
              :label="`Express Versand (Gesamte Order)`"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              height="56"
              v-model="taskCopy.editors.IMPORTED"
              :items="userNameIdMap"
              dense
              item-text="username"
              item-value="uid"
              outlined
              label="Imported By"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              height="56"
              v-model="taskCopy.editors.DRAW"
              :items="userNameIdMap"
              dense
              item-text="username"
              item-value="uid"
              outlined
              label="Drawed By"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              height="56"
              v-model="taskCopy.editors.TEMPLATING"
              :items="userNameIdMap"
              dense
              item-text="username"
              item-value="uid"
              outlined
              label="Templated By"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              height="56"
              v-model="taskCopy.assignedTo"
              :items="userNameIdMap"
              dense
              item-text="username"
              item-value="uid"
              outlined
              label="Assigned To"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              height="56"
              v-model="taskCopy.status"
              :items="allTaskStatus"
              dense
              outlined
              label="Task Status"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              height="56"
              v-model="taskCopy.isRevision"
              :items="['DRAW', 'TEMPLATING']"
              dense
              outlined
              label="Is in Revision"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" v-if="taskCopy.isRevision">
            <v-textarea
              outlined
              hide-details
              label="Revision Reason"
              v-model="taskCopy.revisionReason"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              v-model="taskCopy.orderNote"
              label="Order Note"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              hide-details
              label="Original File 1"
              v-model="taskCopy.image"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              :loading="isUploading === 'image'"
              @click="handleFileImport('image', 'thumb')"
              height="56px"
              width="100%"
            >Upload
            </v-btn>
          </v-col>
          <template v-if="task.image2">
            <v-col cols="4">
              <v-text-field
                outlined
                hide-details
                label="Original File 2"
                v-model="taskCopy.image2"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                :loading="isUploading === 'image2'"
                @click="handleFileImport('image2', 'image2Thumb')"
                height="56px"
                width="100%"
              >Upload
              </v-btn>
            </v-col>
          </template>
          <v-col cols="4">
            <v-text-field
              outlined
              hide-details
              label="Result File 1"
              v-model="taskCopy.result"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              :loading="isUploading === 'result'"
              @click="handleFileImport('result', 'resultThumbnailUrl')"
              height="56px"
              width="100%"
            >Upload
            </v-btn>
          </v-col>
          <template v-if="task.image2">
            <v-col cols="4">
              <v-text-field
                outlined
                hide-details
                label="Result File 2"
                v-model="taskCopy.result2"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                :loading="isUploading === 'result2'"
                @click="handleFileImport('result2', 'resultThumbnailUrl2')"
                height="56px"
                width="100%"
              >Upload
              </v-btn>
            </v-col>
          </template>
          <v-col cols="10">
            <v-text-field
              outlined
              hide-details
              label="Template File"
              v-model="taskCopy.template"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              :loading="isUploading === 'template'"
              @click="handleFileImport('template', 'templateThumb')"
              height="56px"
              width="100%"
            >Upload
            </v-btn>
          </v-col>
          <v-col cols="10">
            <v-text-field
              outlined
              hide-details
              label="Template Result File"
              v-model="taskCopy.finalTemplate"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              :loading="isUploading === 'finalTemplate'"
              @click="
                handleFileImport('finalTemplate', 'finalTemplateThumbnailUrl')
              "
              height="56px"
              width="100%"
            >Upload
            </v-btn>
          </v-col>
        </v-row>
        <input
          ref="uploader"
          style="display: none"
          type="file"
          @change="onFileChanged"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onClose">Cancel</v-btn>
        <v-spacer/>
        <v-btn @click="saveChanges" :disabled="!hasDifferences" color="success"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import {mapActions, mapGetters} from "vuex";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../../../../fb";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {currentMonthYear, getImageUrl} from "@/utils";
import {taskStatus} from "@/constants";
import {v4 as uuidv4} from "uuid";
import {EventBus, events} from "../../../../event-bus";

const _ = require("lodash");
export default {
  name: "TaskDetailsDialog",
  components: {MainDialog},
  data() {
    return {
      taskCopy: {},
      order: {},
      orderCopy: {},
      originalTask: {},
      isSaving: false,
      selectedPrintVariant: this.task.printVariant,
      selectedFieldName: null,
      selectedFieldNameThumb: null,
      selectedFile: null,
      isUploading: null,
      availablePrintVariants: [],
    };
  },
  props: {
    show: {
      default: false,
    },
    onClose: {
      require: true,
    },
    task: {
      required: true,
    },
  },
  computed: {
    ...mapGetters(["userNameIdMap", "printVariants", "loadingPrintVariants"]),
    hasDifferences() {
      return !_.isEqual(this.originalTask, this.taskCopy) || !_.isEqual(this.order, this.orderCopy);
    },
    allTaskStatus() {
      return Object.keys(taskStatus);
    },
    selectedPrintVariantName() {
      return this.printVariants.find(variant => variant.key === this.selectedPrintVariant).name
    }
  },
  watch: {
    selectedPrintVariant() {
      this.taskCopy = {
        ...this.taskCopy,
        customPrintVariant: this.selectedPrintVariant,
        customPrintVariantName: this.selectedPrintVariantName
      }
    }
  },
  created() {
    this.loadPrintVariants()
    this.loadOrder()
    const preparedTask = {
      ...this.task,
      isRevision: this.task.isRevision || false,
      revisionReason: this.task.revisionReason || "",
    };
    this.originalTask = _.cloneDeep(preparedTask);
    this.taskCopy = _.cloneDeep(preparedTask);
  },
  methods: {
    ...mapActions(["loadPrintVariants"]),
    getUserName(uid) {
      const user = this.userNameIdMap.find((user) => (user.uid = uid));
      return user.username;
    },
    async loadOrder() {
      const orderDoc = doc(db, "orders", this.task.orderId);
      const orderRef = await getDoc(orderDoc);
      this.order = orderRef.data();
      this.orderCopy = orderRef.data();
    },
    async saveChanges() {
      this.isSaving = true;
      await setDoc(
        doc(db, `tasks`, this.task.uid),
        {...this.taskCopy, resultThumbnailUrl: this.taskCopy.result},
        {
          merge: true,
        }
      );

      if (this.orderCopy.isExpress !== this.order.isExpress) {
        await setDoc(
          doc(db, `orders`, this.task.orderId),
          {
            isExpress: this.orderCopy.isExpress
          },
          {
            merge: true,
          }
        );
      }
      this.isSaving = false;
      this.originalTask = this.taskCopy;
    },
    async onFileChanged(e) {
      const id = uuidv4();
      this.isUploading = this.selectedFieldName;
      const storage = getStorage();

      // 1. Extract the actual file type from the uploaded file
      let uploadedFileType = e.target.files[0].type.split("/")[1];

      if (e.target.files[0].type.split("/")[1] === 'jpeg') {
        uploadedFileType = 'jpg'
      }

      const fileType =
        this.selectedFieldName === "finalTemplate" ? "jpg" : uploadedFileType; // All templates going to MyPoster needs to be jpg


      // 2. Check if the uploaded file type matches the expected file type
      if (!!fileType && uploadedFileType !== fileType) {
        EventBus.$emit(events.SNACKBAR, {
          message: "Templates must have JPG format",
          type: "error",
        });
        this.isUploading = null;
        return; // Stop further processing
      }

      const storageRef = ref(
        storage,
        `/customTaskImages/${currentMonthYear}/${this.task.uid}_${id}.${fileType}`
      );
      const uploadResponse = await uploadBytes(storageRef, e.target.files[0]);
      this.taskCopy[this.isUploading] = await getDownloadURL(
        uploadResponse.ref
      );
      if (this.selectedFieldNameThumb) {
        this.taskCopy[this.selectedFieldNameThumb] = getImageUrl(
          `customTaskImages/${currentMonthYear}/thumbnails/${this.task.uid}_${id}_500x500.${fileType}`
        );
      }
      this.isUploading = null;
    },
    handleFileImport(fieldName, fieldNameThumb) {
      this.selectedFieldName = fieldName;
      this.selectedFieldNameThumb = fieldNameThumb;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          setTimeout(() => {
            this.selectedFieldName = null;
          }, 500);
        },
        {once: true}
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
  },
};
</script>

<style scoped></style>
