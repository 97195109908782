import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../fb";

const defaultState = {
  manualFulfillments: [],
  loadingManualFulfillments: false,
  unsubManualFulfillments: () => {},
};

const getters = {
  manualFulfillments: (state) => state.manualFulfillments,
  loadingManualFulfillments: (state) => state.loadingManualFulfillments,
};

const actions = {
  async loadManualFulfillments({ commit }) {
    commit("setLoadingManualFulfillments", true);
    commit("setManualFulfillments", []);

    const ordersCollection = collection(db, "manualFulfillments");

    state.unsubOrdersWithError = onSnapshot(ordersCollection, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const fulfillmentData = change.doc.data();

        if (change.type === "added") {
          commit("addManualFulfillment", {
            fulfillment: fulfillmentData,
          });
        } else if (change.type === "modified") {
          commit("updateManualFulfillment", {
            fulfillment: fulfillmentData,
          });
        } else if (change.type === "removed") {
          commit("deleteManualFulfillment", {
            fulfillment: fulfillmentData,
          });
        }
      });
    });

    commit("setLoadingOrders", false);
  },
  unsubManualFulfillments({ state }) {
    state.unsubManualFulfillments();
  },
};

const mutations = {
  setLoadingManualFulfillments: (state, bool) =>
    (state.loadingManualFulfillments = bool),
  setManualFulfillments: (state, fulfillments) =>
    (state.manualFulfillments = fulfillments),
  addManualFulfillment(state, { fulfillment }) {
    // Add the task to the state
    state.manualFulfillments.push(fulfillment);
  },
  updateManualFulfillment(state, { fulfillment }) {
    // Find the index of the task in the state
    const index = state.manualFulfillments.findIndex(
      (o) => o.uid === fulfillment.uid
    );
    // Update the task in the state
    if (index !== -1) {
      state.manualFulfillments.splice(index, 1, fulfillment);
    }
  },
  deleteManualFulfillment(state, { fulfillment }) {
    // Find the index of the task in the state
    const index = state.manualFulfillments.findIndex(
      (o) => o.uid === fulfillment.uid
    );
    // Remove the task from the state
    if (index !== -1) {
      state.manualFulfillments.splice(index, 1);
    }
  },
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).manualFulfillments
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
