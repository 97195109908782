<template>
  <div class="line-chart">
    <canvas ref="lineChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { getDecimalNumberString } from "@/utils"; // Import the utility function

export default {
  name: "BarChart",
  props: {
    data: Object, // Accepts an object containing labels and datasets for the chart
    unit: {
      default: "",
    },
    aspectRatio: {
      default: 3
    },
    hideLegend: {
      default: false
    },
    fraction: {
      default: 2,
    },
    chartHeight: {
      type: String,
    },
    chartType: {
      default: "bar"
    },
  },
  mounted() {
    this.renderLineChart();
  },
  methods: {
    renderLineChart() {
      const ctx = this.$refs.lineChart.getContext("2d");

      const chart = new Chart(ctx, {
        type: this.chartType,
        data: this.data,
        options: {
          responsive: true,
          aspectRatio: this.aspectRatio,
          plugins: {
            legend: {
              display: !this.hideLegend, // Hide the legend for a cleaner display
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.label || ""; // Get the original label
                  const value = context.formattedValue || 0; // Get the numeric value
                  return `${label}: ${getDecimalNumberString(value, this.fraction)} ${this.unit}`; // Add unit to the value
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.line-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .title {
    font-size: 24px;
    text-transform: uppercase;
  }
}

/* Additional styles similar to your bar chart can be added here */
</style>
