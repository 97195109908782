import { countryCodes } from "@/constants";
import dayjs from "dayjs";

const convertToDayMonth = (date, countryCode) => {
  return date ? dayjs(date).format("DD.MM") : "????";
};

const getLatestShipment = (order) => {
  console.log(
    order?.fulfillment?.[Object.keys(order.fulfillment)[0]]?.[0]
      ?.fulfillItems?.[0]?.shipment
  );
  const shipment =
    order?.fulfillment?.[Object.keys(order.fulfillment)[0]]?.[0]
      ?.fulfillItems?.[0]?.shipment;

  return {
    carrier: shipment?.carrier,
    trackingCode: shipment?.trackingCode,
    trackingurl: shipment?.trackingUrl,
  };
};

export const getMailDamagedPackage = (order, defaultLanguage) => {
  return [
    {
      id: "sendNew",
      label: "Send New",
      message: resendPackage(order, defaultLanguage),
    },
  ];
};

const resendPackage = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer?.firstName},</p>
           <p>Es tut uns leid, dass dich deines Bestellung beschädigt erreicht hat. Wir haben deine Bestellung soeben erneut
           in die Produktion gegeben.</p>
           <p>Ich entschuldige mich für die Umstände.</p>
           <p>Liebe Grüße,<br />Anna<p>
        `,
        subject: `Wir haben deine Bestellung erneut verschickt | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer?.firstName},</p>
           <p>Lamentamos que tu pedido haya llegado dañado. Acabamos de volver a enviar tu pedido a producción.</p>
           <p>Nos disculpamos por las molestias.</p>
           <p>Saludos cordiales,<br />Lisa
           <p>
    `,
        subject: `Hemos reenviado tu pedido | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Witaj ${order.customer?.firstName},</p>
           <p>Przykro nam, że Twoje zamówienie dotarło uszkodzone. Właśnie ponownie wysłaliśmy Twoje zamówienie do produkcji.</p>
           <p>Przepraszamy za wszelkie niedogodności.</p>
           <p>Z poważaniem,<br />Lisa
           <p>
    `,
        subject: `Wysłaliśmy ponownie Twoje zamówienie | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const orderInProduction = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer.firstName},</p>
           <p>Deine Bestellung ${order.orderName} befindet sich in Produktion und geht voraussichtlich in 1-2 Tagen in den Versand. Sobald wir deine Sendung an unseren Versanddienstleister übergeben haben, erhältst du eine E-Mail mit den Sendungsdetails :)</p>
           <p>Viele Grüße,<br />Anna</p>
        `,
        subject: `Deine Bestellung ist in Produktion | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer.firstName},</p>
           <p>Tu pedido ${order.orderName} está en producción y se espera que se envíe en 1-2 días. Una vez que entreguemos tu envío a nuestro proveedor de envíos, recibirás un correo electrónico con los detalles del envío :)</p>
           <p>Saludos cordiales,<br />Anna</p>
        `,
        subject: `Tu pedido está en producción | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Cześć ${order.customer.firstName},</p>
           <p>Twoje zamówienie ${order.orderName} jest w trakcie produkcji i spodziewamy się, że zostanie wysłane w ciągu 1-2 dni. Po przekazaniu przesyłki naszej firmie kurierskiej, otrzymasz e-mail z danymi przesyłki :)</p>
           <p>Pozdrowienia,<br />Anna</p>
        `,
        subject: `Twoje zamówienie jest w trakcie produkcji | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const orderShipped = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  const { carrier, trackingurl, trackingCode } = getLatestShipment(order);

  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer.firstName},</p>
           <p>Wir haben deine Sendung bereits an ${carrier} übergeben und du kannst die Sendung <a href="${trackingurl}">hier</a> verfolgen (Sendungsnummer: ${trackingCode})</p>
           <p>Ich gehe davon aus, dass die Zustellung in den kommenden 1-3 Tagen erfolgen wird :)</p>
           <p>Viele Grüße,<br />Anna</p>
        `,
        subject: `Deine Bestellung wurde versandt | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer.firstName},</p>
           <p>Ya hemos entregado tu envío a ${carrier} y puedes rastrearlo <a href="${trackingurl}">aquí</a> (número de envío: ${trackingCode})</p>
           <p>Supongo que la entrega se realizará en los próximos 1-3 días :)</p>
           <p>Saludos cordiales,<br />Lisa</p>
        `,
        subject: `Tu pedido ha sido enviado | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Cześć ${order.customer.firstName},</p>
           <p>Twoja przesyłka została już przekazana ${carrier} i możesz śledzić przesyłkę <a href="${trackingurl}">tutaj</a> (numer przesyłki: ${trackingCode})</p>
           <p>Przewidywana dostawa w ciągu najbliższych 1-3 dni :)</p>
           <p>Pozdrowienia,<br />Lisa</p>
        `,
        subject: `Twoje zamówienie zostało wysłane | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const orderDelayed = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer.firstName},</p>
           <p>Entschuldige bitte vielmals, dass du deine Bestellung noch nicht erhalten hast :-/. Leider kam es kurzfristig zu einer Lieferverzögerung bei unseren Leinwänden/Postern, weshalb sich die Produktion verzögert hat.</p>
           <p>Dein Wandbild befindet sich aber nun in Produktion und sobald dieses an unseren Versanddienstleister übergeben worden ist, erhältst du eine E-Mail mit den Sendungsdetails.</p>
           <p>Für die Unannehmlichkeiten möchte ich mich nochmals entschuldigen,</p>
           <p>Viele Grüße,<br />Anna</p>
        `,
        subject: `Entschuldigung für die Lieferverzögerung | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer.firstName},</p>
           <p>Disculpa mucho que todavía no hayas recibido tu pedido :-/. Lamentablemente, hubo un retraso en la entrega de nuestros lienzos/pósteres, lo que ha retrasado la producción.</p>
           <p>Tu cuadro está ahora en producción y tan pronto como se entregue a nuestro servicio de mensajería, recibirás un correo electrónico con los detalles del envío.</p>
           <p>Por las molestias causadas, me gustaría disculparme de nuevo,</p>
           <p>Saludos cordiales,<br />Lisa</p>
        `,
        subject: `Disculpa por el retraso en la entrega | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Cześć ${order.customer.firstName},</p>
           <p>Przepraszam bardzo, że jeszcze nie otrzymałeś swojego zamówienia :-/. Niestety, doszło do krótkoterminowego opóźnienia w dostawie naszych płócien/plakatów, co spowodowało opóźnienie w produkcji.</p>
           <p>Twoje obraz znajduje się już w produkcji, a gdy zostanie przekazany naszej firmie kurierskiej, otrzymasz e-mail z danymi przesyłki.</p>
           <p>Za wszelkie niedogodności chciałbym się jeszcze raz serdecznie przepraszać,</p>
           <p>Pozdrowienia,<br />Lisa</p>
        `,
        subject: `Przepraszam za opóźnienie w dostawie | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const orderUnsatisfied = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  const fulfillDate = convertToDayMonth(order.fulfillDate);
  const resultsSent = convertToDayMonth(order.resultsSentDate);

  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer.firstName},</p>
           <p>Es tut mir leid, dass du nicht zufrieden mit deiner Bestellung bist :-/. Ich habe gerade nachgeschaut und wir haben dir am ${resultsSent} dein Portrait als Bilddatei per E-Mail zukommen lassen.</p>
           <p>Da wir hier kein Feedback erhalten haben, ist das Poster dann am ${fulfillDate} in Produktion gegangen und nun bei dir eingetroffen.</p>
           <p>Leider ist ein Widerruf nicht möglich, da es sich bei deinem Wandbild um ein personalisierbares Produkt handelt, welches vom Widerruf ausgeschlossen ist. Dies ist bei personalisierbaren Produkten so üblich, da diese natürlich nicht weiterverkauft werden können.</p>
           <p>Ich möchte dir aber etwas entgegenkommen und kann dir 10€ zurückerstatten. Ich hoffe sehr, dass wir so eine Lösung finden und du dann vielleicht doch noch Gefallen an deinem Wandbild finden kannst 🙏</p>
           <p>Ich freue mich auf deine Rückmeldung,</p>
           <p>Viele Grüße,<br />Anna</p>
        `,
        subject: `Entschuldigung für deine Unzufriedenheit | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer.firstName},</p>
           <p>Lamento que no estés satisfecho con tu pedido :-/. Acabo de verificar y te enviamos tu retrato como archivo de imagen por correo electrónico el ${resultsSent}.</p>
           <p>Como no hemos recibido comentarios, el póster se puso en producción el ${fulfillDate} y ahora ha llegado a ti.</p>
           <p>Desafortunadamente, no es posible devolver el pedido, ya que tu cuadro es un producto personalizado que no está sujeto a devolución. Esto es común en productos personalizados, ya que no pueden ser revendidos.</p>
           <p>Sin embargo, me gustaría ofrecerte un reembolso de 10€ como gesto de buena voluntad. Espero que esta solución sea satisfactoria y puedas encontrar algún gusto en tu cuadro 🙏</p>
           <p>Espero tu respuesta,</p>
           <p>Saludos cordiales,<br />Lisa</p>
        `,
        subject: `Disculpas por la insatisfacción con el pedido | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Cześć ${order.customer.firstName},</p>
           <p>Przepraszam, że nie jesteś zadowolony z zamówienia :-/. Sprawdziłem właśnie i wysłaliśmy Ci Twoje portrety jako pliki obrazów pocztą elektroniczną ${resultsSent}.</p>
           <p>Ponieważ nie otrzymaliśmy tutaj żadnej informacji zwrotnej, plakat został wysłany do produkcji ${fulfillDate} i teraz dotarł do Ciebie.</p>
           <p>Niestety, nie ma możliwości zwrotu zamówienia, ponieważ Twój obraz jest personalizowanym produktem, który nie podlega zwrotowi. Jest to standardowe postępowanie w przypadku produktów personalizowanych, ponieważ nie mogą one być dalej sprzedawane.</p>
           <p>Chciałbym jednak zaoferować Ci zwrot 10€ jako gest dobrej woli. Mam nadzieję, że ta propozycja będzie satysfakcjonująca i że znajdziesz jakąś przyjemność w swoim obrazie 🙏</p>
           <p>Czekam na Twoją odpowiedź,</p>
           <p>Pozdrowienia,<br />Lisa</p>
        `,
        subject: `Przepraszam za niezadowolenie z zamówienia | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const orderCancellationRequested = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer.firstName},</p>
           <p>Schade, dass du deine Bestellung stornieren möchtest :-/. Magst du mir vielleicht kurz den Grund nennen? :) Sollte dir nämlich ein Fehler unterlaufen sein beim Bestellvorgang (z.B. falsches Foto hochgeladen, falsche Personalisierung), könnten wir noch Anpassungen vornehmen 🙏</p>
           <p>Ich freue mich auf deine Rückmeldung,</p>
           <p>Viele Grüße,<br />Anna</p>
        `,
        subject: `Anfrage zur Stornierung der Bestellung | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer.firstName},</p>
           <p>Qué lástima que desees cancelar tu pedido :-/. ¿Podrías decirme por favor la razón? :) Si cometiste algún error durante el proceso de pedido (por ejemplo, cargaste una foto incorrecta, personalización incorrecta), aún podríamos realizar ajustes 🙏</p>
           <p>Espero tu respuesta,</p>
           <p>Saludos cordiales,<br />Lisa</p>
        `,
        subject: `Solicitud de cancelación del pedido | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Cześć ${order.customer.firstName},</p>
           <p>Szkoda, że chcesz anulować swoje zamówienie :-/. Czy mógłbyś podać mi krótko powód? :) Jeśli popełniłeś błąd podczas składania zamówienia (np. przesłałeś niewłaściwe zdjęcie, błędna personalizacja), nadal możemy dokonać dostosowań 🙏</p>
           <p>Czekam na Twoją odpowiedź,</p>
           <p>Pozdrowienia,<br />Lisa</p>
        `,
        subject: `Prośba o anulowanie zamówienia | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const damagedOrderReceived = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer.firstName},</p>
           <p>Es tut mir leid, dass dich dein Wandbild mit einem Schaden erreicht hat :-/. Ich gehe davon aus, dass der Schaden auf dem Versandweg entstanden sein muss, da wir eine sehr aufmerksame Fehlerkontrolle haben.</p>
           <p>Kannst du mir ein Foto der Beschädigung zukommen lassen? Dies könnte ich dann an unseren Versanddienstleister weiterleiten und wir würden dir im Anschluss natürlich ein weiteres Wandbild zukommen lassen, welches dann hoffentlich unbeschadet bei dir eintrifft.</p>
           <p>Ich freue mich auf deine Rückmeldung,</p>
           <p>Viele Grüße,<br />Anna</p>
        `,
        subject: `Beschädigung deines Wandbildes | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer.firstName},</p>
           <p>Lamento que tu cuadro haya llegado con daños :-/. Supongo que los daños deben haber ocurrido durante el transporte, ya que tenemos un control de calidad muy cuidadoso.</p>
           <p>¿Podrías enviarme una foto del daño? Esto me ayudaría a transmitir la información a nuestro proveedor de envíos y, posteriormente, te enviaríamos otro cuadro, con suerte llegará sin daños.</p>
           <p>Espero tu respuesta,</p>
           <p>Saludos cordiales,<br />Lisa</p>
        `,
        subject: `Daño en tu cuadro | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Cześć ${order.customer.firstName},</p>
           <p>Przepraszam, że Twój obraz dotarł z uszkodzeniem :-/. Zakładam, że uszkodzenie musiało nastąpić w trakcie transportu, ponieważ mamy bardzo dokładną kontrolę jakości.</p>
           <p>Czy mógłbyś przesłać mi zdjęcie uszkodzenia? Pomogłoby to przekazać informację naszemu dostawcy, a następnie wysłalibyśmy Ci kolejny obraz, który mam nadzieję dotrze do Ciebie nietknięty.</p>
           <p>Czekam na Twoją odpowiedź,</p>
           <p>Pozdrowienia,<br />Lisa</p>
        `,
        subject: `Uszkodzenie Twojego obrazu | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const thankYouFeedback = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer.firstName},</p>
           <p>Ganz lieben Dank für dein Feedback :) Es freut mich sehr zu hören, dass du so zufrieden mit unserer Arbeit bist ❤.</p>
           <p>Viele Grüße und danke nochmal für deine liebe Nachricht,<br />Anna</p>
        `,
        subject: `Danke für dein Feedback | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer.firstName},</p>
           <p>¡Muchas gracias por tu opinión! :) Me alegra mucho saber que estás tan satisfecho con nuestro trabajo ❤.</p>
           <p>Un cordial saludo y gracias de nuevo por tu amable mensaje,<br />Anna</p>
        `,
        subject: `Gracias por tu opinión | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Cześć ${order.customer.firstName},</p>
           <p>Dziękuję Ci bardzo za Twoją opinię :) Bardzo mi miło słyszeć, że jesteś tak zadowolony z naszej pracy ❤.</p>
           <p>Pozdrawiam serdecznie i dziękuję jeszcze raz za Twoją miłą wiadomość,<br /> Anna</p>
        `,
        subject: `Dziękujemy za Twoją opinię | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const missingTextTemp = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  let text = "";
  let subject = "";

  if (order.finalTemplateThumbnailUrl) {
    switch (countryCode) {
      case countryCodes.DE:
        text += `<p>Du kannst eine Vorschau deines gesamten Drucks unter folgendem Link finden: <a href="${order.finalTemplateThumbnailUrl}">${order.finalTemplateThumbnailUrl}</a></p>`;
        break;
      case countryCodes.ES:
        text += `<p>Puedes encontrar una vista previa de tu impresión completa en el siguiente enlace: <a href="${order.finalTemplateThumbnailUrl}">${order.finalTemplateThumbnailUrl}</a></p>`;
        break;
      case countryCodes.PL:
        text += `<p>Podgląd całego Twojego wydruku możesz znaleźć pod poniższym linkiem: <a href="${order.finalTemplateThumbnailUrl}">${order.finalTemplateThumbnailUrl}</a></p>`;
        break;
      default:
        text += `<p>You can find a preview of your entire print at the following link: <a href="${order.finalTemplateThumbnailUrl}">${order.finalTemplateThumbnailUrl}</a></p>`;
    }
  }

  switch (order.countryCode) {
    case countryCodes.DE:
      text = `
        <p>Hallo ${order.customer.firstName},</p>
        <p>Wir versenden die Bilddatei immer mit transparentem Hintergrund und ohne Personalisierung. Auf dem Druck wird die von dir hinterlegte Beschriftung und der ausgewählte Hintergrund aber natürlich berücksichtigt :)</p>
        <p>${text}</p>
        <p>Liebe Grüße,<br />Anna</p>
      `;
      subject = `Vorschau deines Drucks | Artquarell.com ${order.orderName}`;
      break;
    case countryCodes.ES:
      text = `
        <p>Hola ${order.customer.firstName},</p>
        <p>Siempre enviamos el archivo de imagen con fondo transparente y sin personalización. Sin embargo, en el Druck se tendrá en cuenta la inscripción que hayas proporcionado y el fondo seleccionado :)</p>
        <p>${text}</p>
        <p>Saludos cordiales,<br />Lisa</p>
      `;
      subject = `Vista previa de tu impresión | Artcuarela.es ${order.orderName}`;
      break;
    case countryCodes.PL:
      text = `
        <p>Cześć ${order.customer.firstName},</p>
        <p>Zawsze wysyłamy plik obrazu z przezroczystym tłem i bez personalizacji. Jednak na wydruku zostanie uwzględniony przez Ciebie podany napis oraz wybrany tło :)</p>
        <p>${text}</p>
        <p>Pozdrawiam serdecznie,<br />Lisa</p>
      `;
      subject = `Podgląd Twojego wydruku | Artkwarela.pl ${order.orderName}`;
      break;
  }

  return {
    text,
    subject,
  };
};

const deliveryWish = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  const fulfillDate = convertToDayMonth(order.fulfillDate);
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
           <p>Hallo ${order.customer.firstName},</p>
           <p>Wir haben deinen Druck am ${fulfillDate} in Produktion gegeben und ich gehe davon aus, dass der Versand in den nächsten zwei Tagen erfolgen wird. Eine Zustellung bis zum ${order.deliveryDate} wird jedoch leider nicht mehr möglich sein :-/. Allerdings geben wir unsere Versandzeit auch mit bis zu 10 Werktagen an, ich bitte dich daher um Verständnis und hoffe sehr, dass du dem Beschenkten auch nachträglich noch eine Freude machen kannst.</p>
           <p>Liebe Grüße,<br />Anna</p>
        `,
        subject: `Dein gewünschtes Lieferdatum | Artquarell.com ${order.orderName}`,
      };
    case countryCodes.ES:
      return {
        text: `
           <p>Hola ${order.customer.firstName},</p>
           <p>Hemos enviado tu impresión el ${fulfillDate} a producción y espero que el envío se realice en los próximos dos días. Sin embargo, la entrega para el ${order.deliveryDate} ya no será posible :-/. No obstante, nuestro tiempo de envío también se indica con hasta 10 días laborables, así que te pido comprensión y espero que aún puedas alegrar al destinatario más tarde.</p>
           <p>Saludos cordiales,<br />Anna</p>
        `,
        subject: `Fecha de entrega deseada | Artcuarela.es ${order.orderName}`,
      };
    case countryCodes.PL:
      return {
        text: `
           <p>Cześć ${order.customer.firstName},</p>
           <p>Zlecone przez Ciebie drukowanie ${fulfillDate} zostało przekazane do produkcji i zakładam, że wysyłka nastąpi w ciągu najbliższych dwóch dni. Niestety dostawa do ${order.deliveryDate} nie będzie już możliwa :-/. Niemniej jednak, nasz czas dostawy jest również podawany do 10 dni roboczych, dlatego proszę o wyrozumiałość i mam nadzieję, że nadal uda Ci się ucieszyć obdarowanego.</p>
           <p>Pozdrawiam serdecznie,<br />Anna</p>
        `,
        subject: `Żądana data dostawy | Artkwarela.pl ${order.orderName}`,
      };
  }
};

const cancelTooLate = (order, defaultLanguage) => {
  const countryCode = defaultLanguage || order.countryCode;
  switch (countryCode) {
    case countryCodes.DE:
      return {
        text: `
            <p>Hallo ${order.customer.firstName},</p>
            <p>zuerst möchte ich mich aufrichtig entschuldigen, dass du deine Bestellung noch nicht erhalten hast. 😔 Aufgrund einer unerwartet hohen Nachfrage kam es leider zu einer Verzögerung in unserer Produktion, weshalb sich die Fertigstellung der Leinwände verzögert hat. Deine Leinwand ist mittlerweile produziert und versandfertig, sodass eine Stornierung nicht mehr möglich ist. Ich verstehe, dass dies frustrierend sein kann, besonders wenn ein Geschenk betroffen ist.</p>
            <p>Ich möchte auch darauf hinweisen, dass wir auf unserer Produktseite eine Versanddauer von bis zu 10 Werktagen angeben. Wir bitten dich daher um ein wenig Geduld und Verständnis in dieser Situation.</p>
            <p>Ich hoffe sehr, dass die Leinwand, auch wenn sie später ankommt, dem Beschenkten viel Freude bereiten wird und entschuldige mich nochmals für die entstandenen Unannehmlichkeiten.</p>
            <p>Viele Grüße,<br/>Anna</p>
        `,
        subject: `Deine Anfrage zur Stornierung | Artquarell.com ${order.orderName}`,
      };
  }
};

export const getSupportMails = (order, defaultLanguage) => {
  return [
    {
      id: "orderInProduction",
      label: "In Production",
      triggerWords: {
        DE: ["wo", "bleibt", "immer noch nicht", "wie lange", "wann"],
        ES: [
          "dónde",
          "donde",
          "se queda",
          "todavía no",
          "cuánto tiempo",
          "cuándo",
          "cuando",
        ],
        PL: ["gdzie", "zostaje", "jeszcze nie", "jak długo", "kiedy"],
      },
      message: orderInProduction(order, defaultLanguage),
    },
    {
      id: "orderShipped",
      label: "Already Shipped",
      triggerWords: {
        DE: ["wo", "bleibt", "immer noch nicht", "wie lange", "wann"],
        ES: [
          "dónde",
          "donde",
          "se queda",
          "todavía no",
          "cuánto tiempo",
          "cuándo",
          "cuando",
        ],
        PL: ["gdzie", "zostaje", "jeszcze nie", "jak długo", "kiedy"],
      },
      message: orderShipped(order, defaultLanguage),
    },
    {
      id: "orderDelayed",
      label: "Delay",
      triggerWords: {
        DE: ["wo", "bleibt", "immer noch nicht", "wie lange", "wann"],
        ES: [
          "dónde",
          "donde",
          "se queda",
          "todavía no",
          "cuánto tiempo",
          "cuándo",
          "cuando",
        ],
        PL: ["gdzie", "zostaje", "jeszcze nie", "jak długo", "kiedy"],
      },
      message: orderDelayed(order, defaultLanguage),
    },
    {
      id: "orderUnsatisfied",
      label: "Unsatisfied",
      triggerWords: {
        DE: [
          "unzufrieden",
          "überhaupt nicht",
          "beschädigt",
          "gefällt",
          "überhaupt nicht",
          "garnicht",
          "geld zurück",
          "stornieren",
        ],
        ES: [
          "insatisfecho",
          "nada",
          "dañado",
          "gusta",
          "nada",
          "nada",
          "devolución",
          "cancelar",
        ],
        PL: [
          "niezadowolony",
          "wcale",
          "uszkodzony",
          "podoba",
          "wcale",
          "zwrot pieniędzy",
          "anuluj",
        ],
      },
      message: orderUnsatisfied(order, defaultLanguage),
    },
    {
      id: "cancelRequest",
      label: "Cancel Request",
      triggerWords: {
        DE: ["stornieren", "abbrechen", "widerrufen"],
        ES: ["cancelar", "anular", "revocar"],
        PL: ["anulować", "odwołać", "cofnąć"],
        default: ["cancel", "abort", "revoke"],
      },
      message: orderCancellationRequested(order, defaultLanguage),
    },
    {
      id: "damagedOrder",
      label: "Damaged Order",
      triggerWords: {
        DE: ["beschädigt", "kaputt", "defekt"],
        ES: ["dañado", "roto", "defectuoso"],
        PL: ["uszkodzony", "zepsuty", "wadliwy"],
        default: ["damaged", "broken", "defective"],
      },
      message: damagedOrderReceived(order, defaultLanguage),
    },
    {
      id: "thankYouFeedback",
      label: "Thank You",
      triggerWords: {
        DE: ["super", "schön", "wundervoll", "toll"],
        ES: ["super", "hermoso", "maravilloso", "genial"],
        PL: ["super", "piękny", "wspaniały", "świetny"],
      },
      message: thankYouFeedback(order, defaultLanguage),
    },
    {
      id: "missingTextTemp",
      label: "Missing Text Temp",
      triggerWords: {
        DE: ["fehlt", "leer", "unvollständig"],
        ES: ["falta", "vacío", "incompleto"],
        PL: ["brakuje", "puste", "niekompletne"],
      },
      message: missingTextTemp(order, defaultLanguage),
    },
    {
      id: "deliveryWish",
      label: "Delivery Wish",
      triggerWords: {
        DE: ["wunsch", "liefern", "früher"],
        ES: ["deseo", "entregar", "antes"],
        PL: ["życzenie", "dostarczyć", "wcześniej"],
      },
      message: deliveryWish(order, defaultLanguage),
    },
    {
      id: "sendNew",
      label: "Send New",
      triggerWords: {
        DE: [
          "erneut",
          "nachsenden",
          "ersatz",
          "nicht erhalten",
          "kam nicht an",
        ],
        ES: ["otra vez", "reenviar", "sustitución", "no recibido", "no llegó"],
        PL: [
          "ponownie",
          "ponownie wysłać",
          "wymiana",
          "nie otrzymano",
          "nie dotarł",
        ],
      },
      message: resendPackage(order, defaultLanguage),
    },
    {
      id: "cancelTooLate",
      label: "Cancel Too Late",
      triggerWords: {
        DE: ["zu spät", "stornieren", "rücktritt", "geschenk"],
        ES: ["demasiado tarde", "cancelar", "cancelación", "regalo"],
        PL: ["za późno", "anulować", "anulowanie", "prezent"],
      },
      message: cancelTooLate(order, defaultLanguage),
    },
  ];
};
