<template>
  <v-card>
    <v-card-title class="flex justify-space-between">
      Product Types
      <v-btn class="mb-5" @click="showProductTypeDialog = true" color="success"
      >Add Product Type
      </v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="loadingProductTypes"
        :headers="headers"
        :items="productTypes"
        class="elevation-1"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.resultsInTask="{ item }">
          {{ item.resultsInTask }}
        </template>
        <template v-slot:item.isDigital="{ item }">
          {{ item.isDigital }}
        </template>
        <template v-slot:item.useTeeinblue="{ item }">
          {{ item.useTeeinblue }}
        </template>
        <template v-slot:item.imagePropName="{ item }">
          {{ Object.keys(item.imagePropName).join(", ") }}
        </template>
        #<template v-slot:item.costs="{ item }">
        {{ item.costs }}€
      </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="() => editProductType(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <AddProductTypeDialog
      :show="!!showProductTypeDialog"
      :on-close="
        () => {
          showProductTypeDialog = null;
        }
      "
      :product-type="productTypeToEdit"
    />
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AddProductTypeDialog from "@/components/Settings/Products/ProductTypes/ProductTypeDialog";

export default {
  name: "ProductTypes",
  components: {AddProductTypeDialog},
  data() {
    return {
      showProductTypeDialog: null,
      productTypeToEdit: null,
      headers: [
        {text: "Product Type", value: "name"},
        {text: "Results In Task", value: "resultsInTask"},
        {text: "Digital", value: "isDigital"},
        {text: "Use Teeinblue", value: "useTeeinblue"},
        {text: "Available Countries", value: "imagePropName"},
        {text: "Costs Est", value: "costs"},
        {text: "", value: "actions"},
      ],
    };
  },
  computed: {
    ...mapGetters(["productTypes", "loadingProductTypes"]),
  },
  mounted() {
    this.loadProductTypes();
  },
  methods: {
    ...mapActions(["loadProductTypes"]),
    editProductType(productType) {
      this.productTypeToEdit = productType;
      this.showProductTypeDialog = true;
    },
  },
};
</script>

<style scoped></style>
