import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import shopifyTasks from "@/store/modules/shopifyTasks";
import tasks from "@/store/modules/tasks";
import app from "@/store/modules/app";
import user from "@/store/modules/user";
import users from "@/store/modules/users";
import settings from "@/store/modules/settings";
import orders from "@/store/modules/orders";
import manualFulfillments from "@/store/modules/manualFulfillments";
import statistics from "@/store/modules/statistics";
import support from "@/store/modules/support";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "taskDash",
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: {
    app,
    shopifyTasks,
    tasks,
    user,
    users,
    settings,
    orders,
    manualFulfillments,
    statistics,
    support,
  },
  plugins: [vuexPersist.plugin],
});
