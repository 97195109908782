<template>
  <v-navigation-drawer right v-model="isExpanded" fixed temporary>
    <v-list-item>
      <v-list-item-avatar color="primary">
        <v-icon color="white">mdi-account</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ currentUser.username }}</v-list-item-title>
        <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-cash-multiple</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Earnings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="bottom-list">
        <v-btn width="100%" color="error" @click="logout">
          <v-icon class="mr-3">mdi-logout</v-icon>
          Logout
        </v-btn>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getAuth, signOut } from "firebase/auth";

export default {
  name: "MainAccountSidebar",
  data() {
    return {
      drawer: null,
      items: [
        { title: "Home", icon: "mdi-view-dashboard" },
        { title: "About", icon: "mdi-forum" },
      ],
    };
  },
  computed: {
    ...mapGetters(["accountSidebarExpanded", "currentUser"]),
    isExpanded: {
      get() {
        return this.accountSidebarExpanded;
      },
      set(value) {
        this.setAccountSidebarExpanded(value);
      },
    },
  },
  methods: {
    ...mapMutations(["setAccountSidebarExpanded"]),
    logout() {
      const auth = getAuth();
      signOut(auth);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-list {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: 8px;
}
</style>
