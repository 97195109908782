import Vue from "vue";
import VueRouter from "vue-router";
import {
  dbCollection,
  orderStatus,
  permissions,
  taskStatus,
} from "@/constants";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../fb";
import store from "@/store";
import ImportTasks from "../views/ImportTasks.vue";
import DrawTasks from "@/views/DrawTasks";
import TemplatingTasks from "@/views/TemplatingTasks";
import AdminView from "@/views/SettingsView";
import AdminUsers from "@/components/Settings/Users/AdminUsers";
import AllTasks from "@/views/AllTasks";
import ReviewTasks from "@/views/ReviewTasks";
import AdminProducts from "@/components/Settings/Products/AdminProducts";
import FulfillOrders from "@/views/FulfillOrders";
import OrderView from "@/views/OrderView";
import ManualFulfillmentView from "@/views/ManualFulfillmentView";
import AllRatingsView from "@/views/AllRatingsView";
import AdminStatistics from "@/components/Settings/Statistics/AdminStatistics";
import StatisticsView from "@/views/StatisticsView";
import AllOrdersView from "@/views/AllOrdersView";
import SupportView from "@/views/SupportView";
import MockupSettings from "@/components/Settings/Mockups/MockupSettings";

// import Sandbox from "@/views/Sandbox";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [],
});

const allMainRoutes = [
  {
    path: "/importTasks",
    name: "importTasks",
    component: ImportTasks,
    meta: {
      requiredPermission: [permissions.TASK_IMPORTING],
      icon: "mdi-import",
      title: "Import Tasks",
      counterStatus: "ShopifyOrders",
    },
  },
  {
    path: "/drawTasks",
    name: "drawTasks",
    component: DrawTasks,
    meta: {
      requiredPermission: [permissions.TASK_DRAWING],
      icon: "mdi-draw",
      title: "Draw Tasks",
      counterStatus: taskStatus.DRAW,
    },
  },
  {
    path: "/templatingTasks",
    name: "templatingTasks",
    component: TemplatingTasks,
    meta: {
      requiredPermission: [permissions.TASK_TEMPLATING],
      icon: "mdi-file-document-outline",
      title: "Templating Tasks",
      counterStatus: taskStatus.TEMPLATING,
    },
  },
  {
    path: "/reviewTasks",
    name: "reviewTasks",
    component: ReviewTasks,
    meta: {
      requiredPermission: [
        permissions.MANAGER,
        permissions.ADMIN,
        permissions.TASK_REVIEW,
      ],
      icon: "mdi-eye-outline",
      title: "Review Tasks",
      counterStatus: taskStatus.REVIEW,
    },
  },
  {
    path: "/fulfillOrders",
    name: "fulfillOrders",
    component: FulfillOrders,
    meta: {
      requiredPermission: [permissions.ADMIN, permissions.FULFILL_ORDERS],
      icon: "mdi-package-variant-closed",
      title: "Fulfill Orders",
      counterStatus: orderStatus.READY_FOR_FULFILL,
    },
  },
  {
    path: "/manualFulfill",
    name: "manualFulfill",
    component: ManualFulfillmentView,
    meta: {
      requiredPermission: [permissions.ADMIN],
      icon: "mdi-printer",
      title: "Manual Fulfill",
      counterStatus: "MANUALFULFILLMETS",
    },
  },
  {
    path: "/allRatings",
    name: "allRatings",
    component: AllRatingsView,
    meta: {
      requiredPermission: [
        permissions.TASK_DRAWING,
        permissions.MANAGE_RATINGS,
      ],
      icon: "mdi-star-box-multiple",
      title: "Latest Ratings",
      navShowBottom: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
    meta: {
      requiredPermission: [permissions.MANAGE_TASKS, permissions.ADMIN],
      icon: "mdi-headset",
      title: "Support Tickets",
      navShowBottom: true,
      counterStatus: "SUPPORTCOUNTER",
    },
  },
  {
    path: "/statistics",
    name: "statistics",
    component: StatisticsView,
    meta: {
      requiredPermission: [permissions.ADMIN, permissions.STATISTICS],
      icon: "mdi-finance",
      title: "Statistic",
      navShowBottom: true,
    },
  },
  {
    path: "/allTasks",
    name: "allTasks",
    component: AllTasks,
    meta: {
      requiredPermission: [permissions.ADMIN, permissions.MANAGE_TASKS],
      icon: "mdi-history",
      title: "All Tasks",
      navShowBottom: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: AllOrdersView,
    meta: {
      requiredPermission: [permissions.ADMIN, permissions.MANAGE_ORDERS],
      icon: "mdi-inbox",
      title: "All Orders",
      navShowBottom: true,
    },
  },
  {
    path: "/order/:orderUid",
    name: "orders",
    component: OrderView,
    meta: {
      requiredPermission: [permissions.ADMIN, permissions.MANAGE_ORDERS],
    },
  },
  // {
  //   path: "/sandbox",
  //   name: "sandbox",
  //   component: Sandbox,
  //   meta: {
  //     requiredPermission: [permissions.ADMIN, permissions.READ_ALL_USERS],
  //     icon: "mdi-timer-sand-full",
  //     title: "Sandbox",
  //   },
  // },
  {
    path: "/manager",
    name: "manager",
    component: AdminView,
    children: [
      {
        path: "/",
        component: AdminUsers,
      },
      {
        path: "product-settings",
        component: AdminProducts,
      },
      {
        path: "statistics",
        component: AdminStatistics,
      },
      {
        path: "mockup-settings",
        component: MockupSettings,
      },
    ],
    meta: {
      requiredPermission: [
        permissions.MANAGER,
        permissions.ADMIN,
        permissions.SETTINGS,
      ],
      icon: "mdi-cog",
      title: "Manager Panel",
      navShowBottom: true,
    },
  },
];

const auth = getAuth();
onAuthStateChanged(auth, async (user) => {
  if (user) {
    const userRef = doc(db, dbCollection.USERS, user.uid);
    const userDoc = await getDoc(userRef);
    const currentPermissions = userDoc.data().permissions;

    if (currentPermissions.includes(permissions.TASK_IMPORTING)) {
      router.addRoute({
        path: "/",
        name: "importTasks",
        component: ImportTasks,
        meta: {
          requiredPermission: [permissions.TASK_IMPORTING],
          icon: "mdi-import",
          title: "Import Tasks",
          counterStatus: "ShopifyOrders",
        },
      });
    } else if (currentPermissions.includes(permissions.TASK_DRAWING)) {
      router.addRoute({
        path: "/",
        name: "drawTasks",
        component: DrawTasks,
        meta: {
          requiredPermission: [permissions.TASK_DRAWING],
          icon: "mdi-draw",
          title: "Draw Tasks",
          counterStatus: taskStatus.DRAW,
        },
      });
    } else if (currentPermissions.includes(permissions.TASK_TEMPLATING)) {
      router.addRoute({
        path: "/",
        name: "templatingTasks",
        component: TemplatingTasks,
        meta: {
          requiredPermission: [permissions.TASK_TEMPLATING],
          icon: "mdi-file-document-outline",
          title: "Templating Tasks",
          counterStatus: taskStatus.TEMPLATING,
        },
      });
    }

    allMainRoutes.forEach((route) => {
      if (
        route.name !== router.getRoutes()[0]?.name &&
        (currentPermissions.some((permission) =>
          route.meta.requiredPermission.includes(permission)
        ) ||
          !route.meta.requiredPermission)
      ) {
        router.addRoute(route);
      }
    });
    const allRoutes = router.getRoutes();
    store.commit(
      "setNavigationItems",
      allRoutes
        .filter((route) => route.meta?.icon)
        .map((route) => {
          return {
            name: route?.name,
            path: route?.path || "/",
            meta: {
              ...route.meta,
            },
          };
        })
    );
  }
});

export default router;
