<template>
  <div>
    <v-row>
      <v-col
        :key="fulfillment.orderId"
        :cols="getCols(fulfillment.lineItems.length)"
        v-for="fulfillment in manualFulfillments"
      >
        <ManualFulfillmentCard :fulfillment="fulfillment" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ManualFulfillmentCard from "@/components/ManualFulfillment/ManualFulfillmentCard";

export default {
  name: "ManualFulfillment",
  components: { ManualFulfillmentCard },
  computed: {
    ...mapGetters(["manualFulfillments", "loadingManualFulfillments"]),
  },
  methods: {
    getCols(numberLineItems) {
      const cols = 4 * numberLineItems;
      return numberLineItems > 12 ? 12 : cols;
    },
  },
};
</script>

<style scoped></style>
