<template>
  <div>
    <v-snackbar
      v-for="snackbar in snackbars"
      :key="snackbar.id"
      shaped
      :color="snackbar.color"
      v-model="snackbar.show"
      :timeout="10000"
      :style="{ bottom: `${baseOffset + snackbars.indexOf(snackbar) * 70}px` }"
      @input="handleTimeout(snackbar.id)"
    >
      <span v-html="snackbar.message"></span>
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeSnackbar(snackbar.id)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { EventBus } from "../../event-bus";

export default {
  name: "AppSnackbars",
  data() {
    return {
      snackbars: [],
      baseOffset: 20,
    };
  },
  created() {
    EventBus.$on("SNACKBAR", ({ message, type }) => {
      const color = type || "success";
      this.snackbars.push({
        id: Date.now(), // Unique ID based on the current timestamp
        message,
        color,
        show: true,
      });
    });
  },
  methods: {
    closeSnackbar(id) {
      const index = this.snackbars.findIndex((s) => s.id === id);
      if (index !== -1) {
        this.snackbars.splice(index, 1);
      }
    },
    handleTimeout(id) {
      this.closeSnackbar(id);
    },
  },
};
</script>

<style scoped></style>
