import { doc, getDoc } from "firebase/firestore";
import { db } from "../../fb";

export const getManagerOfUser = async (userUid) => {
  return new Promise((resolve) => {
    const assigneeRef = doc(db, "users", userUid);
    getDoc(assigneeRef).then((userSnap) => {
      const userData = userSnap.data();
      resolve(userData.manager);
    });
  });
};
