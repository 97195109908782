<template>
  <div>
    <v-tabs height="60" class="fulfillTabs" centered v-model="selectedTab">
      <v-tab href="#to-fulfill">
        To Fulfill
        <v-badge
          v-if="ordersToFulfill.length"
          offset-x="0"
          offset-y="0"
          :content="ordersToFulfill.length"
        ></v-badge>
      </v-tab>
      <v-tab href="#in-fulfill"
        >Currently Fulfilling
        <v-badge
          v-if="ordersInFulfill.length"
          offset-x="0"
          offset-y="0"
          :content="ordersInFulfill.length"
        ></v-badge
      ></v-tab>
      <v-tab href="#errors"
        >Errors
        <v-badge
          v-if="ordersWithError.length"
          offset-x="0"
          offset-y="0"
          :content="ordersWithError.length"
        ></v-badge
      ></v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item value="to-fulfill">
        <OrdersToFulfill />
      </v-tab-item>

      <v-tab-item value="in-fulfill">
        <OrdersInFulfill />
      </v-tab-item>

      <v-tab-item value="errors">
        <OrdersWithError />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import OrdersToFulfill from "@/components/FulfillOrders/components/OrdersToFulfill";
import OrdersInFulfill from "@/components/FulfillOrders/components/OrdersInFulfill";
import { mapGetters } from "vuex";
import OrdersWithError from "@/components/FulfillOrders/components/OrdersWithError";

export default {
  name: "FulfillOrders",
  components: {OrdersWithError, OrdersInFulfill, OrdersToFulfill },
  data() {
    return {
      selectedTab: "to-fulfill",
    };
  },
  computed: {
    ...mapGetters(["ordersToFulfill", "ordersToFulfillManual", "ordersInFulfill", "ordersWithError"]),
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-slide-group__content {
  background: #e0e0e0;
  margin-bottom: 9px;
}
</style>
