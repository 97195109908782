<template>
  <ManualFulfillment />
</template>

<script>
import ManualFulfillment from "@/components/ManualFulfillment/ManualFulfillment";

export default {
  name: "ManualFulfillmentView",
  components: { ManualFulfillment },
};
</script>

<style scoped></style>
