import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from "../../../fb";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  deleteDoc,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import { taskStatus } from "@/constants";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { filterShopifyTask } from "@/store/utils/shopifyTasksUtils";
import axios from "axios";
import {
  convertAndUploadPngUrlToFirebase,
  currentMonthYear,
  getImageUrl,
} from "@/utils";
import { EventBus, events } from "../../../event-bus";

const defaultState = {
  allTasks: {
    AQ: [],
    TM: [],
  },
  searchTerm: "",
  filter: "unmarked",
  shopifyTasksLoading: true,
  shopifyTasksErrorMessage: "",
  taskIdsAlreadyImported: [],
  initial: true,
  markedTaskIds: [],
  selectedAssignee: "",
  selectedShopId: "TM",
  unsubShopifyTasks: () => {},
};

const getters = {
  shopifyTasksTM: (state) =>
    state.allTasks.TM.filter((task) => {
      return filterShopifyTask({
        task: task,
        term: state.searchTerm,
        filter: state.filter,
      });
    }),
  shopifyTasksAQ: (state) =>
    state.allTasks.AQ.filter((task) => {
      return filterShopifyTask({
        task: task,
        term: state.searchTerm,
        filter: state.filter,
      });
    }),
  shopifyTasksLength: (state) =>
    state.allTasks.AQ.length + state.allTasks.TM.length,
  selectedShopId: (state) => state.selectedShopId,
  shopifyTasksLoading: (state) => state.shopifyTasksLoading,
  shopifyTaskFilter: (state) => state.filter,
  selectedAssignee: (state) => state.selectedAssignee,
  unmarkedTaskIdsLength: (state) => {
    let i = 0;
    Object.keys(state.allTasks).forEach((shopId) => {
      i += state.allTasks[shopId].filter((task) => !task.isMarked)?.length || 0;
    });
    return i;
  },
  markedTaskIdsLength: (state) => {
    let i = 0;
    Object.keys(state.allTasks).forEach((shopId) => {
      i += state.allTasks[shopId].filter((task) => task.isMarked)?.length || 0;
    });
    return i;
  },
};

const actions = {
  async unsubscribeShopifyTasks({ state }) {
    state.unsubShopifyTasks();
  },
  async loadTasksFromShopify({ commit, state }) {
    commit("setShopifyTasksLoading", true);
    commit("setAllTasks", { shopId: "AQ", tasks: [] });
    commit("setAllTasks", { shopId: "TM", tasks: [] });
    const shopifyTasksCollection = collection(db, "shopifyTasks");
    const q = query(shopifyTasksCollection, orderBy("createdAt"));

    state.unsubShopifyTasks();
    state.unsubShopifyTasks = onSnapshot(q, (snapshot) => {
      if (!snapshot.docs.length && state.initial) {
        // No documents, initial load is complete, but no data.
        commit("setShopifyTasksLoading", false);
      }
      snapshot.docChanges().forEach((change) => {
        const taskData = {
          ...change.doc.data(),
        };
        if (change.type === "added") {
          commit("addShopifyTask", {
            task: taskData,
            shopId: taskData.shopId,
          });
        } else if (change.type === "modified") {
          commit("updateShopifyTask", {
            task: taskData,
            shopId: taskData.shopId,
          });
        } else if (change.type === "removed") {
          commit("updateShopifyTask", {
            task: {
              ...taskData,
              removed: true,
            },
            shopId: taskData.shopId,
          });
        }

        if (state.initial) {
          // This was the first snapshot, which means initial loading is done.
          commit("setShopifyTasksLoading", false);
          // After the initial load, set isInitialLoad to false so that subsequent changes do not trigger this block
        }
      });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async markTask({}, { uid, autoReopen }) {
    const taskToMarkDoc = doc(db, "shopifyTasks", uid);
    getDoc(taskToMarkDoc).then(async (task) => {
      const taskData = task.data();
      await setDoc(
        taskToMarkDoc,
        {
          isMarked: !taskData.isMarked,
          timestampMarked: !taskData.isMarked
            ? autoReopen
              ? Date.now()
              : null
            : null,
        },
        { merge: true }
      );

      const updateOrderTags = httpsCallable(
        functions,
        "updateShopifyOrderTags"
      );
      await updateOrderTags({
        orderId: taskData.orderId,
        tags: "ON HOLD",
        countryCode: taskData.countryCode,
        shopId: taskData.shopId,
      });
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async editTask({}, { task }) {
    const taskToEditDoc = doc(db, "shopifyTasks", task.uid);
    await setDoc(taskToEditDoc, task);

    EventBus.$emit(events.SNACKBAR, {
      message: `Updated Task ${task.orderName}`,
      type: "success",
    });
  },
  // eslint-disable-next-line no-empty-pattern
  async cancelTask({}, { task }) {
    try {
      setDoc(doc(db, `tasks`, task?.uid.toString()), {
        taskKey: task.taskKey || "",
        uid: task.uid,
        orderId: task.orderId,
        referrer: task.referrer || null,
        lineItemId: task.lineItemId,
        orderName: task.orderName,
        orderNote: task.orderNote,
        productType: task.productType,
        printVariant: task.printVariantId,
        printVariantName: task.printVariantName,
        thumb: task.imgThumb,
        image: task.img,
        img2: task.img2 || "",
        imgThumb2: task.imgThumb2 || "",
        result: "",
        status: taskStatus.CANCELED,
        templateThumb: task.templateThumb || "",
        template: task.template || "",
        editors: {
          IMPORTED: auth.currentUser.uid,
          CLOSED: auth.currentUser.uid,
        },
        createdAt: task.createdAt,
        shopId: task.shopId,
        countryCode: task.countryCode,
      }).then(async () => {
        const orderDoc = doc(db, `orders`, task?.orderId);
        await getDoc(orderDoc).then((orderRes) => {
          const order = orderRes.data();
          setDoc(
            orderDoc,
            {
              tasks: order.tasks.map((orderTask) => {
                if (orderTask.taskUid === task.uid) {
                  return {
                    ...orderTask,
                    cancelled: true,
                  };
                } else {
                  return orderTask;
                }
              }),
            },
            { merge: true }
          ).then(async () => {
            const taskToCancel = doc(db, "shopifyTasks", task.uid);
            await deleteDoc(taskToCancel);
          });
        });
      });
    } catch (e) {
      EventBus.$emit(events.SNACKBAR, {
        message: `Error cancel Task: ${e.message}`,
        type: "error",
      });
    }
  },
  async importTaskFromShopify(
    { state },
    {
      task,
      callBack,
      image,
      image2,
      customImage,
      customTemplate,
      usedUpscaling,
      existentTask,
      selectedAssignee,
      originalImage,
      originalImageThumb,
    }
  ) {
    let customUrl1 = null;
    let customUrl2 = null;
    let customUrl1Thumb = null;
    let customUrl2Thumb = null;
    let customImageFile1 = customImage?.[1] || null;
    let customImageFile2 = customImage?.[2] || null;
    let template = task.template;
    let templateThumb = task.templateThumb;

    // If the image was upscaled wee need to download it and upload it to own server
    if (usedUpscaling?.[1] && !customImage?.[1]) {
      const imageDownloadResponse = await axios.get(image, {
        responseType: "blob",
      });
      customImageFile1 = await imageDownloadResponse.data;
    }

    if (usedUpscaling?.[2] && !customImage?.[2]) {
      const imageDownloadResponse = await axios.get(image2, {
        responseType: "blob",
      });
      customImageFile2 = await imageDownloadResponse.data;
    }

    if (customImageFile1) {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `/customTaskImages/${currentMonthYear}/${task.uid}_1.png`
      );
      const uploadResponse = await uploadBytes(storageRef, customImageFile1);
      customUrl1 = await getDownloadURL(uploadResponse.ref);
      customUrl1Thumb = getImageUrl(
        `customTaskImages/${currentMonthYear}/thumbnails/${task.uid}_1_500x500.png`
      );
    }

    if (customImageFile2) {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `/customTaskImages/${currentMonthYear}/${task.uid}_2.png`
      );
      const uploadResponse = await uploadBytes(storageRef, customImageFile2);
      customUrl2 = await getDownloadURL(uploadResponse.ref);
      customUrl2Thumb = getImageUrl(
        `customTaskImages/${currentMonthYear}/thumbnails/${task.uid}_2_500x500.png`
      );
    }

    if (customTemplate) {
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `/customTaskImages/${currentMonthYear}/${task.uid}_temp.png`
      );
      const uploadResponse = await uploadBytes(storageRef, customTemplate);
      template = await getDownloadURL(uploadResponse.ref);
      templateThumb = getImageUrl(
        `customTaskImages/${currentMonthYear}/thumbnails/${task.uid}_2_500x500.png`
      );
    }

    let finalTemplate = null;
    let finalTemplateThumbnailUrl = null;
    let result = null;
    let resultThumbnailUrl = null;
    let status = taskStatus.DRAW;

    // If does not result in task, we need to set the required fields for finalTemplate and Result
    if (!task.productType.resultsInTask) {
      const getTemplateFromTeeinblue = httpsCallable(
        functions,
        "getTemplateFromTeeinblue"
      );

      const templateRes = await getTemplateFromTeeinblue({
        orderId: task.orderId,
        customizationId: task.teeInBlue.customizationId,
        countryCode: task.countryCode,
        shopId: task.shopId,
      });
      result = templateRes.data;

      try {
        const jpgTemp = await convertAndUploadPngUrlToFirebase(
          templateRes.data
        );
        finalTemplate = jpgTemp;
      } catch (e) {
        throw e;
      }

      finalTemplateThumbnailUrl = task.img;
      resultThumbnailUrl = task.img;
      status = taskStatus.CLOSED;
    }

    if (existentTask) {
      result = existentTask.result;
      resultThumbnailUrl = existentTask.resultThumbnailUrl || "";
      status = taskStatus.TEMPLATING;
    }

    // Creates A DB Entry for task (Excluding personal customer Information)
    await setDoc(doc(db, `tasks`, task?.uid.toString()), {
      taskKey: task.taskKey || "",
      orderKey: task.orderKey || "",
      uid: task.uid,
      orderId: task.orderId,
      referrer: task.referrer || null,
      lineItemId: task.lineItemId,
      orderName: task.orderName,
      orderNote: task.orderNote,
      productType: {
        productName: task.productName,
        ...task.productType,
      },
      printVariant: task.printVariantId,
      printVariantName: task.printVariantName,
      thumb: customUrl1Thumb || task.imgThumb || "",
      image: customUrl1 || image,
      image2: customUrl2 || image2 || "",
      image2Thumb: customUrl2Thumb || task.img2Thumb || "",
      result: result,
      status: status,
      templateThumb: templateThumb,
      template: template,
      finalTemplate: finalTemplate,
      editors: {
        IMPORTED: auth.currentUser.uid,
      },
      createdAt: task.createdAt,
      assignedTo: !!existentTask ? "" : selectedAssignee, // existentTask means it will not be directed template -> no assignee
      countryCode: task.countryCode,
      shopId: task.shopId,
      finalTemplateThumbnailUrl,
      resultThumbnailUrl,
      numberOfSubTasks: task.numberOfSubTasks,
      originalImage: originalImage || "",
      originalImageThumb: originalImage || "",
    });

    const taskToHide = doc(db, "shopifyTasks", task.uid);
    await deleteDoc(taskToHide);
    callBack();
  },
};

const mutations = {
  setAllTasks: (state, { tasks, shopId }) =>
    (state.allTasks = {
      ...state.allTasks,
      [shopId]: tasks,
    }),
  addShopifyTask(state, { shopId, task }) {
    if (task.productType.isExpress) {
      state.allTasks[shopId].unshift(task);
    } else {
      state.allTasks[shopId].push(task);
    }
  },
  updateShopifyTask(state, { shopId, task }) {
    const updatedTasks = state.allTasks[shopId].map((t) =>
      t.uid === task.uid ? task : t
    );

    state.allTasks = {
      ...state.allTasks,
      [shopId]: updatedTasks,
    };
  },
  deleteShopifyTask(state, { shopId, task }) {
    const updatedTasks = state.allTasks[shopId].find((t) => t.uid !== task.uid);

    state.allTasks = {
      ...state.allTasks,
      [shopId]: updatedTasks,
    };
  },
  setShopifyTasksLoading: (state, bool) => {
    state.initial = bool;
    state.shopifyTasksLoading = bool;
  },
  setSearchTerm: (state, searchTerm) => (state.searchTerm = searchTerm),
  setSelectedShopId: (state, selectedShopId) =>
    (state.selectedShopId = selectedShopId),
  setSearchFilter: (state, filter) => (state.filter = filter),
  setSelectedAssignee: (state, user) => (state.selectedAssignee = user),
};

const state = window.sessionStorage["taskDash"]
  ? JSON.parse(window.sessionStorage["taskDash"]).shopifyTasks
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations,
};
