<template>
  <v-dialog v-model="dialog" width="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="activator-btn"
        :class="task.hasNoAlternativesAuto && alternatives.length === 0 ? 'activator-btn--noAlternative' : ''"
        v-bind="attrs"
        v-on="on"
        rounded
        >{{ buttonText }}</v-btn
      >
    </template>

    <v-card>
      <v-card-title> Alternativen </v-card-title>
      <v-card-text class="card-text">
        <v-row v-if="!loading">
          <v-col cols="6" v-for="(url, index) in alternativeUrls" :key="url">
            <v-card class="image-container">
              <v-img
                height="400px"
                class="image-container__img rounded"
                @click="() => openImageDialog(url)"
                :src="url"
                cover
              />
              <v-btn
                class="image-container__btn"
                @click="
                  () => {
                    downloadImage(url, index);
                  }
                "
                fab
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  :loading="selectingAlternative === url"
                  @click="() => selectAlternative(url)"
                  >Use</v-btn
                >
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <StateDisplay v-else :loading="loading" />
      </v-card-text>
      <ImageDialog
        v-if="imageDialog"
        :show="imageDialog"
        :image="selectedImageUrl || ''"
        :onClose="() => (imageDialog = false)"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { downloadImageFromUrl, getDownloadUrlFromPath } from "@/utils";
import StateDisplay from "@/components/StateDisplay";
import ImageDialog from "@/components/ImageDialog";
import { mapActions } from "vuex";

export default {
  name: "ChooseAlternative",
  components: { ImageDialog, StateDisplay },
  data() {
    return {
      dialog: false,
      alternativeUrls: [],
      loading: true,
      imageDialog: false,
      selectedImageUrl: "",
      selectingAlternative: null,
    };
  },
  props: {
    selectedUrl: {
      required: false,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    alternatives() {
      return this.task.alternatives;
    },
    buttonText() {
      return this.alternatives?.length
        ? this.task.hasAlternatives
          ? `${this.alternatives.length} Alternatives`
          : `${this.alternatives.length} Final Alternatives`
        : this.task.hasNoAlternativesAuto ? "No Response!" : "No Alternatives!";
    },
  },
  mounted() {
    if (this.alternatives?.length > 0) {
      this.loadDownloadUrls();
    }
  },
  methods: {
    ...mapActions(["editTask"]),
    async loadDownloadUrls() {
      this.loading = true;
      for await (const path of this.alternatives) {
        let url = null;
        if (path.includes("https://")) {
          url = path;
        } else {
          url = await getDownloadUrlFromPath(path);
        }
        this.alternativeUrls.push(url);
      }
      this.loading = false;
    },
    async downloadImage(url, index) {
      await downloadImageFromUrl(url, `alternative_${index}`, () => {});
    },
    openImageDialog(url) {
      this.selectedImageUrl = url;
      this.imageDialog = true;
    },
    async selectAlternative(imageUrl) {
      this.selectingAlternative = imageUrl;
      await this.editTask({
        task: {
          ...this.task,
          img: imageUrl || null,
          imgThumb: imageUrl || null,
        },
      });
      this.selectingAlternative = null;
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.activator-btn {
  margin: 12px;

  &--noAlternative {
    pointer-events: none;
    color: #ffffff;
    background-color: rgba(215, 0, 0, 0.4) !important;
  }
}

.card-text {
  min-height: 400px;
}

.image-container {
  position: relative;

  &__btn {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;
  }

  &__img {
    z-index: 1;
  }
}
</style>
