<template>
  <StateDisplay class="mt-12" v-if="loading || error" :loading="loading" :error="error" :no-results="ratings.length === 0" />
  <div v-else>
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="6" :key="rating.taskUid" v-for="rating in ratings">
        <RatingCardArtist :rating="rating" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StateDisplay from "@/components/StateDisplay";
import RatingCardArtist from "@/components/AllRatings/RatingCardArtist";
import {collection, getDocs, limit, orderBy, query, where} from "firebase/firestore";

import {mapGetters} from "vuex";
import {db} from "../../../fb";

export default {
  name: "AllRatingsArtist",
  components: {RatingCardArtist, StateDisplay},
  data() {
    return {
      ratings: [],
      loading: true,
      error: false,
      errorMessage: ""
    }
  },
  created() {
    this.load()
  },
  computed:{
    ...mapGetters(["currentUser"])
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const ratingsCollection = collection(db,'ratings')
        const ratingsQuery = query(
          ratingsCollection,
          where("artistId", "==",this.currentUser.uid),
          orderBy("artistId"),
          orderBy("timestamp", "desc"),
          limit(100)
        );
        const ratingsRes = await getDocs(ratingsQuery)
        ratingsRes.docs.forEach(doc => {
          this.ratings.push(doc.data())
        })
      } catch (e) {
        console.log(e)
        this.errorMessage = "There was an Error Loading the Ratings"
        this.error = true
      }
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
