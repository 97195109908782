<template>
  <div>
    <v-card
      @dragover="showDropZone = true"
      @contextmenu="onRightClick"
      color="primary"
      class="card"
      min-width="200"
    >
      <div v-if="imported || task.removed" class="card-overlay">
        <v-icon size="130" color="white">mdi-check-circle</v-icon>
      </div>
      <div
        v-if="resultsInTask && productTypeNotSupportedByAssignee && !unlockCard"
        class="card-overlay d-flex flex-column text-center"
      >
        <v-icon @click="unlockCard = true" size="130" color="white"
          >mdi-lock
        </v-icon>
        <h2 class="white--text">Product Type Not supported by Assignee</h2>
      </div>
      <div
        v-if="showDropZone"
        @click="() => (showDropZone = false)"
        class="d-flex"
      >
        <v-card
          v-for="index in task.numberOfSubTasks || 1"
          :key="index"
          :width="`${100 / task.numberOfSubTasks}%`"
          @dragleave="showDropZone = false"
          class="card-overlay"
          :style="{ left: 100 - 100 / index ? `${100 - 100 / index}%` : 0 }"
          @drop.prevent="(e) => dropFile(e, index)"
          @dragenter.prevent
          @dragover.prevent
        >
          <v-icon
            :style="{ 'pointer-events': 'none' }"
            @drop.prevent="(e) => dropFile(e, index)"
            size="130"
            color="white"
          >
            mdi-select-drag }}
          </v-icon>
          {{ index }}
        </v-card>
      </div>
      <div class="card-header-function">
        <v-btn
          @click="downloadAll"
          color="primary"
          dark
          small
          fab
          class="card-header-button"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <TimeIndicator :creation-date="task.createdAt" />
        <v-btn
            @click="openTask"
            color="primary"
            dark
            rounded
            link
            class="card-header-button"
        >
          {{ task.orderName }}
          <v-icon x-small class="ml-3">mdi-open-in-new</v-icon>
        </v-btn>
        <OrderNoteTooltip
          :task="task"
          :on-save="(props) => saveOrderNote(props)"
        />
        <SupportDialog :order-id="task.orderId"/>
        <v-btn
          :color="task.isMarked ? 'warning' : 'primary'"
          dark
          fab
          small
          class="card-header-button"
          @click="openInvalidMail"
        >
          <v-icon>{{task.isMarked ? 'mdi-alert' : 'mdi-email-alert'}}</v-icon>
        </v-btn>
        <OrderCountIndicator :order-count="task.orderCount" />
        <ChooseAlternative
          :task="task"
          class="card-header-button"
          v-if="task.hasAlternatives || task.hasNoAlternativesAuto || task.alternatives"
        />
      </div>
      <ExpressLayover :isExpress="task.productType?.isExpress"/>
      <v-card-text class="image-container">
        <div class="d-flex">
          <v-img
            height="500px"
            position="center"
            :src="
              this.customImageUrl[1] ||
              task.imgThumb ||
              'https://archive.org/download/no-photo-available/no-photo-available.png'
            "
            :lazy-src="
              this.customImageUrl[1] ||
              task.imgThumb ||
              'https://archive.org/download/no-photo-available/no-photo-available.png'
            "
            @click="openFullSizeImage(1)"
            class="image"
            :width="`${100 / task.numberOfSubTasks}%`"
          />
          <v-img
            v-if="task.img2"
            height="500px"
            position="center"
            :src="
              this.customImageUrl[2] ||
              task.img2Thumb ||
              'https://archive.org/download/no-photo-available/no-photo-available.png'
            "
            @click="openFullSizeImage(2)"
            class="image"
            :width="`${100 / task.numberOfSubTasks}%`"
          />
        </div>
        <div
          :style="{ 'background-color': referrerBackgroundColor }"
          v-if="task.referrer"
          class="task-referrer"
        >
          {{ task.referrer }}
        </div>
        <div class="task-product-name">
          {{ task.productType.name }}<br />
          {{ task?.customPrintVariantName || task.printVariantName }}
        </div>
        <v-lazy :options="{ rootMargin: '1000px' }" v-model="isVisible">
          <div
            v-if="!task.productType.isPreview && template !== 'none'"
            @mouseenter="hoverThumbnail = true"
            @mouseleave="hoverThumbnail = false"
            class="task-template"
          >
            <v-progress-circular
              class="loading-template"
              color="#9f9f9f"
              v-if="
                (isRegeneratingCustomization || !templateThumbnail) &&
                !customizationRegenerated
              "
              size="30"
              indeterminate
            />
            <v-icon v-else-if="customizationRegenerated">mdi-check-bold</v-icon>
            <div>
              <!-- Load the image before dialog is opened -->
              <img
                alt="image preload"
                style="height: 0; width: 0; visibility: hidden"
                :src="task.img"
              />
              <img
                class="task-template-thumbnail"
                v-if="
                  templateThumbnail &&
                  !isRegeneratingCustomization &&
                  !customizationRegenerated
                "
                height="auto"
                width="140px"
                @click="showTemplateDialog = true"
                :src="customTemplateUrl || templateThumbnail"
              />
            </div>
            <div v-if="hoverThumbnail" class="teeinblue-controls">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleTemplateUpload"
                    v-bind="attrs"
                    v-on="on"
                    class="action-icon"
                    >{{ customTemplate ? "mdi-close" : "mdi-upload" }}
                  </v-icon>
                </template>
                <span>{{
                  customTemplate
                    ? "Remove Custom Template"
                    : "Upload Custom Template"
                }}</span>
                <input
                  type="file"
                  ref="fileInputTemplate"
                  style="display: none"
                  @change="onFileChangeTemplate"
                />
              </v-tooltip>
              <v-tooltip v-if="task.productType.useTeeinblue" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="openInTeeInBlue"
                    v-bind="attrs"
                    v-on="on"
                    class="action-icon"
                    >mdi-open-in-new
                  </v-icon>
                </template>
                <span>Open In TeeInBlue</span>
              </v-tooltip>
              <v-tooltip v-if="task.productType.useTeeinblue" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="() => getTemplateFromTeeinblue(true)"
                    v-bind="attrs"
                    v-on="on"
                    class="action-icon"
                    >mdi-reload
                  </v-icon>
                </template>
                <span>Reload illustration</span>
              </v-tooltip>
              <v-tooltip v-if="task.productType.useTeeinblue" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="removePreview"
                    v-bind="attrs"
                    v-on="on"
                    class="action-icon"
                    >mdi-image-remove
                  </v-icon>
                </template>
                <span>Remove Preview</span>
              </v-tooltip>
            </div>
          </div>
        </v-lazy>
        <div v-if="task.productType.isPreview" class="watermark-preview">
          PREVIEW
        </div>
        <div v-if="task.previewExists" class="watermark-preview">EXISTS</div>
      </v-card-text>
      <v-card-actions>
        <div class="import-button-container">
          <v-tooltip color="success" open-delay="200" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="task.orderCount > 1"
                class="mr-3"
                @click="() => (showPreviousTasks = true)"
                small
                outlined
                color="success"
              >
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </template>
            <span>View Previous Tasks</span>
          </v-tooltip>
          <v-btn
            dark
            small
            @click="pushToDrive"
            :loading="isUploading"
            color="green"
            v-if="!resultsInTask && task?.productType?.isDigital === false"
            >Send To Fulfill
          </v-btn>
          <v-btn
            dark
            v-else-if="selectedAssignee"
            color="green"
            :disabled="(!task.img && !customImage) || imported"
            :loading="isUploading"
            small
            @click="pushToDrive"
            >{{
              imported
                ? "Assigned"
                : customImage[1] || customImage[2]
                ? "Assign Custom"
                : "Assign " + selectedAssignee.username
            }}
          </v-btn>
          <v-btn
            dark
            v-else
            color="green"
            :disabled="(!task.img && !customImage) || imported"
            :loading="isUploading"
            small
            @click="pushToDrive"
            >{{
              imported ? "Imported" : customImage ? "Import Custom" : "Import"
            }}
          </v-btn>
        </div>
        <template v-if="upscaleIndicatorText">
          <v-spacer />
          <div class="use-upscale-indicator">
            {{ upscaleIndicatorText }}
          </div>
        </template>
        <v-spacer />
        <v-btn outlined dark small @mouseup="() => handleUpload(1)">
          <span v-if="!customImage[1]">
            <v-icon>mdi-upload</v-icon>
            <span v-if="task.img2">1</span>
          </span>
          <span v-else>...{{ customImage[1].name.slice(-7) }}</span>
          <v-icon v-if="customImage[1]" @click="() => deleteFile(1)"
            >mdi-close
          </v-icon>
          <input
            type="file"
            ref="fileInput1"
            style="display: none"
            @change="onFileChange"
          />
        </v-btn>
        <v-btn
          v-if="task.img2"
          outlined
          dark
          small
          @mouseup="() => handleUpload(2)"
        >
          <span v-if="!customImage[2]">
            <v-icon>mdi-upload</v-icon>
            2
          </span>
          <span v-else>...{{ customImage[2].name.slice(-7) }}</span>
          <v-icon v-if="customImage[2]" @click="() => deleteFile(2)"
            >mdi-close
          </v-icon>
          <input
            type="file"
            ref="fileInput2"
            style="display: none"
            @change="onFileChange"
          />
        </v-btn>
<!--        <v-btn @click="test">TEST</v-btn>-->
      </v-card-actions>
    </v-card>
    <ImageDialog
      v-show="showSelectedTaskImage"
      :show="showSelectedTaskImage"
      :on-close="() => (showSelectedTaskImage = false)"
      :image="getImageToUse(selectedTaskImageIndex) || ''"
    >
      <div class="switch-upscale-container">
        <v-btn
          ripple
          :dark="
            !useUpscaledImageUltra[selectedTaskImageIndex] &&
            !useUpscaledImage[selectedTaskImageIndex]
          "
          v-on:click.stop="
            () => {
              useUpscaledImageUltra[selectedTaskImageIndex] = false;
              useUpscaledImage[selectedTaskImageIndex] = false;
            }
          "
          class="mr-3"
          >Original
        </v-btn>
        <v-btn
          :loading="isUpscaling[selectedTaskImageIndex]"
          :dark="useUpscaledImage[selectedTaskImageIndex]"
          v-on:click.stop="showUpscaledImage"
          class="mr-3"
        >
          <v-icon>mdi-auto-fix</v-icon>
          Upscale
        </v-btn>
        <v-btn
          :loading="isUpscalingUltra[selectedTaskImageIndex]"
          :dark="useUpscaledImageUltra[selectedTaskImageIndex]"
          v-on:click.stop="showUpscaledImageUltra"
          class="mr-3"
        >
          <v-icon>mdi-auto-fix</v-icon>
          Upscale Ultra
        </v-btn>
        <v-btn
          v-if="useUpscaledImageUltra[selectedTaskImageIndex]"
          :loading="isUpscalingUltra[selectedTaskImageIndex]"
          v-on:click.stop="() => upscaleImageUltra(false)"
        >
          <v-icon>mdi-auto-fix</v-icon>
          Ultra +
        </v-btn>
      </div>
    </ImageDialog>
    <ImageDialog
      v-if="showTemplateDialog"
      :show="showTemplateDialog"
      :image="template || ''"
      :image-thumb="templateThumbnail"
      :onClose="() => (showTemplateDialog = false)"
    />
    <PreviousTasksDialog
      v-if="showPreviousTasks"
      :show="!!showPreviousTasks"
      :on-close="() => (showPreviousTasks = false)"
      :task="taskWithTemplates"
    />
    <MailDialog
      v-if="showMailDialog && isInvalidImage"
      :wait-for-sent-success="isInvalidImage"
      :show="showMailDialog"
      :on-close="onMailClose"
      :on-sent="onMailSent"
      :receiver="task.customerEmail"
      :messages="defaultMails"
      :shop-id="task.shopId"
      :country-code="task.countryCode"
    />
    <OrderMailDialog
      v-else-if="showMailDialog"
      :v2="!isInvalidImage"
      :wait-for-sent-success="isInvalidImage"
      :show="showMailDialog"
      :on-close="onMailClose"
      :on-sent="onMailSent"
      :order-id="task.orderId"
      mail-category="IMPORT"
    />
    <ListMenu :show="showMenu" :x="x" :y="y" :list-items="menuListItems" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {downloadImageFromUrl, getRandomColor, getTrackingUrl, openOrderModal} from "@/utils";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../fb";
import { EventBus, events } from "../../../event-bus";
import MailDialog from "@/components/MailDialog";
import OrderNoteTooltip from "@/components/OrderNoteTooltip";
import { openOrderInShopify } from "@/utils";
import ListMenu from "@/components/ListMenu";
import {getArtworkValuesFromTeeInBlue, removePreviewInTeeInBlue} from "@/api/teeinblue";
import TimeIndicator from "@/components/TaskCards/components/TimeIndicator";
import {
  getMailTemplatesImport,
  getMailTemplatesInvalidImage,
} from "@/content/mails";
import OrderCountIndicator from "@/components/TaskCards/components/OrderCountIndicator";
import PreviousTasksDialog from "@/components/TaskCards/components/PreviousTasksDialog";
import ImageDialog from "@/components/ImageDialog";
import {statisticsColors} from "@/config";
import ChooseAlternative from "@/components/TaskCards/components/ChooseAlternative";
import SupportDialog from "@/components/Support/SupportTooltip";
import ExpressLayover from "@/components/TaskCards/ExpressLayover";
import OrderMailDialog from "@/components/OrderMailDialog.vue";

export default {
  name: "ImportTaskCard",
  components: {
    OrderMailDialog,
    ExpressLayover,
    SupportDialog,
    ChooseAlternative,
    ImageDialog,
    PreviousTasksDialog,
    OrderCountIndicator,
    TimeIndicator,
    ListMenu,
    OrderNoteTooltip,
    MailDialog,
  },
  data() {
    return {
      isRegeneratingCustomization: false,
      showPreviousTasks: false,
      customizationRegenerated: false,
      templateThumbnail: null,
      template: null,
      isUploading: false,
      imported: false,
      upscaledImageUltra: {
        1: null,
        2: null,
      },
      upscaledImage: {
        1: null,
        2: null,
      },
      isUpscalingUltra: {
        1: false,
        2: false,
      },
      isUpscaling: {
        1: false,
        2: false,
      },
      useUpscaledImageUltra: {
        1: false,
        2: false,
      },
      useUpscaledImage: {
        1: false,
        2: false,
      },
      customTemplate: null,
      customTemplateUrl: null,
      customImage: {
        1: null,
        2: null,
      },
      customImageUrl: {
        1: null,
        2: null,
      },
      selectedTaskImageIndex: 1,
      showSelectedTaskImage: false,
      showTemplateDialog: false,
      useCustomUpload: {
        1: null,
        2: null,
      },
      showMenu: false,
      showDropZone: false,
      x: 0,
      y: 0,
      showMailDialog: false,
      showOrderNote: false,
      isVisible: false,
      totalTriesGetThumbnail: 0,
      hoverThumbnail: false,
      unlockCard: false,
      isInvalidImage: false,
      menuListItems: [
        {
          icon: "mdi-download",
          onClick: () => this.downloadOriginalImage(1),
          label: this.task.img2
            ? "Download Original Image Left"
            : "Download Original Image",
        },
        {
          icon: "mdi-download",
          onClick: () => this.downloadOriginalImage(2),
          label: "Download Original Image Right",
          hide: !this.task.img2,
        },
        {
          icon: "mdi-close-circle",
          onClick: () =>
            this.cancelTask({
              task: this.task,
            }),
          label: "Cancel Task",
        },
        {
          icon: "mdi-email-outline",
          onClick: () => {
            this.showMailDialog = true;
          },
          label: "Send Mail",
        },
        {
          icon: "mdi-email-search-outline",
          onClick: this.searchOrderInMails,
          label: "Search For Response",
        },
        {
          icon: "mdi-clipboard-arrow-right",
          onClick: this.copyOrderTrackingUrl,
          label: "Copy Order Tracking URL",
        },
        {
          icon: "mdi-alpha-t-box",
          onClick: this.openInTeeInBlue,
          label: "Open In TeeInBlue",
        },
        {
          icon: "mdi-alert-decagram",
          onClick: this.markAsInvalid,
          label: "Mark Image as Invalid",
        },
      ],
    };
  },
  watch: {
    isVisible() {
      this.getTemplate();
    },
  },
  computed: {
    ...mapGetters(["allUsers"]),
    upscaleIndicatorText() {
      let indicatorText = "";

      if (this.useUpscaledImageUltra[1] || this.useUpscaledImage[1]) {
        indicatorText += "U1";
      }
      if (this.useUpscaledImageUltra[2] || this.useUpscaledImage[2]) {
        indicatorText += " U2";
      }
      return indicatorText;
    },
    taskWithTemplates() {
      return {
        ...this.task,
        templateThumb: this.templateThumbnail,
        template: this.template,
      };
    },
    defaultMails() {
      return this.isInvalidImage
        ? getMailTemplatesInvalidImage({
            customerName: this.task.customerFirstName,
            orderName: this.task.orderName,
            countryCode: this.task.countryCode,
            shopId: this.task.shopId,
            orderKey: this.task.orderKey,
            taskKey: this.task.taskKey,
          })
        : getMailTemplatesImport({
            customerName: this.task.customerFirstName,
            orderName: this.task.orderName,
            countryCode: this.task.countryCode,
          });
    },
    resultsInTask() {
      return this.task.productType.resultsInTask;
    },
    productTypeNotSupportedByAssignee() {
      return (
        this.selectedAssignee.uid &&
        !this.selectedAssignee?.assignedProductTypes?.includes(
          this.task.productType.id
        )
      );
    },
    referrerBackgroundColor() {
      return (
        statisticsColors?.[this.task.referrer] ||
        getRandomColor(this.task.referrer)
      );
    },
  },
  props: {
    task: {
      required: true,
    },
    selectedAssignee: {
      default: "",
    },
    selectedEditStyle: {
      default: "",
    },
    index: {
      type: Number,
      required: true,
    }
  },
  methods: {
    ...mapActions([
      "markTask",
      "importTaskFromShopify",
      "editTask",
      "cancelTask",
    ]),
    async test() {
      const customizationId = this.task.teeInBlue.customizationId;
      const res = await getArtworkValuesFromTeeInBlue(customizationId)
      console.log(res)
    },
    openTask() {
      openOrderModal(this.task.orderId)
    },
    copyOrderTrackingUrl() {
      navigator.clipboard.writeText(getTrackingUrl(this.task.countryCode, this.task.orderKey))
    },
    getImageToUse(imgIndex) {
      if (
        this.customImageUrl[imgIndex] &&
        !this.useUpscaledImageUltra[imgIndex] &&
        !this.useUpscaledImage[imgIndex]
      ) {
        return this.customImageUrl[imgIndex];
      } else if (this.useUpscaledImageUltra[imgIndex]) {
        return this.upscaledImageUltra[imgIndex];
      } else if (this.useUpscaledImage[imgIndex]) {
        return this.upscaledImage[imgIndex];
      } else {
        return imgIndex === 1 ? this.task.img : this.task.img2;
      }
    },
    async removePreview() {
      const customizationId = this.task.teeInBlue.customizationId;
      const orderName = this.task.orderName;
      const teeInBlueId = this.task.teeInBlue.teeInBlueId;
      const shopId = this.task.shopId;
      const countryCode = this.task.countryCode;
      const lineItemId = this.task.lineItemId

      if (customizationId && teeInBlueId && !this.customizationRegenerated) {
        this.isRegeneratingCustomization = true;
        await removePreviewInTeeInBlue({
          lineItemId,
          orderName,
          teeInBlueId,
          shopId,
          countryCode,
        })
          .then(() => {
            this.customizationRegenerated = true;
          })
          .catch((error) => {
            console.log(error);
            this.isRegeneratingCustomization = false;
          });
      }
    },
    async getTemplate() {
      if (this.task.productType.useTeeinblue) {
        await this.getTemplateFromTeeinblue();
      } else {
        this.templateThumbnail = this.task.templateThumb || "none";
        this.template = this.task.template || "none";
      }
    },
    async getTemplateFromTeeinblue(clearCache) {
      this.customizationRegenerated = false;
      this.isRegeneratingCustomization = true;
      if (this.totalTriesGetThumbnail < 6) {
        const fetchTemplateFromTeeinblue = httpsCallable(
          functions,
          "getTemplateFromTeeinblue"
        );

        await fetchTemplateFromTeeinblue({
          orderId: this.task.orderId,
          customizationId: this.task.teeInBlue.customizationId,
          countryCode: this.task.countryCode,
          shopId: this.task.shopId,
        })
          .then((res) => {
            this.isRegeneratingCustomization = false;
            const templateUrl = res.data;
            const baseUrl =
              templateUrl?.substring(0, templateUrl.lastIndexOf(".")) || "";
            // Adding the custom suffix
            this.templateThumbnail = baseUrl
              ? baseUrl + `_thumbnail.webp?time=${clearCache ? Date.now() : ""}`
              : "";
            this.template = templateUrl;
          })
          .catch(() => {
            this.isRegeneratingCustomization = false;
            this.totalTriesGetThumbnail = this.totalTriesGetThumbnail + 1;
            setTimeout(
              this.getTemplateFromTeeinblue,
              1000 + Math.random() * 800
            );
          });
      }
    },
    pushToDrive() {
      this.isUploading = true;
      this.importTaskFromShopify({
        task: {
          ...this.task,
          templateThumb: this.templateThumbnail,
          template: this.template,
        },
        customImage: this.customImage,
        customTemplate: this.customTemplate,
        image: this.getImageToUse(1),
        image2: this.getImageToUse(2),
        usedUpscaling: this.useUpscaledImageUltra,
        selectedAssignee: this.selectedAssignee.uid || "",
        originalImage: this.task.img,
        originalImageThumb: this.task.imgThumb,
        callBack: () => {
          this.isUploading = false;
          this.imported = true;
        },
      });
    },
    openFullSizeImage(imgIndex) {
      this.selectedTaskImageIndex = imgIndex;
      this.showSelectedTaskImage = true;
    },
    openInTeeInBlue() {
      const url =
        "https://portal.teeinblue.com/orders/?s=" +
        encodeURIComponent(this.task.orderName);
      window.open(url, "_blank");
    },
    async showUpscaledImageUltra() {
      if (!this.upscaledImageUltra[this.selectedTaskImageIndex]) {
        await this.upscaleImageUltra();
      } else {
        this.useUpscaledImageUltra[this.selectedTaskImageIndex] = true;
        this.useUpscaledImage[this.selectedTaskImageIndex] = false;
      }
    },
    async upscaleImageUltra(checkIfAlreadyUpscaled) {
      if (checkIfAlreadyUpscaled) {
        if (this.useUpscaledImageUltra[this.selectedTaskImageIndex] || this.isUpscalingUltra[this.selectedTaskImageIndex]) {
          return
        }
      }
      this.isUpscalingUltra[this.selectedTaskImageIndex] = true;
      const upscaleImageUltra = httpsCallable(functions, "upscaleImageUltra");
      const response = await upscaleImageUltra({
        imageUrl: this.getImageToUse(this.selectedTaskImageIndex),
      });

      if (response.data.success) {
        this.upscaledImageUltra[this.selectedTaskImageIndex] =
          response.data.image;
        this.useUpscaledImageUltra[this.selectedTaskImageIndex] = true;
        this.useUpscaledImage[this.selectedTaskImageIndex] = false;
      } else {
        EventBus.$emit(events.SNACKBAR, {
          message: "Could not Upscale Image! Please inform Admin.",
          type: "error",
        });
      }
      this.isUpscalingUltra[this.selectedTaskImageIndex] = false;
    },
    async showUpscaledImage() {
      if (!this.upscaledImage[this.selectedTaskImageIndex]) {
        await this.upscaleImage();
      } else {
        this.useUpscaledImage[this.selectedTaskImageIndex] = true;
        this.useUpscaledImageUltra[this.selectedTaskImageIndex] = false;
      }
    },
    async upscaleImage() {
      this.isUpscaling[this.selectedTaskImageIndex] = true;
      const upscaleImageUltra = httpsCallable(functions, "upscaleImageDecent");
      const response = await upscaleImageUltra({
        imageUrl: this.getImageToUse(this.selectedTaskImageIndex),
      });

      if (response.data.success) {
        this.upscaledImage[this.selectedTaskImageIndex] =
          response.data.img_upscaled;
        this.useUpscaledImage[this.selectedTaskImageIndex] = true;
        this.useUpscaledImageUltra[this.selectedTaskImageIndex] = false;
      } else {
        EventBus.$emit(events.SNACKBAR, {
          message: "Could not Upscale Image! Please inform Admin.",
          type: "error",
        });
      }
      this.isUpscaling[this.selectedTaskImageIndex] = false;
    },
    onMailSent() {
      if (this.isInvalidImage) {
        this.markTask({uid: this.task.uid, autoReopen: true});
        this.isInvalidImage = false;
      }
    },
    onMailClose() {
      this.isInvalidImage = false;
      this.showMailDialog = false;
    },
    markAsInvalid() {
      this.markTask({uid: this.task.uid});
    },
    openInvalidMail() {
      if (this.task.isMarked) {
        this.markTask({uid: this.task.uid});
      } else {
        this.isInvalidImage = true;
        this.showMailDialog = true;
      }
    },
    async downloadAll() {
      await this.downloadOriginalImage(1);
      if (this.task.img2) {
        await this.downloadOriginalImage(1);
      }
    },
    downloadOriginalImage(imageIndex) {
      const imageToDownload = this.getImageToUse(imageIndex);
      const fileName = `${this.index}_${this.task.orderName}_E${this.selectedEditStyle.value}`;
      downloadImageFromUrl(
        imageToDownload,
        fileName,
        (progress) => (this.downloadProgressResultImage = progress)
      );
    },
    onRightClick(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    handleTemplateUpload() {
      if (this.customTemplate) {
        this.$refs.fileInputTemplate.value = ""; // Reset the file input
        this.customTemplate = null;
        this.customTemplateUrl = null;
      } else {
        this.$refs.fileInputTemplate.click();
      }
    },
    handleUpload(imgIndex) {
      if (this.customImage[imgIndex]) {
        this.deleteFile(imgIndex);
      } else {
        this.uploadFile(imgIndex);
      }
    },
    uploadFile(imgIndex) {
      this.selectedTaskImageIndex = imgIndex;
      this.$refs[`fileInput${imgIndex}`].click();
    },
    onFileChangeTemplate(event) {
      const file = event.target.files[0];
      if (file) {
        this.customTemplate = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.customTemplateUrl = e.target.result;
          // Do something with the file, like uploading to the server if needed
        };
        reader.readAsDataURL(file);
      } else {
        this.customTemplate = null;
        this.customTemplateUrl = null;
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.customImage[this.selectedTaskImageIndex] = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.customImageUrl[this.selectedTaskImageIndex] = e.target.result;
          // Do something with the file, like uploading to the server if needed
        };
        reader.readAsDataURL(file);
      } else {
        this.customImage[this.selectedTaskImageIndex] = null;
        this.customImageUrl[this.selectedTaskImageIndex] = null;
      }
    },
    deleteFile(imgIndex) {
      this.$refs[`fileInput${imgIndex}`].value = ""; // Reset the file input
      this.customImageUrl[imgIndex] = null;
      this.customImage[imgIndex] = null;
      // Perform any additional cleanup or actions needed when deleting the file
    },
    dropFile(e, imgIndex) {
      e.preventDefault();
      this.showDropZone = false;
      let droppedFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      if (droppedFiles) {
        this.customImage[imgIndex] = droppedFiles[0];
        this.useCustomUpload[imgIndex] = true;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.customImageUrl[imgIndex] = e.target.result;
          // Do something with the file, like uploading to the server if needed
        };
        reader.readAsDataURL(droppedFiles[0]);
      }
    },
    searchOrderInMails() {
      window.open(
        `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${
          this.task.orderName.split("#")[1]
        }`,
        "_blank"
      );
    },
    saveOrderNote({ orderNote, orderNoteFiles }) {
      this.editTask({
        task: { ...this.task, orderNote: orderNote, orderNoteFiles },
      });
    },
  },
  created() {
    EventBus.$on(`${events.ULTRA_UPSCALE_BY_ID}${this.task.uid}`, () => this.upscaleImageUltra(true))
    EventBus.$on(`${events.REMOVE_TEMPLATE_PREVIEW_BY_ID}${this.task.uid}`, this.removePreview)
  }
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  overflow: hidden;

  .v-card__text {
    padding: 0;
  }
}

.card-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(115, 115, 115, 0.7);
}

.card-header-function {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .v-btn {
    margin: 12px;
  }
}

.image-container {
  position: relative;
}

.image {
  cursor: pointer;
  z-index: 1;
}

.watermark-preview {
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  position: absolute;
  z-index: 2;
  transform: rotate(-45deg);
  bottom: 50%;
  pointer-events: none;
  color: rgba(100, 99, 99, 0.88);
  display: flex;
  align-items: center;
  background: white;
  width: fit-content;
  border-radius: 5px;
  padding: 0 5px;
}

.task-referrer {
  padding: 6px;
  border-radius: 5px;
  position: absolute;
  color: white;
  font-weight: bold;
  max-width: 50%;
  bottom: 78px;
  margin: 10px;
  z-index: 2;
}

.task-product-name {
  padding: 6px;
  border-radius: 5px;
  position: absolute;
  background: rgba(0, 0, 0, 0.87);
  max-width: 50%;
  color: white;
  bottom: 10px;
  margin: 10px;
  z-index: 2;
}

.loading-template {
  position: absolute;
  z-index: 2;
}

.task-template {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  transform-origin: bottom right;
  transform: scale(1);
  transition: all 0.5s;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  min-height: 80px;

  &:hover {
    transform: scale(2);
  }
}

.teeinblue-controls {
  position: absolute;
  width: 100%;
  height: 30px;
  z-index: 10;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  .action-icon {
    font-size: 15px;
    margin-left: 1px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
  }
}

.task-template-thumbnail {
  z-index: 3;
}

.switch-upscale-container {
  z-index: 4;
  position: relative;
  bottom: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.use-upscale-indicator {
  display: flex;
  color: white;
  width: 100%;
  justify-content: center;
  font-weight: bold;
}

.import-button-container {
  display: flex;
  z-index: 3;
}
</style>
