<template>
  <div class="s-message">
    <div class="icon-container">
      <v-icon class="icon-container__main">{{ icon }}</v-icon>
      <v-icon
        :color="ticketData.pending ? 'grey' : 'success'"
        class="icon-container__status"
        >{{
          ticketData.pending ? "mdi-clock-time-eight" : "mdi-check-circle"
        }}</v-icon
      >
    </div>
    <div class="s-message-card">
      <div class="s-message-card__content">
        <div class="message" v-for="message in messages" :key="message.created">
          <div class="message__created">
            {{ getTimeFromNow(message.created) }}
          </div>
          <div class="message__text">
            {{ message.message }}
            <v-btn
              @contextmenu.prevent="() => downloadImage(path)"
              class="message__button"
              x-small
              :loading="loadingImage === index"
              @click="() => openImage(path, index)"
              small
              dark
              v-for="(path, index) in message.images"
              :key="path"
            >
              {{ path.split("/")[path.split("/").length - 1] }}
            </v-btn>
          </div>
          <div v-if="message.response" class="response">
            <div class="response__created">
              {{ message.response.username }}
              {{ getTimeFromNow(message.response.created) }}
            </div>
            <div
              class="response__text"
              @click="
                () => {
                  expandComment = !expandComment;
                }
              "
              :class="{ 'response__text--expand': expandComment }"
            >
              {{ message.response.message.replace(/<\/?[^>]+(>|$)/g, "") }}
            </div>
          </div>
        </div>
      </div>
      <div class="s-message-card__actions">
        <TooltipButton
          icon="mdi-email-search-outline"
          hover-text="Search For Answer"
          :on-click="searchOrderInMails"
        />
        <TooltipButton
          v-if="
            ticketData.pending &&
            (ticketData.type === 'damage' || ticketData.type === 'lost')
          "
          color="warning"
          icon="mdi-package"
          hover-text="Reproduce Order"
          :on-click="openReproduceDialog"
        />
        <TooltipButton
          :on-click="openMailDialog"
          color="success"
          icon="mdi-email"
          hover-text="Contact Customer"
        />
        <div class="button-divider"></div>
        <TooltipButton
          :outlined="false"
          :disabled="isResolving || !ticketData.pending"
          :loading="isResolving"
          icon="mdi-check"
          hover-text="Mark as resolved"
          color="success"
          :holding-time="1000"
          :on-click="resolve"
        ></TooltipButton>
        <TooltipButton
          :outlined="false"
          :disabled="isMarkingAsPending || ticketData.waiting"
          :loading="isMarkingAsPending"
          icon="mdi-clock-fast"
          hover-text="Move To Waiting"
          color="success"
          :on-click="markAsPending"
        ></TooltipButton>
      </div>
    </div>
    <OrderMailDialog
      v2
      :on-sent="saveResponseToTicket"
      :customer-message="lastMessage"
      v-if="showMailDialog"
      :show="showMailDialog"
      :on-close="closeMailDialog"
      :orderId="ticket.orderUid"
      mail-category="SUPPORT"
    />
    <ReproduceOrderDialog
      v-if="showReproduceDialog"
      :order-id="ticket.orderUid"
      :show="showReproduceDialog"
      :on-close="closeReproduceDialog"
    />
    <ImageDialog
      v-if="showImageDialog"
      :image="imageToShow"
      :show="showImageDialog"
      :on-close="() => (showImageDialog = false)"
    />
  </div>
</template>

<script>
import {
  downloadImageFromUrl,
  getDateFromNow,
  getDownloadUrlFromPath,
  openOrderModal,
} from "@/utils";
import { mailTemplateTypes } from "@/content/mails";
import OrderMailDialog from "@/components/OrderMailDialog";
import ImageDialog from "@/components/ImageDialog";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../../fb";
import TooltipButton from "@/components/TooltipButton";
import ReproduceOrderDialog from "@/components/FulfillOrders/components/ReproduceOrderDialog";
import { mapGetters } from "vuex";

export default {
  name: "SupportMessage",
  components: {
    ReproduceOrderDialog,
    TooltipButton,
    ImageDialog,
    OrderMailDialog,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    ticketData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageToShow: null,
      showImageDialog: false,
      loadingImage: null,
      showMailDialog: false,
      showReproduceDialog: false,
      isResolving: false,
      isMarkingAsPending: false,
      expandComment: false,
    };
  },
  methods: {
    openOrder(uid) {
      openOrderModal(uid);
    },
    async resolve() {
      this.isResolving = true;
      const supportDoc = doc(db, "support", this.ticket.orderUid);
      await setDoc(
        supportDoc,
        {
          data: {
            [this.ticketData.type]: {
              pending: false,
              waiting: false,
            },
          },
        },
        { merge: true }
      );
      setTimeout(() => {
        this.isResolving = false;
      }, 5000);
    },
    async markAsPending() {
      this.isMarkingAsPending = true;
      const supportDoc = doc(db, "support", this.ticket.orderUid);
      await setDoc(
        supportDoc,
        {
          data: {
            [this.ticketData.type]: {
              waiting: true,
              pending: true,
            },
          },
        },
        { merge: true }
      );
      setTimeout(() => {
        this.isMarkingAsPending = false;
      }, 5000);
    },
    async saveResponseToTicket({ mail }) {
      const supportDoc = doc(db, "support", this.ticket.orderUid);
      const supportRes = await getDoc(supportDoc);
      const support = supportRes.data();

      const response = mail.message;
      const supportMessages = [...support.data[this.ticketData.type].messages];
      const sMessagesLastIndex = supportMessages.length - 1;
      supportMessages[sMessagesLastIndex].response = {
        created: new Date().toISOString(),
        message: response,
        username: this.currentUser.username || "?",
      };

      await setDoc(
        supportDoc,
        {
          data: {
            [this.ticketData.type]: {
              messages: supportMessages,
            },
          },
        },
        { merge: true }
      );
    },
    async openImage(path, index) {
      this.loadingImage = index;
      this.imageToShow = await getDownloadUrlFromPath(path);
      this.loadingImage = null;
      this.showImageDialog = true;
    },
    searchOrderInMails() {
      const searchUrl = `https://mail.google.com/mail/u/?authuser=lisa@liebespferd.com&tab=wm#search/%23${this.ticket.email}`;

      // Specify the size and position of the new window
      const windowFeatures = "width=1024,height=768,left=100,top=50";

      // Open the new window
      window.open(searchUrl, "_blank", windowFeatures);
    },
    closeMailDialog() {
      this.showMailDialog = false;
    },
    openMailDialog() {
      this.showMailDialog = true;
    },
    openReproduceDialog() {
      this.showReproduceDialog = true;
    },
    closeReproduceDialog() {
      this.showReproduceDialog = false;
    },
    getTimeFromNow(date) {
      return getDateFromNow(date);
    },
    async downloadImage(path) {
      const url = await getDownloadUrlFromPath(path);
      await downloadImageFromUrl(url, this.ticket.orderName + "_support");
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    timeAgo() {
      return getDateFromNow(this.ticket.created);
    },
    messages() {
      return this.ticketData.messages;
    },
    lastMessage() {
      return this.messages?.[this.messages.length - 1]?.message || "";
    },
    icon() {
      switch (this.ticketData.type) {
        case "message":
          return "mdi-mail";
        case "damage":
          return "mdi-package-variant";
        case "lost":
          return "mdi-close-box";
        default:
          return "mdi-border-none-variant";
      }
    },
    mailTemplateType() {
      switch (this.ticketData.type) {
        case "message":
          return mailTemplateTypes.SUPPORT;
        case "damage":
          return mailTemplateTypes.SUPPORT_DAMAGE;
        case "lost":
          return mailTemplateTypes.SUPPORT;
        default:
          return mailTemplateTypes.SUPPORT;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.s-message {
  display: flex;
  align-items: center;
  gap: 12px;

  &__title {
  }
}

.button-divider {
  border: 0.5px solid #9f9f9f;
  min-height: 100%;
  margin-left: 6px;
  margin-right: 6px;
}

.icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &__status {
    position: absolute;
    z-index: 2;
    margin-top: 20px;
    margin-left: 20px;
  }
}

.s-message-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  border-radius: 5px;
  width: 100%;
  gap: 12px;
  padding: 6px 12px;

  &__content {
    width: 100%;
  }
  &__actions {
    display: flex;
    gap: 6px;
  }
}

.message {
  border-radius: 5px;
  padding: 6px;

  &:not(:first-child) {
    margin-top: 6px;
  }

  &__created {
    font-size: 12px;
    font-weight: lighter;
  }

  &__text {
    font-size: 18px;
  }

  &__button {
    margin-left: 3px;
    padding: 3px 6px !important;
    height: 22px !important;
    background-color: #778794 !important;

    span {
      font-size: 16px !important;
    }
  }
}

.response {
  text-align: right;
  margin: 5px;
  &__created {
    font-size: 12px;
    font-weight: lighter;
  }

  &__text {
    font-size: 18px;
    cursor: pointer;
    line-height: 1.2em; /* Adjust this based on your design needs */
    max-height: 2.4em; /* 2 times the line-height, to display 2 lines of text */
    min-height: 2.4em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    &--expand {
      max-height: 100%;
      overflow: scroll;
      display: -webkit-box;
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
      text-overflow: unset;
    }
  }
}
</style>
