<template>
  <v-btn class="account-activator" @click="setAccountSidebarExpanded(true)">
    <div class="d-none d-md-block">
      {{ currentUser.username }}
    </div>
    <v-icon class="pl-3">mdi-account</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MainAccount",
  data() {
    return {
      accountSidebar: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapMutations(["setAccountSidebarExpanded"]),
  },
};
</script>

<style lang="scss" scoped></style>
