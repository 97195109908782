<template>
  <div class="donut-chart">
    <div class="donut-chart-container">
      <canvas ref="donutChart"></canvas>
      <div class="donut-total">{{ parsedTotal }} {{ unit }}</div>
    </div>
    <div class="legend">
      <div v-for="(item, index) in data" :key="index" class="legend-item">
        <span
          class="legend-color"
          :style="{ backgroundColor: item.color }"
        ></span>
        {{ item.label }}: {{ convertToDecimalNumber(item.count) }} {{ unit }}
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import {getDecimalNumberString} from "@/utils"; // Import Chart.js properly

export default {
  name: "DonutChart",
  props: {
    data: Array,
    unit: {
      default: "",
    },
    fraction: {
      default: 0,
    },
    difference: Object,
  },
  data() {
    return {
      total: 0,
    };
  },
  computed: {
    parsedTotal() {
      return getDecimalNumberString(this.total, this.fraction)
    }
  },
  mounted() {
    this.renderDonutChart();
  },
  methods: {
    convertToDecimalNumber(number) {
      return getDecimalNumberString(number, this.fraction)
    },
    renderDonutChart() {
      const ctx = this.$refs.donutChart.getContext("2d");

      // Calculate initial total
      this.total = this.data.reduce((acc, item) => acc + item.count, 0);

      const chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: this.data.map((item) => item.label),
          datasets: [
            {
              data: this.data.map((item) => item.count),
              backgroundColor: this.data.map((item) => item.color),
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false, // Hide the legend for cleaner display
            },
            arc: {
              borderWidth: 2, // Border width for all segments
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.label || ""; // Get the original label
                  const value = context.parsed || 0; // Get the numeric value
                  const formattedValue = getDecimalNumberString(value, 2)
                  return `${label}: ${formattedValue} ${this.unit}`; // Add "€" to the value
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.donut-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .title {
    font-size: 24px;
    text-transform: uppercase;
  }
}

.donut-chart-container {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}

.donut-total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  pointer-events: none;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow content to wrap into multiple lines */
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 12px;
  white-space: nowrap; /* Prevent text from breaking */

  @include sm() {
    font-size: 8px;
  }
}

.legend-color {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
</style>
