<template>
  <div>
    <v-tabs center-active class="tabber">
      <v-tab link to="/manager">Users</v-tab>
      <v-tab link to="/manager/product-settings">Products</v-tab>
      <v-tab link to="/manager/statistics">Statistics</v-tab>
      <v-tab link to="/manager/mockup-settings">Mockups</v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AdminView",
};
</script>

<style lang="scss" scoped>
.tabber {
  margin-bottom: 12px;
}
</style>
