<template>
  <div class="login-container">
    <v-card dark width="500" max-width="100%">
      <v-card-title>
        <div class="logo-container">
          <v-img
            alt="DriveConnector Logo"
            class="shrink mr-2"
            contain
            :src="require('../assets/logo.png')"
            transition="scale-transition"
            width="200"
          />
        </div>
      </v-card-title>
      <v-card-text>
        <v-text-field label="Email" outlined v-model="email"></v-text-field>
        <v-text-field
          label="Password"
          outlined
          type="password"
          v-model="password"
          v-on:keyup.enter="login"
        ></v-text-field>
        <v-btn
          width="100%"
          height="56px"
          :disabled="loading"
          :loading="loading"
          @click="login"
          >Login</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { EventBus, events } from "../../event-bus";

export default {
  name: "AppLogin",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    login() {
      this.loading = true;
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          EventBus.$emit(events.SNACKBAR, {
            message: this.getErrorMessage(error),
            type: "error",
          });
          this.loading = false;
        });
    },
    getErrorMessage(error) {
      switch (error.code) {
        case "auth/wrong-password":
          return "The Password is wrong";
        case "auth/user-not-found":
          return "The email was not found";
        default:
          return error.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
}
</style>
