<template>
  <MainDialog :dark="false" :show="show">
    <v-card>
      <v-card-title>
        Share Rating with {{artistName}}
      </v-card-title>
      <v-card-text>
        <v-textarea :auto-grow="true" :disabled="isSharing" v-model="comment" label="Translated Comment" />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close">cancel</v-btn>
        <v-spacer/>
        <v-btn :loading="isSharing" :disabled="isSharing" @click="share" color="success">Share with {{artistName}}</v-btn>
      </v-card-actions>
    </v-card>
  </MainDialog>
</template>

<script>
import MainDialog from "@/components/MainDialog";
import {mapGetters} from "vuex";
import {doc, setDoc} from "firebase/firestore";
import {db} from "../../../fb";
export default {
  name: "ShareRatingDialog",
  components: {MainDialog},
  data() {
    return {
      artistName: "",
      comment: "",
      isSharing: false
    }
  },
  props: {
    rating: {
      require: true,
      type: Object
    },
    show: {
      required: true,
      type: Boolean,
    },
    close: {
      required: true,
    }
  },
  computed: {
    ...mapGetters(["userNameIdMap"])
  },
  created() {
    const user = this.userNameIdMap.find(user => user.uid === this.rating.artistId)
    this.artistName =  user?.username || 'unknown'
    this.comment = this.rating.comment
  },
  methods: {
    async share() {
      this.isSharing = true
      await setDoc(doc(db, `tasks`, this.rating.taskUid), {
        feedback: {
          shared: true,
        }
      }, { merge: true })

      await setDoc(doc(db, `ratings`, this.rating.taskUid), {
        ...this.rating,
        result: null,
        result2: null,
        comment: this.comment
      })
      this.$emit('shared')
      this.close()
      this.isSharing = false
    }
  }
}
</script>

<style scoped>

</style>
