<template>
  <v-dialog dark persistent width="500" max-width="100%" v-model="showDialog">
    <v-card dark>
      <v-card-title>Enter New TeeInBlue Bearer</v-card-title>
      <v-card-text>
        <v-textarea
          label="Bearer Token (eyJ0...)"
          dark
          outlined
          v-model="newBearerToken"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-spacer />
        <v-btn
          @click="save"
          :disabled="isSaving"
          :loading="isSaving"
          color="success"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TeeInBlueBearerDialog",
  data() {
    return {
      newBearerToken: "",
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters(["showDialogTeeInBlueBearer"]),
    showDialog: {
      get() {
        return this.showDialogTeeInBlueBearer;
      },
      set(newValue) {
        this.setShowDialogTeeInBlueBearer(newValue);
      },
    },
  },
  methods: {
    ...mapMutations(["setTeeInBlueBearer", "setShowDialogTeeInBlueBearer"]),
    async save() {
      this.isSaving = true;
      await this.setTeeInBlueBearer(this.newBearerToken);
      this.isSaving = false;
      this.showDialog = false;
    },
  },
};
</script>

<style scoped></style>
